const translations = {
    messageEmailInvalid: "Ops, email invalid",
    placeHolderEmail: "Email...",
    label_userBlocked: "This user {{name}} is blocked,to unblock contact ADMIN",
    label_userDeactivated: "This user is deactivated",
    placeHolderPassword: "Password...",
    labelForgotPassword: "Forgot Password",
    checkBoxKeepSigned: "Keep me signed in",
    required_password_valid: "The password must be between 8 and 25 characters long, contain at least: one lowercase letter, one uppercase letter and one number.",
}
export default translations;