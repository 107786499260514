import React, { Component, Fragment } from "react";
import PickerLogo from "components/pickerLogo";
import CustomButton from "components/customButton";
import CustomError from "components/customErrorBox";
import * as services from "redux/actions";
import { Formik, Form, Field } from "formik";
import CustomInputComponent from "helper/formikComponents/customInput";
import CustomCheckBox from "components/customCheckbox";
import checkBoxImage from "assets/img/check_system_icn.png";
import "./index.scss";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import SignupSchema from "./validations";
import CustomLabel from "components/customLabel";
import placeHolderCompanyImage from "assets/img/wizard/place_holder_company_header2x.png";
import FormikEffect from "components/formikEffect";
import { connect } from "react-redux";
import phoneCode from "helper/phoneCode";
import $ from "jquery";
import mask from "jquery-mask-plugin";
import Loading from "components/loading";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter,
} from "rxjs/operators";

class AddSite extends Component {
  state = {
    triedSave: false,
    phone_code: "",
    diseases: [],
    zip_code: "",
    zip_search_error: false,
    getCep: false,
  };

  componentWillUnmount() {
    window.setFieldError = null;
    window.setFieldValue = null;
  }
  componentWillMount = async () => {
    if (!this.props.diseases || this.props.diseases.length == 0) {
      const diseases = await this.props.getDiseases();
      return this.setState({
        diseases,
      });
    }
    this.setState({ diseases: this.props.diseases });
  };

  fetchCode = (zip_code) => {
    this.setState({ showLoading: true });

    let result = {};

    this.props
      .getAddresses(zip_code, this.props.i18n.language)
      .then((data) => {
        result = data;
        if (Object.keys(data).length == 0) {
          window.setFieldValue("place", "");
          window.setFieldValue("city", "");
          window.setFieldValue("state", "");
          window.setFieldValue("country", "");
          window.setFieldValue("address", "");
          window.setFieldValue("short_name", "");
          return window.setFieldError(
            "zip_code",
            this.props.t("label_ZipInvalid")
          );
        }
        const newPlace = `${data.city}, ${data.state}, ${data.country}`;
        window.setFieldValue("zip_code", data.zipcode);
        window.setFieldValue("place", newPlace);
        window.setFieldValue("city", data.city);
        window.setFieldValue("state", data.state);
        window.setFieldValue("country", data.country);
        window.setFieldValue("short_name", data.short_name);
        window.setFieldValue("latitude", data.latitude);
        window.setFieldValue("longitude", data.longitude);
        window.setFieldValue("address", data.street);
        const code = phoneCode.find((country) => country.name == data.country);

        const phone = $("contryArea").val();
        if (code != phone) {
          window.setFieldValue("phone_code", code.dial_code);
        }

        return this.setState({
          zip_code: data.zipcode,
        });
      })
      .finally(() => {
        this.setState({
          showLoading: false,
          zip_search_error: Object.keys(result).length == 0,
        });
      });
  };

  componentDidMount() {
    const props = this.props;

    $(document).ready(() => {
      const searchBox = document.getElementsByClassName("zip_code");
      if (!searchBox.length) return false;

      const keyup$ = fromEvent(searchBox, "focusout");

      keyup$
        .pipe(
          map((i) => {
            return i.currentTarget.value;
          }),
          filter((val) => !!val && val.length >= 3)
        )
        .subscribe((value) => this.fetchCode(value));

      $(".contryArea").mask("+ZZZ", {
        translation: {
          Z: {
            pattern: /[0-9]/,
            optional: true,
          },
        },
      });
      switch (props.i18n.language) {
        case "ptBR":
          $(".phone_number_modal").mask("(99) 9999-9999Z", {
            translation: {
              Z: {
                pattern: /[0-9]/,
                optional: true,
              },
            },
          });
          $(".zip_code").mask("99999-999");
          break;
        case "en":
          $(".phone_number_modal").mask("(999) 999-9999");
          $(".zip_code").mask("9999Z", {
            translation: {
              Z: {
                pattern: /[0-9]/,
                optional: true,
              },
            },
          });
      }
    });
  }

  getInitialValues = () => {
    if (!this.props.data)
      return {
        name: this.props.site ? this.props.site.name : "",
        country: null,
        zip_code: "",
        phone_code: "+",
        address: "",
        disease_types: [],
        phone_number: "",
        place: "",
        places: [],
        project_type: null,
        city: null,
        state: null,
        logo_filename: "",
        logo: "",
        eds_status: null,
      };

    return {
      eds_status: this.props.data.eds_status,
      latitude: this.props.data.latitude,
      longitude: this.props.data.longitude,
      short_name: this.props.data.short_name,
      logo:
        this.props.data.logo && this.props.data.logo.length > 0
          ? this.props.data.logo
          : "",
      logo_filename:
        this.props.data.logo_filename &&
        this.props.data.logo_filename.length > 0
          ? this.props.data.logo_filename
          : "",
      name: this.props.data.name,
      country: this.props.data.country,
      zip_code: this.props.data.zip_code ? this.props.data.zip_code : "",
      phone_code: this.props.data.phone_code,
      address: this.props.data.address,
      disease_types: this.props.data.disease_types,
      phone_number: this.props.data.phone_number,
      place: !!this.props.data.city
        ? `${this.props.data.city} ${this.props.data.state} ${this.props.data.country}`
        : "",
      city: this.props.data.city,
      state: this.props.data.state,
    };
  };

  validatePhoneField = (errors, touched) => {
    if (
      !errors.hasOwnProperty("phone_number") &&
      !errors.hasOwnProperty("phone_code")
    )
      return false;
    if (!touched.hasOwnProperty("phone_number")) return false;
    return true;
  };

  render() {
    return (
      <Fragment>
        <Formik
          initialValues={this.getInitialValues()}
          validationSchema={SignupSchema}
          onSubmit={(values, { setFieldError, setFieldValue }) => {
            if (this.state.zip_search_error) {
              return setFieldError(
                "zip_code",
                this.props.t("label_ZipInvalid")
              );
            }
            if (this.props.data) {
              values.id = this.props.data.id;
            }

            this.props.save(_.omit(values, ["place", "places"]));
          }}
          enableReinitialize={true}
          render={({
            errors,
            initialValues,
            setFieldValue,
            setFieldTouched,
            values,
            touched,
            handleBlur,
            setFieldError,
          }) => {
            const { t } = this.props;
            window.setFieldValue = setFieldValue;
            window.setFieldError = setFieldError;
            return (
              <Form className="divStep1">
                <FormikEffect onChange={(newValues, oldValues) => ""} />
                <div className="divContainer">
                  <PickerLogo
                    img={placeHolderCompanyImage}
                    token={this.props.token}
                    setFile={({filename}) =>
                      setFieldValue("logo_filename", filename)
                    }
                    file={values.logo}
                  />
                  <div className={"displayFlex"}>
                    <div className={"w30"}>
                      <Field
                        name="name"
                        errorMessage={t("inputRequiredField")}
                        component={CustomInputComponent}
                        placeHolder={t("label_SiteName") + `*`}
                        label={t("label_SiteName") + `*`}
                        readOnly={
                          !!this.props.fieldsReadOnly &&
                          this.props.fieldsReadOnly.includes("name")
                        }
                      />
                    </div>
                    <div className={"w30 heightDefault"}>
                      <Field
                        name="zip_code"
                        component={CustomInputComponent}
                        max={10}
                        placeHolder={t("placeHolderInputZip")}
                        label={t("labelInputZip")}
                        errorMessage={
                          errors["zip_code"] == t("label_ZipInvalid")
                            ? t("label_ZipInvalid")
                            : t("inputRequiredField")
                        }
                        inputClass="zip_code"
                      />
                    </div>
                    <div className={"w30"}>
                      <Field
                        readOnly={
                          this.state.showLoading ||
                          this.state.zip_search_error ||
                          !values.zip_code.length
                        }
                        name="place"
                        errorMessage={t("inputRequiredField")}
                        component={CustomInputComponent}
                        max={100}
                        placeHolder={t("Fill the zip code.")}
                        label={t("LOCATION *")}
                      />
                    </div>
                  </div>
                  <div className={"displayFlex"}>
                    <div className={"w30"}>
                      <Field
                        readOnly={
                          this.state.showLoading ||
                          this.state.zip_search_error ||
                          !values.zip_code.length
                        }
                        name="address"
                        errorMessage={t("inputRequiredField")}
                        component={CustomInputComponent}
                        placeHolder={t("Fill the zip code.")}
                        label={t("labelInputAddress")}
                      />
                    </div>
                    <div className={"w30"}>
                      <CustomLabel label={t("labelInputPhoneNumber")} />
                      <div className="displayFlex">
                        {/* <div className='' */}
                        <Field
                          name="phone_code"
                          component={CustomInputComponent}
                          placeHolder={values.phone_code}
                          inputClass="contryArea"
                        />
                        <Field
                          name="phone_number"
                          component={CustomInputComponent}
                          inputClass={"phone_number_modal"}
                        />
                      </div>
                      {!!this.validatePhoneField(errors, touched) && (
                        <CustomError errorMessage={t("inputRequiredField")} />
                      )}
                    </div>
                    <div className={"w30 displayFlex d-column jc-even"}>
                      <CustomLabel label={t("label_TestTypes")} />
                      {this.state.diseases.map((e) => (
                        <div className="displayFlex ali-center">
                          <Field
                            disabled={
                              this.props.fieldsReadOnly &&
                              this.props.fieldsReadOnly.includes(
                                "disease_types"
                              )
                            }
                            className="inputTest"
                            name={"diseaseSelected"}
                            bgColorInternal={(() => {
                              if (
                                this.props.fieldsReadOnly &&
                                this.props.fieldsReadOnly.includes(
                                  "disease_types"
                                )
                              ) {
                                return "#d3d3d3";
                              }
                              return !!values.disease_types.find(
                                (event) => event == e.id
                              )
                                ? "#6BC38C"
                                : "";
                            })()}
                            component={CustomCheckBox}
                            imgInternal={checkBoxImage}
                            active={
                              !!values.disease_types.find(
                                (event) => event == e.id
                              )
                            }
                            action={() => {
                              if (
                                this.props.fieldsReadOnly &&
                                this.props.fieldsReadOnly.includes(
                                  "disease_types"
                                )
                              ) {
                                return false;
                              }
                              setFieldTouched("disease_types", true);
                              setFieldValue(
                                "disease_types",
                                values.disease_types.includes(e.id)
                                  ? values.disease_types.filter(
                                      (vl) => vl !== e.id
                                    )
                                  : [...values.disease_types, e.id]
                              );
                            }}
                          />
                          <CustomLabel
                            label={e.name}
                            className="testlabel m0"
                          />
                        </div>
                      ))}
                      {errors.disease_types && this.state.triedSave && (
                        <CustomError errorMessage={t("inputRequiredField")} />
                      )}
                    </div>
                  </div>
                  <div className={"displayFlex justify-space-beetwen"}>
                    {this.props.userData &&
                      this.props.userData.status !== "R" &&
                      !this.props.showCancel && (
                        <div
                          className={"w30 textRight heigthDefault btn-step1"}
                        >
                          <CustomButton
                            type={"button "}
                            className={"buttonCancel"}
                            value={t("label_Cancel")}
                            handleChange={this.props.handleChangePrev}
                          />
                        </div>
                      )}
                    <div className={"w30 textRight heigthDefault btn-step1"}>
                      {values.disease_types &&
                        values.disease_types.length > 0 &&
                        !_.isEqual(initialValues, values) && (
                          <CustomButton
                            tabIndex="0"
                            type={"button "}
                            className={`${
                              !!this.state.zip_search_error ||
                              Object.keys(errors).length > 0 ||
                              this.state.showLoading
                                ? "buttonCancel ci"
                                : "buttonNext"
                            } submit`}
                            value={t("label_Save")}
                            handleChange={() =>
                              this.setState({ triedSave: true })
                            }
                          />
                        )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  id: store.UserReducer.userData.user_id,
  user: store.UserReducer.userData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAddresses: (zip_code, language) =>
      dispatch(services.getAddressByZipCode(zip_code, language)),
    getDiseases: () => dispatch(services.getDiseases()),
  };
};

AddSite = connect(mapStateToProps, mapDispatchToProps)(AddSite);

export default withTranslation()(AddSite);
