import React, { Component, Fragment } from "react";
import arrowDown from "assets/img/dropdown_icon.png";
import "./index.scss";
import closeImage from "assets/img/close_invite_user_bg_icn.png";
import CustomErrorBox from "components/customErrorBox";
import onClickOutside from "react-onclickoutside";

class CustomSelectMultiple extends Component {
  _openOptionsList = () => {
    if (this.props.options.length > 1) {
      this.setState({ open: !this.state.open });
    }
  };

  _selectOption = (option) => {
    if (!option.id) return false;

    const wasSelected = this.props.values.find((op) => op.id === option.id);

    if (!!wasSelected && this.props.values.length == 1) return false;

    const newValues = wasSelected
      ? this.props.values.filter((value) => value.id !== option.id)
      : [...this.props.values, option];

    this.props.setFieldValue(this.props.field.name, newValues);
  };

  handleClickOutside = () => {
    if (this.state.open) {
      this.setState({
        open: false,
      });
    }
  };

  state = {
    open: false,
  };

  static defaultProps = {
    options: [],
    selected: [],
  };

  render() {
    const { open } = this.state;
    const {
      field,
      errorMessage,
      values,
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    } = this.props;
    return (
      <Fragment>
        <div
          className={`select-formik-multiple-container ${
            errorMessage && touched[field.name] && errors[field.name]
              ? "error"
              : ""
            } `}
        >
          {this.props.leftIcon && <img src={this.props.leftIcon} />}
          <div className="values-container" onClick={() => this.setState({ open: true })}>
            {!values || !values.length ? (
              <div className={``}>
                {this.props.placeHolder ? this.props.placeHolder : "Select ..."}
              </div>
            ) : (
                values.map((item) => (
                  <div className={`value-item`}>
                    <div className="selectedopt">{item.value}</div>
                    {this.props.values.length > 1 &&
                      !this.props.disableRemove && (
                        <img
                          className={"cp"}
                          src={closeImage}
                          onClick={() => this._selectOption(item)}
                        />
                      )}
                  </div>
                ))
              )}
          </div>
          {
            this.props.options && this.props.options.length > 1 &&
            <img
              onClick={() => this._openOptionsList()}
              src={this.props.arrow || arrowDown}
              className="arrow-image cp"
              style={open ? { transform: "rotate(180deg)" } : {}}
            />
          }
          {this.props.options && this.props.options.length > 1 && open && (
            <>
              <div className={"opt-container"}>
                {this.props.options.map((option) => (
                  <div
                    className="opt cp"
                    onClick={() => this._selectOption(option)}
                  >
                    {option.value}
                  </div>
                ))}
              </div>
            </>
          )}
          {errorMessage && touched[field.name] && errors[field.name] && (
            <CustomErrorBox errorMessage={errorMessage} />
          )}
        </div>
      </Fragment>
    );
  }
}

export default onClickOutside(CustomSelectMultiple);
