import React, { Component, Fragment} from "react";
import PropTypes from "prop-types";
import "./index.scss";

class CustomTestCard extends Component {
  static propTypes = {
    prop: PropTypes
  };

  static defaultProps = {
    customTestCardClass: "customTestCard",
    customTestCardLeftValue: "customTestCardLeftValue",
    customTestRightValue: "customTestRightValue",
    leftValue: null
  };

  render() {
    let imageBg = this.props.image?this.props.image:'';
    let boxWidth = this.props.boxWidth?'w'+this.props.boxWidth:'w25';
    return (
      <div className={'padding-card-test '+boxWidth} style={{backgroundImage: 'url("'+imageBg+'")', backgroundRepeat: 'no-repeat', cursor: 'pointer'}}>
        <div className={"customTestCard " + this.props.customTestCardClass} onClick={this.props.onClick}>
          {this.props.leftValue != null && (
            <div
              className={
                "customTestCardLeftValue " + this.props.customTestCardLeftValue
              }
            >
              {this.props.leftValue}
            </div>
          )}
          {this.props.rightValue && (
            <Fragment>
              <div
                className={
                  "customTestRightValue " + this.props.customTestRightValue
                }
              >
                <div>{this.props.rightValue}</div>
                {this.props.rightSubValue && (
                  <div>
                    {this.props.rightSubValue}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default CustomTestCard;
