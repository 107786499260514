const translations = {
    title_SystemAdminTools_SiteManagement: "SITE MANAGEMENT",
    title_SystemAdminTools_DeviceManagement: "DEVICE MANAGEMENT",
    label_SystemAdminSiteManagement_CardStatus: "STATUS",
    label_CardPilot: "PILOT",
    label_CardDeactivated: "DEACTIVATED",
    label_SystemAdminSiteManagement_CardEdsSites: "EDS SITES",
    label_SystemAdminSiteManagement_CardEdsSubtitle: "INVITED",
    label_SystemAdminSiteManagement_CardParticipation: "PARTICIPATION",
    label_SystemAdminSiteManagement_CardParticipationFirstSubtitle: "CLINICAL TESTING",
    label_SystemAdminSiteManagement_CardParticipationStatusSubtitle: "RESEARCH PROJECT",
}

export default translations;