import _ from 'lodash';


export const getTotalPages = (devices, qty_page) => {
    return (devices.length / qty_page) % 1 != 0 ? Math.ceil(devices.length / qty_page) : Math.ceil(devices.length / qty_page)
}

export const getItemsByPage = (devices, page, qty_page) => {
    const totalPages = getTotalPages(devices, qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? devices : init + qty_page
    return (totalPages) == 1 ? devices : devices.slice(init, end);
}