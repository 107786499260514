const translations = {
    title_devices_operations: "DEVICE OPERATIONS CONSOLE",
    title_devices_utilization: "DEVICE UTILIZATION",
    label_data_sync: "DATA SYNC",
    label_data_sync_error: "SYNC ERROR",
    label_deleted: "DELETED",
    label_average_used: "AVERAGE CARTRIDGEDS USED",
    label_mobile_tests_performed: "MOBILE TESTS PERFORMED BY SITE",
    label_monthly_count: "MONTHLY COUNT OF ACTIVE DEVICES",
}

export default translations;