const translations = {
label_ReadersDiagnosticSupportCode: "CÓDIGO DE SUPORTE DE DIAGNOSTICO DO LEITOR",
title_Readers:"LEITORES",
label_Online: "ATIVO",
label_ReaderNotFound: "Leitor não encontrado",
label_StatusOk: "OK",
label_StatusNotOk: "NÃO OK",
label_HardwareVersion: "Versão do Hardware",
label_InstalledFirmwareVersion: "Versão de Firmware instalada",
label_Run: "Feitos",
label_ShippedDate: "Data de envio",
label_ShippedMethod: "Método de envio",
label_TrackingNumber: "Número de rastreio",
label_Shipped: "Enviado",
label_NotConnected: "CONFIGURAR CONEXÃO",
label_FirmwareLastUpdate: "Última atualização do sistema",
label_ReaderDetails: "DETALHES DO LEITOR",
label_UsageInPeriod: "MUDANÇA NO PERIODO",
label_RegistrationDate:"Data do registro",
label_RegistrationTime:"Hora do registro",
label_LastDataSync:"Ultima sincronização dos dados",
label_NumberOfRecords:"Número de registros",
label_ReaderSettings:"CONFIGURAÇÕES DO LEITOR",
label_UpdateNotApplied:"Update não aplicado",
label_UpdateApplied:"Update aplicado",
label_DateFormat:"Formato da data",
label_Clock:"Hora",
label_Gender:"Genero",
label_Weight:"Peso",
label_Height:"Altura",
}
export default translations;