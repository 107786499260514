import React, { Component, Fragment } from "react";
import PageContainer from "components/pageContainer/pageContainer";
import Footer from "components/footer";
import UserView from './userView';
import AdminView from './adminView';
import "./index.scss";
import { withTranslation } from 'react-i18next';
class SystemAdmin extends Component {

  isAdmin = () => {
    return this.props.router.location.pathname.includes('/admin')
  }

  render() {
    return (
      <Fragment>
        <PageContainer>
          <div className="system-admin-container">
            {this.isAdmin() ? <AdminView  {...this.props} /> : <UserView {...this.props} />}
          </div>
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }
}


export default withTranslation()(SystemAdmin);
