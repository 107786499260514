const translations = {
    title_SystemAdminTools_SiteManagement: "GERENCIAR SITES",
    title_SystemAdminTools_DeviceManagement: "GERENCIAR DISPOSITIVOS",
    label_SystemAdminSiteManagement_CardStatus: "STATUS",
    label_CardPilot: "PILOTO",
    label_CardDeactivated: "DESATIVADOS",
    label_SystemAdminSiteManagement_CardEdsSites: "EDS SITES",
    label_SystemAdminSiteManagement_CardEdsSubtitle: "CONVIDADOS",
    label_SystemAdminSiteManagement_CardParticipation: "PARTICIPAÇÃO",
    label_SystemAdminSiteManagement_CardParticipationFirstSubtitle: "TESTES CLINICOS",
    label_SystemAdminSiteManagement_CardParticipationStatusSubtitle: "RESEARCH PROJECT",
}

export default translations;