import * as SystemAdminServices from "redux/services/SystemAdmin";

const initial = {
    counters: {
        adminUsers: [],
        readers: {
            readers_count: 0
        }
    }
}

const types = {
    GET_COUNTERS_SYSTEM_ADMIN_ADMIN_PAGE: "GET_COUNTERS_SYSTEM_ADMIN_ADMIN_PAGE"
}


const SystemAdminReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.GET_COUNTERS_SYSTEM_ADMIN_ADMIN_PAGE: {
            return {
                ...state,
                counters: action.payload
            }
        }
    }
    return state
}

export const get_counters_system_admin_admin = () => async (dispatch) => {

    const counters = await SystemAdminServices.getSystemAdminCounters();
    const adminUsers = await SystemAdminServices.getAdminUsers();

    const payload = {
        ...counters,
        adminUsers
    }
    dispatch({ type: types.GET_COUNTERS_SYSTEM_ADMIN_ADMIN_PAGE, payload })
}

export default SystemAdminReducer