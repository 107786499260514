import React from "react";
import CustomCheckBox from "components/customCheckbox";
import "./style.scss";
import ArrowSort from "assets/img/arrowDown.png";
import _ from "lodash";

class CustomTable extends React.Component {
  renderRow = (rw, rk, row) => {
    const header = this.props.header[Object.keys(this.props.header)[rk]];

    return !!header && header.type == "image" ? (
      <img
        src={row[rw]}
        onClick={() => this.props.onClick(row, header.th)}
        className={header.th}
      />
    ) : (
      <div onClick={() => this.props.onClick(row, header.th)}>{row[rw]}</div>
    );
  };

  getTdContent = (rw, rk, row) => {
    if (rk == 0 && Object.keys(this.props.header)[0] == "checkbox") {
      return (
        <CustomCheckBox
          active={
            this.props.rowCheckeds.includes(row[rw]) || this.props.allChecked
          }
          action={() => this.props.rowCheckAction(row[rw])}
        />
      );
    }

    if (rw == "type" && row.childrens_name && !!row.childrens_name.length) {
      return (
        <div className={`divText`}>
          <div className="title">Satellites</div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <div className="scd-title">
              {row.childrens_name.map((children, key) => {
                console.log("row", row, row.childrens_name);
                return `${children}${
                  key + 1 - row.childrens_name.length ? ", " : ""
                } `;
              })}
            </div>
          </div>
          <div>
            <div className="div-arrow"></div>
          </div>
        </div>
      );
    }
    return this.renderRow(rw, rk, row);
  };
  renderTd = (row) => {
    return Object.keys(row).map((rw, rk) => {
      if (rk + 1 > Object.keys(this.props.header).length) {
        return null;
      }

      if (rw == "childrens_name") return null;      
      return (
        <td
          className={
            this.props.customTd && this.props.customTd.includes(rw) ? rw + " " + rk : "td" + rw
          }
        >
          <div style={{ position: "relative" }} className="columnHover">
            {this.getTdContent(rw, rk, row)}
          </div>
        </td>
      );
    });
  };

  getField = (hk) => {
    return this.props.rows && this.props.rows.length > 0
      ? Object.keys(_.omit(this.props.rows[0], "childrens_name"))[hk]
      : "";
  };

  renderSortArrow = (header, item, hk) => {
    if (!header[item].sort) {
      return false;
    }

    let arrowStyle = {
      height: "10px",
      width: "auto",
      marginLeft: "5px",
      cursor: "pointer",
      marginTop: `2px`,
    };

    if (this.props.sort_type && this.props.sort_type == "desc")
      arrowStyle = { ...arrowStyle, transform: "rotate(180deg" };

    let field = this.getField(hk);

    const arrowSort = (
      <img
        src={ArrowSort}
        style={arrowStyle}
        onClick={() => (this.props.onSort ? this.props.onSort(field) : "")}
      />
    );

    return arrowSort;
  };

  render() {
    const { header } = this.props;
    console.log("header", this.props.header);
    console.log("row", this.props.rows);
    return (
      <table class="table-default">
        <thead>
          <tr>
            {Object.keys(header).map((item, hk) => {
              return (
                <th
                  className={header[item].th ? header[item].th : ""}
                  height={50}
                >
                  <div
                    className={
                      header[item].className ? header[item].className : item
                    }
                  >
                    {item != "checkbox" && (
                      <div
                        className={
                          header[item].type == "sortCamp"
                            ? "cp"
                            : `header_item ${header[item].th}`
                        }
                        onClick={() =>
                          header[item].type == "sortCamp" &&
                          this.props.onSort(this.getField(hk))
                        }
                      >
                        {item.toUpperCase()}
                      </div>
                    )}
                    {header[item].type && header[item].type == "checkbox" && (
                      <CustomCheckBox
                        active={this.props.allChecked}
                        action={() =>
                          this.props.onClickHeader(
                            header[item],
                            hk,
                            !header[item].checked
                          )
                        }
                      />
                    )}
                    {header[item].type &&
                      header[item].type == "sortCamp" &&
                      this.renderSortArrow(header, item, hk)}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {this.props.rows.map((rw) => {
            // if (Object.keys(this.props.header).length != Object.keys(rw).length) {
            //     return console.log("ROW OR HEADER INVALID")
            // }
            return <tr height={50}>{this.renderTd(rw)}</tr>;
          })}
        </tbody>
        {!!this.props.tFootter && Object.keys(this.props.tFootter).length > 0 && (
          <tfoot className={this.props.footer}>
            <tr>{this.renderTd(this.props.tFootter)}</tr>
          </tfoot>
        )}
      </table>
    );
  }
}

export default CustomTable;
