import React from 'react'
import "./index.scss";
import _ from 'lodash';

export default function Card({featuredRightValue, normalLeftValues, active, onClick, filterActive = 2, leftValue = null}) {
    return (
        <div className={"cardLeftText "+active} onClick={onClick}>
            <div className={"left-container "+active}>
                    {leftValue}
                {!_.isEmpty(normalLeftValues) && normalLeftValues.map((value, key) => {
                    if(typeof(value) == 'object'){
                        return (
                            <div className={"cp"} onClick={() => onClick(value.key)}>
                                {value.key == filterActive ? <u>{value.value}</u> : value.value}
                            </div>
                        )
                    }return (
                        <div className={"cp " + key} onClick={() => onClick(value)}>
                            {value}
                        </div>
                    )
                }
                )}
            </div>
            <div className={"right-container " + active}>
                {featuredRightValue &&
                <div className={"featuredValue " + active}>
                    {featuredRightValue}
                </div>
                }
            </div>
        </div>
    )
}
