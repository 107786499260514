import React, { Fragment, useState } from 'react';
import HeaderAdmin from 'features/admin/header';
import MapHeader from 'features/admin/mapHeader';
import Map from 'components/worldMap';
import SubHeader from 'features/subHeader';
import SearchBarTable from 'components/searchBarTable';
import AdminCardsContainer from './AdminCardsContainer';
import TestCard from 'components/customTestCard';
import GenericTableHeader from 'components/tables/genericTableHeader';
import { tableHeaderReadersDevicesUtilization } from 'helper/getTableHeader.js';
import GenericTableRow from 'components/tables/genericTableRow';
import './index.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as services from 'redux/actions';
import { withTranslation } from 'react-i18next';
import { filterReaders, getTotalPages, getItemsByPage } from './logics';
import _ from 'lodash';
import Loading from 'components/loading';
import AddDevice from 'features/readers/modalDevice';
import PageContainer from 'components/pageContainer/pageContainer';
import Swal from 'sweetalert2';
import Footer from 'components/footer';
import { readers } from 'redux/reducers/DeviceManagementReducer';
import * as actions from 'redux/reducers/DeviceManagementReducer';
import * as patientActions from 'redux/reducers/PatientsReducer.js';
import * as readersActions from 'redux/reducers/ReaderReducer.js';
import Filters from './Filters/index';
import Check from 'components/checkComponent';

class ReadersAdminView extends React.Component {
    setView = (view) => {
        this.setState({
            view
        });
    };
    state = {
        view: 'map',
        sites: 0,
        countries: 0,
        readersOffline: 0,
        readersDeactivated: 0,
        readersTotal: 0,
        malaria: {
            tests: 0,
            failed: 0,
            reruns: 0,
            reuse: 0,
            repeat: 0
        },
        hb: {
            tests: 0,
            failed: 0,
            reruns: 0,
            reuse: 0,
            repeat: 0
        },
        devices: [],
        qty_page: 10,
        currentPage: 0,
        showLoading: false,
        devicesCounters: {
            active_readers: 0,
            cartridgesUsed: 0,
            mobile_test: 0
        },
        addDevice: false,
        sorts: {
            online: 'sort',
            product_number: null,
            serial_number: null,
            site_name: null,
            reruns: null,
            reused: null,
            repeat_tests: null,
            cartridge_used: null,
            mobile_tests: null,
            wifi: null,
            pc_laptop: null,
            sw_version: null,
            last_update: null
        },
        filterOpen: false,
        allChecked: false,
        itemsChecked: []
    };

    getFilters = () => {
        let filters = { ...this.props.filters };
        filters.readers_hardware_last_version = [];
        filters.readers_site_type = [];
        filters.readers_city = [{ id: 0, value: 'ALL' }];
        filters.last_updated_date = [];

        filters.updateds = [];
        filters.notUpdateds = [];

        const readers = this.props.all_readers;

        readers.forEach((reader) => {
            if (reader.firmware_status == 'Y') {
                filters.notUpdateds.push(reader.firmware_status);
            } else if (reader.firmware_status == 'G') {
                filters.updateds.push(reader.firmware_status);
            }

            if (
                reader.hardware_last_version &&
                !filters.readers_hardware_last_version.includes(
                    reader.hardware_last_version
                )
            ) {
                filters.readers_hardware_last_version.push(
                    reader.hardware_last_version
                );
            }
            if (!filters.readers_site_type.includes(reader.site_type)) {
                filters.readers_site_type.push(reader.site_type);
            }
            if (!filters.readers_city.includes(reader.site_city)) {
                filters.readers_city.push({
                    id: reader.id,
                    value: reader.site_city
                });
            }
            if (
                reader.updated_at &&
                !filters.last_updated_date.includes(reader.updated_at)
            ) {
                filters.last_updated_date.push(reader.updated_at);
            }
        });
        // filters.sites = this.props.filterSiteOptions;

        // if (this.props.filterSiteOptions.length == 1) {
        //     filters.site = this.props.filterSiteOptions[0].value
        // } else {
        //     filters.site = this.props.filterSiteSelected.value
        // }
        // let test_types_options = [];

        // filters.testLocations = [{ id: 0, value: "ALL" }];

        // this.props.patientsDB.forEach((patient) => {
        //     patient.tests.forEach((test) => {

        //         if (!filters.testLocations.find(lock => lock.value == test.location)) {
        //             filters.testLocations.push({ id: test.id, value: test.location })
        //         }

        //         let diseaseItem = test.disease_id == 1 ? "malaria" : "hb";
        //         if (filters.diseases[diseaseItem] && !test_types_options.includes(test.result)) {
        //             test_types_options.push(test.result);
        //         }
        //     })
        // })

        // filters.test_types_options = test_types_options;

        return filters;
    };

    createDevice = async (values) => {
        try {
            await this.props.services.createDevice(values);
            await this.makeRequests();
            this.setState({ addDevice: false });
        } catch (e) {
            const text = !!e && e.data.message ? e.data.message : 'Error!';
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33'
            });
            return;
        }
    };

    makeRequests = () => {
        const deactivated = true;
        this.props.patientActions.getMalariaCounter(
            null,
            this.props.filterDateSelected.id,
            deactivated
        );
        this.props.patientActions.getHbCounter(
            null,
            this.props.filterDateSelected.id,
            deactivated
        );
        this.props.readersActions.getReadersAdmin(
            this.props.filterDateSelected.id
        );
        // this.props.readersActions.getReadersDeactivated(this.props.filterDateSelected.id)
        // this.props.readersActions.getReadersCount(this.props.filterDateSelected.id)
        // const counters = await this.props.services.getHomeCounters()

        // this.setState({
        //     // sites: counters.site.entityCount,
        //     // countries: counters.site.countries,
        //     // readersOffline: devices.offline,
        //     // readersDeactivated: devices.deleted,
        //     // readersTotal: devices.total,
        //     // devices: readers.map((rd) => { return { ...rd, status: !!rd.online ? "green" : "B" } }),
        //     // showLoading: false,
        //     // devicesCounters
        // })
    };

    componentWillMount = () => this.makeRequests();

    componentDidUpdate = (prevProps) => {
        if (
            !_.isEqual(
                prevProps.filterDateSelected,
                this.props.filterDateSelected
            )
        ) {
            this.makeRequests();
        }
    };

    getContriesMarkers = (sites) => {
        return sites.reduce(
            (initial, site) => {
                if (!site.short_name) return initial;
                initial.countries[site.short_name] = '#FFA300';
                if (!site.latitude || !site.longitude) return initial;

                if (initial.readers[site.city]) {
                    initial.readers[site.city] = {
                        ...initial.readers[site.city],
                        count: initial.readers[site.city].count + 1,
                        readers_total:
                            initial.readers[site.city].readers_total +
                            (site.readers_ative + site.readers_inactive),
                        readers_active:
                            initial.readers[site.city].readers_active +
                            site.readers_ative,
                        readers_inactive:
                            initial.readers[site.city].readers_inactive +
                            site.readers_inactive
                    };
                } else {
                    initial.readers[site.city] = {
                        ...initial.readers[site.city],
                        count: 1,
                        readers_total:
                            site.readers_ative + site.readers_inactive,
                        readers_active: site.readers_ative,
                        readers_inactive: +site.readers_inactive
                    };
                }

                if (site.readers_inactive > 0 || site.readers == 0) {
                    initial.markers.push({
                        latLng: [site.latitude, site.longitude],
                        name: `${site.city} - ${this.props.t(
                            'label_devices'
                        )}: ${
                            initial.readers[site.city].readers_total
                        } - TOTAL SITES: ${initial.readers[site.city].count}`,
                        code: site.short_name,
                        sitename: site.name,
                        style: { fill: '#EB143F' },
                        inactive: site.readers_inactive
                    });
                } else if (site.readers_ative > 0) {
                    initial.markers.push({
                        latLng: [site.latitude, site.longitude],
                        name: `${site.city} - ${this.props.t(
                            'label_devices'
                        )}: ${
                            initial.readers[site.city].readers_active
                        } - TOTAL SITES: ${initial.readers[site.city].count}`,
                        code: site.short_name,
                        sitename: site.name,
                        style: { fill: '#21de03' }
                    });
                }
                return initial;
            },
            { countries: {}, markers: [], readers: {} }
        );
    };

    sort = (sortTitle) => {
        if (!sortTitle) return false;
        if (this.props.sort == sortTitle) {
            return this.props.actions.setSortType(
                this.props.sort_type == 'asc' ? 'desc' : 'asc'
            );
        }
        this.props.actions.setSortType('asc');
        this.props.actions.setSort(sortTitle);
    };

    getCurrentVersion = () => {
        if (!this.props.readers.length) return '-';
        const readers = _.orderBy(this.props.readers, 'sw_version', 'desc');
        const currentFirmwareVersion = readers[0].sw_version;
        const release_date = readers[0].updated_at
            ? readers[0].updated_at
            : '-';
        return {
            currentFirmwareVersion,
            release_date
        };
    };

    checkAllRows = (bool) => {
        if (bool) {
            this.setState({ allChecked: false });
            this.setState({
                itemsChecked: this.props.readers.map((pt) => pt.id)
            });
        } else {
            this.props.actions.toggleAll();
            this.props.actions.toggleItem([]);
        }
    };

    render() {
        const { cardSelected, t } = this.props;
        // const { countries, markers } = this.getContriesMarkers(this.props.user.sites);
        const readers = this.props.readers;
        const {
            currentFirmwareVersion,
            release_date
        } = this.getCurrentVersion();
        return (
            <PageContainer>
                <div className="admin-devices">
                    {this.state.addDevice && (
                        <AddDevice
                            admin={true}
                            save={(values) => this.createDevice(values)}
                            open={this.state.addDevice}
                            onClose={() => this.setState({ addDevice: false })}
                            sites={this.props.user.sites.map((site) => {
                                return { ...site, value: site.name };
                            })}
                        />
                    )}
                    <Loading show={this.state.showLoading} />
                    <HeaderAdmin router={this.props.router} />
                    <SubHeader
                        selectSite={false}
                        title={`${this.props.t('title_devices_operations')} ( ${
                            this.props.filterDateSelected.value
                        } )`}
                        page={'READERS'}
                        setView={(view) => this.setView(view)}
                        view={this.state.view}
                    />
                    {this.props.view === 'map' ? (
                        <></>
                    ) : (
                        // <Fragment>
                        //     <AdminCardsContainer {...this.state} t={this.props.t} />
                        //     <MapHeader
                        //         leftValues={[`${this.state.countries} ${t('label_countries')}            `, `${this.state.sites} SITES            `, `${this.state.readersTotal} ${t('title_Readers')}`]}
                        //     />
                        //     <Map countries={countries} markers={markers} />
                        // </Fragment>
                        <Fragment>
                            <div
                                className="displayFlex readers-card-container"
                                style={{ userSelect: 'none' }}
                            >
                                <TestCard
                                    rightValue={[
                                        <div className="">TOTAL TESTS RUN</div>,
                                        <div className="">
                                            {this.props.Malaria.counter} MALARIA
                                        </div>,
                                        <div className="">
                                            {this.props.Hb.counter} HB VARIANT
                                        </div>
                                    ]}
                                    customTestCardClass={`border-red ${
                                        cardSelected === 1
                                    }`}
                                    customTestCardLeftValue={cardSelected === 1}
                                    customTestRightValue={cardSelected === 1}
                                />
                                <TestCard
                                    rightValue={[
                                        <div className="">
                                            {this.props.readers.length}{' '}
                                            REGISTERED READERS
                                        </div>
                                    ]}
                                    customTestCardClass={`border-red ${
                                        cardSelected === 1
                                    }`}
                                    customTestCardLeftValue={cardSelected === 1}
                                    customTestRightValue={cardSelected === 1}
                                />
                                <TestCard
                                    rightValue={[
                                        <div className="">
                                            {
                                                this.props.readers.filter(
                                                    (reader) =>
                                                        reader.status ==
                                                            'inactive' ||
                                                        reader.status ==
                                                            'discarted'
                                                ).length
                                            }{' '}
                                            DEACTIVATED READERS
                                        </div>
                                    ]}
                                    customTestCardClass={`border-red ${
                                        cardSelected === 1
                                    }`}
                                    customTestCardLeftValue={cardSelected === 1}
                                    customTestRightValue={cardSelected === 1}
                                />
                                <TestCard
                                    rightValue={[
                                        <div className="">
                                            CURRENT FIRMWARE AVAILABLE
                                        </div>,
                                        <div className="">
                                            {currentFirmwareVersion} VERSION
                                        </div>,
                                        <div className="">
                                            {release_date} RELEASE DATE
                                        </div>
                                    ]}
                                    customTestCardClass={`border-red ${
                                        cardSelected === 1
                                    }`}
                                    customTestCardLeftValue={cardSelected === 1}
                                    customTestRightValue={cardSelected === 1}
                                />
                            </div>
                            <SearchBarTable
                                hideDelete={true}
                                placeHolder={t('Search for sites...')}
                                value={this.props.searchValue}
                                router={this.props.router}
                                onChange={(value) =>
                                    this.props.actions.search(value)
                                }
                                add={() => this.setState({ addDevice: true })}
                                perPage={this.props.qty_page}
                                changeQtyPage={(value) =>
                                    this.props.actions.changeQtyPage(value)
                                }
                                currentPage={this.props.currentPage}
                                changePage={(value) =>
                                    this.props.changePage(value)
                                }
                                totalPages={this.props.totalPages}
                                filterBox={
                                    <Filters
                                        filters={this.getFilters()}
                                        toggleFilter={(filters) =>
                                            this.props.actions.toggleFilter(
                                                filters
                                            )
                                        }
                                        selectSite={(option) =>
                                            this.props.SubHeaderActions.setSite(
                                                option
                                            )
                                        }
                                    />
                                }
                            />
                            <div className="pages-table-container">
                                <div className="div_rows">
                                    <GenericTableHeader
                                        checked={this.state.allChecked}
                                        cols={tableHeaderReadersDevicesUtilization(
                                            t,
                                            this.props.sort
                                        )}
                                        onClick={(index, sort_camp) =>
                                            this.sort(index)
                                        }
                                        sort_type={this.props.sort_type}
                                        onClick={(index, sortTitle) =>
                                            this.sort(sortTitle)
                                        }
                                        checkAction={(bool) =>
                                            this.checkAllRows(bool)
                                        }
                                    />
                                    {readers.map((row) => {
                                        return (
                                            <GenericTableRow
                                                sites_children={[
                                                    row.sites_children.length >
                                                    0
                                                        ? row.sites_children
                                                        : null
                                                ]}
                                                hover={false}
                                                checksSelecteds={[]}
                                                cols={tableHeaderReadersDevicesUtilization(
                                                    t,
                                                    this.props.sort
                                                )}
                                                row={{
                                                    ..._.omit(row, [
                                                        'id',
                                                        'sites_children'
                                                    ]),
                                                    site_primary: (
                                                        <Check
                                                            check={
                                                                row.site_primary
                                                            }
                                                            className={
                                                                'tdsite_primary'
                                                            }
                                                            color={
                                                                row.site_primary
                                                                    ? '#5dd33d'
                                                                    : 'red'
                                                            }
                                                        />
                                                    ),
                                                    type:
                                                        row.type == 1
                                                            ? `${this.props.t(
                                                                  'label_SingleSite'
                                                              )}`
                                                            : `${this.props.t(
                                                                  'label_MultiSite'
                                                              )}`
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
                <Footer />
            </PageContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    filterDateSelected: state.SubHeaderReducer.selected_date,
    filterDateOptions: state.ReadersReducer.FILTER_DATE[window.currentLanguage],
    user: state.UserReducer.userData,
    cardSelected: state.ReadersReducer.cardSelected,
    Hb: state.PatientsReducer.hbTestsCounter,
    Malaria: state.PatientsReducer.malariaTestsCounter,
    readers: readers(state),
    all_readers: state.ReaderReducer.readers,
    view: state.DeviceManagementReducer.view,
    sort: state.DeviceManagementReducer.sort,
    sort_type: state.DeviceManagementReducer.sort_type,
    search: state.DeviceManagementReducer.search,
    qty_page: state.DeviceManagementReducer.qty_page,
    currentPage: state.DeviceManagementReducer.qty_page,
    totalPages: getTotalPages(
        readers(state),
        state.DeviceManagementReducer.qty_page
    ),
    filters: state.DeviceManagementReducer.filters
});

const mapDispatchToProps = (dispatch) => ({
    services: bindActionCreators(services, dispatch),
    actions: bindActionCreators(actions, dispatch),
    patientActions: bindActionCreators(patientActions, dispatch),
    readersActions: bindActionCreators(readersActions, dispatch)
});

ReadersAdminView = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReadersAdminView);

export default withTranslation()(ReadersAdminView);
