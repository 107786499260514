import React from 'react';
import { VectorMap } from 'react-jvectormap'
import _ from 'lodash';
import './index.scss'
import $ from 'jquery';
class Map extends React.Component {

    removeCard = () => {
        const hasElementRenderized = document.getElementsByClassName('mapHoverCard');

        if (hasElementRenderized && hasElementRenderized.length > 0) {
            hasElementRenderized[0].remove();
        }
    }

    componentWillUnmount() {
        this.removeCard();
    }

    componentDidMount() {

        var container = document.querySelector('.page-container');

        container.addEventListener('click', (event) => {

            this.removeCard();

            const { index } = event.target.dataset;

            if (!index || !this.props.markers[index]) return false;

            const marker = this.props.markers[index];

            if(!this.map || !this.map['$mapObject']) return false;
            
            this.map['$mapObject'].setFocus({ region: marker.code });

            const top = $(`circle[data-index=${index}]`).offset().top;
            const left = $(`circle[data-index=${index}]`).offset().left;

            var rect = container.getBoundingClientRect();

            var x = left - rect.left;

            var y = top - rect.top;

            const { fill } = marker.style

            if (fill == '#EB143F') {
                const card =
                    `<div class='mapHoverCard' style='top: ${y}px;left: ${x}px'}> 
                    <div class='mapHoverHeader'>SITE NAME: ${marker.sitename}</div>   
                    <div class='mapHoverInfo'> 
                        <div> ${marker.inactive} READER OFFLINE </div>
                        <div>1 SITE AFFECTED </div>
                    </div> 
                </div>`
                $('.page-container').prepend(card)
            }

        }, false);
    }

    render() {
        window.onzoom = (e) => {
            this.removeCard();
        };
        return (
            <div style={{ width: '100%', height: 'calc(100vh - 400px)' }}>
                <VectorMap
                    map={'world_mill'}
                    backgroundColor="white"
                    ref={map => this.map = map}
                    containerStyle={{
                        width: '100%',
                        height: '100%'
                    }}
                    markerStyle={{
                        initial: {
                            fill: '#6BC38C',
                        }
                    }}
                    markers={this.props.markers || []}
                    series={{
                        regions: [{
                            values: this.props.countries ? { ...this.props.countries } : {}
                        }]
                    }}
                    containerClassName="map"
                    regionStyle={{
                        initial: {
                            fill: '#B1B0B3',
                            "fill-opacity": 1,
                            stroke: 'none',
                            "stroke-width": 0,
                            "stroke-opacity": 1
                        },
                        hover: {
                            "fill-opacity": 0.8,
                            cursor: 'pointer'
                        },
                        selected: {
                            fill: 'yellow'
                        },
                        selectedHover: {
                        }
                    }}
                    onRegionClick={(e, code, isSelected, selectedRegions) => {
                        if (!Object.keys(this.props.countries).includes(code)) return false;
                        this.map['$mapObject'].setFocus({ region: code });
                    }}
                />
            </div>
        )
    }
}


export default Map