import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import DropDown from 'features/user/header/components/DropDown'
import usaLanguage from 'assets/img/header-new/header_language_usa.png';
import brLanguage from 'assets/img/header-new/brazil_language_icn.png';
import spanLanguage from 'assets/img/header-new/spanish_language_icn.png';
import niLanguage from 'assets/img/header-new/nigeria_language_icn.png';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { UserActions } from 'redux/reducers';

class Header extends Component {
    state = {
        icon: this.props.i18n.language == 'ptBR' ? brLanguage : usaLanguage,
        languageList: {
            'en': usaLanguage,
            'ptBR': brLanguage,
            'span': spanLanguage,
            'ni': niLanguage
        },
    }

    setLanguage = (icon) => {
        switch (icon) {
            case usaLanguage: {
                if (Object.keys(this.props.userData).length > 0 && !!this.props.userData.languageDefault) {
                    this.props.UserActions.updateUserLanguage('en', this.props.i18n)
                    this.props.i18n.changeLanguage('en')
                    break;
                } else {
                    this.props.i18n.changeLanguage('en')
                    break
                }
            }
            case brLanguage: {
                if (Object.keys(this.props.userData).length > 0 && !!this.props.userData.languageDefault) {
                    this.props.UserActions.updateUserLanguage('ptBR', this.props.i18n)
                    this.props.i18n.changeLanguage('ptBR')
                    break;
                } else {
                    this.props.i18n.changeLanguage('ptBR')
                    break
                }
            }
        }
    }

    render() {
        const { title, step, sites } = this.props;
        const stepsBlockedSkip = [3];

        if (sites.length == 0) stepsBlockedSkip.push(1)
        return (
            <Fragment>
                <div className="div-page">
                    {title}
                    {(!stepsBlockedSkip.includes(step)) &&
                        <button className={'buttonSkip'} onClick={() => this.props.skip()}>{this.props.t('label_Skip')}</button>
                    }
                    <div className='buttonSkip lng'>
                        <DropDown
                            icons={this.state.languageList}
                            setIcon={icon => this.setLanguage(icon)}
                            icon={this.props.i18n.language == 'ptBR' ? brLanguage : usaLanguage}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapDispatchToProps = (dispatch) => ({
	UserActions: bindActionCreators(UserActions, dispatch),
});


Header = connect(null, mapDispatchToProps)(Header);

export default withTranslation()(Header);
