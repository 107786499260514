import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header";
import BoxForgotPassword from "./boxForgotPassword";
import Footer from "components/footer";


export default class ForgotPassword extends Component {
    static propTypes = {
        prop: PropTypes
    };

    render() {
        return (
            <Fragment>
                <PageContainer page={'login'}>
                    <Header page={'login'} />
                    <BoxForgotPassword {...this.props}/>
                </PageContainer>
                <Footer />
            </Fragment>
        );
    }
}
