import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

const statusComponent = ({ color, className }) => {
  return (
    <FontAwesomeIcon
      icon={faCircle}
      className={className}
      style={{
        fontSize: 16,
        textAlign: "center",
        color: color,
      }}
    />
  );
};

export default statusComponent;
