import React, { Component } from 'react';
import './style.scss';
import logo from 'assets/img/admin/header_hemex_health_logo2x.png'
import Step1 from "assets/img/tutorial_assets/step1.jpg"
import Step2 from "assets/img/tutorial_assets/step2.jpg"
import Step3 from "assets/img/tutorial_assets/step3.jpg"
import Step4 from "assets/img/tutorial_assets/step4.jpg"
import Step5 from "assets/img/tutorial_assets/step5.jpg"
import Step6 from "assets/img/tutorial_assets/step6.jpg"
import Step7 from "assets/img/tutorial_assets/step7.jpg"
import CloseAlert from "assets/img/close_invite_user_bg_icn.png";
import CloseIcon from 'assets/img/close_grey_icn.png'
import CheckSuccess from 'assets/img/check_green_icn.png'
import LoadingImage from 'assets/img/loading_black_icn.png'
import ProblemIcon from 'assets/img/alert_red_icn.png'
import Footer from 'components/footer';
import $ from 'jquery';
import mask from 'jquery-mask-plugin';
import * as services from 'redux/actions';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { VerifyReaderActions } from "redux/reducers";
import SerialNumber from 'assets/img/wizard/serial_numer_img.jpg';
import AddDevice from 'components/addDevice';
import Swal from 'sweetalert2'
import InputMask from "react-input-mask";

import _ from 'lodash';

const imgObj = {
    0: Step1,
    1: Step2,
    2: Step3,
    3: Step4,
    4: Step5,
    5: Step6,
    6: Step7,
    7: Step1,
    8: Step1,
}

class VerifyReader extends Component {

    state = {
        steps: 9,
        step: 1,
        titles: {
            0: this.props.t("label_AddNewReader"),
            10: this.props.t("label_PowerUp"),
            11: this.props.t("label_ClinicalTest"),
            12: this.props.t("label_MacValidation"),
            13: this.props.t("label_ConnectWifi")
        },
        image_sub_title: {
            en: {
                0: (() => <> <b>{this.props.t("label_TurnOn")}</b> {this.props.t("label_GazelleReader")}</>)(),
                1: (() => <> {this.props.t("label_Press")} <b>{this.props.t("label_Settings")}</b> {this.props.t("label_Icon")} </>)(),
                2: (() => <> {this.props.t("label_Press")} <b>{this.props.t("label_Admin")}</b> {this.props.t("label_Icon")} </>)(),
                3: (() => <> {this.props.t("label_EnterLogin")} <b>{this.props.t("label_Credentials")}</b> {this.props.t("label_Icon")} </>)(),
                4: (() => <> {this.props.t("label_Press")} <b> {this.props.t("label_ScrollButton")} </b> {this.props.t("label_Once")} </>)(),
                5: (() => <> {this.props.t("label_Press")} <b> {this.props.t("label_AboutDevice")} </b> {this.props.t("label_Button")} </>)(),
                6: (() => <> {this.props.t("label_Identify")} <b> {this.props.t("label_MacAddress")}</b> </>)(),
                7: (() => <> <b>  {this.props.t("label_EnterMac")} </b>{this.props.t("label_Shown")} </>)(),
                8: (() => <> <b>  {this.props.t("label_EnterMac")} </b>{this.props.t("label_Shown")} </>)(),
            },
            ptBR: {
                0: (() => <> <b>{this.props.t("label_TurnOn")} </b>{this.props.t("label_GazelleReader")}</>)(),
                1: (() => <> {this.props.t("label_Press")} {this.props.t("label_Icon")} <b>{this.props.t("label_Settings")}</b> </>)(),
                2: (() => <> {this.props.t("label_Press")} {this.props.t("label_Icon")} <b>{this.props.t("label_Admin")}</b> </>)(),
                3: (() => <> <b>{this.props.t("label_Credentials")}</b> </>)(),
                4: (() => <> {this.props.t("label_Press")} <b> {this.props.t("label_ScrollButton")} </b> {this.props.t("label_Once")} </>)(),
                5: (() => <> {this.props.t("label_Press")} {this.props.t("label_Button")} <b> {this.props.t("label_AboutDevice")} </b> </>)(),
                6: (() => <> {this.props.t("label_Identify")} <b> {this.props.t("label_MacAddress")}</b> </>)(),
                7: (() => <> <b>  {this.props.t("label_EnterMac")} </b>{this.props.t("label_Shown")} </>)(),
                8: (() => <> <b>  {this.props.t("label_EnterMac")} </b>{this.props.t("label_Shown")} </>)()
            }
        },
        add: false,
        mac_value: "",
        error_mac: false,
        error_connection: true,
        checking: false,
        verifying_connection: false,
        verified: false,
        reader_online: false,
        placeHolder: "00:00:00:00:00:00"
    }

    componentDidMount() {
        if (!Object.keys(this.props.reader).length) {
            this.setState({
                steps: 10, step: 0
            })
        }
    }

    componentWillUnmount() {
        this.props.VerifyReaderActions.set_initial()
    }

    canAdvance = () => {
        if (this.state.step == 8 && !this.state.verified) return false;
        return true;
    }


    createReader = async (values) => {
        try {
            await this.props.services.createDevice(values);
            await this.props.services.getReadersOfflineCounter()
            this.props.VerifyReaderActions.set_reader(values)
            this.setState({ step: 1, add: true })
        } catch (e) {
            const text = !!e && !!e.data && e.data.message ? e.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            return;
        }
    };

    verifyMac = () => {
        if (this.state.mac_value.length < 8) return false;

        this.setState({
            checking: true
        });

        this.props.services.verifyReaderMacAddress(this.state.mac_value)
            .then((result) => {
                this.setState({
                    verified: true,
                    reader_online: true,
                    checking: false
                })
                this.props.services.getReadersOfflineCounter()
            })
            .catch(() => {
                this.setState({ checking: false, error_mac: true })
            });
    };

    getImg = () => {
        return imgObj[this.state.step - 1]
    }

    getHTML = () => {
        switch (this.state.step) {
            case 8:
                if (this.state.checking) {
                    return (
                        <div className='icon_loading'>
                            <img src={LoadingImage} className="loadingImage" />
                            VERIFYING CONNECTION
                        </div>
                    )
                }

                if (this.state.verified) {
                    return (
                        <div className='verified'>
                            <img src={CheckSuccess} className="checkSuccess" />
                            MAC ADDRESS VERIFIED
                        </div>
                    )
                }
                if (!this.state.verified && !this.state.checking && !this.state.error_mac) {
                    return (
                        <div className={`notVerified ${this.state.mac_value.length < 17}`} onClick={this.verifyMac}>
                            VERIFY AND CONNECT
                        </div>
                    )
                }

                if (this.state.error_mac) {
                    return (
                        <div className='verified error'>
                            <img src={ProblemIcon} className="checkSuccess" />
                            PROBLEM WITH MAC ADDRESS, PLEASE TRY AGAIN OR CONTACT US
                            <img src={CloseAlert} className='close_icon' onClick={() => this.setState({ error_mac: false })} />
                        </div>
                    )
                }
            case 9:
                if (this.state.verifying_connection) {
                    return (
                        <div className='icon_loading verifying'>
                            <img src={LoadingImage} className="loadingImage" />
                            VERIFYING CONNECTION
                        </div>
                    )
                }

                if (this.state.reader_online) {
                    return (
                        <div className='verified online'>
                            <img src={CheckSuccess} className="checkSuccess" />
                            READER ONLINE
                        </div>
                    )
                }
                if (this.state.error_connection) {
                    return (
                        <div className='verified error connection'>
                            <img src={ProblemIcon} className="checkSuccess" />
                            CONNECTION FAILED
                            <img src={CloseAlert} className='close_icon' onClick={() => this.setState({ error_connection: false })} />
                        </div>
                    )
                }
        }
    }

    render() {
        const steps = Array.from(Array(this.state.steps));
        const hasReader = Object.keys(this.props.reader).length > 0;
        const stepsAll = this.state.add ? this.state.steps - 1 : this.state.steps;
        return (
            <>
                <div className='verify-container'>
                    <div className='verify-box'>
                        <div className='header'>
                            <img src={CloseIcon} onClick={() => {
                                this.props.VerifyReaderActions.set_initial()
                                this.props.router.history.push('/')
                            }} />
                        </div>
                        <div className='logo'>
                            <img className='logo-item' src={logo} />
                        </div>
                        <div className='reader-info'>
                            {!!hasReader && this.state.step > 0 ? this.props.reader.serial_number + " - GAZELLE READER SETUP" : "GAZELLE READER SETUP"}
                        </div>
                        <div className='step-container'>
                            <div className='step'>
                                {!!hasReader && this.state.step > 0 && `STEP  ${this.state.add ? this.state.step + 1 : this.state.step}`}
                            </div>
                            <div className='text'>
                                {this.state.titles[this.state.step]}
                            </div>
                            <div className='text-main-image'>
                                {this.state.image_sub_title[window.currentLanguage][this.state.step - 1]}
                            </div>
                        </div>
                        <div className='img-main'>
                            <img className={`img ${this.state.step == 8 || this.state.step == 9 || this.state.step == 0 || !this.state.step == 10}`} src={!!hasReader && this.state.step > 0 ? this.getImg() : SerialNumber} />
                        </div>

                        {this.state.step == 0 &&
                            <AddDevice sites={this.props.sites} hideDoubt={true} save={values => this.createReader(values)} />
                        }
                        {this.state.step == 8 &&
                            <div className='box-mac'>
                                <InputMask
                                    mask={'**:**:**:**:**:**'}
                                    maskChar={""}
                                    type={"text"}
                                    readOnly={false}
                                    className={`inputmac`}
                                    onClick={() => this.setState({ placeHolder: "" })}
                                    maxLength={17}
                                    onChange={(e) => this.setState({
                                        mac_value: e.target.value,
                                        verified: false, reader_online: false
                                    })}
                                    value={this.state.mac_value.toUpperCase()}
                                />
                            </div>
                        }
                        {this.getHTML()}
                    </div>
                </div>
                <div className='bottom-steps-container'>
                    <div className='bottom-steps'>
                        <div className='bottom-item initial reverse'>
                            {(this.state.step > 1 && !this.state.add || this.state.add && this.state.step > 0) &&
                                <div className='text-bottom-bar' onClick={() => this.setState({ step: this.state.step - 1 })}>BACK</div>
                            }
                        </div>
                        <div className='bottom-item'>
                            {steps.map((step, key) => {
                                key = !!hasReader && !this.state.add ? key + 1 : key
                                if (key == this.state.step) {
                                    return (<div className='ball true' />)
                                } else {
                                    return (<div className='ball' />)
                                }
                            })}
                        </div>
                        {this.state.step == stepsAll &&
                            <div className='bottom-item initial'>
                                {this.state.verified && this.state.reader_online &&
                                    <div className='text-bottom-bar' onClick={() => {
                                        this.props.VerifyReaderActions.set_initial()
                                        this.props.router.history.push('/')
                                    }}>DONE</div>
                                }
                            </div>
                        }
                        {this.state.step < stepsAll &&
                            <div className='bottom-item initial'>
                                {!!hasReader && !!this.canAdvance() &&
                                    <div className='text-bottom-bar' onClick={() => this.setState({ step: this.state.step + 1 }, () => {
                                        $("inputmac").mask("00:00:00:00:00:00")
                                    })}>NEXT</div>
                                }
                            </div>
                        }
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}


const mapStateToProps = state => {
    let sites = !!state.UserReducer.userData && _.isArray(state.UserReducer.userData.sites) ? [...state.UserReducer.userData.sites.map((site) => {
        return { ...site, value: site.name }
    })] : [];
    return ({
        reader: state.VerifyReaderReducer.reader,
        sites
    })
}


const mapDispatchToProps = dispatch => ({
    VerifyReaderActions: bindActionCreators(VerifyReaderActions, dispatch),
    services: bindActionCreators(services, dispatch),
})

VerifyReader = connect(mapStateToProps, mapDispatchToProps)(VerifyReader)

export default withTranslation()(VerifyReader)