import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import CustomModal from 'components/customModal';
import HemexLogo from 'assets/img/admin/header_hemex_health_logo.png';

import stepOne from 'assets/img/tutorial/en/tutorial_1.jpg'
import stepTwo from 'assets/img/tutorial/en/tutorial_2.jpg'
import stepThree from 'assets/img/tutorial/en/tutorial_3.jpg'
import stepFour from 'assets/img/tutorial/en/tutorial_4.jpg'

import stepOnePT from 'assets/img/tutorial/pt/tutorial_1.jpg'
import stepTwoPT from 'assets/img/tutorial/pt/tutorial_2.jpg'
import stepThreePT from 'assets/img/tutorial/pt/tutorial_3.jpg'
import stepFourPT from 'assets/img/tutorial/pt/tutorial_4.jpg'

import stepOneWFT from 'assets/img/tutorial/wft/tutorial_1.jpg'
import stepTwoWFT from 'assets/img/tutorial/wft/tutorial_2.jpg'
import stepThreeWFT from 'assets/img/tutorial/wft/tutorial_3.jpg'
import stepFourWFT from 'assets/img/tutorial/wft/tutorial_4.jpg'

import Footer from 'components/footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withTranslation } from 'react-i18next'
import usaLanguage from 'assets/img/header-new/header_language_usa.png';
import brLanguage from 'assets/img/header-new/brazil_language_icn.png';
import spanLanguage from 'assets/img/header-new/spanish_language_icn.png';
import niLanguage from 'assets/img/header-new/nigeria_language_icn.png';
import DropDown from 'features/user/header/components/DropDown'
import { UserActions }from 'redux/reducers';


const imageStep = [stepOne, stepTwo, stepThree, stepFour]
const imageStepPt = [stepOnePT, stepTwoPT, stepThreePT, stepFourPT]
const imageStepWFT = [stepOneWFT, stepTwoWFT, stepThreeWFT, stepFourWFT]

class BoxTutorial extends Component {
    static propTypes = {
        prop: PropTypes
    };

    state = {
        page: 1,
        texts: {
            1: this.props.t('tutorial_image1'),
            2: this.props.t('tutorial_image2'),
            3: this.props.t('tutorial_image3'),
            4: this.props.t('tutorial_image4'),
        },
        icon: this.props.i18n.language == 'ptBR' ? brLanguage : usaLanguage,
        languageList: {
            'en': usaLanguage,
            'ptBR': brLanguage,
            'span': spanLanguage,
            'ni': niLanguage
        },
    };

    static defaultProps = {
        open: false
    };

    isAdmin = () => {
        return this.props.router.location.pathname.includes('/admin')
    }

    setLanguage = (icon) => {
        switch (icon) {
            case usaLanguage: {
                if (Object.keys(this.props.userData).length > 0 && !!this.props.userData.languageDefault) {
                    this.props.UserActions.updateUserLanguage('en', this.props.i18n)
                    break;
                } else {
                    this.props.i18n.changeLanguage('en')
                    break
                }
            }
            case brLanguage: {
                if (Object.keys(this.props.userData).length > 0 && !!this.props.userData.languageDefault) {
                    this.props.UserActions.updateUserLanguage('ptBR', this.props.i18n)
                    break;
                } else {
                    this.props.i18n.changeLanguage('ptBR')
                    break
                }
            }
        }
    }

    render() {
        const { page } = this.state

        let url = this.isAdmin() ? `${process.env.REACT_APP_FRONT_URL}/admin/` : `${process.env.REACT_APP_FRONT_URL}/`;

        let imageStepLang

        switch (this.props.i18n.language) {
            case 'ptBR':
                imageStepLang = imageStepPt
                break;
            case 'en':
                imageStepLang = imageStep
                break;
            default: imageStepLang = imageStepWFT
        }

        const txt = this.state.texts[this.state.page];

        return (
            <div className="tutorial-page" style={{
                backgroundImage: `url(${imageStepLang[page - 1]})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center 50px'
            }}>
                <div className="tutorial-header">
                    <div className='skip' onClick={() => this.props.router.history.push(url)}>
                        {this.props.t('label_Skip')}
                    </div>
                    <DropDown
                        icons={this.state.languageList}
                        setIcon={icon => this.setLanguage(icon)}
                        icon={this.state.icon}
                    />
                </div>
                <div className="box-section">
                    <div className="box-middle">
                        <div>{this.props.t('label_WelcomeTo').toUpperCase()}</div>
                        <div><img src={HemexLogo} /></div>
                        <div style={{ height: 405 }} />
                        {/* <div><img className="imageTutorial" src={imageStepLang[page - 1]} /></div> */}
                        <div className='text-description'>{txt}</div>
                    </div>
                    <div className="box-bottom">
                        <div className={this.state.page > 1 ? "btn-back-container active" : "btn-back-container"} >
                            <button
                                className="btn-back"
                                onClick={() => this.setState({ page: this.state.page - 1 })}
                            >
                                {this.props.t('label_Back')}
                            </button>
                        </div>
                        <div className="page-circle">
                            <div className={page === 1 ? "circle active" : "circle"} />
                            <div className={page === 2 ? "circle active" : "circle"} />
                            <div className={page === 3 ? "circle active" : "circle"} />
                            <div className={page === 4 ? "circle active" : "circle"} />
                        </div>
                        <div className="btn-next-container">
                            <button className="btn-next" onClick={() => this.state.page === 4 ? this.props.router.history.push(url) : this.setState({ page: page + 1 })}>
                                {this.state.page == 4 ? this.props.t('label_Done') : this.props.t('label_Next')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    userLanguage: state.UserReducer.userData.languageDefault,
    userData: state.UserReducer.userData
})

const mapDispatchToProps = dispatch => ({
    UserActions: bindActionCreators(UserActions, dispatch)
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BoxTutorial));
