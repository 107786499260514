import React, { Component } from "react";
import PropTypes from "prop-types";
import './index.scss'
class CustomButton extends Component {
  static propTypes = {
    prop: PropTypes
  };

  static defaultProps = {
    className: "customButton",
    value: ""
  };

  handleChange = () => {
    return this.props.handleChange ? this.props.handleChange() : null;
  };

  render() {
    let size = this.props.size ? 'button' + this.props.size : '';
    let full = this.props.full ? 'buttonFull' : '';

    return (
      <button
        className={'customButton ' + this.props.className + ' ' + size + ' ' + full}
        onClick={() => this.handleChange()}
        type={this.props.type ? this.props.type : 'button'}
        disabled={this.props.disabled || false}
        tabIndex={this.props.tabIndex || ''}
      >
        {this.props.children}{this.props.value}
      </button>
    );
  }
}

export default CustomButton;
