import React, { Component } from "react";
import "./index.scss";
import cartridges from "assets/img/header-new/header_cartridges_selected_white@2x.png";

class BoxReaders extends Component {
  render() {
    let imageBg = this.props.image ? this.props.image : '';
    return (
      <div className="BoxReaders">
        <div className="divBox" style={{ backgroundImage: 'url("' + imageBg + '")', backgroundRepeat: 'no-repeat', backgroundPosition: '40px bottom', cursor: "initial" }}>
          <div className="firstDiv">
            <div>
              <div className="circleCartridges">
                <img src={cartridges} />
              </div>
            </div>
            <div className="leftDiv">
              <div className="title fs16 fs-580">{this.props.title}</div>
              <div className="numberRed fs-580 fs14">{this.props.defaultNumber}</div>
            </div>
            <div className="divRight">
              <div className="firstItem fs14">{this.props.firstItem}</div>
              <div className="secoundItem fs-580 fs26">{this.props.secoundItem}</div>
              <div className="thirdItem fs14 fs-580">{this.props.thirdItem}</div>
            </div>
          </div>
        </div>
        <div className="divInformation">
          <div className="divInformationLeft"><b>Last Data Sync: </b>{this.props.dataSync}</div>
          {/* <div className="divInformationRight"><b>Number of Records:</b> {this.props.numberOfRecords}</div> */}
        </div>
      </div>
    );
  }
}

export default BoxReaders;
