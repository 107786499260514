import { createSelector } from "reselect";
import _ from 'lodash';

const initialState = {
    view: 'table',
    search: "",
    qty_page: 10,
    currentPage: 0,
    addDevice: false,
    sort: "online",
    sort_type: "asc",
    filters: {
        with_errors: null,
        no_errors: null,
        firmware_status: {
            current: null,
            not_current: null
        },
        site_type: {
            single: false,
            multi: false
        },
        city: null,
        version: [],
        last_updated_firmware_version: [],
    }
}

const types = {
    "SEARCH": "SEARCH",
    "SET_SORT": "SET_SORT",
    "SET_SORT_TYPE": "SET_SORT_TYPE",
    "CHANGE_QTY_PAGE": "CHANGE_QTY_PAGE",
    "CHANGE_CURRENT_PAGE": "CHANGE_CURRENT_PAGE",
    "SET_FILTERS": "SET_FILTERS",
}


const DeviceManagementReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case types.SEARCH: {
            return { ...state, search: action.payload }
        }
        case types.SET_SORT: {
            return { ...state, sort: action.payload }
        }
        case types.SET_SORT_TYPE: {
            return { ...state, sort_type: action.payload }
        }
        case types.CHANGE_QTY_PAGE: {

            return { ...state, qty_page: action.payload, currentPage: 0 }
        }
        case types.CHANGE_CURRENT_PAGE: {
            return { ...state, currentPage: action.payload }
        }

        case types.SET_FILTERS: {
            return { ...state, filters: action.payload }
        }
    }
    return state;
};

export const search = payload => ({ type: types.SEARCH, payload })
export const changeQtyPage = payload => ({ type: types.CHANGE_QTY_PAGE, payload })
export const changeCurrentPage = payload => ({ type: types.CHANGE_CURRENT_PAGE, payload })
export const setSort = payload => ({ type: types.SET_SORT, payload })
export const setSortType = payload => ({ type: types.SET_SORT_TYPE, payload })
export const toggleFilter = (payload) => ({ type: types.SET_FILTERS, payload })

const rdrs = state => state.ReaderReducer.readers
const src = state => state.DeviceManagementReducer.search
const pg = state => state.DeviceManagementReducer.currentPage
const qtpage = state => state.DeviceManagementReducer.qty_page
const str = state => state.DeviceManagementReducer.sort
const strt = state => state.DeviceManagementReducer.sort_type
const fts = state => state.DeviceManagementReducer.filters;

const formatReader = reader => {
    return {
        id: reader.id,
        sites_children: reader.child_names,
        status: reader.requireUpdate ? "Y" : reader.status == "active" ? "G" : "R",
        serial_number: reader.serial_number,
        mac_address: reader.mac_address,
        sw_version: reader.software,
        last_update: reader.updated_at ? reader.updated_at : "-",
        firmware_status: reader.requireUpdate ? "Y" : "G",
        malaria_tests: reader.malaria_tests,
        hb_tests: reader.hb_tests,
        repeat_tests: reader.repeat_tests,
        site_name: reader.site_name,
        type: reader.site_type,
        site_primary: `${reader.site_primary}`,
        city: reader.site_city,
    }
}


export const getTotalPages = (devices, qty_page) => {
    return (devices.length / qty_page) % 1 != 0 ? Math.ceil(devices.length / qty_page) : Math.ceil(devices.length / qty_page)
}

const getItemsByPage = (devices, page, qty_page) => {
    const totalPages = getTotalPages(devices, qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? devices : init + qty_page
    return (totalPages) == 1 ? devices : devices.slice(init, end);
}


export const readers = createSelector(
    rdrs, src, pg, qtpage, str, strt, fts, (readers, search, page, qty_page, sort, sort_type, filters) => {
        let items = _.chain(readers)
            .filter((reader) => search.length > 0 ? reader.site_name.toLowerCase().includes(search.toLowerCase()) : reader)
            .filter((reader) => !filters.with_errors ? true : reader.status != "active")
            .filter((reader) => !filters.no_errors ? true : reader.status == "active")
            .filter((reader) => {
                if (!Object.values(filters.firmware_status).includes(true)) return reader;

                if (filters.firmware_status.current && filters.firmware_status.not_current) {
                    return reader;
                }

                if (filters.firmware_status.current) {
                    return reader.requireUpdate == false;
                } else {
                    return reader.requireUpdate == true;
                }
            })
            .filter((reader) => !filters.version.length ? true : filters.version.includes(reader.software))
            .filter((reader) => {
                if (!!filters.site_type.single && !!filters.site_type.multi || !filters.site_type.single && !filters.site_type.multi) {
                    return reader;
                }
                if (filters.site_type.single && !filters.site_type.multi) {
                    return reader.site_type == 1;
                }
                if (!filters.site_type.single && filters.site_type.multi) {
                    return reader.site_type == 2;
                }
            })
            .filter((reader) => {
                if (!filters.city || filters.city.value == "ALL") {
                    return reader;
                }
                return reader.site_city == filters.city.value
            })
            .map((item) => formatReader(item))
            .value()

        items = _.orderBy(items, sort, sort_type);

        return getItemsByPage(items, page, qty_page);
    }
);

export default DeviceManagementReducer;