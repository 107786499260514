import { createStore, combineReducers, applyMiddleware } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session';
import combinedReducers from "../reducers/combineReducers";
import reduxThunk from "redux-thunk";
import logger from 'redux-logger'

const persistConfig = {
  key: 'usr_data',
  storage,
  whitelist: ["UserReducer", "ManagementPortalReducer"]
}

const persistedReducer = persistReducer(persistConfig, combinedReducers)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(reduxThunk, logger)));
export const persistor = persistStore(store)

