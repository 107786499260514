import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import AddDevice from 'components/addDevice';

import CustomModal from 'components/customModal';

export default class ModalDevice extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        return (
            <div className="modal-device-container">
                <CustomModal open={this.props.open} title={"DEVICE"} onClose={this.props.onClose}>
                    <AddDevice {...this.props}/>
                </CustomModal>
            </div>
        )
    }
}
