import SystemAdminSiteUser from './SystemAdminSite/user'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import NewPassword from './NewPassword'
import SiteRegistrationConfirmation from './SiteRegistrationConfirmation'
import RegisterSite from './RegisterSite'
import SiteRegisterConfirmed from './SiteRegisterConfirmed'
import Wizard from './Wizard';
import ManagementPortalUser from './ManagementPortal/user';
import Patients from './Patients';
import Readers from './Readers';
import Cartridges from './Cartridges';
import SystemAdminUserManagement from './SystemAdminUserManagement'
import SystemAdminPageContainer from './SystemAdminPageContainer'
import ValidationsMessages from './ValidationsMessages'
import Header from './Header';
import TableHeader from './TableHeader';
import AdminManagementPortal from './Admin/DashboardManagementPortal'
import AdminDevices from './Admin/Devices'
import TermsOfUseSiteUser from './TermsOfUse'
import Tutorial from './Tutorial';
import InviteEds from './Admin/modalInviteEds'
import AdminAddSite from './Admin/AddSite'
import VerifyReader from "./VerifyNewReader";

const translations = {

    label_AllSites: "TODOS SITES",
    /**
     * GENERAL
     */
    label_Print: "Imprimir",
    label_SitesWithReaders: "Sites com leitores",
    label_RegisteredSitesWithActiveGazelleReaders: "Sites registrados com leitores ativos",
    label_SitesWithIssuesToResolve: "Sites com readers com problemas",
    label_GazelleReadersInUse: "Leitores Gazelle em uso",
    label_ActiveGazelleReaders: "Leitores Ativos",
    label_GazelleReadersWithIssues: "Leitores com problemas para Revisar/Resolver",
    label_TotalHbTestsDone: "Total de testes de Hb Variant finalizados",
    label_RepeatHbTests: "Total de testes de Hb Variant repetidos",
    label_TotalMalariaTestsDone: "Total de testes de Malaria finalizados",
    label_StatusSiteReaderRed: "Site com leitores com problemas",
    label_RegisteredReaders: "Leitores registrados",
    label_StatusSiteReaderYellow: "Site com reader precisando de atualização",
    label_StatusSiteReaderGreen: "Reader sem problemas detectados",
    label_RepeatMalariaTests: "Testes de Malaria repetidos",
    label_ToStartUsingReaders: "Para começar utilizar seus {{qty}} leitores registrados, será necessário configurar a conexão nesse portal.",
    label_ClickHereToProceed: "Clique aqui para configurar!",
    label_Search: "Buscar",
    label_ReaderSerialNumberSearch: "PESQUISA POR NÚMERO SERIAL",
    label_Delete: "Desativar",
    label_Active: "Ativo",
    label_Discarded: "Deletados",
    label_Updated: "Atualizado",
    label_NotUpdated: "Não atualizado",
    label_Eds: "EDS",
    label_Block: "Bloquear",
    label_Unblock: "Desbloquear",
    label_Add: "Adicionar",
    label_Version: "Versão",
    label_AccessToTheSite: "Acesso ao site",
    label_TermsOfUseDeclined: "Termos de Uso recusado",
    label_AreYouSure: "Tem certeza?",
    label_SendRequest: "ENVIAR REQUISIÇÃO",
    label_Days: "Dias",
    label_In: "De",
    label_AddSatelliteSite: "ADICIONAR SITE SATÉLITE",
    label_AddPrimarySite: "ADICIONAR SITE PRIMÁRIO",
    label_Success: "Sucesso",
    label_Send: "Enviar",
    label_Feedback: "Comentários / Suporte",
    label_EmailTestReport: "Relatório de Testes",
    label_Message: "Mensagem",
    label_To: "Para",
    label_Subject: "Assunto",
    label_Comment: "Mensagem",
    label_Back: "VOLTAR",
    label_Pending: "PENDENTE",
    label_WelcomeTo: "Bem vindo a",
    label_Last30Days: 'ÚLTIMOS 30 DIAS',
    label_Last60Days: 'ÚLTIMOS 60 DIAS',
    label_Last90Days: 'ÚLTIMOS 90 DIAS',
    label_Last120Days: 'ÚLTIMOS 120 DIAS',
    label_LastWeek: "ÚLTIMA SEMANA",
    label_LastMonth: "ÚLTIMO MÊS",
    label_AllPeriod: "TODO PERIODO",
    label_Decline: "RECUSAR",
    label_Skip: "PULAR",
    label_Next: "PRÓXIMO",
    label_SiteName: "NOME DO SITE",
    label_ProductNumber: "NÚMERO DO PRODUTO",
    label_SerialNumber: "NÚMERO SERIAL",
    label_Done: "FINALIZAR",
    label_AcceptTerms: "ACEITAR OS TERMOS",
    label_TermsOfUse: "Termos de Uso",
    label_SearchForPatients: "Pesquise por pacientes...",
    label_SearchForUsers: "Pesquise por usuarios...",
    label_SearchForSites: "Pesquise por sites...",
    label_SearchForCartridges: "Pesquise por cartuchos...",
    label_PositiveTests: "TESTES POSITIVOS",
    label_Recurring: "RECORRENTES",
    label_Current: "ATUAL",
    label_ReadersWithErrors: "Readers with Errors",
    label_ReadersWithErrorsLog: "Readers with error logs",
    label_TotalDevices: "Total Readers",
    label_FirmwareCurrent: "Firmware atual",
    label_FirmwareOuDate: "Firmware desatualizado",
    tableHeaderClinicalTesting: "Teste clinico",
    tableHeaderResearchProject: "Projeto de pesquisa",
    tableHeaderPilotSites: "Site piloto",
    tableHeaderEdsSite: "EDS Site",
    label_MalariaTests: "MALARIA TESTES",
    label_HbTests: "Hb VARIANT TESTES",
    label_SignIn: "ENTRAR",
    label_SignUp: "CADASTRAR-SE",
    label_ContactSupport: "Contatar o suporte",
    label_Start: "COMEÇAR",
    label_Yes: "Sim",
    label_No: "Não",
    label_MobileTests: "TESTES MOBILE",
    label_CartridgesTests: "CARTUCHOS",
    label_Tests: "Tests",
    label_Test: "TESTE",
    label_TestSingle: "TESTE",
    label_Run: "Feitos",
    label_PatientTestHistory: "HISTÓRICO DO PACIENTE",
    label_PatientTestReport: "RELATÓRIO DO PACIENTE",
    label_ClickToViewTestDetails: "Clique para ver os detalhes do teste",
    label_ClickToViewPatientHistory: "Clique para ver o historico do paciente",
    label_ClickToViewTestReport: "Clique para ver o relatório",
    label_ClickToViewReaderDetails: "Clique para ver os detalhes do readers",
    label_ZipInvalid: "Código Inválido",
    label_Inactive: "Inativos",
    label_Registering: "Registrando",
    label_Positive: "POSITIVOS",
    label_Avaliable: "DISPONIVEIS",
    label_Percent: "Porcentagem",
    label_Total: "Total",
    label_Used: "USADOS",
    label_Save: "SALVAR",
    label_Cancel: "CANCELAR",
    label_AddSite: "ADICIONAR SITE",
    label_TestTypes: "TIPOS DE TESTES",
    label_Malaria: "Malaria",
    label_Hb: "Hb Variant",
    label_Both: "Ambos",
    label_Roles: "Regras",
    label_AlreadyOrdered: "JÁ PEDIDOS",
    label_AlreadyRegistered: 'Já cadastrado?',
    label_Received: "RECEBIDOS",
    label_PlaceOrder: "FAZER O PEDIDO",
    label_ShippingDetails: "DETALHES DE ENTREGA",
    label_DeliveryEstimate: "ENTREGA ESTIMADA",
    label_Ordered: 'PEDIDOS',
    label_Quantity: "QTD",
    label_Boxes: "CAIXAS",
    label_CartridgesType: "TIPOS DE CARTUCHO",
    label_Cartridges: "CARTUCHOS",
    label_Failed: 'FALHAS',
    label_CartridgesAddReceived: "ADICIONAR CARTUCHOS RECEBIDOS",
    label_SearchForPatientNameOrId: "Pesquisar por nome do paciente ou id",
    /**
     * TABLES
     */
    ...TableHeader,

    /**
     * SIGN IN
     */
    ...SignIn,

    /**
    * /FORGOT PASSWORD
    */
    ...ForgotPassword,

    /**
    * /NEW PASSWORD
    */
    ...NewPassword,

    /**
     * /REGISTER YOUR SITE
     */
    ...RegisterSite,

    /**
     * SITE CONFIRMATION REGISTRATION
     * */
    ...SiteRegistrationConfirmation,


    /**
    * SITE REGISTER CONFIRMED
    * */
    ...SiteRegisterConfirmed,

    /**
     * WIZARD
     */
    ...Wizard,

    /**
     * SUB-HEADER
     * 
     */

    placeHolderSelectSites: "TODOS SITES",

    placeHolderSelectDate30: "ÚLTIMOS 30 DIAS",
    placeHolderSelectDate60: "ÚLTIMOS 60 DIAS",
    placeHolderSelectDate90: "ÚLTIMOS 90 DIAS",
    placeHolderSelectDate120: "ÚLTIMOS 120 DIAS",

    /**
     * MANAGEMENT PORTAL
     */
    ...ManagementPortalUser,

    /**
     * PATIENTS
     */
    ...Patients,

    /**
     * READERS
     */
    ...Readers,

    /**
     * Cartridges
     */
    ...Cartridges,

    /**
     * SYSTEM ADMIN 
     */
    ...SystemAdminPageContainer,

    /**
    *SYSTEM ADMIN - USER MANAGEMENT
    */
    ...SystemAdminUserManagement,

    /**
     * SYSTEM ADMIN USER MANAGEMENT - MODAL INVITE USER
     */
    title_SystemAdminTools_UserManagementModalInviteUser: "CONVIDAR USUÁRIO",

    /**
     * SYSTEM ADMIN USER SITE MANAGEMENT
     * 
     */
    ...SystemAdminSiteUser,

    /**
     * VALIDATION MESSAGES
     */
    ...ValidationsMessages,

    ...Header,

    /**
     * Tutorial
     */
    ...Tutorial,

    /**
     * ..TermsOfUseSiteUser
     */
    ...TermsOfUseSiteUser,


    /**
     * ADMIN
     */

    ...AdminManagementPortal,
    ...AdminDevices,
    ...AdminAddSite,
    ...InviteEds,
    ...VerifyReader
}


export default translations;