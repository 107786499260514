import React, { Fragment } from 'react';
import './index.scss'
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PatientsActions } from "redux/reducers"
import SendEmail from "components/modalSendEmail"
import imgEmail from "assets/img/header-new/table_email_icon.png";
import imgClose from "assets/img/close_invite_user_bg_icn.png";
import { withRouter } from "react-router-dom";
import HeaderAdmin from "features/admin/header";
import queryString from 'query-string';
import HeaderUser from "features/user/header";
import printJS from "print-js";

class PatientPdf extends React.Component {

    state = {
        modalEmail: false,
        url: "",
        test_id: ""
    }

    redirect = () => {
        this.props.PatientsActions.setPatientHistoryUrl("")

        if (this.props.userData.isAdmin == 2) {
            return window.location.href = process.env.REACT_APP_FRONT_URL + '/admin/patients'
        } else {
            return window.location.href = process.env.REACT_APP_FRONT_URL + '/'
        }
    }

    componentWillMount = async () => {
        const { test_id } = this.props;

        if (!test_id) return this.redirect();

        const url = await this.props.PatientsActions.getTestUrl('pdf', test_id)

        this.setState({ url, test_id: test_id })
    }

    render() {
        return (
            <Fragment>
                {this.state.modalEmail &&
                    <SendEmail
                        ids={[this.state.test_id]}
                        open={this.state.modalEmail}
                        onClose={() => this.setState({ modalEmail: false })}
                    />
                }
                <div className="patient-pdf-container" ref={this.myRef} id="page-patient-pdf">
                    {this.props.userData.isAdmin == 2 ?
                        <HeaderAdmin router={this.props.router} />
                        :
                        <HeaderUser router={this.props.router} />
                    }
                    <div className='displayFlex' style={{ backgroundColor: "#ffa300", display: "flex", alignItems: "center" }}>
                        <div className="" style={{ flex: 1, display: "flex", alignItems: "center" }} />
                        <div className="" style={{ flex: 1, display: "flex", alignItems: "center" }}>
                            <div className="patient-history-bar">
                                {this.props.t("label_PatientTestReport")}
                            </div>
                        </div>
                        <div className="" style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end", cursor: "pointer" }}>
                            <img src={imgEmail} onClick={() => this.setState({ modalEmail: true })} />
                            <img src={imgClose} style={{ marginLeft: "20px", marginRight: "50px" }} onClick={() => this.redirect()} />
                        </div>
                    </div>
                    <div className={"webview"}>
                        <iframe className={"webview"} src={`${this.state.url}`} id="printf" name="printf" />
                    </div>
                    <div className="line" />
                </div>
            </Fragment >
        )
    }
}

const mapStateToProps = state => ({
    test_id: state.PatientsReducer.test_id,
    userData: state.UserReducer.userData
});

const mapDispatchToProps = dispatch => ({
    PatientsActions: bindActionCreators(PatientsActions, dispatch)
});

PatientPdf = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientPdf);

export default PatientPdf = withTranslation()(PatientPdf)