import React from "react";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CustomCheckBox from "components/customCheckbox";
import "./style.scss";

class Filters extends React.Component {
	toggleFilter = (filterName, value) => {
		let filters = { ...this.props.filter };
		filters[filterName] = value;
		this.props.toggleFilter(filters);
	};

	render() {
		console.log("filters", this.props);
		return (
			<div className="divFilter">
				<div className="titleFilter mt50">SITE</div>
				<div className="mb-25">
					<div className="divWithCheckbox">
						<div className="w100" style={{ height: "36px" }}>
							<CustomSelectDiv
								options={this.props.filter.sites}
								value={this.props.filter.site}
								onChange={(val) => this.props.selectSite(val)}
							/>
						</div>
					</div>
				</div>

				{this.props.diseases && this.props.diseases.length > 1 && (
					<>
						<div className="titleFilter">TEST TYPE</div>
						<div className="mb-25">
							<div className="divWithCheckbox">
								<CustomCheckBox
									action={() => {
										return this.toggleFilter("diseases", {
											...this.props.filter.diseases,
											malaria: !this.props.filter.diseases
												.malaria,
											both: false,
										});
									}}
									active={this.props.filter.diseases.malaria}
								/>{" "}
								MALARIA
							</div>
							<div className="divWithCheckbox">
								<CustomCheckBox
									action={() => {
										return this.toggleFilter("diseases", {
											...this.props.filter.diseases,
											hb: !this.props.filter.diseases.hb,
											both: false,
										});
									}}
									active={this.props.filter.diseases.hb}
								/>{" "}
								Hb VARIANT
							</div>
						</div>
					</>
				)}

				{this.props.filter &&
					this.props.filter.test_control_items.length > 0 && (
						<>
							<div className="titleFilter">
								POSITIVE / NEGATIVE CONTROL
							</div>
							<div className="mb-25">
								{this.props.filter.test_control_items.includes(
									"POSITIVE CONTROL"
								) && (
									<div className="divWithCheckbox">
										<CustomCheckBox
											action={() => {
												return this.toggleFilter(
													"test_control",
													{
														...this.props.filter
															.test_control,
														positive: !this.props
															.filter.test_control
															.positive,
													}
												);
											}}
											active={
												this.props.filter.test_control
													.positive
											}
										/>{" "}
										POSITIVE CONTROL
									</div>
								)}
								{this.props.filter.test_control_items.includes(
									"NEGATIVE CONTROL"
								) && (
									<div className="divWithCheckbox">
										<CustomCheckBox
											action={() => {
												return this.toggleFilter(
													"test_control",
													{
														...this.props.filter
															.test_control,
														negative: !this.props
															.filter.test_control
															.negative,
													}
												);
											}}
											active={
												this.props.filter.test_control
													.negative
											}
										/>{" "}
										NEGATIVE CONTROL
									</div>
								)}
							</div>
						</>
					)}
				{this.props.filter &&
					this.props.filter.test_types_options.length > 0 && (
						<>
							<div className="titleFilter">TEST RESULT</div>
							<div className="mb-25 test-result">
								{this.props.filter.test_types_options.map(
									(item) => {
										return (
											<div className="divWithCheckbox">
												<CustomCheckBox
													action={() => {
														if (
															this.props.filter.test_result.includes(
																item
															)
														) {
															return this.toggleFilter(
																"test_result",
																this.props.filter.test_result.filter(
																	(test) =>
																		test !=
																		item
																)
															);
														} else {
															return this.toggleFilter(
																"test_result",
																[
																	...this
																		.props
																		.filter
																		.test_result,
																	item,
																]
															);
														}
													}}
													active={this.props.filter.test_result.includes(
														item
													)}
												/>{" "}
												{item}
											</div>
										);
									}
								)}
								{/* {this.props.diseases.includes(1) &&
                                <>
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                if (this.props.filter.test_result.includes("POSITIVE")) {
                                                    return this.toggleFilter("test_result", this.props.filter.test_result.filter((test) => test != "POSITIVE"))
                                                } else {
                                                    return this.toggleFilter("test_result", [...this.props.filter.test_result, "POSITIVE"])
                                                }
                                            }}
                                            active={this.props.filter.test_result.includes("POSITIVE")}
                                        /> POSITIVE
                                    </div>
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                if (this.props.filter.test_result.includes("NEGATIVE")) {
                                                    return this.toggleFilter("test_result", this.props.filter.test_result.filter((test) => test != "NEGATIVE"))
                                                } else {
                                                    return this.toggleFilter("test_result", [...this.props.filter.test_result, "NEGATIVE"])
                                                }
                                            }}
                                            active={this.props.filter.test_result.includes("NEGATIVE")}
                                        /> NEGATIVE
                                </div>
                                </>
                            } */}
								{/* {this.props.diseases.includes(2) &&
                                <>
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                if (this.props.filter.test_result.includes("Phenotype A")) {
                                                    return this.toggleFilter("test_result", this.props.filter.test_result.filter((test) => test != "Phenotype A"))
                                                } else {
                                                    return this.toggleFilter("test_result", [...this.props.filter.test_result, "Phenotype A"])
                                                }
                                            }}
                                            active={this.props.filter.test_result.includes("Phenotype A")}
                                        /> Phenotype A
                                    </div>
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                if (this.props.filter.test_result.includes("Phenotype A")) {
                                                    return this.toggleFilter("test_result", this.props.filter.test_result.filter((test) => test != "Phenotype A"))
                                                } else {
                                                    return this.toggleFilter("test_result", [...this.props.filter.test_result, "Phenotype A"])
                                                }
                                            }}
                                            active={this.props.filter.test_result.includes("Phenotype A")}
                                        /> BOTH
                                    </div>
                                </>
                            } */}
							</div>
						</>
					)}
				<div className="titleFilter">READER LOCATION</div>
				<div className="mb-25">
					<div className="divWithCheckbox">
						<CustomCheckBox
							action={() => {
								return this.toggleFilter("reader_location", {
									...this.props.filter.reader_location,
									on_site: !this.props.filter.reader_location
										.on_site,
								});
							}}
							active={this.props.filter.reader_location.on_site}
						/>{" "}
						ONSITE
					</div>
					<div className="divWithCheckbox">
						<CustomCheckBox
							action={() => {
								return this.toggleFilter("reader_location", {
									...this.props.filter.reader_location,
									out_site: !this.props.filter.reader_location
										.out_site,
								});
							}}
							active={this.props.filter.reader_location.out_site}
						/>{" "}
						OFFSITE
					</div>
				</div>

				{this.props.filter.testLocations &&
					this.props.filter.testLocations.length && (
						<>
							<div className="titleFilter mt50">
								TEST LOCATION
							</div>
							<div className="mb-25">
								<div className="divWithCheckbox">
									<div
										className="w100"
										style={{ height: "36px" }}
									>
										<CustomSelectDiv
											options={
												this.props.filter.testLocations
											}
											value={
												this.props.filter.testLocation
											}
											onChange={({ value }) =>
												this.toggleFilter(
													"testLocation",
													value
												)
											}
										/>
									</div>
								</div>
							</div>
						</>
					)}
			</div>
		);
	}
}

export default Filters;
