import {
SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS,
SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER,
SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER,
SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER,
SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE,
SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE,
SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST,
SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES,
SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER,
SET_SORT_TYPE,
SET_SORTS,
} from '../reducer'

export const toogleCheckBoxAllUsers = bool => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS,
  bool
});

export const toogleCheckBoxUniqUser = arraySelecteds => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER,
  arraySelecteds
});

export const searchUser = value => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER,
  value
});

export const toogleSiteFilter = value => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER,
  value
});

export const toogleDateFilter = value => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER,
  value
});

export const toogleCard = id => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST,
  id
});

export const changePage = page => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE,
  page
});

export const changeQtyPage = qty => ({
  type: SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE,
  qty
});

export const setSort = payload => ({type: SET_SORTS, payload })

export const setSortType = payload => ({type: SET_SORT_TYPE, payload })


export const initialValues = () => ({ type: SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES });
