import * as ReadersServices from "../services/Readers";

const initial = {
    readers: [],
    readers_deactivated: {},
    readers_count: {},
    readers_offline: 0,
    closed_readers_alert: false,
}

const types = {
    SET_READERS_DEACTIVATED: "SET_READERS_DEACTIVATED",
    SET_READERS_COUNT: "SET_READERS_COUNT",
    SET_READERS: "GET_READERS",
    SET_READERS_OFFLINE: "SET_READERS_OFFLINE",
    SET_CLOSED_READERS_ALERT: "SET_CLOSED_READERS_ALERT"
}

export const getReadersAdmin = (date) => async (dispatch) => {
    const payload = await ReadersServices.getAdminDevices(date)
    dispatch({ type: types.SET_READERS, payload })
}

export const getReadersDeactivated = (date) => async (dispatch) => {
    const payload = await ReadersServices.getReadersDeactivated(date)
    dispatch({ type: types.SET_READERS_DEACTIVATED, payload })
}

export const getReadersCount = (date) => async (dispatch) => {
    const payload = await ReadersServices.getDevicesCounters(date)
    dispatch({ type: types.SET_READERS_COUNT, payload })
}

export const setClosedReaders = () => ({ type: types.SET_CLOSED_READERS_ALERT });

export const getReaders = (date = 0, site = null) => async (dispatch) => {
    const payload = await ReadersServices.getReaders(date, site)
    dispatch({ type: types.SET_READERS, payload })
}

export const getReadersOffline = () => async (dispatch) => {
    const payload = await ReadersServices.getReadersOfflineCounter()
    dispatch({ type: types.SET_READERS_OFFLINE, payload: payload.readers_off })
}

export const getAuditLog = (period = 0, serial_number) => async (dispatch) => {
    try {
        return await ReadersServices.getAuditLog(period, serial_number)
    } catch (e) {
        return await Promise.reject(e);
    }
}

const ReaderReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.SET_READERS: {
            return {
                ...state,
                readers: action.payload
            }
        }
        case types.SET_READERS_OFFLINE: {
            return {
                ...state,
                readers_offline: action.payload
            }
        }

        case types.SET_CLOSED_READERS_ALERT: {
            return {
                ...state,
                closed_readers_alert: true
            }
        }
    }
    return state
}

export default ReaderReducer