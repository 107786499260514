import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import $ from 'jquery';
import axios, { url } from 'helper/axiosHelper';
export default class PickerLogo extends Component {
    static propTypes = {
        prop: PropTypes
    };

    state = {
        file: ''
    };
    _select = () => {
        $('#fileToUpload').trigger('click');
    };

    _fileSelected = (event) => {
        if (event.target.files.length !== 1) return false;
        const file = event.target.files[0];
        let data = new FormData();
        data.append('file', file);
        $.ajax({
            type: 'POST',
            url: `${url}/site/logo`,
            headers: {
                Authorization: `Bearer ${this.props.token}`
            },
            data,
            dataType: 'JSON',
            processData: false,
            contentType: false
        }).then(({ url, filename }) => {
            this.setState({
                file: url
            });
            this.props.setFile({ url, filename });
        });
    };

    componentWillMount() {
        if (!!this.props.file && this.props.file.length > 0) {
            this.setState({
                file: this.props.file
            });
        }
    }
    render() {
        return (
            <Fragment>
                <div className="logo-picker">
                    <div className="logo-image" onClick={() => this._select()}>
                        <img
                            src={
                                this.state.file && this.state.file.length > 0
                                    ? this.state.file
                                    : this.props.img
                            }
                        />
                        <input
                            type="file"
                            name="fileUpload"
                            id="fileToUpload"
                            style={{ display: 'none' }}
                            onChange={(event) => this._fileSelected(event)}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}
