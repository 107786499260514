import React from "react";
import * as SiteManagementAdminReducer from "redux/reducers/SiteManagementAdminReducer";
import * as UserReducer from "redux/reducers/UserReducer";

import { bindActionCreators } from "redux";
import AddSiteUser from "components/addSiteUser";
import CustomModal from "components/customModal";
import { connect } from "react-redux";
import "./style.scss";
class AddSite extends React.Component {
  render() {
    const { user } = this.props;
    const { sites } = user;
    let name = "";
    if (user.entity_name && user.entity_name.length) {
      name = user.entity_name;
    } else {
      name = sites[0].name;
    }

    return (
      <div className="modal-add-site">
        <CustomModal
          title={
            this.props.data && this.props.data.id ? "EDIT SITE" : "ADD SITE"
          }
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <AddSiteUser
            {...this.props}
            fieldsReadOnly={this.props.data ? ["disease_types", "name"] : []}
            handleChangePrev={() => this.props.onClose()}
            save={async (values) => {
              if (values.id) {
                await this.props.SiteManagementAdminReducer.updateSite(values);
                if(values.name == name){
                    this.props.UserReducer.setSiteLogo(values.logo)
                }
              } else {
                await this.props.SiteManagementAdminReducer.createSite(values);
              }

              return this.props.onClose("save");
            }}
          />
        </CustomModal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SiteManagementAdminReducer: bindActionCreators(
    SiteManagementAdminReducer,
    dispatch
  ),
  UserReducer: bindActionCreators(UserReducer, dispatch)
});

const mapStateToProps = (state) => ({
  user: state.UserReducer.userData
});
export default connect(mapStateToProps, mapDispatchToProps)(AddSite);
