import React, { Fragment, useState, useEffect } from 'react';
import CustomModal from 'components/customModal'
import CustomCheckbox from 'components/customCheckbox'
import CustomButton from 'components/customButton';
import NoLogo from 'assets/img/placeholder_nosite.png';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as services from 'redux/actions'
import axios from 'helper/axiosHelper'
import './index.scss';

class ModalDownloadEdsFiles extends React.Component {

    state = {
        files_selecteds: [],
        all: false,
        files: []
    }

    componentWillMount() {
        this.props.services.getEdsFiles(this.props.data.id)
            .then((files) => this.setState({ files }))
    }

    download = () => {
        this.state.files_selecteds.map((fl) => {
            const file = this.state.files[fl];
            var link = document.createElement("a");
            link.download = file.filename;
            link.href = file.url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    render() {
        return (
            <div className="modalEdsFiles">
                <CustomModal title="AVALIABLE EDS FILES FOR DOWNLOAD" open={this.props.open} onClose={this.props.onClose}>
                    <div className='padding-container'>
                        <div className='primary-site-container'>
                            <img className='img mr' src={this.props.data.site_logo && this.props.logo.length > 0 ? this.props.data.site_logo : NoLogo} />
                            <div className='sitename'>{this.props.data.name}</div>
                        </div>
                        <div className='line'>
                            <CustomCheckbox active={this.state.all} action={() => this.setState({ all: !this.state.all, files_selecteds: !!this.state.all ? [] : this.state.files.map((file, key) => key) })} />
                            <label>
                                FILES ({this.props.data.eds_files})
                            </label>
                        </div>
                        <hr />
                        {this.state.files.map((file, key) => {
                            return (
                                <div className='line'>
                                    <CustomCheckbox
                                        active={this.state.files_selecteds.includes(key)}
                                        action={() => {
                                            this.setState({
                                                files_selecteds: this.state.files_selecteds.includes(key) ?
                                                    this.state.files_selecteds.filter((f) => f != key) :
                                                    [...this.state.files_selecteds, key]
                                            })
                                        }}
                                    />
                                    <label>
                                        {file.filename}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                    {this.state.files_selecteds.length > 0 &&
                        <div className='buttonContainer'>
                            <CustomButton
                                handleChange={this.download}
                                value={'DOWNLOAD SELECTED FILES'}
                            />
                        </div>
                    }
                </CustomModal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch)
})

export default connect(null, mapDispatchToProps)(ModalDownloadEdsFiles);
