import React from "react";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CustomCheckBox from "components/customCheckbox"
import "./style.scss"
import { withTranslation } from 'react-i18next';

class Filters extends React.Component {


    toggleFilter = (filterName, value) => {
        let filters = { ...this.props.filters };
        filters[filterName] = value;
        this.props.toggleFilter(filters)
    }

    render() {

        const statusEnum = {
            0: this.props.t("label_StatusSiteReaderRed"),
            1: this.props.t("label_StatusSiteReaderYellow"),
            2: this.props.t("label_StatusSiteReaderGreen")
        }

        console.log("filters", this.props.filters)
        return (
            <div className="divFilter">
                <span className="titleFilter">ERROR STATUS</span>
                <div className="mb-15">
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("with_errors", this.props.filters.with_errors ? this.props.filters.with_errors = false : this.props.filters.with_errors = true)
                            }}
                            active={this.props.filters.with_errors}
                        /> Readers with Errors
                    </div>
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("no_errors", this.props.filters.no_errors ? this.props.filters.no_errors = false : this.props.filters.no_errors = true)
                            }}
                            active={this.props.filters.no_errors}
                        /> Readers without Errors
                    </div>
                </div>
                {this.props.filters.readers_hardware_last_version && this.props.filters.readers_hardware_last_version.length > 0 &&
                    <>
                        <span className="titleFilter">FIRMWARE VERSION</span>
                        {this.props.filters.readers_hardware_last_version.map((vs) => {
                            const active = this.props.filters.version.includes(vs);
                            return (
                                <div className="mb-15">
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                return this.toggleFilter("version", !!active ? this.props.filters.version.filter((i => i != vs)) : [...this.props.filters.version, vs])
                                            }}
                                            active={active}
                                        /> {vs}
                                    </div>
                                </div>
                            )
                        })}
                    </>
                }
                <span className="titleFilter">FIRMWARE STATUS</span>
                <div className="mb-15">
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("firmware_status", { ...this.props.filters.firmware_status, current: !this.props.filters.firmware_status.current })
                            }}
                            active={this.props.filters.firmware_status.current}
                        /> CURRENT FIRMWARE
                    </div>
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("firmware_status", { ...this.props.filters.firmware_status, not_current: !this.props.filters.firmware_status.not_current })
                            }}
                            active={this.props.filters.firmware_status.not_current}
                        /> FIRMWARE NOT CURRENT
                    </div>
                </div>
                <span className="titleFilter">SITE TYPE</span>
                <div className="mb-15">
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("site_type", { ...this.props.filters.site_type, single: !this.props.filters.site_type.single })
                            }}
                            active={this.props.filters.site_type.single}
                        /> SINGLE SITE
                    </div>
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("site_type", { ...this.props.filters.site_type, multi: !this.props.filters.site_type.multi })
                            }}
                            active={this.props.filters.site_type.multi}
                        /> MULTI SITE
                    </div>
                </div>
                <span className="titleFilter">CITY</span>
                <div className="mb-15">
                    <div className="divWithCheckbox">
                        <div className="w100" style={{ height: "36px" }}>
                            <CustomSelectDiv
                                options={this.props.filters.readers_city}
                                value={this.props.filters.city ? this.props.filters.city.value : ""}
                                onChange={val => this.toggleFilter("city", val)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Filters);