const translations = {

    label_ReadersDiagnosticSupportCode: "READER DIAGNOSTIC SUPPORT CODE FOR",
    title_Readers: "READERS",
    label_Online: "ONLINE",
    label_ReaderNotFound: "Reader Not Found",
    label_StatusOk: "OK",
    label_StatusNotOk: "NOT OK",
    label_ShippedDate: "DATE SHIPPED",
    label_ShippedMethod: "Shipped Method",
    label_TrackingNumber: "Tracking Number",
    label_UsageInPeriod: "CHANGE IN PERIOD",
    label_HardwareVersion: "Hardware Version",
    label_FirmwareVersion: "Firmware Version",
    label_InstalledFirmwareVersion: "Installed Firmware Version",
    label_Shipped: "Shipped",
    label_NotConnected: "SETUP CONNECTION",
    label_FirmwareLastUpdate: "Software last version",
    label_ReaderDetails: "READER DETAILS",
    label_RegistrationDate: "Registration Date",
    label_RegistrationTime: "Registration Time",
    label_LastDataSync: "Last data sync",
    label_NumberOfRecords: "Number of Records",
    label_ReaderSettings: "Reader Settings",
    label_UpdateNotApplied: "UPDATE NOT APPLIED",
    label_UpdateApplied: "UPDATE APPLIED",
    label_DateFormat: "Date Format",
    label_Clock: "Clock",
    label_Gender: "Gender",
    label_Weight: "Weight",
    label_Height: "CM",
}

export default translations;