import { FILTRO_DATE_ENUM, ALL_SITES } from "helper/enum";

const initial = {
  CARD_SELECTED: 2,

  ...ALL_SITES,

  usersBlocked: 0,

  usersActive: 0,

  DEACTIVATED_USERS: 0,

  UPDATED_USERS: 0,

  ...FILTRO_DATE_ENUM,

  SEARCH_USER: "",

  ALL_USERS_SELECTEDS: false,

  USERS_SELECTEDS: [],

  USERS_DB: [],

  PAGE: 0,

  QTY_PAGE: 10,

  sorts: "status",
  sort_type: "asc"
};

export default initial;
