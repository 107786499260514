import React from "react";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CustomCheckBox from "components/customCheckbox"
class Filters extends React.Component {


    toggleFilter = (filterName, value) => {
        let filters = { ...this.props.filters };
        filters[filterName] = value;
        this.props.toggleFilter(filters)
    }

    render() {
        console.log("filters", this.props.filters)
        return (
            <div className="divFilter">
                <div className="titleFilter mt50">SITE TYPE</div>
                <div className="mb-25">
                    <div className="divWithCheckbox">
                        <div className="w100" style={{ height: "36px" }}>
                            <CustomSelectDiv
                                options={this.props.filters.site_types}
                                value={this.props.filters.site_type}
                                onChange={val => this.toggleFilter("site_type", val.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="titleFilter mt50">CITY</div>
                <div className="mb-25">
                    <div className="divWithCheckbox">
                        <div className="w100" style={{ height: "36px" }}>
                            <CustomSelectDiv
                                options={this.props.filters.cities}
                                value={this.props.filters.city}
                                onChange={val => this.toggleFilter("city", val.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="titleFilter mt50">COUNTRY</div>
                <div className="mb-25">
                    <div className="divWithCheckbox">
                        <div className="w100" style={{ height: "36px" }}>
                            <CustomSelectDiv
                                options={this.props.filters.countries}
                                value={this.props.filters.country}
                                onChange={val => this.toggleFilter("country", val.value)}
                            />
                        </div>
                    </div>
                </div>


                {this.props.filters && this.props.filters.diseases.length > 1 &&
                    <>
                        <div className="titleFilter">TEST TYPE</div>
                        <div className="mb-25">
                            <div className="divWithCheckbox">
                                <CustomCheckBox
                                    action={() => {
                                        return this.toggleFilter("disease", { ...this.props.filters.disease, malaria: !this.props.filters.disease.malaria, both: false })
                                    }}
                                    active={this.props.filters.disease.malaria}
                                /> MALARIA
                            </div>
                            <div className="divWithCheckbox">
                                <CustomCheckBox
                                    action={() => {
                                        return this.toggleFilter("disease", { ...this.props.filters.disease, hb: !this.props.filters.disease.hb, both: false })
                                    }}
                                    active={this.props.filters.disease.hb}
                                /> Hb VARIANT
                            </div>
                        </div>
                    </>
                }
                {/* {this.props.filter && this.props.filter.test_types_options.length > 0 &&
                    <>
                        <div className="titleFilter">TEST RESULT</div>
                        <div className="mb-25">
                            {this.props.filter.test_types_options.map((item) => {
                                return (
                                    <div className="divWithCheckbox">
                                        <CustomCheckBox
                                            action={() => {
                                                if (this.props.filter.test_result.includes(item)) {
                                                    return this.toggleFilter("test_result", this.props.filter.test_result.filter((test) => test != item))
                                                } else {
                                                    return this.toggleFilter("test_result", [...this.props.filter.test_result, item])
                                                }
                                            }}
                                            active={this.props.filter.test_result.includes(item)}
                                        /> {item}
                                    </div>
                                )
                            })}
                        </div>
                    </>
                } */}
                {/* <div className="titleFilter">READER LOCATION</div>
                <div className="mb-25">
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("reader_location", { ...this.props.filter.reader_location, on_site: !this.props.filter.reader_location.on_site })
                            }}
                            active={this.props.filter.reader_location.on_site}
                        /> ONSITE
                    </div>
                    <div className="divWithCheckbox">
                        <CustomCheckBox
                            action={() => {
                                return this.toggleFilter("reader_location", { ...this.props.filter.reader_location, out_site: !this.props.filter.reader_location.out_site })
                            }}
                            active={this.props.filter.reader_location.out_site}
                        /> OFFSITE
                    </div>
                </div> */}

                {/* {this.props.filter.testLocations && this.props.filter.testLocations.length &&
                    <>
                        <div className="titleFilter mt50">TEST LOCATION</div>
                        <div className="mb-25">
                            <div className="divWithCheckbox">
                                <div className="w100" style={{ height: "36px" }}>
                                    <CustomSelectDiv
                                        options={this.props.filter.testLocations}
                                        value={this.props.filter.testLocation}
                                        onChange={({ value }) => this.toggleFilter("testLocation", value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                } */}

            </div>
        )
    }
}

export default Filters;