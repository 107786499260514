import React, { useState, useRef, Fragment, useEffect } from 'react';
import CustomError from 'components/customErrorBox';

import './style.scss';

const InputDefault = ({ leftIcon, rightIcon, onClickRightIcon, value, onChange, placeHolder, error, type, max }) => {

    const [placeholder, setPlaceHolder] = useState(placeHolder);

    const handleClick = e => {
        if (!node.current.contains(e.target)) return setPlaceHolder(placeHolder)
    }

    const node = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    return (
        <Fragment>
            <div className="inputDefaultContainer">
                <img src={leftIcon} className="input-default-icon" />
                <input
                    type={!!type ? type : "text"}
                    ref={node}
                    value={value}
                    placeholder={placeholder}
                    className="inputDefault"
                    onChange={(e) => onChange(e.target.value)}
                    onClick={() => setPlaceHolder("")}
                    maxLength={max || 40}
                />
                {!!rightIcon &&
                    <img src={rightIcon} className="input-default-icon right cPointer" onClick={() => onClickRightIcon()} />
                }
            </div>
            {error && error.length > 0 &&
                <CustomError errorMessage={error} />
            }
        </Fragment>
    )
}

export default InputDefault;
