import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
export default class Loading extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {

        if(!this.props.show) return null;
        return (
            <div class="div_loading_general">
                <div class="loader_circle"></div>
            </div>
        )
    }
}
