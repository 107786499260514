const translations = {
    label_Clinical: "Clinico",
    label_Type: "Tipo",
    label_Research: "Projeto de pesquisa",
    label_Pilot: "Piloto",
    label_SingleSite: "Único Site",
    label_MultiSite: "Multiplos sites",
    label_ClinicalTesting: "Site de teste clinico"
}

export default translations;