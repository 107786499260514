import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import logoHemex from 'assets/img/footer_hemex_health_logo@2x.png';
import moment from "moment";

export default class Footer extends Component {
  static propTypes = {
    prop: PropTypes
  };

  render() {
    return (
      <Fragment>
        <div className="footer">
          <div className="display-flex">
            <div className="left-footer">
              © Copyright {moment().format("YYYY")}<b>HEMEX HEALTH</b> - All Rights Reserved
                    </div>
            <div className="right-footer">
              powered by
                        <img className="logo-footer" src={logoHemex} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
