import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import ReactTooltip from "react-tooltip";

import ArrowSort from "assets/img/arrowDown.png";
import ArrowSortDown from "assets/img/arrowDown.png";

import CustomCheckbox from "../../customCheckbox";

class GenericTableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { check: false };
  }

  static defaultProps = {
    cols: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checked && this.props.checked != this.state.check) {
      this.setState({ check: this.props.checked })
    }
  }

  renderStyle = val => {
    switch (val) {
      case 5:
        return style.col5;
      case 10:
        return style.col10;
      case 13:
        return style.col13;
      case 15:
        return style.col15;
      case 20:
        return style.col20;
      case 25:
        return style.col25;
      case "status":
        return style.textCenter5;
      case "id":
        return style.id;
      case "center5":
        return style.center5;
      case "center10":
        return style.center10;
      case "center15":
        return style.center15;
      case "site_logo":
        return [style.center5, style.justifyContent];
      case "style_logo":
        return style.style_logo;
      default:
        return style.col10;
    }
  };

  renderCheck = (index, valStyle, check) => {
    return (
      <div
        key={index}
        style={{ width: "5%" }}
        className={"check-item"}
      >
        <CustomCheckbox active={check} action={() => this.handleCheck()} style={{ marginRight: "0 !important" }} />
      </div>
    );
  };

  handleCheck = () => {
    const { checkAction } = this.props;
    const { check } = this.state;

    if (checkAction) {
      checkAction(!check);
    }

    this.setState({
      ...this.state,
      check: !check
    });
  };


  arrow = (sort_camp, index, sortTitle) => {

    if (this.props.onClick == undefined) return '';

    if (sort_camp === undefined || sort_camp == null || sort_camp == false) return '';

    let arrowStyle = {
      height: "10px",
      width: "auto",
      marginLeft: '5px',
      cursor: 'pointer',
      marginTop: `2px`,
    }

    if (this.props.sort_type && this.props.sort_type == "desc") arrowStyle = { ...arrowStyle, transform: "rotate(180deg" }

    const arrowSort = (<img src={ArrowSort} style={arrowStyle}

      onClick={() => this.props.onClick ? this.props.onClick(index, sortTitle) : ''}
    />
    )
    return arrowSort;
  }
  render() {
    const { check } = this.state;
    return (
      <div className={css(style.headerTable)} id={"table-header"}>
        {this.props.cols.map((val, index) => {

          const clickableStyle =
            val.action != undefined ? style.clickable : null;

          if (val.title === "") {
            return this.renderCheck(index, val.style, check);
          }

          const cp = this.props.onClick ? { cursor: "pointer" } : { cursor: "initial" };
          return (
            <div
              key={index}
              className={css([
                this.renderStyle(val.style),
                style.fontDefault,
                style.flexAliCenter,
                clickableStyle,
              ])}
              id={val.sortTitle}
              style={cp}
              data-tip data-for={val.hasOwnProperty("sortCamp") ? "colum_sort" + index : ""}
              onClick={() => this.props.onClick ? this.props.onClick(index, val.sortTitle) : ""}
            >
              {val.title.toUpperCase()}{" "}
              <ReactTooltip id={'colum_sort' + index} type="dark" className="tooltip-hb-result">
                <span> Click here to sort </span>
              </ReactTooltip>
              {this.arrow(val.sortCamp, index, val.sortTitle)}
            </div>
          );
        })}
      </div>
    );
  }
}

GenericTableHeader.propTypes = {
  cols: PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.string,
    ordered: PropTypes.bool
  }),
  handleCheckBox: PropTypes.func
};

export default GenericTableHeader;

const style = StyleSheet.create({
  fontDefault: {
    color: "#313132",
    fontWeight: 900,
    fontSize: "11px"
  },
  style_logo: {
    textAlign: 'center',
    width: "10%"
  },
  headerTable: {
    width: "100%",
    borderBottom: "solid 2px #C6C6C6",
    fontWeight: 700,
    display: "flex !important",
    flexDirection: "row",
    padding: "8px 20px",
    alignItems: 'center',
    height: 56
  },

  id: {
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexAliCenter: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "pre-wrap"
  },
  justifyContent: {
    justifyContent: "center"
  },
  col5: {
    width: "5%"
  },

  col10: {
    width: "10%"
  },

  col15: {
    width: "15%"
  },
  col13: {
    width: "13%"
  },

  col20: {
    width: "20%"
  },

  col25: {
    width: "25%"
  },

  textCenter: {
    textAlign: "center"
  },

  textCenter15: {
    textAlign: "center",
    width: "15%"
  },

  textCenter10: {
    textAlign: "center",
    width: "10%"
  },

  textCenter5: {
    textAlign: "center",
    width: "5%",
    marginRight: '20px'
  },

  clickable: {
    cursor: "pointer"
  },

  center10: {
    width: "10%",
    textAlign: "center"
  },
  center5: {
    width: "5%",
    textAlign: "center",
    margin: '0px 20px 0px 0px'
  },
  center15: {
    width: "15%",
    textAlign: "center"
  }
});
