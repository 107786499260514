import React, {Fragment} from 'react';
import axios from '../../helper/axiosHelper'
import CustomSelectDiv from 'components/customSelect/customSelectDiv';
import Header from 'features/user/header';
import GenericTableHeader from "components/tables/genericTableHeader";
import GenericTableRow from "components/tables/genericTableRow";

export class DataView extends React.Component {

    state = {
        options: ['backend/users', 'backend/sites', 'backend/readers', 'backend/patients', 'backend/cartridges', 'backend/tests', 'backend/orders'],
        data: [],
    }

    getData = async (value) => {
        const result = await axios.get(`${value.name}`);
        this.setState({data: result, selected: value.name})
    };

    getLine = (data) => {
        const keys = Object.keys(data);
        let line = {};
        keys.forEach((key) => {
            if (key == 'id') {
                key = 'DataId';
            }
            line[key] = key == 'DataId' ? data['id'] : data[key]
        });
        return line;
    };

    renderHeader = () => {
        if (!this.state.data.length) return false;

        const keys = Object.keys(this.state.data[0]);

        let table = [];

        keys.forEach((key) => {
            table.push({
                style: 5,
                title: key == 'id' ? 'DataId' : key
            });
        });

        return table;
    };

    render() {
        return (
            <Fragment>
                <Header page={'login'} admin={'admin'}/>
                <div style={{display: "flex", marginTop: 50, marginLeft: 20}}>
                    <div style={{width: 300, height: 40}}>
                        <CustomSelectDiv
                            options={this.state.options.map((op) => {
                                return {name: op, value: op}
                            })}
                            onChange={(value) => {
                                return this.getData(value)
                            }}
                            value={this.state.selected}
                        />
                    </div>
                    <div onClick={() => this.getData()}
                         style={{display: "flex", alignItens: 'center', cursor: 'pointer', marginLeft: 10}}>
                        <h1>REFRESH</h1>
                    </div>
                </div>
                <div style={{padding: 20}}>
                    {this.state.data.length > 0 &&
                    <Fragment>
                        <h6>* Table attributes</h6>
                        <div>
                            <GenericTableHeader
                                cols={this.renderHeader()}
                                type={"PATIENTS"}
                                checkAction={bool =>
                                    this.props.actions.toogleCheckBoxAllPatients(bool)
                                }
                            />
                        </div>
                    </Fragment>
                    }

                    {this.state.data.map((data, key) => {
                        const row = this.getLine(data)
                        return (
                            <div style={{backgroundColor: key % 2 == 0 ? 'black' : 'black'}}>
                                <GenericTableRow
                                    checksSelecteds={false}
                                    allCheckSelected={[]}
                                    cols={this.renderHeader()}
                                    checkAction={id => this._handleCheckRow(id)}
                                    row={row}
                                />
                            </div>
                        )
                    })
                    }
                </div>
            </Fragment>
        )
    }
}

export default DataView