import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.scss';
import CustomButton from 'components/customButton'
import CustomModal from 'components/customModal'
import CustomSelectDiv from 'components/customSelect/customSelectDiv';
import EditIcon from 'assets/img/cartridges/edit_shipping_icn.png';
import { withTranslation } from 'react-i18next';

class ModalPlaceOrder extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        const { t } = this.props;
        return (
            <CustomModal title={t('TERMS OF USE DECLINED')} open={this.props.open} onClose={() => this.props.onClose()}>
                <div className="terms-decline-container">
                    <div className="sure-container">
                        <div className="sure-text">{this.props.t('label_AreYouSure')}</div>

                        <div className="sure-info">
                            <div>
                                {this.props.t('terms_user_declined')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'btns-container btn-padding-big'}>
                    <div className="div-buttons">
                        <div className="btn_cancel cp" onClick={() => this.props.decline()}>
                            {t('label_Yes')}
                        </div>
                    </div>
                    <div className="div-buttons">
                        <div className="btn_no cp" onClick={() => this.props.onClose()}>
                            {t('label_No')}
                        </div>
                    </div>
                </div>
            </CustomModal>

        )
    }
}
export default withTranslation()(ModalPlaceOrder)