import { createSelector } from "reselect";
import initial from "../initial";
import _ from "lodash";

export const COUNTER_USER_ACTIVE = "COUNTER_USER_ACTIVE";

export const COUNTER_USER_BLOCKED = 'COUNTER_USER_BLOCKED';

export const COUNTER_USER_DEACTIVE = 'COUNTER_USER_DEACTIVE';

export const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS =
  "SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS";

export const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER =
  "SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER";

export const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER = "SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER";

export const SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER = "SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER";

export const SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE = "SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE";

export const SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE = "SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE";

export const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST = "SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST";

export const SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES = "SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES";

export const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER = "SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER";

export const SET_SORTS = "SET_SORTS"

export const SET_SORT_TYPE = "SET_SORT_TYPE"

const AdminUserManagementReducer = (state = initial, action) => {
  switch (action.type) {

    case SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES: {
      return initial;
    }

    case COUNTER_USER_ACTIVE:
      return {
        ...state,
        usersActive: action.counter
      }
    case COUNTER_USER_DEACTIVE:
      return {
        ...state,
        DEACTIVATED_USERS: action.counter
      }

    case "USER_MANAGEMENT_USERS":
      return {
        ...state,
        USERS_DB: action.users
      }
    case COUNTER_USER_BLOCKED:
      return {
        ...state,
        usersBlocked: action.counter
      }

    case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS: {
      return { ...state, ALL_USERS_SELECTEDS: action.bool };
    }

    case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER: {
      return {
        ...state,
        USERS_SELECTEDS: action.arraySelecteds
      };
    }
    case SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER: {
      return {
        ...state,
        SEARCH_USER: action.value
      };
    }
    case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST: {
      return {
        ...state,
        CARD_SELECTED: action.id
      };
    }

    case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER: {
      return { ...state, SELECTED_SITE: action.value };
    }

    case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER: {
      return {
        ...state,
        SELECTED_DATE: action.value
      };
    }

    case SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE: {
      return {
        ...state,
        PAGE: action.page
      }
    }
    case SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE: {
      return {
        ...state,
        QTY_PAGE: action.qty
      }
    }
    case SET_SORTS: {
      return {
        ...state,
        sorts: action.payload
      }
    }
    case SET_SORT_TYPE: {
      return {
        ...state,
        sort_type: action.payload
      }
    }
  }
  return state;
};

export const qty_page = state => state.AdminUserManagementReducer.QTY_PAGE;

export const page = state => state.AdminUserManagementReducer.PAGE;

export const searchFilter = state =>
  state.AdminUserManagementReducer.SEARCH_USER;

export const allUsersDB = state => state.AdminUserManagementReducer.USERS_DB;

export const allUsersSelecteds = state => state.AdminUserManagementReducer.ALL_USERS_SELECTEDS;

export const usersSelecteds = state => state.AdminUserManagementReducer.USERS_SELECTEDS;

export const cardSelected = state =>
  state.AdminUserManagementReducer.CARD_SELECTED;

export const siteSelected = state =>
  state.AdminUserManagementReducer.SELECTED_SITE;

export const dateSelected = state =>
  state.AdminUserManagementReducer.SELECTED_DATE;

export const userSorts = state =>
  state.AdminUserManagementReducer.sorts;

  export const sort_type = state =>
  state.AdminUserManagementReducer.sort_type;


export const getTestsData = createSelector(
  allUsersDB,
  users => {
    return users.reduce(
      (initial, value) => {
        if (value.testId === 1 && value.testResult === "Positive") {
          initial.malaria.positive++;
        } else if (value.testId === 2 && value.testResult === "Positive") {
          initial.hb.positive++;
        }
        return initial;
      },
      {
        malaria: {
          tests: users.length,
          positive: 0
        },
        hb: {
          tests: users.length,
          positive: 0
        }
      }
    );
  }
);

export const allUsersFiltered = createSelector(
  allUsersDB,
  searchFilter,
  cardSelected,
  qty_page,
  page,
  userSorts,
  sort_type,
  (users, searchFilter, cardSelected, qty_page, page, userSorts, st) => {
    if (!users) return []

    let usrs = users;
    let results = _.chain(usrs)
      .filter(user => searchFilter.length > 0 ? user.first_name.includes(searchFilter) : user)
      .filter((user) => {
        if (!user.status) return false;

        if (cardSelected == 0) {
          return user;
        }
        if (cardSelected == 1) {
          return user.status == "B"
        }
        if (cardSelected == 2) {
          return user.status == "A"
        }
        if (cardSelected == 3) {
          return user.status == "D"
        }
        if (cardSelected == 4) {
          return !!user.updated_at
        }
        return user.status == "I"
      })
      .value()
    results =  _.orderBy(results, userSorts, st);

    const totalPages = (results.length / qty_page) % 1 != 0 ? Math.ceil(results.length / qty_page) : Math.ceil(results.length / qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? results : init + qty_page
    let arrayFormatado = (totalPages) == 1 ? results : results.slice(init, end);
    return {
      allUsersFiltered: arrayFormatado,
      counter: results.length,
      active: users.filter((user) => user.status == 'A').length,
      inactive: users.filter((user) => user.status == "I").length,
      deactive: users.filter((user) => user.status == 'D').length,
      blocked: users.filter((user) => user.status == 'B').length,
      updated: users.filter((user) => !!user.updated_at).length,
      totalPages: parseInt(totalPages)
    }
  }
);

export default AdminUserManagementReducer;
