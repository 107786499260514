import React, { Component } from "react";
import "./index.scss";

class SimpleBox extends Component {
  render() {
    const { boxWidth, text, value, type, valueColor, usageImage } = this.props;
    let width = boxWidth ? 'w' + boxWidth : 'w25';
    return (
      <div className={'padding-card ' + width}>
        <div className={"boxSimple " + type}>
          <div className={"simple-blox-title fs14 " + type}>{text}</div>
          <div className={`simple-blox-value displayFlex ali-center jc-center ${type === 'usage' ? 'fs26' : 'fs16'} ${valueColor}`}>
            {type === 'usage' && !!usageImage &&
              // <div className={'usage-image'}>
                <img src={usageImage} />
              // </div>
            }
            {value}</div>
        </div>
      </div>
    );
  }
}

export default SimpleBox;
