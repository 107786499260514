import * as Patients from "redux/services/Patients";
import { createSelector } from "reselect";
import _ from "lodash";
import { getDateByLanguage, getHourByLanguage } from 'helper/dateByLanguage';
import getDateTimeByLanguage from 'helper/dateByLanguage';
import { TestsResultEnum, FILTRO_DATE_ENUM } from 'helper/enum';

const initial = {
    malariaTestsCounter: {
        counter: 0,
        positive: 0
    },
    patientHistoryUrl: "",

    CARD_SELECTED: 1,

    ...FILTRO_DATE_ENUM,

    SELECTED_SITE: {
        id: 0,
        value: "ALL SITES"
    },


    PAGE: 0,

    QTY_PAGE: 10,

    SEARCH_PATIENT: "",

    ALL_PATIENTS_SELECTEDS: false,

    PATIENTS_SELECTEDS: [],

    patients: [],

    testsPositiveCounter: 0,

    hbTestsCounter: {
        counter: 0,
        positive: 0
    },

    recurring: {
        counter: 0,
        idsRecurring: []
    },
    sortPatient: {
        patient_name: "sort",
        patient_id_encrypted: null,
        test_type: null,
        test_result: null,
        date: null,
    },

    patient_id: null,

    test_id: null,
}

const types = {
    GET_MALARIA_COUNTERS: "GET_MALARIA_COUNTERS",
    GET_HB_COUNTERS: "GET_HB_COUNTERS",
    GET_ALL_PATIENTS: "GET_ALL_PATIENTS",
    GET_POSITIVE_TESTS: "GET_POSITIVE_TESTS",
    GET_RECURRING_COUNTER: "GET_RECURRING_COUNTER",
    SET_PATIENT_HISTORY_URL: "SET_PATIENT_HISTORY_URL",

    PATIENTS_TOOGLE_CHECK_ALL_PATIENTS: "PATIENTS_TOOGLE_CHECK_ALL_PATIENTS",
    PATIENTS_TOOGLE_CHECK_UNIQ_PATIENT: "PATIENTS_TOOGLE_CHECK_UNIQ_PATIENT",
    PATIENTS_SEARCH_PATIENT: "PATIENTS_SEARCH_PATIENT",
    PATIENTS_INITIAL_VALUES: "PATIENTS_INITIAL_VALUES",
    PATIENTS_TOOGLE_CARD: "PATIENTS_TOOGLE_CARD",
    PATIENTS_TOOGLE_SITE_FILTER: "PATIENTS_TOOGLE_SITE_FILTER",
    PATIENTS_TOOGLE_DATE_FILTER: "PATIENTS_TOOGLE_DATE_FILTER",
    HB_TESTS_COUNTER: "HB_TESTS_COUNTER",
    MALARIA_TESTS_COUNTER: "MALARIA_TESTS_COUNTER",
    PATIENTS: "PATIENTS",
    TESTS_POSITIVE_COUNTER: "TESTS_POSITIVE_COUNTER",
    TESTS_RECURRING: "TESTS_RECURRING",
    PATIENTS_CHANGE_PAGE: "PATIENTS_CHANGE_PAGE",
    PATIENTS_CHANGE_QTY_PAGE: "PATIENTS_CHANGE_QTY_PAGE",
    SET_SORTS: "SET_SORTS",
    SET_PATIENT_ID: "SET_PATIENT_ID",
    SET_TEST_ID: "SET_TEST_ID"
}


export const toogleCheckBoxAllPatients = bool => ({
    type: types.PATIENTS_TOOGLE_CHECK_ALL_PATIENTS,
    bool
});
export const toogleCheckBoxUniqPatient = arraySelecteds => ({
    type: types.PATIENTS_TOOGLE_CHECK_UNIQ_PATIENT,
    arraySelecteds
});
export const searchPatient = value => ({
    type: types.PATIENTS_SEARCH_PATIENT,
    value
});
export const changePage = page => ({
    type: types.PATIENTS_CHANGE_PAGE,
    page
});
export const changeQtyPage = qty => ({
    type: types.PATIENTS_CHANGE_QTY_PAGE,
    qty
});
export const toogleSitefilter = value => ({
    type: types.PATIENTS_TOOGLE_SITE_FILTER,
    value
});
export const toogleDateFilter = value => ({
    type: types.PATIENTS_TOOGLE_DATE_FILTER,
    value
});

export const setPatientHistoryUrl = value => ({
    type: types.SET_PATIENT_HISTORY_URL,
    payload: value
});

export const setTestId = payload => ({ type: types.SET_TEST_ID, payload })

export const setSorts = payload => ({ type: types.SET_SORTS, payload })

export const toggleCard = id => ({ type: types.PATIENTS_TOOGLE_CARD, id });
export const initialValues = () => ({ type: types.PATIENTS_INITIAL_VALUES });

const PatientsReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.GET_MALARIA_COUNTERS: {
            return {
                ...state,
                malariaTestsCounter: action.payload
            }
        }
        case types.GET_HB_COUNTERS: {
            return {
                ...state,
                hbTestsCounter: action.payload
            }
        }

        case types.GET_POSITIVE_TESTS: {
            return {
                ...state,
                testsPositiveCounter: action.payload
            }
        }
        case types.GET_ALL_PATIENTS: {
            return {
                ...state,
                patients: action.payload
            }
        }

        case types.GET_RECURRING_COUNTER: {
            return {
                ...state,
                recurring: action.payload
            }
        }

        case types.SET_PATIENT_HISTORY_URL: {
            return {
                ...state,
                patientHistoryUrl: action.payload
            }
        }

        case types.PATIENTS_TOOGLE_CARD: {
            return { ...state, CARD_SELECTED: action.id }
        }

        case types.PATIENTS_SEARCH_PATIENT: {
            return { ...state, SEARCH_PATIENT: action.value }
        }

        case types.PATIENTS_CHANGE_PAGE: {
            return { ...state, PAGE: action.page }
        }

        case types.PATIENTS_CHANGE_QTY_PAGE: {
            return { ...state, QTY_PAGE: action.qty }
        }

        case types.PATIENTS_TOOGLE_SITE_FILTER: {
            return { ...state, SELECTED_SITE: action.value }
        }

        case types.PATIENTS_TOOGLE_DATE_FILTER: {
            return { ...state, SELECTED_DATE: action.value }
        }

        case types.SET_SORTS: {
            return { ...state, sortPatient: { ...action.payload } }
        }

        case types.SET_PATIENT_ID: {
            return { ...state, patient_id: action.payload }
        }

        case types.SET_TEST_ID: {
            return {
                ...state,
                test_id: action.payload
            }
        }
    }
    return state
}

export const getMalariaCounter = (site = null, lastNDays = 0, deactivated = false) => async (dispatch) => {
    const payload = await Patients.getMalariaCounter(site, lastNDays, deactivated);
    dispatch({ type: types.GET_MALARIA_COUNTERS, payload })
}

export const downloadTest = (testId) => async (dispatch) => {
    return await Patients.downloadFile(testId);
}

export const getTestUrl = (file_type, test_id) => async (dispatch) => {
    return await Patients.getTestFile(file_type, test_id);
}

export const getHbCounter = (site = null, lastNDays = 0, deactivated = false) => async (dispatch) => {
    const payload = await Patients.getHbCounter(site, lastNDays, deactivated);
    dispatch({ type: types.GET_HB_COUNTERS, payload })
}

export const getPositivesTest = (site = null, lastNDays = 0) => async (dispatch) => {
    const payload = await Patients.getPositiveTests(site, lastNDays);
    dispatch({ type: types.GET_POSITIVE_TESTS, payload })
}

export const getRecurringCounter = (site = null, lastNDays = 0) => async (dispatch) => {
    const payload = await Patients.getTestsRecurring(site, lastNDays);
    dispatch({ type: types.GET_RECURRING_COUNTER, payload })
}


export const getPatientTests = (site = null, lastNDays = 0) => async (dispatch) => {
    const payload = await Patients.getPatientsTests(site, lastNDays);
    dispatch({ type: types.GET_ALL_PATIENTS, payload })
}

export const setPatientId = payload => ({ type: types.SET_PATIENT_ID, payload })

export const qty_page = state => state.PatientsReducer.QTY_PAGE;

export const page = state => state.PatientsReducer.PAGE;

export const searchFilter = state => state.PatientsReducer.SEARCH_PATIENT;

export const idsPatientsRecurring = state => state.PatientsReducer.recurring.idsRecurring;

export const allPatientsDB = state => state.PatientsReducer.patients;

export const siteSelected = state => state.PatientsReducer.SELECTED_SITE;

export const allSites = state => state.UserReducer.userData.sites;

export const cardSelected = state => state.PatientsReducer.CARD_SELECTED;

export const sortPatient = state => state.PatientsReducer.sortPatient

export const getTestsData = createSelector(
    allPatientsDB,
    patients => {
        return patients.reduce(
            (initial, value) => {
                if (value.testId === 1 && value.testResult === "Positive") {
                    initial.malaria.positive++;
                } else if (value.testId === 2 && value.testResult === "Positive") {
                    initial.hb.positive++;
                }
                return initial;
            },
            {
                malaria: {
                    tests: patients.length,
                    positive: 0
                },
                hb: {
                    tests: patients.length,
                    positive: 0
                }
            }
        );
    }
);

export const patientsData = createSelector(
    allPatientsDB,
    searchFilter,
    cardSelected,
    idsPatientsRecurring,
    qty_page,
    page,
    sortPatient,
    (patients, searchFilter, cardSelected, idsRecurring, qty_page, page, sortsPatient) => {

        let tests = [];

        let sorts = [];

        for (let i in sortsPatient) {
            if (sortsPatient[i] != null) {
                sorts.push(i)
            }
        }

        const results = _.chain(patients)
            .filter(patient =>
                searchFilter.length > 0 ? patient.name.toLowerCase().includes(searchFilter.toLowerCase()) : patient
            )
            .value()
        results.forEach((patient) => {
            patient.tests.forEach((test) => {
                if ((cardSelected === 1 && test.result == 'Positive') ||
                    (cardSelected === 2 && test.result == 'Recurring') ||
                    (cardSelected == 3 && test.disease_id == 1) ||
                    (cardSelected == 4 && test.disease_id == 2)) {
                    const resultString = Object.keys(TestsResultEnum).filter((items) => items.includes(test.result));
                    if (window.location.pathname.includes('/admin')) {
                        tests.push({
                            id: test.id,
                            patient_id: patient.id,
                            patient_name: patient.name,
                            patient_id_encrypted: '-',
                            test_type: test.test_type,
                            test_result: resultString.length == 0 ? test.result : TestsResultEnum[resultString[0]][window.currentLanguage],
                            date: getDateTimeByLanguage(test.applied_at, window.currentLanguage),
                            dob: '-',
                            performed_by: test.performed_by,
                            scd_band: !test.scd_band ? [] : JSON.parse(test.scd_band)
                        })
                    } else {
                        tests.push({
                            id: test.id,
                            patient_id: patient.id,
                            patient_name: patient.name,
                            test_type: test.test_type,
                            test_result: resultString.length == 0 ? test.result : TestsResultEnum[test.result][window.currentLanguage],
                            date: getDateByLanguage(test.applied_at, window.currentLanguage),
                            date_time: getHourByLanguage(test.applied_at, window.currentLanguage),
                            dob: '-',
                            performed_by: test.performed_by
                        })
                    }
                }
            });
        });
        tests = sorts.length == 0 ? tests : _.orderBy(tests, sorts, ['asc']);

        const totalPages = (tests.length / qty_page) % 1 != 0 ? Math.ceil(tests.length / qty_page) : Math.ceil(tests.length / qty_page)
        const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
        const end = totalPages == 0 ? tests : init + qty_page
        let arrayFormatado = (totalPages) == 1 ? tests : tests.slice(init, end);

        return {
            allPatientsFiltered: arrayFormatado,
            counter: tests.length,
            totalPages: parseInt(totalPages)
        }
    }
);

export const cards = createSelector(
    siteSelected, allSites,
    (siteSelected, allSites) => {
        if (!allSites) return []
        let cards = [];
        if (allSites.length == 1) {
            allSites[0].disease_types.map((disease) => {
                cards.push(disease)
            });
            return cards;
        }

        if (Object.keys(siteSelected).length == 2) {
            allSites.forEach((site) => {
                site.disease_types.map((disease) => {
                    cards.push(disease)
                });
            });

            return cards;
        }

        siteSelected.disease_types.map((disease) => {
            cards.push(disease)
        });

        return cards;
    }
)


export default PatientsReducer;