const translations = {
    tableHeaderPrimarySite: "PRIMARY SITE",
    tableHeaderSiteContact: 'SITE CONTACT',
    tableHeaderPatientId: "PATIENT ID",
    tableHeaderPatientIdEncrypted: "PATIENT ID (Encrypted)",
    tableHeaderPatientName: "PATIENT NAME",
    tableHeaderTestId: "TEST ID",
    tableHeaderTestResult: "TEST\nRESULT",
    tableHeaderDateTime: "TEST DATE",
    tableHeaderLocation: "LOCATION",
    tableHeaderTestReport: "TEST REPORT",
    tableHeaderTestComments: "TEST\nCOMMENTS",
    tableHeaderCountry: "COUNTRY",
    tableHeaderZip: "ZIP",
    tableHeaderPhoneNumber: "PHONE\nNUMBER",
    tableHeaderAddress: "ADDRESS",
    tableHeaderCity: "CITY",
    tableHeaderState: "STATE",
    tableHeaderTestType: "TEST TYPES",
    tableHeaderName: "NAME",
    tableHeaderEmail: "EMAIL",
    tableHeaderRole: "ROLE",
    tableHeaderSite: "SITE",
    tableHeaderReadersWithError: "error\nlog(s)",
    tableHeaderReadersWithFirmwareMissmach: "firmware\nmismatch",
    tableHeaderSiteType: "SITE TYPE",
    tableHeaderLastUpdatedDate: "LAST UPDATED DATE",
    tableHeaderLastUpdatedBy: "LAST UPDATED BY",
    tableHeaderProductNumber: "PRODUCT NUMBER",
    tableHeaderSerialNumber: "SERIAL NUMBER",
    tableHeaderMacAddress: "MAC ADDRESS",
    tableHeaderSiteName: "SITE NAME",
    tableHeaderReRuns: "RE-RUNS",
    tableHeaderReUsed: "RE-USED",
    tableHeaderRepeatTest: "REPEAT\nTESTS",
    tableHeaderCartridgesUsed: "CARTRIDGES USED",
    tableHeaderMobileTests: "MOBILE TESTS",
    tableHeaderWifi: "WI-FI",
    tableHeaderPcLaptop: "PC/LAPTOP",
    tableHeaderSWVersion: "FIRMWARE\nVERSION",
    tableHeaderLastUpdate: "LAST UPDATE",
    tableHeaderFirmwareStatus: "Firmware\nStatus",
    tableHeaderDate: "DATE",
    tableHeaderTime: "TIME",
    tableHeaderDOB: "DOB",
    tableHeaderPerformedBy: "PERFORMED BY",
    tableHeaderType: "TYPE",
    tableHeaderQuantityOnHand: "QUANTITY ON HAND",
    tableHeaderOrderDate: "ORDER DATE",
    tableHeaderQuantityOrdered: "QUANTITY ORDERED",
    tableHeaderReceivedDate: "DATE RECEIVED",
    tableHeaderQuantityReceived: "QUANTITY RECEIVED",


}

export default translations;
