import React from "react";
import { StyleSheet, css } from "aphrodite";
import moment from "moment";
import CustomCheckbox from "../../customCheckbox";
import testCommentsIcon from "assets/img/header-new/dashboard_table_test_comments_icon@2x.png";
import testReportIcon from "assets/img/header-new/dashboard_table_test_report_icon@2x.png";
import $ from "jquery";
import ReactTooltip from "react-tooltip";
import "./style.scss";
import help from "assets/img/header-new/header_help.png";
import { withTranslation } from "react-i18next";

class GenericTableRow extends React.Component {
  static defaultProps = {
    pos: 0,
  };

  state = {
    showModal: { status: false, lineId: null },
  };

  handleHover = (val) => {
    if (this.props.hover == undefined || this.props.hover == true) {
      this.setState({
        ...this.state,
        hover: val,
      });
    }
  };

  handleCheck = (id) => {
    const { checkAction } = this.props;
    if (checkAction) {
      checkAction({ id });
    }
  };

  showModal = (id) => {
    this.setState({
      showModal: {
        status: true,
        lineId: id,
      },
    });
  };

  renderStatus = (obj1, obj2) => {
    if (obj2 === 0) {
      return (
        <span>
          <span className={css([style.pin, style.pinAlert])} /> PENDING
        </span>
      );
    }

    if (obj1 === true) {
      return (
        <span>
          <span className={css([style.pin, style.pinActive])} />
          {moment(obj2).format("MM/DD/YYYY")}
        </span>
      );
    } else {
      return (
        <span>
          <span className={css([style.pin, style.pinDanger])} />
          INACTIVE
        </span>
      );
    }
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      ...this.state,
      check: nextProps.checked,
    });
  };

  renderRows = (row) => {
    const { cols } = this.props;

    const { check } = this.state;

    let colors = {
      blue: style.pinActive,
      yellow: style.pinAlert,
      red: style.pinDanger,
      green: style.pinGreen,
      black: style.pinBlack,
    };
    let colsSize = {
      5: style.col5,
      10: style.col10,
      13: style.col13,
      15: style.col15,
      20: style.col20,
      25: style.col25,
      id: style.id,
      status: style.col5Center,
      campaign_order: style.col15Center,
      center10: style.center10,
      center15: style.center15,
    };

    let values = Object.keys(row);

    return values.map((val, index) => {
      let color = "green";

      switch (row[val]) {
        case "U":
          color = "blue";
          break;
        case "I":
        case "Y":
          color = "yellow";
          break;
        case "B":
        case "R":
          color = "red";
          break;
        case "D":
          color = "black";
      }

      if (val == "patient_name" || val == "patient_id") {
        return (
          <>
            <div
              key={index}
              onClick={() => (this.props.onClick ? this.props.onClick() : "")}
              className={css([
                style.col,
                colsSize[cols[index].style],
                style.fontDefault,
                style.supportHover,
              ])}
              style={{ wordBreak: "break-word" }}
            >
              <div className="columnHover">
                {row[val]}
                <div className={`divText`}>
                  <div className="title">
                    Result{" "}
                    {this.props.test_type == "hb" ? "HB Variant" : "Malaria"}
                  </div>
                  <div className={css(style.scdContainer)}>
                    <div className="scd-title">
                      {this.props.t("label_ClickToViewPatientHistory")}
                    </div>
                  </div>
                  <div>
                    <div className="div-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }

      if (val == "test_result" && this.props.test_type == "hb") {
        const dtfor = "hb_result" + index;
        const elmt = document.getElementById(dtfor);
        return (
          <>
            <div
              key={index}
              onClick={() => (this.props.onClick ? this.props.onClick() : "")}
              className={css([
                style.col,
                colsSize[cols[index].style],
                style.fontDefault,
                style.supportHover,
              ])}
              data-tip
              data-for={dtfor}
              style={{ wordBreak: "break-word" }}
            >
              <div className="columnHover">
                {row[val]}
                <div className={`divText multi`}>
                  <div className="title">
                    Result{" "}
                    {this.props.test_type == "hb" ? "HB Variant" : "Malaria"}
                  </div>

                  {this.props.scd_band &&
                    this.props.scd_band.map((scd) => {
                      return (
                        <div
                          className={css(style.scdContainer)}
                          style={{ display: "" }}
                        >
                          {scd.HbType == "Interpretation" ? (
                            <div>
                              <div className="scd-title">{scd.HbType}:</div>
                              <div className="scd_item interpretation">
                                {scd.Percentage}
                              </div>
                            </div>
                          ) : (
                              <>
                                <div className="scd-title">{scd.HbType}:</div>
                                <div className="scd_item">{scd.Percentage}%</div>
                              </>
                            )}
                        </div>
                      );
                    })}
                  <div>
                    <div className="div-arrow"></div>
                  </div>
                </div>
              </div>
              {/* <div className="tooltip-hb-result" id={dtfor}>
                                <span className="title"> Result HB Variant </span>
                                <div className={css(style.lineSeparatorResult)} />
                                {this.props.scd_band && this.props.scd_band.map((scd) => {
                                    return (
                                        <div className={css(style.scdContainer)}>
                                            <div className="scd-title">
                                                {scd.HbType}:
                                            </div>
                                            <div className="scd_item">
                                                {scd.Percentage}%
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}
              {/* <ReactTooltip id={dtfor} type="light" className="tooltip-hb-result">
                            <span className="title"> Result HB Variant </span>
                            <div className={css(style.lineSeparatorResult)} />
                            {this.props.scd_band && this.props.scd_band.map((scd) => {
                                return (
                                    <div className={css(style.scdContainer)}>
                                        <div className="scd-title">
                                            {scd.HbType}:
                                            </div>
                                        <div className="scd_item">
                                            {scd.Percentage}%
                                            </div>
                                    </div>
                                )
                            })} */}
              {/* </span> */}
              {/* </ReactTooltip> */}
            </div>
          </>
        );
      }

      if (
        val == "type" &&
        row[val] == this.props.t("label_MultiSite") &&
        this.props.sites_children
      ) {
        return (
          <>
            <div
              key={index}
              className={css([
                style.col,
                colsSize[cols[index].style],
                style.fontDefault,
                style.supportHover,
              ])}
              style={{ wordBreak: "break-word" }}
            >
              <div className="columnHover">
                {row[val]}
                <div className={`divText multisite`}>
                  <div className="title">Satellites</div>
                  <div className={css(style.scdContainer)}>
                    <div className="scd-title">
                      {this.props.sites_children.map((children, key) => (
                        <>
                          {`${children}${
                            key + 1 - this.props.sites_children.length
                              ? ", "
                              : ""
                            } `}
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <div className="div-arrow"></div>
                  </div>
                </div>
              </div>
              {/* <div className="tooltip-hb-result" id={dtfor}>
                                <span className="title"> Result HB Variant </span>
                                <div className={css(style.lineSeparatorResult)} />
                                {this.props.scd_band && this.props.scd_band.map((scd) => {
                                    return (
                                        <div className={css(style.scdContainer)}>
                                            <div className="scd-title">
                                                {scd.HbType}:
                                            </div>
                                            <div className="scd_item">
                                                {scd.Percentage}%
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}
              {/* <ReactTooltip id={dtfor} type="light" className="tooltip-hb-result">
                            <span className="title"> Result HB Variant </span>
                            <div className={css(style.lineSeparatorResult)} />
                            {this.props.scd_band && this.props.scd_band.map((scd) => {
                                return (
                                    <div className={css(style.scdContainer)}>
                                        <div className="scd-title">
                                            {scd.HbType}:
                                            </div>
                                        <div className="scd_item">
                                            {scd.Percentage}%
                                            </div>
                                    </div>
                                )
                            })} */}
              {/* </span> */}
              {/* </ReactTooltip> */}
            </div>
          </>
        );
      }

      if (val == "testReport") {
        return (
          <>
            <div
              key={index}
              onClick={() => (this.props.showPdf ? this.props.showPdf() : "")}
              className={css([
                style.col,
                colsSize[cols[index].style],
                style.fontDefault,
                style.supportHover,
              ])}
              style={{ wordBreak: "break-word" }}
            >
              <div className="columnHover">
                <div
                  id={"testReportLine" + index}
                  style={{
                    width: "10%",
                  }}
                >
                  <img
                    src={testReportIcon}
                    className={css(style.icon_size)}
                    onClick={() => this.props.showPdf()}
                  />
                </div>
                <div className="divText">
                  <div className="title">
                    REPORT{" "}
                    {this.props.test_type == "hb" ? "HB Variant" : "Malaria"}
                  </div>
                  <div className={css(style.scdContainer)}>
                    <div className="scd-title">
                      {this.props.t("label_ClickToViewTestReport")}
                    </div>
                  </div>
                  <div>
                    <div className="div-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }

      if (val === "status" || val == "firmware_status") {
        const { rowStatusToolTip } = this.props;
        let value = !!rowStatusToolTip ? rowStatusToolTip.value : "";
        return (
          <>
            <div
              data-tip
              data-for={rowStatusToolTip ? rowStatusToolTip.id : ""}
              key={index}
              className={css(colsSize[cols[index].style])}
              style={{
                display: "flex",
                margin: "0px 20px 0px 0px !important",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
              }}
              onClick={() => (this.props.onClick ? this.props.onClick() : "")}
            >
              <span>
                <span
                  className={css([style.pin, colors[color], style.fontDefault])}
                />
              </span>
            </div>
            {this.props.rowStatusToolTip && (
              <ReactTooltip id={rowStatusToolTip.id} type="dark">
                <span>{value}</span>
              </ReactTooltip>
            )}
          </>
        );
      }
      if (val === "id") {
        return (
          <div key={index} style={{ width: "5%" }} className={"check-item"}>
            <CustomCheckbox
              active={
                this.props.allCheckSelected ||
                this.props.checksSelecteds.includes(row[val])
              }
              style={{ marginRight: "0px !important" }}
              action={() => this.handleCheck(row[val])}
            />
          </div>
        );
      }
      if (val === "site_logo") {
        return (
          <div
            id={"testReportLine" + index}
            className={css(colsSize[cols[index].style])}
            style={{
              display: "flex",
              verticalAlign: "middle",
              alignItem: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "5%",
              height: 43,
              margin: "0px 20px 0px 0px",
            }}
            onClick={() => (this.props.onClick ? this.props.onClick() : "")}
          >
            <img
              style={{ height: "43px", width: "43px", borderRadius: "22px" }}
              src={row[val]}
            />
          </div>
        );
      }

      if (val == "files") {
        return (
          <div
            key={index}
            onClick={() =>
              this.props.onClick ? this.props.openDownloadEds() : ""
            }
            className={css([
              style.col,
              colsSize[cols[index].style],
              style.fontDefault,
            ])}
          >
            {row[val]}
          </div>
        );
      }

      if (val === "trash") {
        return (
          <div
            id={"testReportLine" + index}
            className={css(colsSize[cols[index].style])}
            style={{
              display: "flex",
              verticalAlign: "middle",
              alignItem: "center",
              justifyContent: "center",
              textAlign: "center",
              width: "5%",
              height: 43,
            }}
            onClick={() => this.props.delete(true)}
          >
            <img style={{ height: "30px", width: "30px" }} src={row[val]} />
          </div>
        );
      }

      if (val === "icon") {
        return (
          <div
            id={"testReportLine" + index}
            className={css(colsSize[cols[index].style])}
            style={{
              display: "flex",
              verticalAlign: "middle",
              alignItem: "center",
              height: 20,
              width: 20,
            }}
            onClick={() => (this.props.onClick ? this.props.onClick() : "")}
          >
            <img
              src={row[val]}
              onClick={() => this.showModal("testReportLine" + index)}
            />
          </div>
        );
      }

      if (val === "campaign_order") {
        return (
          <div
            key={index}
            className={css([
              style.col,
              colsSize[cols[index].style],
              style.fontDefault,
              style.textCenter,
            ])}
          >
            {row[val]}
          </div>
        );
      }

      if (val === "email") {
        return (
          <div
            key={index}
            onClick={() => (this.props.onClick ? this.props.onClick() : "")}
            className={css([
              style.col,
              colsSize[cols[index].style],
              style.fontDefault,
            ])}
            style={{ wordBreak: "break-word" }}
          >
            <a
              className={css(style.linkEmail)}
              href={`mailto: ${row[val]}`}
              target="_blank"
            >
              {row[val]}
            </a>
          </div>
        );
      }

      if (val === "eds_files") {
        const styleCursor = row[val] == "-" ? "" : style.cursorPointer;
        return (
          <div
            onClick={!!row[val] && row[val] > 0 ? this.props.openModalEds : ""}
            key={index}
            className={css([
              style.col,
              colsSize[cols[index].style],
              style.fontDefault,
              style.textCenter,
              styleCursor,
            ])}
          >
            {row[val]}
          </div>
        );
      }

      const styleCursor = val == "readers" ? style.cursorPointer : "";

      return (
        <div
          id={val}
          key={index}
          onClick={() =>
            this.props.onClick ? this.props.onClick(val, index) : ""
          }
          className={css([
            style.col,
            colsSize[cols[index].style],
            style.fontDefault,
            styleCursor,
          ])}
          style={{ wordBreak: "break-word" }}
        >
          {row[val]}
        </div>
      );
    });
  };

  render() {
    const { pos, row, actionClick } = this.props;
    const stl =
      this.state.hover || this.state.check
        ? style.rowHover
        : pos % 2 === 0
          ? style.row
          : style.row2;

    // onClick={()=>actionClick(row.id)} --> TODO : put it on label or text colum

    return (
      <div
        className={css(stl)}
        onMouseOver={() => this.handleHover(true)}
        onMouseOut={() => this.handleHover(false)}
      >
        {this.renderRows(row)}
      </div>
    );
  }
}

export default withTranslation()(GenericTableRow);

const style = StyleSheet.create({
  fontDefault: {
    color: "#4E4E4E",
    fontWeight: 500,
    fontSize: "11px",
    textTransform: "uppercase",
  },
  supportHover: {
    // padding: "20px",
    position: "relative",
  },
  lineSeparatorResult: {
    width: "95%",
    height: "2px",
    marginTop: "5px",
    marginBottom: "10px",
    backgroundColor: "#CECECE",
  },
  scdContainer: {
    width: "100%",
    display: "flex",
    marginBottom: "5px",
    alignItems: "center",
  },
  scdTitle: {
    color: "#54565A",
    fontsize: "17px",
    marginRight: "10px",
  },
  linkEmail: {
    textDecoration: "none",
    color: "black",
  },
  row: {
    margin: "0px !important",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 20px",
    background: "white",
    borderBottom: "solid 1px #dbdbdb",
  },
  row2: {
    margin: "0px !important",
    display: "flex",
    flexDirection: "row",
    padding: "8px 20px",
    background: "#f2f2f2",
    borderBottom: "solid 1px #dbdbdb",
  },
  rowHover: {
    margin: "0px !important",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 20px",
    background: "#E5EAFF",
    cursor: "pointer",
    borderBottom: "solid 1px #dbdbdb",
  },
  col: {
    // paddingLeft: 16,
    // paddingRight: 16,
    paddingTop: 10,
    paddingBottom: 10,
  },
  pin: {
    width: 8,
    height: 8,
    borderRadius: 10,
    display: "inline-block",
    marginTop: 7,
  },

  pinActive: {
    backgroundColor: "#23E200",
  },

  pinAlert: {
    backgroundColor: "#FFCE00",
  },

  pinDanger: {
    backgroundColor: "#C90000",
  },

  pinGreen: {
    backgroundColor: "#21de03",
  },
  pinBlack: {
    backgroundColor: "black",
  },

  iconOpacity: {
    opacity: 0.3,
    maxWidth: 24,
    maxHeight: 24,
  },
  icon: {
    maxWidth: 24,
    maxHeight: 24,
  },
  badget: {
    height: 18,
    display: "block",
    paddingLeft: 7,
    paddingRight: 7,
    fontSize: 10,
    textAlign: "center",
    color: "white",
    borderRadius: 20,
    position: "absolute",
    zIndex: "9",
    marginTop: "-8px",
    backgroundColor: "#4969D6",
    left: "50%",
  },
  badgetBlue: {
    height: 18,
    display: "block",
    paddingLeft: 7,
    paddingRight: 7,
    fontSize: 10,
    textAlign: "center",
    color: "white",
    borderRadius: 20,
    position: "absolute",
    zIndex: "9",
    marginLeft: "10px",
    marginTop: "-3px",
    backgroundColor: "#4969D6",
  },

  cursorPointer: {
    cursor: "pointer",
    position: "relative",
  },

  colCheck: {
    margin: "0px 20px !important",
    width: "initial !important",
  },
  id: {
    width: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  col5: {
    width: "5%",
  },

  col10: {
    width: "10%",
  },

  col10Center: {
    width: "10%",
    textAlign: "center",
  },

  col15Center: {
    width: "15%",
    textAlign: "center",
  },

  col5Center: {
    width: "5%",
    textAlign: "center",
    marginRight: "20px !important",
  },

  col15: {
    width: "15%",
  },
  col13: {
    width: "13%",
  },

  col20: {
    width: "20%",
  },

  col25: {
    width: "25%",
  },

  textCenter: {
    textAlign: "center",
  },

  icon_size: {
    width: "18px",
  },

  center10: {
    width: "10%",
    textAlign: "center",
  },

  center15: {
    width: "15%",
    textAlign: "center",
  },
});
