import React from 'react';
import CustomModal from 'components/customModal'
import CustomRadio from 'components/customRadio';
import CustomInput from 'components/customInput';
import CustomCheckbox from 'components/customCheckbox'
import CustomButton from 'components/customButton';
import NoLogo from 'assets/img/placeholder_nosite.png';
import { connect } from "react-redux";
import './index.scss';
import { bindActionCreators } from 'redux';
import { create_site_admin } from "redux/reducers/SiteManagementAdminReducer"

import Loading from 'components/loading';
import Swal from 'sweetalert2'
import { withTranslation } from 'react-i18next';

class ModalAddSiteAdmin extends React.Component {

    state = {
        clinical: {
            check: false,
            eds: false
        },
        project: {
            research: false,
            pilot: false,
            eds: false
        },
        name: '',
        request: false
    }

    alertError = error => {
        Swal.fire({
            text: error,
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: false,
            // cancelButtonColor: '#d33',
        });
    }

    serializeData = () => {
        let project_type = 1;

        if (this.state.project.research && this.state.project.pilot) {
            project_type = 3;
        }

        if (!this.state.project.research && this.state.project.pilot) {
            project_type = 2;
        }

        let data = {
            clinical_testing: !!this.state.clinical.check ? 1 : 0,
            project_type,
            name: this.state.name,
            site_type: 2,
            eds_status: this.state.clinical.eds,
            status: 1,
        }

        if (this.props.data) {
            data.entity_master_id = this.props.data.id
        }

        return data;
    }


    create = async () => {
        const data = this.serializeData();

        if (this.state.project.research && !this.state.clinical.eds) {
            return this.alertError('Fill the eds field')
        }

        if (!this.state.name) return this.alertError('Fill the site name')

        try {
            let { id } = await this.props.create_site_admin({ ...data, eds_status: data.eds_status ? 'invited' : null })
            if (this.props.data) id = this.props.data.id
            return this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/wizard?id=${id}`);
        } catch (e) {
            const text = !!e && e.data && e.data.message ? e.data.message : 'Error!'
            this.setState({ request: false })
            return this.alertError(text)
        }
    }

    render() {
        return (
            <div className="modalAddSiteAdmin">
                <Loading show={this.state.request} />
                <CustomModal title={this.props.data ? this.props.t('label_AddSatelliteSite') : this.props.t('label_AddPrimarySite')} open={this.props.open} onClose={this.props.onClose}>
                    <div className='padding-container'>
                        {!!this.props.data &&
                            <div className='primary-site-container'>
                                <div className='title mr'>PRIMARY SITE:</div>
                                <img className='img mr' src={this.props.logo && this.props.logo.length > 0 ? this.props.logo : NoLogo} />
                                <div className='sitename'>{this.props.data.name}</div>
                            </div>
                        }
                        <div className='displayFlex'>
                            <div className='flex1 items'>
                                <div className='title'>
                                    {/* {this.props.t('label_Clinical').toUpperCase()} */}
                                    {this.props.t('label_ClinicalTesting')}
                                    <div className='displayFlex jc-space'>
                                        <div className='displayFlex my-10'>
                                            <CustomRadio
                                                onClick={() => this.setState({ clinical: { ...this.state.clinical, check: true } })}
                                                active={this.state.clinical.check}
                                            />
                                            {this.props.t('label_Yes')}
                                        </div>
                                    </div>
                                    <div className='displayFlex'>
                                        <CustomRadio
                                            onClick={() => this.setState({ clinical: { ...this.state.clinical, check: false } })}
                                            active={!this.state.clinical.check}
                                        />
                                        {this.props.t('label_No')}
                                    </div>
                                </div>
                                <div className='title marginLeft'>
                                    {this.props.t('label_Type').toUpperCase()}
                                </div>
                                <div className='displayFlex ali-center'>
                                    <div className={'div-children displayFlex ali-center ' + this.state.project.research}>
                                        <CustomCheckbox
                                            action={() => this.setState({ project: { ...this.state.project, research: !this.state.project.research } })}
                                            active={this.state.project.research}
                                        />
                                        {this.props.t('label_Research')}
                                    </div>
                                    {!this.state.clinical.eds && !!this.state.project.research &&
                                        <div className='divRequired'>
                                            REQUIRED EDS
                                        <div />
                                        </div>
                                    }
                                </div>
                                <div className='displayFlex ali-center jc-space' style={{ marginTop: '20px' }}>
                                    <div className='div-children displayFlex ali-center'>
                                        <CustomCheckbox
                                            action={() => this.setState({ project: { ...this.state.project, pilot: !this.state.project.pilot } })}
                                            active={this.state.project.pilot}
                                        />{this.props.t('label_Pilot')}
                                    </div>
                                    {/* {this.props.data &&
                                        <div className='div-children displayFlex ali-center'>
                                            <CustomCheckbox />EDS
                                        </div>
                                    } */}
                                </div>

                                <div className='title marginLeft'>
                                    Allow extended DATA Share
                                    <div className='displayFlex jc-space'>
                                        <div className='displayFlex my-10'>
                                            <CustomRadio
                                                onClick={() => this.setState({ clinical: { ...this.state.clinical, eds: true } })}
                                                active={this.state.clinical.eds}
                                            />
                                            {this.props.t('label_Yes')}
                                        </div>
                                    </div>
                                    <div className='displayFlex'>
                                        <CustomRadio
                                            onClick={() => this.setState({ clinical: { ...this.state.clinical, eds: false } })}
                                            active={!this.state.clinical.eds}
                                        />
                                        {this.props.t('label_No')}
                                    </div>
                                </div>

                            </div>
                            <div className='flex1 items'>
                                <div className='title'>
                                    {this.props.t('label_SiteName').toUpperCase() + '*'}
                                </div>
                                <div className='displayFlex ali-center heightDefault'>
                                    <CustomInput
                                        max={40}
                                        value={this.state.name}
                                        onChange={value => this.setState({ name: value })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex1 btn'>
                            <CustomButton value={this.props.t('label_Next')} handleChange={() => this.create()} />
                        </div>
                    </div>
                </CustomModal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ create_site_admin }, dispatch);

ModalAddSiteAdmin = connect(null, mapDispatchToProps)(ModalAddSiteAdmin)

export default withTranslation()(ModalAddSiteAdmin);