export const CHANGE_LANGUAGE_DATA = 'CHANGE_LANGUAGE_DATA';

export const SET_USER_DATA = 'SET_USER_DATA';

export const SET_LOADER = `SET_LOADER`;

export const SET_ROUTER = `SET_ROUTER`;

export const SET_INITIAL = 'SET_INITIAL'

export const SET_ALERT = 'SET_ALERT';

export const TESTS_POSITIVE_COUNTER = 'TESTS_POSITIVE_COUNTER';

export const TESTS_RECURRING = 'TESTS_RECURRING';

export const SET_LOGO = 'SET_LOGO'