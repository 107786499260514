import React, { Fragment } from "react";

import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header";
import BoxConfirmed from "features/user/signUp/registerSite/registerConfirmed/boxConfirmed";
import Footer from "components/footer";
import { bindActionCreators } from "redux";
import * as actions from 'redux/actions';
import { connect } from "react-redux";
import axios from 'helper/axiosHelper'
import queryString from 'query-string'
class Confirmed extends React.Component {

    state = {
        confirmed: false,
        code: null,
        data: null
    }

    componentDidMount() {

        if (this.state.confirmed) return false;

        var {token} = queryString.parse(this.props.location.search)

        if (!token) return false;

        axios.post('confirm', {
            email_token: token
        }).then((data) => {
            this.setState({
                confirmed: true,
                data
            })
        }).catch(() => {
            this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
        })
    }

    render() {
        return (
            <Fragment>
                {!this.state.confirmed ? <Fragment> <Header page={'login'} /></Fragment> :
                    <Fragment>
                        <PageContainer page={'login'}>
                            <Header page={'login'} />
                            <BoxConfirmed data={this.state.data}/>
                        </PageContainer>
                        <Footer />
                    </Fragment>
                }
            </Fragment>
        )
    }
}


const mapStateToProps = state => ({
    loader: state.UserReducer.loader
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

Confirmed = connect(mapStateToProps, mapDispatchToProps)(Confirmed);

export default Confirmed;
