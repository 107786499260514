import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserActions } from 'redux/reducers';
import CustomButton from "components/customButton";
import "./index.scss";
import toastr from 'reactjs-toastr';
import CustomModal from 'components/customModal'
import { withTranslation } from 'react-i18next';
class ModalFeedBack extends Component {

  state = {
    subject: '',
    comment: '',
    success: false
  }

  send = async () => {

    if(!this.state.subject.length && !this.state.comment.length) return null;

    try {
      await this.props.UserActions.feedback(this.state.subject, this.state.comment)
      this.setState({ success: true, subject: '', comment: '' })
    } catch (e) {
      toastr.error('Error', '', { displayDuration: 2000 })
    }
  }


  render() {
    return (
      <CustomModal title={this.props.t('label_Feedback').toUpperCase()} open={this.props.open} headerColor={"yellow"} onClose={this.props.onClose}>
        <div className="boxFeedBack">
          <div className="box-title">
            <label>{this.props.t('label_Subject').toUpperCase()}</label>
          </div>
          <div>
            <input className="input-box" placeholder="Subject" onChange={e => this.setState({ subject: e.target.value, success: false })} value={this.state.subject} />
          </div>
          <div className="box-title">
            <label>{this.props.t('label_Comment').toUpperCase()}</label>
          </div>
          <div>
            <textarea rows={6} className={'text-area'} style={{ resize: 'none', outline: 'initial' }} placeholder="Comment.." onChange={e => this.setState({ comment: e.target.value, success: false })} value={this.state.comment} />
          </div>
        </div>
        <div className="btn-container">
          <CustomButton value={this.state.success ? this.props.t('label_Success').toUpperCase() : this.props.t('label_Send').toUpperCase()} handleChange={this.send} />
        </div>
      </CustomModal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  UserActions: bindActionCreators(UserActions, dispatch)
})

ModalFeedBack = connect(null, mapDispatchToProps)(ModalFeedBack);
export default withTranslation()(ModalFeedBack)