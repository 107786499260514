import React from 'react'
import { Switch, Route } from 'react-router-dom'
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModal } from './modal/IdleModal'
import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import * as actions from "../../redux/reducers/UserReducer";
import { connect } from 'react-redux';


class SessionManagement extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            timeout: 10000 * 15 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.intervalForceLogout = null;
    }

    forceLogout = () => {
        clearInterval(this.intervalForceLogout);
        this.props.actions.setInitialUser();
        window.location.href = `${process.env.REACT_APP_FRONT_URL}/login`;
    }

    createIntervalForceLogout = () => {
        this.intervalForceLogout = setTimeout(() => {
            return this.forceLogout();
        }, 900000)
    }

    _onAction(e) {
        this.idleTimer.reset();
    }

    _onActive(e) {
    }

    _onIdle(e) {
        if (this.state.showModal) return false;
        this.setState({ showModal: true });
        this.createIntervalForceLogout();
    }

    handleLogout() {
        this.forceLogout()
    }

    handleRefresh = () => {
        try {
            clearInterval(this.intervalForceLogout);
            this.props.actions.refresh_token(this.props.user.refresh_token);
            this.setState({ showModal: false })
        } catch (e) {
            this.forceLogout();
        }
    }

    render() {
        const { match } = this.props
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout} />

                <div className="">
                    <IdleTimeOutModal
                        showModal={this.state.showModal}
                        handleRefresh={this.handleRefresh}
                        handleLogout={this.handleLogout}
                    />
                </div>
            </>
        )
    }

}

SessionManagement.propTypes = {
    match: PropTypes.any.isRequired,
    history: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    user: state.UserReducer.userData
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SessionManagement);
