import React, { Fragment } from 'react';
import './index.scss';
export default function Card(props) {
    return (
        <div
            className={("card-container cp " + props.type)}
            onClick={props.onClick ? props.onClick : ""}
            style={{
                backgroundImage: `url(${props.image ? props.image : null})`
            }}
        >
            {!!props.header &&
                <div className="blocked-users">
                    {props.t('label_SystemAdminCardUserManagementBlockedUsers')}
                </div>
            }
            <div className="card-inside displayFlex jc-space">
                <div className="left-value-container">
                    {props.leftValue}
                </div>
                <div className="right-value-container">
                    {props.rightValue}
                </div>
            </div>
        </div>

    )
}