import React, { Component, Fragment } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import logout from "assets/img/header-new/header_sign_off.png";
import person from "assets/img/header-new/user_placeholder.png";
import home from "assets/img/header-new/home_icn.png";
import devices from "assets/img/header-new/reader_icn.png";
import cog from "assets/img/header-new/gear_icn.png";
import help from "assets/img/header-new/help_icn.png";
import comment_tips_icn from "assets/img/header-new/comment_icn.png";
import info_tips_icn from "assets/img/header-new/faq_icn.png";
import lamp_tips_icn from "assets/img/header-new/lamp_icn.png";
import logoGazelle from "assets/img/header-new/header_gazelle_logo.png";
import homeOrange from "assets/img/header-new/home_orange_icn.png";
import devicesOrange from "assets/img/header-new/reader_orange_icn.png";
import cogOrange from "assets/img/header-new/gear_orange_icn.png";
import { bindActionCreators } from "redux";
import usaLanguage from "assets/img/header-new/header_language_usa.png";
import brLanguage from "assets/img/header-new/brazil_language_icn.png";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UserActions } from 'redux/reducers';
import * as userManagementActions from "redux/reducers/UserManagementReducer";
import * as managementPortalActions from "redux/reducers/ManagementPortalReducer";
import NoLogo from "assets/img/placeholder_nosite.png";
import DropDown from "./components/DropDown";
import ModalFeedback from "components/modalFeedBack";
import Faq from "components/faq";
import { withRouter } from "react-router-dom";
import logoutHelper from "helper/logoutHelper";

class Header extends Component {
	state = {
		dropDownLanguage: false,
		dropDownHelp: false,
		languageSelected: null,
		helpSelected: help,
		icon: this.props.i18n.language == "ptBR" ? brLanguage : usaLanguage,
		languageList: {
			en: usaLanguage,
			ptBR: brLanguage,
		},
		helpList: {
			help: help,
			tutorial: lamp_tips_icn,
			feedback: comment_tips_icn,
			info: info_tips_icn,
		},
	};

	getLinkSignUp = () => {
		return `${process.env.REACT_APP_FRONT_URL}/register`;
	};

	_redirect = (url) => {
		if (this.props.location.pathname.includes("/admin/"))
			return this.props.history.push(
				`${process.env.REACT_APP_FRONT_URL}/admin/${url}`
			);

		return this.props.history.push(
			`${process.env.REACT_APP_FRONT_URL}${url}`
		);
	};

	getUserRoleTranslation = () => {
		if (this.props.userData.role_id == 2) {
			return this.props.t("label_role_user_admin");
		}
		return this.props.t("label_role_user_site");
	};

	getEntityHeader = () => {
		const user = this.props.userData;
		if (!user || !user.sites || !user.sites.length) return { name: "", logo: "" };

		const site = user.sites[0];

		if (user.entity_name)
			return {
				name: user.entity_name,
				logo: user.entity_logo ? user.entity_logo : NoLogo,
			};

		if (user.sites.length)
			return {
				name: site.name,
				logo: site.logo && site.logo.length ? site.logo : NoLogo
			};
	};

	componentWillMount() {
		if (
			this.props.userLanguage &&
			this.props.i18n.language != this.props.userLanguage
		) {
			this.props.i18n.changeLanguage(this.props.userLanguage);
			this.setState({
				icon:
					this.props.i18n.language == "ptBR"
						? brLanguage
						: usaLanguage,
			});
		}
	}

	setLanguage = (icon) => {
		switch (icon) {
			case usaLanguage: {
				if (
					Object.keys(this.props.userData).length > 0 &&
					!!this.props.userData.languageDefault
				) {
					this.props.UserActions.updateUserLanguage(
						"en",
						this.props.i18n
					);
					break;
				} else {
					this.props.i18n.changeLanguage("en");
					break;
				}
			}
			case brLanguage: {
				if (
					Object.keys(this.props.userData).length > 0 &&
					!!this.props.userData.languageDefault
				) {
					this.props.UserActions.updateUserLanguage(
						"ptBR",
						this.props.i18n
					);
					break;
				} else {
					this.props.i18n.changeLanguage("ptBR");
					break;
				}
			}
		}
	};

	render() {
		const { page } = this.props;

		if (page == "login") {
			return (
				<Fragment>
					<div className="header-login">
						<div className="logo">
							<img src={logoGazelle} className="logo-img" />
						</div>
						{!this.props.admin && (
							<div className="nav-container">
								<Link
									to={this.getLinkSignUp()}
									className="buttonSignUp"
								>
									{this.props.t("label_SignUp")}
								</Link>
								<DropDown
									icons={this.state.languageList}
									setIcon={(icon) => this.setLanguage(icon)}
									icon={
										this.props.i18n.language == "ptBR"
											? brLanguage
											: usaLanguage
									}
								/>
							</div>
						)}
					</div>
				</Fragment>
			);
		}
		if (page == "SignUp") {
			return (
				<Fragment>
					<div className="header-login">
						<div className="logo">
							<img src={logoGazelle} className="logo-img" />
						</div>
						<div className="already">
							<div className="text">
								{this.props.t("label_AlreadyRegistered")}
							</div>
							<div className="nav-container">
								<Link
									to={`${process.env.REACT_APP_FRONT_URL}/login`}
									className="buttonSignUp"
								>
									{this.props.t("label_SignIn")}
								</Link>
								<DropDown
									icons={this.state.languageList}
									setIcon={(icon) => this.setLanguage(icon)}
									icon={
										this.props.i18n.language == "ptBR"
											? brLanguage
											: usaLanguage
									}
								/>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}

		return (
			<Fragment>
				{this.state.ModalFeedback && (
					<ModalFeedback
						open={this.state.ModalFeedback}
						onClose={() => this.setState({ ModalFeedback: false })}
					/>
				)}
				{this.state.faq && (
					<Faq
						open={this.state.faq}
						onClose={() => this.setState({ faq: false })}
					/>
				)}
				<div className="header">
					<div className="logo">
						<Fragment>
							<img
								src={this.getEntityHeader().logo}
								className="logo-img"
							/>
							{this.getEntityHeader().name}
						</Fragment>
					</div>
					<div className="nav-container">
						<div className="icons-container">
							<div
								className="icon-container"
								onClick={() => {
									this.props.managementPortalActions.initialValues();
									this._redirect("/");
								}}
							>
								<img
									className={`icon ${
										this.props.location.pathname === "/"
										} `}
									src={
										this.props.location.pathname === "/"
											? homeOrange
											: home
									}
								/>
							</div>
							<div
								className="icon-container"
								onClick={() => this._redirect("/readers")}
							>
								<img
									className={`icon ${this.props.location.pathname.includes(
										"/readers"
									)} `}
									src={
										this.props.location.pathname.includes(
											"/readers"
										)
											? devicesOrange
											: devices
									}
								/>
								{this.props.userData.readers_off > 0 && (
									<div className="icon-counters">
										{this.props.userData.readers_off}
									</div>
								)}
							</div>
							<div className="icon-container">
								<div className="separator" />
							</div>
							{this.props.userData.role_id == 2 && (
								<div
									className="icon-container"
									onClick={() =>
										this._redirect("/system-admin")
									}
								>
									<img
										className={`icon ${this.props.location.pathname.includes(
											"/system-admin"
										)} `}
										src={
											this.props.location.pathname.includes(
												"/system-admin"
											)
												? cogOrange
												: cog
										}
									/>
								</div>
							)}
							<DropDown
								icons={this.state.languageList}
								setIcon={(icon) => this.setLanguage(icon)}
								icon={this.state.icon}
							/>
							<DropDown
								icons={this.state.helpList}
								icon={help}
								notChange={true}
								setIcon={(icon, iconKey) => {
									if (iconKey == "feedback") {
										return this.setState({
											ModalFeedback: true,
										});
									}
									if (iconKey == "tutorial") {
										return (window.location.href = `${process.env.REACT_APP_FRONT_URL}/tutorial`);
									}
									return this.setState({ faq: true });
								}}
							/>
						</div>
						<div className="user-container">
							<div className="user-container-image">
								<img
									src={
										!!this.props.userData.photo &&
											this.props.userData.photo.length > 0
											? this.props.userData.photo
											: person
									}
									className="user-image cp"
									onClick={() =>
										this.props.userManagementActions.modalUser()
									}
								/>
							</div>
							<div
								className="info-person-container cp"
								onClick={() =>
									this.props.userManagementActions.modalUser()
								}
							>
								<div className="info-person-name">
									{this.props.userData.name}
								</div>
								<div className="info-person-profission">
									{this.getUserRoleTranslation()}
								</div>
							</div>
						</div>
						<div className="logout" onClick={() => logoutHelper(this.props.UserActions)}>
							<img src={logout} className="logout-image cp" />
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = (state) => ({
	userLanguage: state.UserReducer.userData.languageDefault,
	userData: state.UserReducer.userData,
});

const mapDispatchToProps = (dispatch) => ({
	UserActions: bindActionCreators(UserActions, dispatch),
	userManagementActions: bindActionCreators(userManagementActions, dispatch),
	managementPortalActions: bindActionCreators(
		managementPortalActions,
		dispatch
	),
});

Header = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

export default withTranslation()(Header);
