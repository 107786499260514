import React from 'react'
import "./index.scss";
import _ from 'lodash';

export default function Card({ featuredRightValue, normalRightValues, leftValue, active, rightValueItemDiffColor, onClick, filterActive}) {
    
    return (
        <div className={"card " + !!active}>
            <div className={"left-container " + !!active+ ' cp'} onClick={() =>onClick('all')}>
                {filterActive  == 'all' ? <u>{leftValue}</u> : leftValue}
            </div>
            <div className={"right-container " + !!active}>
                {featuredRightValue &&
                    <div className={"featuredValue"}>
                        {featuredRightValue}
                    </div>
                }
                {!_.isEmpty(normalRightValues) && normalRightValues.map((value, key) => {
                    if(typeof(value) == 'object'){
                        return (
                            <div className={"cp"} onClick={() => onClick(value.key)}>
                                {value.key == filterActive ? <u>{value.value}</u> : value.value}
                            </div>
                        )
                    }return (
                        <div className={"cp " + key} onClick={() => onClick(value)}>
                            {value}
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}
