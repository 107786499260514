import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userPermission } from 'helper/permissions';

export default ({ userData, header, component: Component, ...rest }) => {
    if (userData.isAdmin == userPermission.user) {
        return <Redirect to={`${process.env.REACT_APP_FRONT_URL}/`} />;
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Component
                    {...userData}
                    {...props}
                    router={props}
                    header={header}
                />
            )}
        />
    );
};
