import React, { Component } from "react";
import add from "assets/img/header-new/table_add_icon.png";
import { withTranslation } from 'react-i18next';
import arrowDown from "assets/img/header-new/dropdown_arrow.png";
import calendar from "assets/img/header-new/filter_calendar_icon.png";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CloseGrey from 'assets/img/close_invite_user_bg_icn.png'
import AlertIcon from 'assets/img/alert_red_icn.png'
import "./index.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "redux/reducers/SubHeaderReducer";
import * as siteActions from "redux/reducers/SiteManagementReducer";
import {withRouter} from "react-router-dom";


class SubHeader extends Component {

    renderIcons() {
        const { page } = this.props;

        if (page === "systemAdmin") return null;

        if (!!page && page.includes("readers")) {
            return (
                <div className="display-flex">
                    {/* <div className="second-section-printer-container">
                        <img src={printer} className="printer-icon cp" />
                    </div> */}
                    <div className="second-section-printer-container">
                        <img src={add} className="printer-icon cp" onClick={this.props.addReader} />
                    </div>
                </div>
            );
        }
        return (
            null
            // <div className="second-section-printer-container">
            //   <img src={printer} className="printer-icon cp"/>
            // </div>
        );
    }

    componentWillMount() {
        this.props.siteActions.get_sites();
        if (this.props.firstSelected.id == 0) {
            if (this.props.firstOptions.length > 1 || this.props.firstOptions.length == 0) {
                this.props.actions.setSite({ id: 0, value: this.props.t('label_AllSites') })
            } else {
                const site = this.props.firstOptions[0];
                this.props.actions.setSite({ id: site.id, value: site.name })
            }
        }
        if (this.props.secondSelected.id == 0) {
            this.props.actions.setDate(this.props.secondOptions[0])
        }
    }

    render() {
        const { t, page } = this.props;
        return (
            <div className="subHeaderSection-container">
                <div className="first-section">
                    <div className={`first-section-title ${this.props.onClick ? 'cp' : ''}`}
                        onClick={!!this.props.onClick ? this.props.onClick : ''}>{this.props.title}</div>
                    {this.renderIcons()}
                </div>
                {
                    page !== "systemAdmin" &&
                    <div className="second-section">
                        {!!this.props.selectSite && this.props.firstOptions.length > 2 &&
                            <div className="subHeader-select cp">
                                <CustomSelectDiv
                                    arrow={arrowDown}
                                    value={this.props.firstSelected.value}
                                    options={this.props.firstOptions}
                                    onChange={this.props.actions.setSite}
                                />
                            </div>
                        }
                        {page == '/admin/readers' && this.props.showChild &&
                            <div className="subHeader-select cp">
                                <CustomSelectDiv
                                    arrow={arrowDown}
                                    value={this.props.childSelected.value}
                                    options={this.props.childOptions}
                                    onChange={this.props.childOnchange}
                                />
                            </div>
                        }
                        <div className="subHeader-select cp">
                            <CustomSelectDiv
                                leftIcon={calendar}
                                arrow={arrowDown}
                                value={this.props.secondSelected.value}
                                options={this.props.secondOptions}
                                onChange={this.props.actions.setDate}
                            />
                        </div>
                    </div>
                }
                {!!this.props.readers_qty && parseInt(this.props.readers_qty) > 0 && this.props.show_alert &&
                    <div className='connect-readers'>
                        <div className='left-container'>
                            <div className='icon alert'>
                                <img src={AlertIcon} />
                            </div>
                            <a className='text'>
                                {t('label_ToStartUsingReaders', { qty: this.props.readers_qty })}
                            </a>
                            <a className='click' onClick={() => {
                                this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/readers`)
                            }}>
                                {t('label_ClickHereToProceed')}
                            </a>
                        </div>
                        <div className='right-container'>
                            <div className='icon close cp'>
                                <img src={CloseGrey} onClick={() => this.props.onCloseAlert()} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const sites = props.sites ? props.sites : state.SiteManagementReducer.sites.map((site) => { return { ...site, value: site.name } }).filter((site) => site.status != 3);
    return {
        firstOptions: sites.length > 1 ? [{ id: 0, value: props.t('label_AllSites') }, ...sites] : sites,
        secondOptions: state.SubHeaderReducer.dates[window.currentLanguage],
        secondSelected: state.SubHeaderReducer.selected_date,
        firstSelected: state.SubHeaderReducer.selected_site,
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
    siteActions: bindActionCreators(siteActions, dispatch)
})

SubHeader = connect(mapStateToProps, mapDispatchToProps)(SubHeader);

SubHeader.defaultProps = {
    selectSite: true
}

SubHeader = withTranslation()(SubHeader)

export default withRouter(SubHeader);

