import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendTestReport } from 'redux/reducers/UserReducer';
import { getTestUrl } from "redux/reducers/PatientsReducer";
import "./style.scss";
import CustomModal from 'components/customModal'
import Loading from 'components/loading';
import { withTranslation } from 'react-i18next';
class SendEmail extends Component {

    state = {
        to: '',
        message: '',
        loading: false,
        url: null
    }

    componentWillMount = async () => {
        if (this.props.ids.length == 1) {
            const url = await this.props.getTestUrl("pdf", this.props.ids[0]);
            this.setState({ url })
        }
    }
    save = async (e) => {
        this.setState({
            loading: true
        })
        e.preventDefault();
        await this.props.sendTestReport({ message: this.state.message, email: this.state.to, test_ids: this.props.ids })
        this.setState({ loading: false })
        this.props.onClose();
    }


    render() {
        console.log(this.state.url);
        return (
            <>
                <Loading show={this.state.loading} />
                <CustomModal title={this.props.t('label_EmailTestReport').toUpperCase()} open={this.props.open} headerColor={"yellow"} onClose={this.props.onClose}>
                    <form onSubmit={this.save}>
                        <div className="boxSendemail">
                            <div className="box-title">
                                <label>{this.props.t('label_To').toUpperCase()}:</label>
                            </div>
                            <div className="input-container">
                                <input required={true} className="input-box" onChange={e => this.setState({ to: e.target.value })} value={this.state.to} type={"email"} />
                            </div>
                            <div className="box-title mt">
                                <label>{this.props.t('label_Subject').toUpperCase()}:</label>
                            </div>
                            <div className="box-title">
                                {this.props.patientsIds &&
                                    <label>TEST REPORT FOR PATIENT ID {this.props.patientsIds.map((id) => <> {id}</>)}</label>
                                }
                            </div>
                            {this.props.ids.length == 1 && !!this.state.url &&
                                <div className="box-title">
                                    <label>URL: <br /> <a href={this.state.url}>{this.state.url}</a></label>
                                </div>
                            }
                            <div className="box-title mt">
                                <label>{this.props.t('label_Message').toUpperCase()}:</label>
                            </div>
                            <div className="area">
                                <textarea
                                    style={{ width: "100%" }}
                                    required={true}
                                    className="input-box"
                                    onChange={e => this.setState({ message: e.target.value })} value={this.state.message}
                                    type={"text"}
                                    rows={10}
                                />
                            </div>
                            <div className="note">
                                NOTE: This email will be sent with a secure link for the recipient to access the test result report.
                        </div>
                        </div>
                        <div className="btn-container">
                            <button>
                                SEND
                        </button>
                        </div>
                    </form>
                </CustomModal>
            </>
        );
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ sendTestReport, getTestUrl }, dispatch);

SendEmail = connect(null, mapDispatchToProps)(SendEmail);
export default withTranslation()(SendEmail)