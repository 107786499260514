const translations = {
    title_SystemAdminTools: "SYSTEM ADMIN TOOLS",
    label_SystemAdminCardUserManagement: "USER MANAGEMENT",
    label_SystemAdminCardUserManagementBlockedUsers: "BLOCKED USER(S)",
    label_SystemAdminCardUserManagementActive: "active user",
    label_SystemAdminCardUserManagementBlocked: "blocked user",
    label_DeviceManagement: 'DEVICE MANAGEMENT',
    label_SystemAdminCardSiteManagement: "SITE MANAGEMENT",
    label_SystemAdminAdminManagement: "HEMEX ADMIN USER MANAGEMENT"
}

export default translations;