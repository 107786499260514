import React, { Fragment } from 'react';
import './index.scss'

const MapHeader = (props) => (
    <div className={'map-header'}>
        <div className="header-padding">
            <div className="left-container">
                {props.leftValues}
            </div>
            {props.rightValue &&
                <div className="right-container">
                    <div className="right-item">
                        {props.rightValue}
                    </div>
                </div>
            }
        </div>
    </div>
)

export default MapHeader