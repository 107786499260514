import React, { Component, Fragment, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import uuidv4 from 'uuid/v4'


import CustomErrorBox from "components/customErrorBox"
const CustomInputComponent = ({
  field,
  mask,
  maskChar,
  label,
  type,
  max,
  placeHolder,
  readOnly,
  inputClass,
  labelClass,
  errorMessage,
  handleBlur,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  props, // { name, value, onChange, onBlur }
  dataDate, 
  dataDateFormat
}) => {
  const [placeholderHooks, setPlaceHolder] = useState(placeHolder);

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (placeHolder !== placeholderHooks) {
      setPlaceHolder(placeHolder);
    }
  });

  return (
    <Fragment>
      {label && label.length > 0 &&
        <div className={'customInputLabel displayFlex jc-space'}>
          {label && label}
        </div>
      }
      <Fragment>
        <input
          type={type ? type : "text"}
          {...field}
          onBlur={!!handleBlur ? handleBlur : ''}
          readOnly={readOnly ? readOnly : false}
          className={`customInput ${inputClass} ${touched[field.name] && errors[field.name] ? true : false} ${readOnly ? 'readonly' : ''}`}
          placeHolder={placeholderHooks}
          onClick={() => setPlaceHolder("")}
          maxlength={max || 40}
          autoComplete="off"
        />
        {/* } */}
        {errorMessage && touched[field.name] && errors[field.name] && <CustomErrorBox errorMessage={errorMessage} />}
      </Fragment>
    </Fragment>
  );
}
export default CustomInputComponent;
