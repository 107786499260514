const translations = {
    label_Clinical: "Clinical",
    label_Type: "Type",
    label_Research: "Research project",
    label_Pilot: "Pilot",
    label_SingleSite: "Single Site",
    label_MultiSite: "Multi Site",
    label_ClinicalTesting: "Clinical Testing Site"
}

export default translations;