import React from 'react'
import { StyleSheet, css } from 'aphrodite'

class CustomCheckbox extends React.Component {

    render() {
        let checkStyleInternal = this.props.active ? style.intActive : style.intInactive

        const active = {
            width: '15px',
            height: '15px',
            border: 'solid 1px #707070',
            cursor: 'pointer',
            marginRight: '7px'
        }

        const inactive = {
            width: '15px',
            height: '15px',
            border: 'solid 1px #707070',
            cursor: 'pointer',
            marginRight: '7px'
        }

        let currentCss = this.props.active ? active : inactive;

        currentCss = {
            ...currentCss,
            backgroundColor: this.props.bgColorInternal || '',
            cursor: this.props.disabled ? 'initial' : 'pointer',
            border: this.props.bgColorInternal ? 'none !important' : currentCss.border,
        };

        currentCss = this.props.style ? { ...currentCss, ...this.props.style } : currentCss
        return (
            <div onClick={() => this.props.action()} style={currentCss}>
                {this.props.imgInternal ?
                    <div className={css(style.imgInternal)}>
                        <img src={this.props.imgInternal} />
                    </div>
                    : <div className={css(checkStyleInternal)} />
                }
            </div>
        )
    }
}

export default CustomCheckbox

const style = StyleSheet.create({

    imgInternal: {
        display: 'flex',
        justifyContent: 'center',
    },
    active: {
        width: 15,
        height: 15,
        border: 'solid 1px #707070',
        cursor: 'pointer',
        marginRight: 7
    },
    inactive: {
        width: 15,
        height: 15,
        border: 'solid 1px #707070',
        cursor: 'pointer',
        marginRight: 7
    },

    intActive: {
        width: 7,
        height: 7,
        backgroundColor: '#ffa300',
        margin: 3,
    },
    intInactive: {
        width: 8,
        height: 8,
        margin: 3,
        marginRight: 6
    },

    intDisabled: {
        width: 8,
        height: 8,
        margin: 3,
        marginRight: 6,
        backgroundColor: `#d3d3d3`
    }

})