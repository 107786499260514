import React, { Fragment } from 'react';

import BoxReaders from "./boxReaders"
import SubHeader from "components/subHeader";
import HeaderUser from "features/user/header";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash';
import BoxNoData from "components/boxNoData";
import AddDevice from '../../modalDevice/index'
import * as services from 'redux/actions'
import * as actions from "redux/reducers/ReadersReducer";
import { SET_READER } from 'redux/reducers/VerifyReaderReducer'
import Swal from 'sweetalert2'
import Loading from 'components/loading';
import { withTranslation } from 'react-i18next';

class ReadersUser extends React.Component {

    state = {
        counter: 0,
        addDevice: false,
        request: true
    }
    selectReader = (reader) => {
        this.props.actions.toogleReader(reader);
        this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/readers-details`)
    };

    makeRequests = async () => {
        this.setState({
            request: true
        })
        await this.props.services.getSites(this.props.filterSiteSelected.id == 0 ? null : this.props.filterSiteSelected.id, this.props.filterDateSelected.id)
        await this.props.services.getReaders(this.props.filterDateSelected.id)
        const counter = await this.props.services.getReadersCounter(this.props.filterDateSelected.id)
        this.setState({
            counter,
            request: false
        })
    }

    createReader = async (values) => {
        try {
            await this.props.services.createDevice(values)
            await this.makeRequests();
            this.setState({ addDevice: false })
        } catch (e) {
            const text = !!e && e.data.message ? e.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            return;
        }
    };

    showAlert = async () => {
        return await Swal.fire({
            text: "Are you sure you want to Delete?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        });
    }

    deleteReader = async (id) => {
        const { value } = await this.showAlert();
        if (!value) return false;
        await this.props.services.deleteReader({ id })
        await this.makeRequests()
    }

    componentWillMount = async (props) => {
        await this.makeRequests()
    }


    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.filterSiteSelected.id, this.props.filterSiteSelected.id) || !_.isEqual(prevProps.filterDateSelected.id, this.props.filterDateSelected.id)) {
            return this.makeRequests()
        }
    }

    setup_readers = (reader) => {
        this.props.setup_reader(reader);
        this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/verify-reader`)
    }

    render() {
        console.log("readers", this.props.readers);
        return (<Fragment>
            <Loading show={this.state.request} />
            <HeaderUser
                router={this.props.router}
            />
            {this.state.addDevice &&
                <AddDevice
                    open={this.state.addDevice}
                    sites={this.props.sites && this.props.sites.map((site) => {
                        return { ...site, value: site.name }
                    })}
                    save={(values) => this.createReader(values)}
                    onClose={() => this.setState({ addDevice: !this.state.addDevice })}
                />
            }
            <SubHeader
                addReader={() => this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/reader`)}
                page={'readers'}
                title={`READERS (${this.state.counter})`}
                firstOptions={this.props.filterSiteOptions}
                firstSelected={this.props.filterSiteOptions.length == 1 ? {
                    id: this.props.filterSiteOptions[0].id,
                    value: this.props.filterSiteOptions[0].name
                } : this.props.filterSiteSelected}
                firstOnChange={option =>
                    this.props.actions.toogleSitefilter(option)
                }
                secondOptions={this.props.filterDateOptions}
                secondSelected={this.props.filterDateSelected}
                secondOnChange={option =>
                    this.props.actions.toogleDateFilter(option)
                }
            />
            {!this.props.readers.length && !this.state.request ?
                <BoxNoData
                    data={"NO READERS AVAILABLE"}
                    styles={{
                        margin: "20px",
                        height: "calc(100vh - 226px)"
                    }}
                />
                :
                <div className="div-padding">
                    {this.props.readers.map(reader => {
                        return (
                            <BoxReaders
                                setupReader={() => this.setup_readers(reader)}
                                router={this.props.router}
                                onClick={() => this.selectReader(reader)}
                                reader={reader}
                                t={this.props.t}
                                dateSelected={this.props.filterDateSelected}
                                deleteReader={(id) => this.deleteReader(id)}
                            />
                        )
                    })}
                </div>
            }
        </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => {
    let allSites = !!state.UserReducer.userData && _.isArray(state.UserReducer.userData.sites) ? [...state.UserReducer.userData.sites.map((site) => {
        return { ...site, value: site.name }
    })] : [];
    return {
        filterSiteOptions: allSites.length == 1 ? allSites : [{ id: 0, value: props.t('label_AllSites') }, ...allSites].filter((site) => site.status != 3),
        filterSiteSelected: state.ReadersReducer.SELECTED_SITE.id == 0 ? { id: 0, value: props.t('label_AllSites') } : state.ReadersReducer.SELECTED_SITE,
        filterDateOptions: state.ReadersReducer.FILTER_DATE[window.currentLanguage],
        filterDateSelected: state.ReadersReducer.SELECTED_DATE.id == 0 ? state.ReadersReducer.FILTER_DATE[window.currentLanguage][0] : state.ReadersReducer.SELECTED_DATE,
        readers: state.ReadersReducer.readers,
        sites: state.UserReducer.userData.sites.filter((site) => site.status != 3),
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    services: bindActionCreators(services, dispatch),
    setup_reader: settings => dispatch(SET_READER(settings))
})

ReadersUser = connect(mapStateToProps, mapDispatchToProps)(ReadersUser)

export default withTranslation()(ReadersUser)
