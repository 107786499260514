import React, {Fragment} from 'react'
import './index.scss';
import HeaderUser from 'features/user/header'
import axios from 'helper/axiosHelper';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import * as services from 'redux/actions';
import {withTranslation} from "react-i18next";
import { matchPath, Redirect } from 'react-router-dom'

class Terms extends React.Component {

    state = {
        errorScroll: false,
        position: 0,
        modalDecline: false
    }

    getScrollPosition = () => {
        return document.querySelector('.margin-default').scrollTop
    }

    componentDidMount() {
        document.querySelector('.margin-default').addEventListener('scroll', () => {
            this.setState(({
                errorScroll: this.state.errorScroll ? this.getScrollPosition() == 610 : false,
                position: this.getScrollPosition()
            }))
        })
    }

    _accept = async () => {

        const match = matchPath(this.props.router.history.location.pathname, {
            path: this.props.path,
            exact: true,
            strict: false
        })
        const hash = match.params.id;

        if(!hash) return false;

        this.props.services.actionEds(hash, 'accepted')
        .then(() => this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}`))
    }

    _decline = async () => {
        const match = matchPath(this.props.router.history.location.pathname, {
            path: this.props.path,
            exact: true,
            strict: false
        })
        const hash = match.params.id;

        if(!hash) return false;

        this.props.services.actionEds(hash, 'denied')
        .then(() => this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}`))
    }

    render() {

        return (
            <div className="termsPage">
                <HeaderUser {...this.props} page={'login'}/>
                <div className="terms-container">
                    <div className="modal-terms">
                        <div className="modal-title">
                            Research Project Terms of Use
                        </div>
                        <div className="margin-default">
                            <div className="termsText">
                                The Hemex Health website located at http://hemexhealth.com/ is a copyrighted work
                                belonging to Hemex Health. Certain features of the Site <br/>
                                may be subject to additional guidelines, terms, or rules, which will be posted on the
                                Site in connection with such features.<br/><br/>
                                All such additional terms, guidelines, and rules are incorporated by reference into
                                these Terms.<br/><br/>
                                These Terms of Use described the legally binding terms and conditions that oversee your
                                use of the Site. BY LOGGING INTO THE SITE, YOU ARE BEING COMPLIANT THAT THESE TERMS and
                                you represent that you have the authority and capacity to enter into these Terms. YOU
                                SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF THE
                                PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.<br/><br/>
                                These terms require the use of arbitration Section 10.2 on an individual basis to
                                resolve disputes and also limit the remedies available to you in the event of a dispute.
                                These Terms of Use were created with the help of the Terms Of Use Generator and the
                                Privacy Policy Template. <br/><br/>
                            </div>
                            <div className="version">Access to the site</div>
                            <div className="termsText">
                                Subject to these Terms. Company grants you a non-transferable, non-exclusive, revocable,
                                limited license to access the Site solely for your own personal, noncommercial use.
                                Certain Restrictions. The rights approved to you in these Terms are subject to the
                                following restrictions: (a) you shall not sell, rent, lease, transfer, assign,
                                distribute, host, or otherwise commercially exploit the Site; (b) you shall not change,
                                make derivative works of, disassemble,
                                reverse compile or reverse engineer any part of the Site; (c) you shall not access the
                                Site in order to build a similar or emails, or whether Company posts notices on the Site
                                or communicates with you via email. For contractual purposes, you (a) consent to receive
                                communications from Company in an electronic form; and (b) agree that all terms and
                                conditions, agreements, notices, disclosures, and other communications that Company
                                provides to you electronically satisfy any legal obligation that such communications
                                would satisfy if it were be in a hard copy writing. <br/> <br/>
                                Entire Terms. These Terms constitute the entire agreement between you and us regarding
                                the use of the Site. Our failure to exercise or enforce any right or provision of these
                                Terms shall not operate as a waiver of such right or provision.
                                The section titles in these Terms are for convenience only and have no legal or
                                contractual effect. The word "including" means "including without limitation". If any
                                provision of these Terms is held to be invalid or unenforceable, the other provisions of
                                these Terms will be unimpaired and the invalid or unenforceable provision will be deemed
                                modified so that it is valid and enforceable to the maximum extent permitted by law.
                                Your relationship to Company is that of an independent contractor, and neither party is
                                an agent or partner of the other. These Terms, and your rights and obligations herein,
                                may not be assigned, subcontracted, delegated, or otherwise transferred by you without
                                Company’s prior written consent, and any attempted assignment, subcontract, delegation,
                                or transfer in violation of the foregoing will be null and void. Company may freely
                                assign these Terms. The terms and conditions set forth in these Terms shall be binding
                                upon assignees.<br/><br/>
                                Your Privacy. Please read our Privacy Policy. <br/> <br/>
                                Copyright/Trademark Information. Copyright ©. All rights reserved. All trademarks, logos
                                and service marks displayed on the Site are our property or the property of other
                                third-parties. You are not permitted to use these Marks without our prior written
                                consent or the consent of such third party which may own the Marks. <br/><br/>
                                Contact Information <br/> <br/>
                                Address: 4640 SW Macadam Avenue Suite 250 Portland, OR 97239 <br/><br/>
                                Email: p.white@hemexhealth.com
                            </div>
                            <div className="version"/>
                        </div>
                        <div className="bottons displayFlex jc-space">
                            <div className="decline cp" onClick={() => this._decline()}>DECLINE</div>
                            <div
                                className={`accept cp ${this.state.position < 610 ? 'btnScrollError' : ''}`}
                                onClick={() => this._accept()}>ACCEPT TERMS
                            </div>

                            {this.state.errorScroll &&
                            <div className="accept errorScroll">
                                Scroll down and read <br/>
                                the Terms of Use to continue
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.userData
})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch),
})

Terms = connect(mapStateToProps, mapDispatchToProps)(Terms)

export default withTranslation()(Terms)