import React, { Fragment } from 'react';
import malaria from 'assets/img/header-new/widgets_malaria_background_icon.png';
import hb from 'assets/img/header-new/widgets_hbvariant_background_icon.png';
import cartridges from 'assets/img/header-new/widgets_cartridges_background_icon.png';
import sitesIcon from 'assets/img/site_system_admin_bg_icn.png';
import devicesIcon from 'assets/img/readers/device_system_admin_bg_icn.png';
import GenericTableHeader from 'components/tables/genericTableHeader';
import GenericTableRow from 'components/tables/genericTableRow';
import { tableHeaderManagementPortalAdmin } from 'helper/getTableHeader.js';
import MapHeader from 'features/admin/mapHeader';
import WorldMap from 'components/worldMap';
import grid from 'assets/img/mapHeader/grid_view_icn.png';
import world from 'assets/img/mapHeader/world_icn.png';
import './index.scss';
import * as services from 'redux/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ManagementPortalCardAdmin from './components/card';
import Loading from 'components/loading';
import _ from 'lodash';
import { toogleReader } from 'redux/reducers/ReadersReducer';
import ReactTooltip from 'react-tooltip';
import PageContainer from 'components/pageContainer/pageContainer';
import HeaderAdmin from 'features/admin/header';
import SubHeader from 'features/subHeader';
import { withTranslation } from 'react-i18next';
import PaginatorTable from 'components/paginatorTable/';
import searchBarTable from 'components/searchBarTable/';
import Filters from './Filters/index';
import paginator from 'helper/paginator';
import FilterIcon from 'assets/img/header-new/table_filter_icon.png';
import printer from 'assets/img/header-new/table_print_icon.png';
import Check from "components/checkComponent";

const statusEnum = {
    0: 'R',
    1: 'Y',
    2: 'G'
};

class ManagementPortalAdmin extends React.Component {
    state = {
        view: 'table',
        sites: {
            counter: 0,
            inactive: 0,
            registering: 0,
            countries: 0
        },
        devices: {
            counter: 0,
            offline: 0
        },
        cartridges: {
            counter: 0,
            percent: 0
        },
        malaria: {
            tests: 0,
            reruns: 0,
            reuse: 0,
            repeat: 0
        },
        hb: {
            tests: 0,
            reruns: 0,
            reuse: 0,
            repeat: 0
        },
        loading: true,
        sort: 'status',
        sort_type: 'asc',
        searchError: false,
        searchValue: '',
        qty_page: 10,
        totalPages: 1,
        page: 0,
        filterOpen: false,
        filters: {
            city: [],
            country: [],
            name: '',
            site_type: [],
            status: []
        }
    };

    getContriesMarkers = (sites) => {
        return sites.reduce(
            (initial, site) => {
                if (!site.short_name) return initial;
                initial.countries[site.short_name] = '#FFA300';
                if (!site.latitude || !site.longitude) return initial;

                if (initial.readers[site.city]) {
                    initial.readers[site.city] = {
                        ...initial.readers[site.city],
                        count: initial.readers[site.city].count + 1,
                        readers_total:
                            initial.readers[site.city].readers_total +
                            (site.readers_ative + site.readers_inactive),
                        readers_active:
                            initial.readers[site.city].readers_active +
                            site.readers_ative,
                        readers_inactive:
                            initial.readers[site.city].readers_inactive +
                            site.readers_inactive
                    };
                } else {
                    initial.readers[site.city] = {
                        ...initial.readers[site.city],
                        count: 1,
                        readers_total:
                            site.readers_ative + site.readers_inactive,
                        readers_active: site.readers_ative,
                        readers_inactive: +site.readers_inactive
                    };
                }

                if (site.readers_inactive > 0 || site.readers == 0) {
                    initial.markers.push({
                        latLng: [site.latitude, site.longitude],
                        name: `${site.city} - ${this.props.t(
                            'label_devices'
                        )}: ${
                            initial.readers[site.city].readers_total
                        } - TOTAL SITES: ${initial.readers[site.city].count}`,
                        code: site.short_name,
                        sitename: site.name,
                        style: { fill: '#EB143F' },
                        inactive: site.readers_inactive
                    });
                } else if (site.readers_ative > 0) {
                    initial.markers.push({
                        latLng: [site.latitude, site.longitude],
                        name: `${site.city} - ${this.props.t(
                            'label_devices'
                        )}: ${
                            initial.readers[site.city].readers_active
                        } - TOTAL SITES: ${initial.readers[site.city].count}`,
                        code: site.short_name,
                        sitename: site.name,
                        style: { fill: '#21de03' }
                    });
                }
                return initial;
            },
            { countries: {}, markers: [], readers: {} }
        );
    };

    makeRequests = async () => {
        try {
            await this.props.services.getSites(
                this.props.filterDateSelected.id
            );
            const counters = await this.props.services.getHomeCounters(
                this.props.filterDateSelected.id
            );
            const malariaCounters = await this.props.services.getTestsCounter(
                'malaria',
                this.props.filterDateSelected.id
            );
            const hbCounters = await this.props.services.getTestsCounter(
                'hb',
                this.props.filterDateSelected.id
            );
            this.setState({
                sites: {
                    counter: counters.site.entityCount,
                    inactive: counters.site.entitiesInactives,
                    registering: counters.site.entitiesRegistering,
                    countries: counters.site.countries
                },
                devices: {
                    counter: counters.reader.readers_count,
                    inactive: counters.reader.readers_offline
                },
                cartridges: { ...counters.cartridges },
                malaria: { ...malariaCounters },
                hb: { ...hbCounters },
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (
            !_.isEqual(
                this.props.filterDateSelected.id,
                prevProps.filterDateSelected.id
            )
        ) {
            this.setState(
                { loading: true },
                async () => await this.makeRequests()
            );
        }
    };

    componentWillMount = async () => await this.makeRequests();

    setView = (view) => this.setState({ view });

    getSitesReduced = (sites) => {
        return sites.map((row) => {
            return {
                id: row.id,
                status: row.reader_status ? statusEnum[row.reader_status] : 'R',
                status_id: row.reader_status,
                name: row.name,
                type:
                    row.site_type == 1
                        ? this.props.t('label_SingleSite')
                        : this.props.t('label_MultiSite'),
                site_primary: row.site_primary,
                sites_children: row.childrens_name,
                site_type: row.site_type,
                readers: row.readers,
                count_error_log: row.count_error_log ? row.count_error_log : 0,
                count_firmware_mismatch: row.count_firmware_mismatch
                    ? row.count_firmware_mismatch
                    : 0,
                site_contact: row.name,
                email: row.owner_email ? row.owner_email : '-',
                phone_number: row.phone_number,
                city: row.city,
                country: row.country
            };
        });
    };

    sort_column = (sortTitle) => {
        if (!sortTitle) return false;
        if (this.state.sort == sortTitle) {
            return this.setState({
                sort_type: this.state.sort_type == 'asc' ? 'desc' : 'asc'
            });
        }
        this.setState({ sort_type: 'asc', sort: sortTitle });
    };

    sortItems = (sites) => {
        if (!sites || sites.length == 0) return [];

        sites = this.getSitesReduced(sites);

        return _.orderBy(sites, this.state.sort, this.state.sort_type);
    };

    searchBySerialNumber = (val) => {
        if (!val || val.length == 0) return false;
        this.props.services
            .getReaderBySerialNumber(val)
            .then((device) => {
                const site_id = /\[(.*)\]/.exec(device.reader.site);
                const site_name = device.reader.site.slice(
                    device.reader.site.indexOf(']') + 1
                );

                this.props.setReader({
                    ...device.reader,
                    site_id: site_id[1],
                    site_name,
                    diseases: device.diseases,
                    isSupport: true,
                    tests: device.tests
                });
                this.props.router.history.push(
                    `${process.env.REACT_APP_FRONT_URL}/admin/readers-details`
                );
            })
            .catch(() => {
                this.setState({ searchError: true });
            });
    };

    getFilters = () => {
        let filters = { ...this.state.filters };

        filters.statusAvailable = [];
        filters.sites_types = [];
        filters.cities = [];
        filters.countries = [];

        this.props.sites.forEach((site) => {
            if (
                !filters.statusAvailable.includes(site.reader_status) &&
                !!site.reader_status
            ) {
                filters.statusAvailable.push(site.reader_status);
            }
            if (
                !filters.sites_types.includes(site.site_type) &&
                !!site.site_type
            ) {
                filters.sites_types.push(site.site_type);
            }
            if (!filters.cities.includes(site.city) && !!site.city) {
                filters.cities.push(site.city);
            }
            if (!filters.countries.includes(site.country) && !!site.country) {
                filters.countries.push(site.country);
            }
        });

        return filters;
    };

    getStatusToolTip = (key, status) => {
        let obj = { id: key.toString(), value: '' };
        if (status == 'R') {
            obj.value = this.props.t('label_StatusSiteReaderRed');
        }
        if (status == 'Y') {
            obj.value = this.props.t('label_StatusSiteReaderYellow');
        }
        if (status == 'G') {
            obj.value = this.props.t('label_StatusSiteReaderGreen');
        }
        return obj;
    };

    openReader = (row) => {
        this.props.router.history.push(
            `${process.env.REACT_APP_FRONT_URL}/admin/readers?id=${row.id}`
        );
    };

    filterItems = (itemsPerPage) => {
        const filters = this.state.filters;
        return _.chain(itemsPerPage)
            .filter((site) =>
                filters.status.length
                    ? !!filters.status.find((i) => i == site.status_id)
                    : true
            )
            .filter((site) => {
                return filters.site_type.length
                    ? filters.site_type.includes(site.site_type)
                    : true;
            })
            .filter((site) => {
                return filters.city.length
                    ? filters.city.includes(site.city)
                    : true;
            })
            .filter((site) => {
                return filters.country.length
                    ? filters.country.includes(site.country)
                    : true;
            })
            .filter((site) => {
                return filters.name.length
                    ? site.name
                          .toLowerCase()
                          .includes(filters.name.toLowerCase())
                    : true;
            })
            .value();
    };

    print = () => {
        window.print();
    };

    render() {
        const view = this.state.view;
        const props = this.props;
        const { countries, markers } = this.getContriesMarkers(
            this.props.sites
        );
        let sites_sorted = this.sortItems(this.props.sites);
        sites_sorted = this.filterItems(sites_sorted);
        let { totalPages, itemsPerPage } = paginator(
            sites_sorted,
            this.state.qty_page,
            this.state.page
        );
        sites_sorted = itemsPerPage.map((item) =>
            _.omit(item, ['site_type', 'status_id'])
        );
        return (
            <Fragment>
                <PageContainer>
                    <HeaderAdmin router={this.props.router} />
                    <SubHeader
                        title={`${this.props.t(
                            'DASHBOARD MANAGEMENT PORTAL'
                        )} (${this.props.filterDateSelected.value})`}
                        selectSite={false}
                    />
                    <div className="admin-managementportal">
                        <Loading show={this.state.loading} />
                        <div className="managementPortalCard-card-container">
                            <ManagementPortalCardAdmin
                                title={props
                                    .t('label_SitesWithReaders')
                                    .toUpperCase()}
                                value={{
                                    first: [
                                        <>
                                            <div
                                                className={
                                                    'card-managementPortalCard-container-info-data-container-item-first '
                                                }
                                                data-tip
                                                data-for="label_RegisteredSitesWithActiveGazelleReaders"
                                            >
                                                {this.state.sites.counter}
                                            </div>
                                            <ReactTooltip
                                                id="label_RegisteredSitesWithActiveGazelleReaders"
                                                type="dark"
                                            >
                                                <span>
                                                    {this.props.t(
                                                        'label_RegisteredSitesWithActiveGazelleReaders'
                                                    )}
                                                </span>
                                            </ReactTooltip>
                                        </>
                                    ],
                                    second: [
                                        <>
                                            <div
                                                className={
                                                    'card-managementPortalCard-container-info-data-container-item-second '
                                                }
                                                data-tip
                                                data-for="label_SitesWithIssuesToResolve"
                                            >
                                                {this.state.sites.inactive}
                                            </div>
                                            <ReactTooltip
                                                id="label_SitesWithIssuesToResolve"
                                                type="dark"
                                            >
                                                <span>
                                                    {this.props.t(
                                                        'label_SitesWithIssuesToResolve'
                                                    )}
                                                </span>
                                            </ReactTooltip>
                                        </>
                                    ]
                                }}
                                image={sitesIcon}
                            />
                            <ManagementPortalCardAdmin
                                title={props
                                    .t('label_GazelleReadersInUse')
                                    .toUpperCase()}
                                value={{
                                    first: [
                                        <>
                                            <div
                                                className={
                                                    'card-managementPortalCard-container-info-data-container-item-first '
                                                }
                                                data-tip
                                                data-for="label_ActiveGazelleReaders"
                                            >
                                                {this.state.devices.counter}
                                            </div>
                                            <ReactTooltip
                                                id="label_ActiveGazelleReaders"
                                                type="dark"
                                            >
                                                <span>
                                                    {this.props.t(
                                                        'label_ActiveGazelleReaders'
                                                    )}
                                                </span>
                                            </ReactTooltip>
                                        </>
                                    ],
                                    second: [
                                        <>
                                            <div
                                                className={
                                                    'card-managementPortalCard-container-info-data-container-item-second '
                                                }
                                                data-tip
                                                data-for="label_GazelleReadersWithIssues"
                                            >
                                                {this.state.devices.inactive ||
                                                    0}
                                            </div>
                                            <ReactTooltip
                                                id="label_GazelleReadersWithIssues"
                                                type="dark"
                                            >
                                                <span>
                                                    {this.props.t(
                                                        'label_GazelleReadersWithIssues'
                                                    )}
                                                </span>
                                            </ReactTooltip>
                                        </>
                                    ]
                                }}
                                image={devicesIcon}
                            />
                            {!this.props.user.isSupport && (
                                <>
                                    <ManagementPortalCardAdmin
                                        value={{
                                            second: [
                                                <>
                                                    <div
                                                        className="normal-card-value"
                                                        data-tip
                                                        data-for="label_TotalMalariaTestsDone"
                                                    >
                                                        {
                                                            this.state.malaria
                                                                .tests
                                                        }
                                                    </div>
                                                    <ReactTooltip
                                                        id="label_TotalMalariaTestsDone"
                                                        type="dark"
                                                    >
                                                        <span>
                                                            {this.props.t(
                                                                'label_TotalMalariaTestsDone'
                                                            )}
                                                        </span>
                                                    </ReactTooltip>
                                                    <div
                                                        className={`normal-card-value ${
                                                            this.state.malaria
                                                                .repeat > 0
                                                                ? true
                                                                : false
                                                        }`}
                                                        data-tip
                                                        data-for="label_RepeatMalariaTests"
                                                    >
                                                        {
                                                            this.state.malaria
                                                                .repeat
                                                        }
                                                        {` ${this.props.t(
                                                            'tableHeaderRepeatTest'
                                                        )}`}
                                                    </div>
                                                    <ReactTooltip
                                                        id="label_RepeatMalariaTests"
                                                        type="dark"
                                                    >
                                                        <span>
                                                            {this.props.t(
                                                                'label_RepeatMalariaTests'
                                                            )}
                                                        </span>
                                                    </ReactTooltip>
                                                </>
                                            ]
                                        }}
                                        title={[
                                            <div>
                                                {' '}
                                                {props.t(
                                                    'label_MalariaTests'
                                                )}{' '}
                                            </div>
                                        ]}
                                        type="malaria"
                                        image={malaria}
                                    />
                                    <ManagementPortalCardAdmin
                                        value={{
                                            second: [
                                                <>
                                                    <div
                                                        className="normal-card-value"
                                                        data-tip
                                                        data-for="label_TotalHbTestsDone"
                                                    >
                                                        <div>
                                                            {
                                                                this.state.hb
                                                                    .tests
                                                            }
                                                        </div>
                                                    </div>
                                                    <ReactTooltip
                                                        id="label_TotalHbTestsDone"
                                                        type="dark"
                                                    >
                                                        <span>
                                                            {this.props.t(
                                                                'label_TotalHbTestsDone'
                                                            )}
                                                        </span>
                                                    </ReactTooltip>
                                                    <div
                                                        className={`normal-card-value ${
                                                            this.state.malaria
                                                                .repeat > 0
                                                                ? true
                                                                : false
                                                        }`}
                                                        data-tip
                                                        data-for="label_RepeatHbTests"
                                                    >
                                                        {this.state.hb.repeat}
                                                        {` ${this.props.t(
                                                            'tableHeaderRepeatTest'
                                                        )}`}
                                                    </div>
                                                    <ReactTooltip
                                                        id="label_RepeatHbTests"
                                                        type="dark"
                                                    >
                                                        <span>
                                                            {this.props.t(
                                                                'label_RepeatHbTests'
                                                            )}
                                                        </span>
                                                    </ReactTooltip>
                                                </>
                                            ]
                                        }}
                                        title={[
                                            <div>
                                                {' '}
                                                {props.t('label_HbTests')}{' '}
                                            </div>
                                        ]}
                                        type="hb"
                                        type="hb"
                                        image={hb}
                                    />
                                </>
                            )}
                            {this.props.user.isSupport && (
                                <ManagementPortalCardAdmin
                                    flex={4}
                                    search={true}
                                    removeError={() =>
                                        this.setState({ searchError: false })
                                    }
                                    searchError={this.state.searchError}
                                    onClick={() =>
                                        props.actions.toogleCardTest(2)
                                    }
                                    title={props.t(
                                        'label_ReaderSerialNumberSearch'
                                    )}
                                    onSearch={(value) =>
                                        this.searchBySerialNumber(value)
                                    }
                                    image={cartridges}
                                />
                            )}
                        </div>
                        <MapHeader
                            rightValue={
                                view === 'table' ? (
                                    <>
                                        <div data-tip data-for="label_Print">
                                            <img
                                                src={printer}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '36px'
                                                }}
                                                onClick={this.print}
                                            />
                                            <ReactTooltip
                                                id="label_Print"
                                                type="dark"
                                            >
                                                <span>
                                                    {this.props.t(
                                                        'label_Print'
                                                    )}
                                                </span>
                                            </ReactTooltip>
                                        </div>
                                        <div data-tip data-for="map">
                                            <img
                                                src={world}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '36px',
                                                    marginLeft: '10px'
                                                }}
                                                onClick={() =>
                                                    this.setView(
                                                        view === 'map'
                                                            ? 'table'
                                                            : 'map'
                                                    )
                                                }
                                            />
                                            <ReactTooltip id="map" type="dark">
                                                <span>
                                                    {this.props.t('View map')}
                                                </span>
                                            </ReactTooltip>
                                        </div>

                                        <div
                                            className={`navContainerIcon filterIcon ${
                                                this.state.filterOpen == 1 &&
                                                'menuActiveFilter'
                                            }`}
                                        >
                                            <img
                                                className="navIcon"
                                                src={FilterIcon}
                                                onClick={() =>
                                                    this.setState({
                                                        filterOpen: !this.state
                                                            .filterOpen
                                                    })
                                                }
                                            />
                                            {this.state.filterOpen && (
                                                <Filters
                                                    filters={this.getFilters()}
                                                    toggleFilter={(filters) => {
                                                        this.setState({
                                                            filters
                                                        });
                                                    }}
                                                    default={{
                                                        city: [],
                                                        country: [],
                                                        name: '',
                                                        site_type: [],
                                                        status: []
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div data-tip data-for="map">
                                        <img
                                            src={grid}
                                            style={{
                                                cursor: 'pointer',
                                                width: '36px'
                                            }}
                                            onClick={() =>
                                                this.setView(
                                                    view === 'map'
                                                        ? 'table'
                                                        : 'map'
                                                )
                                            }
                                        />
                                        <ReactTooltip id="map" type="dark">
                                            <span>
                                                {this.props.t('View table')}
                                            </span>
                                        </ReactTooltip>
                                    </div>
                                )
                            }
                            leftValues={
                                <>
                                    <div
                                        className="left-item"
                                        data-tip
                                        data-for="tool_countries"
                                    >
                                        {' '}
                                        {this.state.sites.countries}{' '}
                                        {this.state.sites.countries == 1
                                            ? this.props.t('label_countrie')
                                            : this.props.t(
                                                  'label_countries'
                                              )}{' '}
                                    </div>
                                    <ReactTooltip
                                        id="tool_countries"
                                        type="dark"
                                    >
                                        <span>
                                            {this.props.t(
                                                'With sites and readers'
                                            )}
                                        </span>
                                    </ReactTooltip>
                                    <div
                                        className="left-item"
                                        data-tip
                                        data-for="tool_readers_sites"
                                    >
                                        {' '}
                                        {this.state.sites.counter}{' '}
                                        {this.props.t('SITES')}{' '}
                                    </div>
                                    <ReactTooltip
                                        id="tool_readers_sites"
                                        type="dark"
                                    >
                                        <span>
                                            {this.props.t('With readers')}
                                        </span>
                                    </ReactTooltip>
                                    <div
                                        className="left-item"
                                        data-tip
                                        data-for="total"
                                    >
                                        {' '}
                                        {this.state.devices.counter}{' '}
                                        {this.props.t('title_Readers')}{' '}
                                    </div>
                                    <ReactTooltip id="total" type="dark">
                                        <span>
                                            {this.props.t('label_Total')}
                                        </span>
                                    </ReactTooltip>
                                    {this.state.view == 'table' && (
                                        <PaginatorTable
                                            placeHolder={this.props.t(
                                                'label_SearchForSites'
                                            )}
                                            value={this.state.searchValue}
                                            router={this.props.router}
                                            onChange={(value) =>
                                                this.setState({
                                                    searchValue: value
                                                })
                                            }
                                            perPage={this.state.qty_page}
                                            changeQtyPage={(val) =>
                                                this.setState({
                                                    qty_page: val,
                                                    page: 0
                                                })
                                            }
                                            currentPage={this.state.page}
                                            changePage={(val) =>
                                                this.setState({ page: val })
                                            }
                                            totalPages={totalPages}
                                        />
                                    )}
                                </>
                            }
                        />
                        {view == 'map' ? (
                            <div className="map-container">
                                {' '}
                                <WorldMap
                                    countries={countries}
                                    markers={markers}
                                />{' '}
                            </div>
                        ) : (
                            <>
                                <div className="pages-table-container">
                                    <GenericTableHeader
                                        cols={tableHeaderManagementPortalAdmin(
                                            props.t,
                                            this.state.sort
                                        )}
                                        sort_type={this.state.sort_type}
                                        onClick={(index, sortTitle) =>
                                            this.sort_column(sortTitle)
                                        }
                                    />
                                    <div className="div_rows">
                                        {sites_sorted.map((row, key) => {
                                            row.site_primary = (
                                                <Check
                                                    check={row.site_primary}
                                                    className={'tdsite_primary'}
                                                    color={
                                                        row.site_primary
                                                            ? '#5dd33d'
                                                            : 'red'
                                                    }
                                                />
                                            );
                                            return (
                                                <GenericTableRow
                                                    sites_children={
                                                        row.sites_children
                                                            .length > 0
                                                            ? row.sites_children
                                                            : null
                                                    }
                                                    hover={false}
                                                    cols={tableHeaderManagementPortalAdmin(
                                                        props.t,
                                                        this.state.sort
                                                    )}
                                                    row={_.omit(row, [
                                                        'state',
                                                        'id',
                                                        'sites_children'
                                                    ])}
                                                    rowStatusToolTip={this.getStatusToolTip(
                                                        key,
                                                        row.status
                                                    )}
                                                    onClick={(val, key) =>
                                                        val == 'readers' &&
                                                        this.openReader(row)
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </PageContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    const allSites = state.SiteManagementReducer.sites.map((site) => {
        return { ...site, value: site.name };
    });

    return {
        sites: allSites,
        user: state.UserReducer.userData,
        sorts: state.ManagementPortalReducer.sorts,
        filterDateSelected: state.SubHeaderReducer.selected_date
    };
};

const mapDispatchToProps = (dispatch) => ({
    setReader: (val) => dispatch(toogleReader(val)),
    services: bindActionCreators(services, dispatch)
});

ManagementPortalAdmin = connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagementPortalAdmin);

export default withTranslation()(ManagementPortalAdmin);
