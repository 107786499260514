import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header";
import BoxRegister from "features/user/signUp/registerSite/boxRegister";
import Footer from "components/footer";


export default class Register extends Component {
    static propTypes = {
        prop: PropTypes
    };

    render() {
        return (
            <Fragment>
                <PageContainer page={'login'}>
                    <Header page={'SignUp'} />
                    <BoxRegister {...this.props}/>
                </PageContainer>
                <Footer />
            </Fragment>
        );
    }
}
