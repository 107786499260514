import { Route, Redirect } from 'react-router-dom';
import HeaderUser from 'features/user/header';
import React from 'react';
import { userPermission } from 'helper/permissions';
import RedirectManager from "helper/redirectManager";

export default ({ userData, component: Component, ...rest }) => {
    const redirectManager = RedirectManager();
    const { isAdmin, eds_hash, eds_status, role_id, status } = userData;
    try {
        redirectManager.middleUserPrivateRoutes(isAdmin, userPermission.admin)
        redirectManager.middleEds(eds_status, eds_hash, role_id)
        redirectManager.middleTerms(status)
        redirectManager.middleWizard(role_id)
    } catch (e) {
        return <Redirect to={e} />
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <Component
                    {...userData}
                    {...props}
                    router={props}
                    header={HeaderUser}
                />
            )}
        />
    );
};
