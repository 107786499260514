import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.scss';

export default class CustomLabel extends Component {
    static propTypes = {
        prop: PropTypes
    }

    render() {
        const { className } = this.props;
        return (
            <label className={`customLabel ${className}`}> {this.props.label}</label >
        )
    }
}
