

export function tableHeaderSystemAdminToolsUserManagement(translate, sortCamp) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: "status",
            title: 'STATUS',
            sortTitle: "status",
            sortCamp: sortCamp == "status",
        },
        {
            style: 15,
            title: translate('tableHeaderName'),
            sortTitle: "first_name",
            sortCamp: sortCamp == "first_name",
        },
        {
            style: 20,
            title: translate('tableHeaderEmail'),
            sortTitle: "email_address",
            sortCamp: sortCamp == "email_address",
        },
        {
            style: 10,
            title: translate('tableHeaderRole'),
            sortTitle: "role",
            sortCamp: sortCamp == "role",
        },
        {
            style: 10,
            title: translate('tableHeaderSite'),
            sortTitle: "site",
            sortCamp: sortCamp == "site",
        },
        {
            style: 15,
            title: translate('tableHeaderLastUpdatedDate'),
            sortTitle: "updated_at",
            sortCamp: sortCamp == "updated_at",
        },
        {
            style: 10,
            title: translate('tableHeaderLastUpdatedBy'),
            sortTitle: "updated_by",
            sortCamp: sortCamp == "updated_by",
        },
    ]
}

export function tableHeaderSystemAdminToolsUserManagementAdmin(translate, sortCamp) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: "status",
            title: 'STATUS',
            sortTitle: "status",
            sortCamp: sortCamp == "status",
        },
        {
            style: 15,
            title: translate('tableHeaderName'),
            sortTitle: "first_name",
            sortCamp: sortCamp == "first_name",
        },
        {
            style: 20,
            title: translate('tableHeaderEmail'),
            sortTitle: "email_address",
            sortCamp: sortCamp == "email_address",
        },
        {
            style: 10,
            title: translate('tableHeaderRole'),
            sortTitle: "role",
            sortCamp: sortCamp == "role",
        },
        {
            style: 10,
            title: translate('tableHeaderSite'),
            sortTitle: "site",
            sortCamp: sortCamp == "site",
        },
        {
            style: 15,
            title: translate('tableHeaderLastUpdatedDate'),
            sortTitle: "updated_at",
            sortCamp: sortCamp == "updated_at",
        },
        {
            style: 10,
            title: translate('tableHeaderLastUpdatedBy'),
            sortTitle: "last_updated_by",
            sortCamp: sortCamp == "last_updated_by",
        },
    ]
}

export function tableHeaderSystemAdminToolsAdminManagement(translate, sortCamp) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: "status",
            title: 'STATUS',
            sortTitle: "status",
            sortCamp: sortCamp == "status",
        },
        {
            style: 15,
            title: translate('tableHeaderName'),
            sortTitle: "name",
            sortCamp: sortCamp == "name",
        },
        {
            style: 20,
            title: translate('tableHeaderEmail'),
            sortTitle: "email",
            sortCamp: sortCamp == "email",
        },
        {
            style: 10,
            title: translate('tableHeaderRole'),
            sortTitle: "role",
            sortCamp: sortCamp == "role",
        }
    ]
}

export function tableHeaderReadersDevicesUtilization(translate, sortCamp) {
    return [
        {
            style: "status",
            title: 'STATUS',
            sortTitle: "online",
            sortCamp: sortCamp == "online",
        },
        {
            style: 10,
            title: translate('tableHeaderSerialNumber'),
            sortTitle: "serial_number",
            sortCamp: sortCamp == "serial_number",
        },
        {
            style: 10,
            title: translate('tableHeaderMacAddress'),
            sortTitle: "mac_address",
            sortCamp: sortCamp == "mac_address",
        },
        {
            style: 10,
            title: translate('tableHeaderSWVersion'),
            sortTitle: "sw_version",
            sortCamp: sortCamp == "sw_version",
        },
        {
            style: 10,
            title: translate('tableHeaderLastUpdate'),
            sortTitle: "last_update",
            sortCamp: sortCamp == "last_update",
        },
        {
            style: 10,
            title: translate('tableHeaderFirmwareStatus'),
            sortTitle: "firmware_status",
            sortCamp: sortCamp == "firmware_status",
        },
        {
            style: 10,
            title: translate('label_MalariaTests'),
            sortTitle: "malaria_tests",
            sortCamp: sortCamp == "malaria_tests",
        },
        {
            style: 10,
            title: translate('label_HbTests'),
            sortTitle: "hb_tests",
            sortCamp: sortCamp == "hb_tests",
        },
        {
            style: 10,
            title: translate('tableHeaderRepeatTest'),
            sortTitle: "repeat_tests",
            sortCamp: sortCamp == "repeat_tests",
        },
        {
            style: 10,
            title: translate('tableHeaderSiteName'),
            sortTitle: "site_name",
            sortCamp: sortCamp == "site_name",
        },
        {
            style: 10,
            title: translate('tableHeaderSiteType'),
            sortTitle: "site_type",
            sortCamp: sortCamp == "site_type",
        },
        {
            style: 10,
            title: translate('tableHeaderPrimarySite'),
            sortTitle: "site_primary",
            sortCamp: sortCamp == "site_primary",
        },
        {
            style: 10,
            title: translate('tableHeaderCity'),
            sortTitle: "city",
            sortCamp: sortCamp == "city",
        },
    ]
}

export function tableHeaderAdminSystemAdminToolsSiteManagement(translate) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: 15,
            title: translate('tableHeaderName')
        },
        {
            style: 10,
            title: translate('tableHeaderCity')
        },
        {
            style: 10,
            title: translate('tableHeaderCountry')
        },
        {
            style: 10,
            title: translate('CONTACT')
        },
        {
            style: 10,
            title: translate('tableHeaderPhoneNumber')
        },
        {
            style: 15,
            title: translate('tableHeaderAddress')
        },
        {
            style: 10,
            title: translate('tableHeaderTestType')
        },
        {
            style: 15,
            title: translate('tableHeaderClinicalTesting')
        },
        {
            style: 15,
            title: translate('tableHeaderResearchProject')
        },
        {
            style: 15,
            title: translate('tableHeaderEDS')
        },
    ]
}


export function tableHeaderSystemAdminToolsSiteManagement(translate) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: 'site_logo',
            title: 'SITE LOGO'
        },
        {
            style: 15,
            title: translate('tableHeaderName')
        },
        {
            style: 10,
            title: translate('tableHeaderCountry')
        },
        {
            style: 10,
            title: translate('tableHeaderZip')
        },
        {
            style: 10,
            title: translate('tableHeaderPhoneNumber')
        },
        {
            style: 15,
            title: translate('tableHeaderAddress')
        },
        {
            style: 10,
            title: translate('tableHeaderCity')
        },
        {
            style: 15,
            title: translate('tableHeaderState')
        },
        {
            style: 10,
            title: translate('tableHeaderTestType')
        },
    ]
}

export function tableHeaderManagementPortal(translate, sortCamp) {
    return [
        {
            style: 10,
            title: translate('tableHeaderPatientId'),
            sortTitle: "patient_id",
            sortCamp: sortCamp == "patient_id",
        },
        {
            style: 15,
            title: translate('tableHeaderPatientName'),
            sortTitle: "patient_name",
            sortCamp: sortCamp == "patient_name",
        },
        {
            style: 10,
            title: translate('tableHeaderDateTime'),
            sortTitle: "date",
            sortCamp: sortCamp == "date",
        },
        {
            style: 10,
            title: translate('tableHeaderSiteName'),
            sortTitle: "site_name",
            sortCamp: sortCamp == "site_name",
        },
        {
            style: 10,
            title: translate('tableHeaderPrimarySite'),
            sortTitle: "site_primary",
            sortCamp: sortCamp == "site_primary",
        },
        {
            style: 10,
            title: translate('tableHeaderTestType'),
            sortTitle: "test_type",
            sortCamp: sortCamp == "test_type",
        },
        {
            style: 10,
            title: translate('tableHeaderTestResult'),
            sortTitle: "test_result",
            sortCamp: sortCamp == "test_result",
        },
        {
            style: 10,
            title: translate('tableHeaderLocation'),
            sortTitle: "location",
            sortCamp: sortCamp == "location",
        },
        {
            style: 10,
            title: translate('tableHeaderTestReport'),
        },
        {
            style: 10,
            title: translate('tableHeaderTestComments'),
        }
    ]

}

export function tableHeaderPatients(translate) {
    return [
        {
            style: 10,
            title: translate('tableHeaderPatientName')
        },
        {
            style: 10,
            title: translate('tableHeaderTestType')
        },
        {
            style: 10,
            title: translate('tableHeaderTestResult')
            //   action: () => setSortCamp("patient_type"),
            //   ordered: sortCamp.column == "patient_type" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderDate')
            //   action: () => setSortCamp("start_date"),
            //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderTime')
            //   action: () => setSortCamp("start_date"),
            //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderDOB')
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
        {
            style: 20,
            title: translate('tableHeaderPerformedBy')
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
    ]
}

export function tableHeaderPatientsAdmin(translate, sortCamp) {
    return [
        {
            style: 10,
            title: translate('tableHeaderPatientName'),
            sortCamp: sortCamp.patient_name,
        },
        {
            style: 15,
            title: translate('tableHeaderPatientIdEncrypted'),
            sortCamp: sortCamp.patient_id_encrypted,
        },
        {
            style: 10,
            title: translate('tableHeaderTestType'),
            sortCamp: sortCamp.test_type,
        },
        {
            style: 10,
            title: translate('tableHeaderTestResult'),
            sortCamp: sortCamp.test_result,
            //   action: () => setSortCamp("patient_type"),
            //   ordered: sortCamp.column == "patient_type" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderDateTime'),
            sortCamp: sortCamp.date,

            //   action: () => setSortCamp("start_date"),
            //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderDOB')
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
        {
            style: 20,
            title: translate('tableHeaderPerformedBy')
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
    ]
}

export function tableHeaderManagementPortalAdmin(translate, sortCamp) {
    return [
        {
            style: "status",
            title: "STATUS",
            sortCamp: sortCamp == "status",
            sortTitle: "status"
        },
        {
            style: 10,
            title: translate('tableHeaderSite'),
            sortCamp: sortCamp == "name",
            sortTitle: "name"
        },
        {
            style: 10,
            title: translate('tableHeaderSiteType'),
            sortCamp: sortCamp == "type",
            sortTitle: "type"
        },
        {
            style: 10,
            title: translate('tableHeaderPrimarySite'),
            sortCamp: sortCamp == "site_primary",
            sortTitle: "site_primary"
        },
        {
            style: 10,
            title: translate('label_RegisteredReaders'),
            sortCamp: sortCamp == "readers",
            sortTitle: "readers"
        },
        {
            style: 10,
            title: translate('tableHeaderReadersWithError'),
            sortCamp: sortCamp == "count_error_log",
            sortTitle: "count_error_log"
        },
        {
            style: 10,
            title: translate('tableHeaderReadersWithFirmwareMissmach'),
            sortCamp: sortCamp == "count_firmware_mismatch",
            sortTitle: "count_firmware_mismatch"
        },
        {
            style: 10,
            title: translate('tableHeaderSiteContact'),
            sortCamp: sortCamp == "site_contact",
            sortTitle: "site_contact"
        },
        {
            style: 15,
            title: translate('tableHeaderEmail'),
            sortCamp: sortCamp == "email",
            sortTitle: "email"
        },
        {
            style: 10,
            title: translate('Phone Number'),
            sortCamp: sortCamp == "phone_number",
            sortTitle: "phone_number"
        },
        {
            style: 10,
            title: translate('tableHeaderCity'),
            sortCamp: sortCamp == "city",
            sortTitle: "city"
        },
        {
            style: 10,
            title: translate('tableHeaderCountry'),
            sortCamp: sortCamp == "country",
            sortTitle: "country"
        },
    ]
}

export function tableHeaderDeviceManagementAdmin(translate, sortCamp) {
    return [
        {
            style: 'id',
            title: ""
        },
        {
            style: 10,
            title: translate('label_TotalDevices'),
            sortCamp: sortCamp == "devices",
            sortTitle: "devices"

        },
        {
            style: 10,
            title: translate('label_Active'),
            sortCamp: sortCamp == "active",
            sortTitle: "active"

        },
        {
            style: 10,
            title: translate('label_FirmwareCurrent'),
            sortCamp: sortCamp == "updated",
            sortTitle: "updated"

        },
        {
            style: 5,
            title: translate('label_FirmwareOuDate'),
            sortCamp: sortCamp == "not_updated",
            sortTitle: "not_updated"

        },
        {
            style: 10,
            title: translate('label_CardDeactivated'),
            sortCamp: sortCamp == "discarted",
            sortTitle: "discarted"

        },
        {
            style: 10,
            title: translate('label_TestTypes')

        },
        {
            style: 15,
            title: translate('tableHeaderSiteName'),
            sortCamp: sortCamp == "site",
            sortTitle: "site"
        },
        {
            style: 15,
            title: translate('tableHeaderSiteType'),
            sortCamp: sortCamp == "site_type",
            sortTitle: "site_type"
        },
        {
            style: 15,
            title: translate('tableHeaderPrimarySite'),
            sortCamp: sortCamp == "site_primary",
            sortTitle: "site_primary"
        },
        {
            style: 10,
            title: translate('tableHeaderCountry'),
            sortCamp: sortCamp == "country",
            sortTitle: "country"
        },
    ]
}

export function tableHeaderCartridges(translate, sortCamp) {
    return [
        {
            style: 5,
            title: "",
        },
        {
            style: 10,
            title: translate('tableHeaderType'),
        },
        {
            style: 10,
            title: translate('tableHeaderQuantityOnHand'),
            sortCamp: sortCamp.quantity_onhand,
        },
        {
            style: 15,
            title: "SITE ",
            sortCamp: sortCamp.site,
            //   action: () => setSortCamp("patient_type"),
            //   ordered: sortCamp.column == "patient_type" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderOrderDate'),
            sortCamp: sortCamp.orderDate,
            //   action: () => setSortCamp("start_date"),
            //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderQuantityOrdered'),
            sortCamp: sortCamp.quantityOrdered,
            //   action: () => setSortCamp("start_date"),
            //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
        },
        {
            style: 10,
            title: translate('tableHeaderReceivedDate'),
            sortCamp: sortCamp.dateReceived,
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
        {
            style: "10",
            title: translate('tableHeaderQuantityReceived'),
            sortCamp: sortCamp.quantityReceived,
            //   action: () => setSortCamp("created_by"),
            //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
        },
    ]
}

export function getTableHeaderWizardSites(translate) {
    return [{
        style: 'site_logo',
        title: translate('SITE LOGO')
    },
    {
        style: 10,
        title: translate('tableHeaderName')
    },
    {
        style: 10,
        title: translate('tableHeaderCountry')
    },
    {
        style: 10,
        title: translate('tableHeaderZip')
        //   action: () => setSortCamp("patient_type"),
        //   ordered: sortCamp.column == "patient_type" ? sortCamp.order : undefined
    },
    {
        style: 10,
        title: translate('tableHeaderPhoneNumber')
        //   action: () => setSortCamp("start_date"),
        //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
    },
    {
        style: 10,
        title: translate('tableHeaderAddress')
        //   action: () => setSortCamp("start_date"),
        //   ordered: sortCamp.column == "start_date" ? sortCamp.order : undefined
    },
    {
        style: 10,
        title: translate('tableHeaderCity')
        //   action: () => setSortCamp("created_by"),
        //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
    },
    {
        style: 10,
        title: translate('tableHeaderState')
        //   action: () => setSortCamp("created_by"),
        //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
    },
    {
        style: 10,
        title: translate('label_TestTypes')

        //   action: () => setSortCamp("created_by"),
        //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
    },
    {
        style: "site_logo",
        title: translate('label_Delete').toUpperCase()
        //   action: () => setSortCamp("created_by"),
        //   ordered: sortCamp.column == "created_by" ? sortCamp.order : undefined
    },
    ]
}

export function headerWizardSites(translate) {
    return {
        [translate('SITE LOGO')]: {
            th: "site_logo",
            type: "image"
        },
        [translate('tableHeaderName')]: {
            th: "site_name"
        },
        [translate('tableHeaderCountry')]: {
            th: "site_country"
        },
        [translate('tableHeaderZip')]: {
            th: "site_zip"
        },
        [translate('tableHeaderPhoneNumber')]: {
            th: "site_phone_number"
        },
        [translate('tableHeaderAddress')]: {
            th: "site_address"
        },
        [translate('tableHeaderCity')]: {
            th: "site_city"
        },
        [translate('tableHeaderState')]: {
            th: "site_state"
        },
        [translate('label_TestTypes')]: {
            th: "site_test_type"
        },
        [translate('label_Delete')]: {
            th: "site_delete cp",
            type: "image"
        },
    }
}

export function headerWizardDevices(translate) {
    return {
        [translate('tableHeaderProductNumber')]: {
            th: "product_number"
        },
        [translate('tableHeaderSerialNumber')]: {
            th: "serial_number"
        },
        [translate('tableHeaderSiteName')]: {
            th: "site_name"
        },
        [translate('label_Delete')]: {
            th: "label_delete cp",
            type: "image"
        },
    }
}

export function headerWizardUsers(translate) {
    return {
        [translate('tableHeaderName')]: {
            th: "tableHeaderName"
        },
        [translate('tableHeaderEmail')]: {
            th: "email_address"
        },
        [translate('tableHeaderRole')]: {
            th: "role"
        },
        [translate('tableHeaderSiteName')]: {
            th: "site_name"
        },
        [translate('label_Delete')]: {
            th: "label_delete cp",
            type: "image"
        },
    }
}


export function getTableHeaderWizardDevices(translate) {


    return [
        {
            style: 15,
            title: translate('tableHeaderProductNumber')
        },
        {
            style: 15,
            title: translate('tableHeaderSerialNumber')
        },
        {
            style: 20,
            title: translate('tableHeaderSiteName')
        },
        {
            style: 10,
            title: translate('label_Delete').toUpperCase()
        }
    ]
}

export function getTableHeaderWizardInvites(translate) {
    return [
        {
            style: 10,
            title: translate('tableHeaderName')
        },
        {
            style: 10,
            title: translate('tableHeaderEmail')
        },
        {
            style: 10,
            title: translate('tableHeaderRole')
        },
        {
            style: 10,
            title: translate('tableHeaderSiteName')
        },
        {
            style: 10,
            title: translate('label_Delete').toUpperCase()
        },
    ]
}
