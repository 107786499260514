import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import logo from "assets/img/header-new/site_logo.png";
import organization from "assets/img/wizard/organization_icon@2x.png";
import device from "assets/img/wizard/devices_icon@2x.png";
import organizationOrange from "assets/img/wizard/wizard_site_logo_icon_filled@2x.png";
import backArrow from "assets/img/wizard/back_arrow_icon@2x.png";
import deviceOrange from "assets/img/wizard/wizard_readers_icon_filled@2x.png";
import userOrange from "assets/img/wizard/wizard_user_icon_filled@2x.png";
import { withTranslation } from 'react-i18next';
import user from "assets/img/wizard/users_icon@2x.png";
import "./index.scss";

class Header extends Component {
    static propTypes = {
        prop: PropTypes
    };

    render() {
        const { stepActive, status, changeStep, previousStep, changePreviousStep, devices, invites } = this.props;

        return (
            <Fragment>
                <div className="div-steps">
                    {stepActive !== 1 && <img src={backArrow} className="back_arrow cp" onClick={() => changePreviousStep(previousStep)} />}
                    <div className="div-step cp" onClick={() => status !== 'R' ? changeStep(1, null) : null}>
                        <div className="div-container-itens">
                            <div className={stepActive === 1 ? 'boxActive' : stepActive > 1 ? 'boxWhite' : 'box'}>
                                <img src={stepActive > 1 ? organizationOrange : organization} />
                            </div>
                        </div>
                    </div>
                    <div className={status !== 'R' ? 'lineActive' : 'line'}></div>
                    <div className={status !== 'R' && devices.length > 0 ? "div-step cp" : "div-step"} onClick={(status !== 'R' && devices.length > 0 ? () => changeStep(2, stepActive) : null)}>
                        <div className="div-container-itens">
                            <div className={stepActive === 2 ? 'boxActive' : devices.length > 0 ? 'boxWhite' : 'box'}>
                                <img src={stepActive === 2 || devices.length === 0 ? device : deviceOrange} />
                            </div>
                        </div>
                    </div>
                    <div className={devices.length > 0 ? 'lineActive' : 'line'}></div>
                    <div className={status !== 'R' && devices.length > 0 ? "div-step cp" : "div-step"} onClick={(status !== 'R' && devices.length > 0 ? () => changeStep(3, stepActive) : null)}>
                        <div className="div-container-itens">
                            <div className={stepActive === 3 ? 'boxActive' : devices.length > 0 ? 'boxWhite' : 'box'}>
                                <img src={stepActive === 3 || devices.length === 0 ? user : userOrange} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div-steps-text">
                    <div className={"div-step cp"} onClick={() => status !== 'R' ? changeStep(1, null) : null}>
                        <div className="div-container-itens">
                            <div className={stepActive >= 1 ? 'boxActive' : 'box'}>
                                <div>{this.props.t('label_SiteInformation').replace('<br/>', '\n')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className={status !== 'R' && stepActive > 2 ? "div-step cp" : "div-step"} onClick={(status !== 'R' && devices.length > 0 ? () => changeStep(2, stepActive) : null)}>
                        <div className="div-container-itens">
                            <div className={stepActive >= 2 || devices.length > 0 ? 'boxActive' : 'box'}>
                                <div>{this.props.t('label_RegisterGazelle')}</div>
                            </div>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className={status !== 'R' && stepActive > 3 ? "div-step cp" : "div-step"} onClick={(status !== 'R' && devices.length > 0 ? () => changeStep(3, stepActive) : null)}>
                        <div className="div-container-itens">
                            <div className={stepActive === 3 || devices.length > 0 ? 'boxActive' : 'box'}>
                                <div>{this.props.t('label_AddMoreUsersToSite')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(Header)