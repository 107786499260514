import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import idGenerator from "helper/idGenerator";
import arrowDown from "assets/img/dropdown_icon.png";
import "./index.scss";
import onClickOutside from "react-onclickoutside";
import CustomErrorBox from "components/customErrorBox"

class CustomSelectDiv extends Component {
    static propTypes = {
        prop: PropTypes
    };

    _openOptionsList = () => {
        if (this.props.options.length > 1 && !this.props.disabled) {
            // this.props.setFieldTouched(this.props.field.name, true);
            this.setState({ open: !this.state.open });
        }
    };

    _selectOption = option => {
        this.setState({ open: !this.state.open }, () =>
            this.props.setFieldValue(this.props.field.name, option)
        );
    };

    handleClickOutside = () => {
        if (this.state.open) {
            this.setState({
                open: false
            });
        }
    };

    state = {
        open: false,
        id: idGenerator()
    };

    static defaultProps = {
        options: [],
        selected: {}
    };


    render() {
        const { open } = this.state;
        const {
            field,
            errorMessage,
            value,
            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        } = this.props;
        return (
            <Fragment>
                <div
                    className={`custom-select-formik-container ${errorMessage && touched[field.name] && errors[field.name] ? 'error' : ''} `}
                    onClick={() => this._openOptionsList()}
                >
                    <div className={`custom-select-formik-selected ${value && value ? null : 'placeHolder'}`}>
                        {!!value ? value : 'Select...'}
                    </div>
                    {this.props.options.length > 1 && !this.props.disabled &&
                        <div className="custom-select-formik-arrow-container">
                            <img src={this.props.arrow || arrowDown} className="arrow-image"
                                style={open ? { transform: "rotate(180deg)" } : {}} />
                        </div>
                    }
                    <div className={"custom-select-formik-section-space-container "} />
                </div>
                {this.props.options.length > 0 && open && !this.props.disabled && (
                    <div className={"option-container"}>
                        {this.props.options.map(option =>
                            <div
                                className="custom-select-formik-option cp"
                                onClick={() => this._selectOption(option)}
                            >
                                {option.value}
                            </div>
                        )}
                    </div>
                )}
                {errorMessage && touched[field.name] && errors[field.name] &&
                    <CustomErrorBox errorMessage={errorMessage} />}
            </Fragment>
        );
    }
}

export default CustomSelectDiv;
