const translation = {
    title_SystemAdminTools: "FERRAMENTAS DE GERENCIAMENTO",
    label_SystemAdminCardUserManagement: "Gerenciar Usuários",
    label_SystemAdminCardUserManagementBlockedUsers: "USÚARIOS BLOQUEADOS",
    label_SystemAdminCardUserManagementActive: "Usuários ativos",
    label_SystemAdminCardUserManagementBlocked: "Usuários bloqueados",
    label_DeviceManagement: 'GERENCIAR DISPOSTIVOS',
    label_SystemAdminCardSiteManagement: "Gerenciar Sites",
    label_SystemAdminAdminManagement: "GERENCIAR ADMINISTRADORES"
}

export default translation;