import React, { useState, useEffect } from 'react';
import DropDownOption from '../dropDownOption'
import './style.scss';

export default function DropDown(props) {


    const [open, setOpen] = useState(false)
    const [icon, setIcon] = useState(props.icon || Object.values(props.icons)[0])
    const icons = Object.values(props.icons).filter(ic => ic != icon);

    useEffect(() => {
        setIcon(props.icon)
    }, [props.icon])
    
    return (
        <div className="DropDownIconContainer">
            <img className="icon languageSelected" src={icon} onClick={() => setOpen(!open)} />
            {open &&
                <DropDownOption
                    icons={icons}
                    selected={icon}
                    clickOutSide={() => setOpen(false)}
                    open={open}
                    set={(icon) => {
                        let iconKey = '';

                        for (let icn in props.icons) {
                            if (props.icons[icn] == icon) {
                                iconKey = icn
                            }
                        }
                        setOpen(false)
                        if (props.notChange) {
                            return props.setIcon ? props.setIcon(icon, iconKey) : ''
                        }
                        setIcon(icon)
                        return props.setIcon ? props.setIcon(icon, iconKey) : ''
                    }
                    }
                />
            }
        </div>
    );
}
