import React, { Fragment } from 'react'
import './index.scss';
import HeaderUser from 'features/user/header'
import axios from 'helper/axiosHelper';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { UserActions } from "redux/reducers";
import * as services from 'redux/actions';
import ModalDecline from './modalDecline/index'
import { withTranslation } from "react-i18next";

class Terms extends React.Component {

    state = {
        errorScroll: false,
        position: 0,
        modalDecline: false
    }

    getScrollPosition = () => {
        return document.querySelector('.margin-default').scrollTop
    }

    componentDidMount() {
        document.querySelector('.margin-default').addEventListener('scroll', () => {
            this.setState(({
                errorScroll: this.state.errorScroll ? this.getScrollPosition() == 340 : false,
                position: this.getScrollPosition()
            }))
        })
    }

    _accept = async () => {

        if (this.state.position < 340) {
            return this.setState({ errorScroll: !this.state.errorScroll })
        }

        await this.props.services.acceptTerms()

        this.props.UserActions.setUser({
            ...this.props.user,
            status: "R"
        })
        return this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/wizard`)
    }

    confirmDecline = async () => {
        await this.props.services.declineTerms()
        this.setState({ modalDecline: false })
        return this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
    }

    _decline = async () => {
        return this.setState({ modalDecline: true });
    }

    render() {

        return (
            <div className="user-terms-of-use">
                {this.state.modalDecline &&
                    <ModalDecline open={this.state.modalDecline} {...this.props}
                        decline={() => this.confirmDecline()}
                        onClose={() => this.setState({ modalDecline: false })} />
                }
                <HeaderUser {...this.props} page={'login'} admin={true} />
                <div className="terms-container">
                    <div className="modal-terms">
                        <div className="modal-title">
                            {this.props.t('label_TermsOfUse')}
                        </div>
                        <div className="margin-default">
                            <div className="version">{this.props.t('label_Version')} 1.0</div>
                            <div className="termsText">
                                {this.props.t('terms_version_user')}
                            </div>
                            <div className="version">{this.props.t('label_AccessToTheSite')}</div>
                            <div className="termsText">
                                {this.props.t('terms_access_user')}
                            </div>
                        </div>
                        <div className="bottons displayFlex jc-space">
                            <div className="decline cp" onClick={() => this._decline()}>{this.props.t('label_Decline')}</div>
                            <div
                                className={`accept cp ${this.state.position < 340 ? 'btnScrollError' : ''}`}
                                onClick={() => this._accept()}>{this.props.t('label_AcceptTerms')}
                            </div>

                            {this.state.errorScroll &&
                                <div className="accept errorScroll" onClick={() => this._decline()}>
                                    Scroll down and read <br />
                                    the Terms of Use to continue
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.userData
})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch)
})

Terms = connect(mapStateToProps, mapDispatchToProps)(Terms)

export default withTranslation()(Terms)