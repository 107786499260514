import * as types from "../types";
import * as ReadersTypes from "redux/reducers/ReadersReducer";
import axios from "helper/axiosHelper";
import moment from "moment";
import { async } from "q";

export const getReadersOfflineCounter = () => async (dispatch, getState) => {
	try {
		const readers = await axios.get("/reader/offline");
		let value = getState().UserReducer.userData;
		value.readers_off = readers.readers_off;
		dispatch({ type: types.SET_USER_DATA, value });
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const setUserData = (value) => ({
	type: types.SET_USER_DATA,
	value,
});

export const getSites = (lastNDays = 0) => async (dispatch, getState) => {
	try {
		let params = {};

		if (lastNDays != undefined) params.lastNDays = lastNDays;

		let sites = await axios.get("site", { params });

		let value = {
			...getState().UserReducer.userData,
			sites: sites.sort((a, b) => a.name - b.name),
			entityType: sites.find((site) => site.site_type == 2) ? 2 : 1,
		};
		dispatch({ type: types.SET_USER_DATA, value });
	} catch (e) {
		return await Promise.reject();
	}
};

export const getSitesDeactivated = (lastNDays = 0) => async (
	dispatch,
	getState
) => {
	try {
		let sitesDeactivated = await axios.get("site/deactivated", {
			params: lastNDays,
		});
		dispatch({ type: `SET_DEACTIVATED`, sitesDeactivated });
	} catch (e) {
		return await Promise.reject();
	}
};

export const inviteUser = (values) => async () => {
	try {
		let invite = await axios.post("/invite/create", {
			...values,
		});
		window.showAlert({ type: "success" });
		return invite;
	} catch (e) {
		window.showAlert({ type: "error" });
		return await Promise.reject(e);
	}
};

export const editUser = (values) => async (dispatch, getState) => {
	try {
		await axios.put("/user/" + values.id, {
			...values,
		});
		let userSession = getState().UserReducer.userData;
		if (!userSession || userSession.user_id != values.id) return true;
		userSession.name = values.firstname + " " + values.lastname;
		userSession.photo = values.photo_url || values.photo;
		return setUserData(userSession);

		// console.log('Success edit')
	} catch (e) {
		console.log("Fail edit");
		await Promise.reject();
	}
};

export const getUsers = () => async (dispatch, getState) => {
	try {
		const users = await axios.get(`/user/`);
		dispatch({ type: "USER_MANAGEMENT_USERS", users });
	} catch (e) {
		//window.showAlert({type: "error"})
		await Promise.reject();
	}
};

export const deleteUser = (id) => async () => {
	try {
		await axios.delete(`/user/${id}`);
	} catch (e) {
		window.showAlert({ type: "error" });
		await Promise.reject();
	}
};

export const blockUser = (id) => async () => {
	try {
		await axios.post(`/user/block/${id}`);
	} catch (e) {
		window.showAlert({ type: "error" });
		await Promise.reject();
	}
};
export const unblockUser = (id) => async () => {
	try {
		await axios.post(`/user/unblock/${id}`);
	} catch (e) {
		window.showAlert({ type: "error" });
		await Promise.reject();
	}
};

export const deleteSite = (id) => async () => {
	try {
		await axios.delete(`/site/${id}`);
	} catch (e) {
		window.showAlert({ type: "error" });
		await Promise.reject();
	}
};

export const getInvites = () => async (dispatch, getState) => {
	try {
		let invites = await axios.get("/invite");

		let value = {
			...getState().UserReducer.userData,
			invites,
		};
		return dispatch({ type: types.SET_USER_DATA, value });
	} catch (e) {
		Promise.reject();
	}
};

export const getEntitiesDiseasesCounter = () => async (dispatch, getState) => {
	try {
		const counters = await axios.get("/disease/counters");
		return counters;
	} catch (e) {
		return await Promise.reject();
	}
};

export const createSite = (values) => async (dispatch, getState) => {
	try {
		const site = await axios.post("/site", {
			...values,
		});

		let value = {
			...getState().UserReducer.userData,
			entity_logo:
				getState().UserReducer.userData.status == "R" ? site.logo : "",
			status:
				getState().UserReducer.userData.status == "R"
					? "W"
					: getState().UserReducer.userData.status,
		};
		dispatch({ type: types.SET_USER_DATA, value });
		window.showAlert({ type: "success" });
		return site;
	} catch (e) {
		window.showAlert({ type: "error" });
		return await Promise.reject();
	}
};

export const getDiseases = () => async () => {
	try {
		return await axios.get("disease");
	} catch (e) {
		return Promise.reject();
	}
};

export const confirmInvite = (
	email_token,
	password,
	passwordConfirmation,
	isAdmin = null
) => async (dispatch) => {
	if (isAdmin) {
		try {
			return await axios.post("/invite/confirm", {
				email: email_token,
				password,
				passwordConfirmation,
				isAdmin,
			});
		} catch (e) {
			return Promise.reject();
		}
	} else {
		try {
			return await axios.post("/invite/confirm", {
				email_token,
				password,
				passwordConfirmation,
				isAdmin,
			});
		} catch (e) {
			return Promise.reject();
		}
	}
};

export const acceptTerms = () => async () => {
	try {
		return await axios.post("/terms/accept");
	} catch (e) {
		return Promise.reject();
	}
};

export const getUsersActive = () => async (dispatch) => {
	const { counter } = await axios.get("/user/A");

	dispatch({ type: "COUNTER_USER_ACTIVE", counter });
};

export const getUsersDeactived = () => async (dispatch) => {
	const { counter } = await axios.get("/user/D");

	dispatch({ type: "COUNTER_USER_DEACTIVE", counter });
};

export const getUsersBlocked = () => async (dispatch) => {
	const { counter } = await axios.get("/user/B");

	dispatch({ type: "COUNTER_USER_BLOCKED", counter });
};

export const declineTerms = () => async () => {
	try {
		return await axios.post("/terms/reject");
	} catch (e) {
		return await Promise.reject();
	}
};

export const getRoles = () => async () => {
	try {
		return await axios.get("/role");
	} catch (e) {
		return await Promise.reject();
	}
};

export const getInviteDetails = (email_token) => async () => {
	try {
		return await axios.get("/invite/details", { params: { email_token } });
	} catch (e) {
		return await Promise.reject();
	}
};

export const getInviteDetailsAdmin = (email_token) => async () => {
	try {
		return await axios.get("/admin/invite/details", {
			params: { email_token },
		});
	} catch (e) {
		return await Promise.reject();
	}
};

export const getAddressByZipCode = (zipcode, language) => async (dispatch) => {
	let places = await axios.get(
		`places/address?language=${language}&zipcode=${zipcode}`
	);

	if (places.predicts.length == 0) return {};

	return {
		...places.predicts[0],
		value: `${places.predicts[0].description}`,
	};
};

export const setInitial = () => (dispatch) => {
	return dispatch({ type: types.SET_INITIAL });
};

/**
 * READERS
 *
 */

export const getReadersCounter = (date = 0) => async (dispatch, getState) => {
	const site = getState().ReadersReducer.SELECTED_SITE.id;

	let params = {
		lastNDays: date,
	};

	if (!!site) {
		params.site = site;
	}

	const { counter } = await axios.get("/reader/count", { params });

	return counter;
};

export const getReaders = (date = 0) => async (dispatch, getState) => {
	const site = getState().ReadersReducer.SELECTED_SITE.id;

	let params = {
		lastNDays: date,
	};

	if (!!site) {
		params.site = site;
	}

	try {
		const readers = await axios.get("/reader", {
			params,
		});

		dispatch({ type: ReadersTypes.GET_READERS, readers });
	} catch (e) {
		await Promise.reject();
	}
};

export const deleteReader = (id) => async (dispatch, getState) => {
	try {
		await axios.delete("/reader", {
			data: {
				...id,
			},
		});
		window.showAlert({ type: "success" });
		await getReadersOfflineCounter();
		return true;
	} catch (e) {
		window.showAlert({ type: "error" });
		await Promise.reject();
	}
};

export const createDevice = (values) => async (dispatch, getState) => {
	try {
		const device = await axios.post("/reader", {
			settings: {
				product_number: values.product_number,
				serial_number: values.serial_number,
			},
			...values,
			entity_master_id: values.site_id,
		});
		window.showAlert({ type: "success" });
		await getReadersOfflineCounter();
		return device;
	} catch (e) {
		window.showAlert({ type: "error" });
		return await Promise.reject(e.response || null);
	}
};

export const loginUser = (values) => (dispatch, getState) => {
	const value = {
		...values,
		keepSign: false,
		dateExpires: moment().add(300, "milliseconds").format("x"),
	};
	dispatch({ type: "SET_USER_DATA", value });
};

export const updateSite = (values) => async (dispatch, getState) => {
	try {
		const { id } = values;
		await axios.put(`/site/${id}`, {
			...values,
		});
		window.showAlert({ type: "success" });
		return true;
	} catch (e) {
		window.showAlert({ type: "error" });
		return await Promise.reject();
	}
};

export const updateDevice = ({ id, product_number, serial_number }) => async (
	dispatch,
	getState
) => {
	try {
		const device = await axios.put("/reader", {
			id,
			settings: {
				product_number,
				serial_number,
			},
			product_number,
			serial_number,
		});
		window.showAlert({ type: "success" });
		return device;
	} catch (e) {
		window.showAlert({ type: "error" });
		return await Promise.reject(e.response || null);
	}
};


/**
 * management portal
 */

export const getPatientHistory = (patient) => async (dispatch, getState) => {
	let params = {
		patient,
	};
	try {
		const history = await axios.get("/patients", {
			params,
		});

		return history;
	} catch (e) {
		return await Promise.reject(e);
	}
};

export const getTestFile = (file_type, id) => async (dispatch, getState) => {
	try {
		const { url } = await axios.get(`/tests/file/${file_type}/${id}`);

		return url;
	} catch (e) {
		return await Promise.reject(e);
	}
};
/**
 * patients
 */
export const getPositiveTests = (site = null, lastNDays = 0) => async (
	dispatch,
	getState
) => {
	let params = {};
	if (!!site) {
		params.site = site;
	}

	try {
		const { counter } = await axios.get(`/tests/positive/${lastNDays}`, {
			params,
		});

		dispatch({ type: types.TESTS_POSITIVE_COUNTER, counter });
	} catch (e) {
		Promise.reject(e);
	}
};

export const getTestsRecurring = (site = null, lastNDays = 0) => async (
	dispatch,
	getState
) => {
	let params = {};

	if (!!site) {
		params.site = site;
	}

	try {
		const recurring = await axios.get(`/tests/recurring/${lastNDays}`, {
			params,
		});
		return dispatch({ type: types.TESTS_RECURRING, recurring });
	} catch (e) {
		Promise.reject(e);
	}
};

//skip readers

export const skipReaders = () => async (dispatch, getState) => {
	try {
		await axios.post(`/leaveWizard`);
		const value = {
			...getState().UserReducer.userData,
			status: "A",
		};
		return dispatch({ type: "SET_USER_DATA", value });
	} catch (err) {
		return await Promise.reject(err.response);
	}
};

/**
 *
 * admin actions
 */

export const getAdminInvites = (entity_master_id) => async (
	dispatch,
	getState
) => {
	return await axios.get("/admin/invite", {
		params: { entity_master_id },
	});
};

export const getReadersDeactivated = (date = 0) => async (
	dispatch,
	getState
) => {
	return await axios.get("/admin/readers/deactivated", {
		params: { lastNDays: date },
	});
};

export const getHomeCounters = (date = 0) => async (dispatch, getState) => {
	return await axios.post("/admin/home", {
		lastNDays: date,
	});
};

export const getAdminDevices = (date = 0) => async (dispatch, getState) => {
	try {
		return await axios.get("/admin/readers", {
			params: { lastNDays: date },
		});
	} catch (e) {
		return await Promise.reject(e);
	}
};

export const getTestsCounter = (disease_type, date = 0) => async (
	dispatch,
	getState
) => {
	try {
		return await axios.get(`/admin/tests/${disease_type}/${date}`);
	} catch (e) {
		return await Promise.reject(e);
	}
};

export const getDevicesCounters = (date = 0) => async (dispatch, getState) => {
	try {
		return await axios.get("/admin/readers/counts", {
			params: { lastNDays: date },
		});
	} catch (e) {
		return await Promise.reject(e);
	}
};

export const getDevicesSites = (date = 0) => async (dispatch, getState) => {
	try {
		return await axios.get("/admin/sites");
	} catch (e) {
		return await Promise.reject(e);
	}
};

export const getSitesAdmin = (id = null) => async (dispatch, getState) => {
	try {
		if (!!id) {
			let sites = await axios.get(`/admin/sites/${id}`);
			return sites.sort((a, b) => a.name - b.name);
		}
		let sites = await axios.get("site", { params: { lastNDays: 0 } });
		return sites.sort((a, b) => a.name - b.name);
	} catch (e) {
		return await Promise.reject();
	}
};

export const getReadersAdmin = (master, child, lastNDays = null) => async (
	dispatch,
	getState
) => {
	try {
		if (!lastNDays) {
			let readers = await axios.get(
				`/admin/readers/master/${master}/child/${child}`
			);
			return readers;
		} else {
			let readers = await axios.get(
				`/admin/readers/master/${master}/child/${child}`,
				{ params: { lastNDays } }
			);
			return readers;
		}
	} catch (e) {
		return await Promise.reject();
	}
};

export const invitesAdmin = (master_id) => async (dispatch) => {
	try {
		return await axios.get("/invites", { params: master_id });
	} catch (e) {
		return await Promise.reject();
	}
};

export const inviteEds = (entity_master_id) => async (dispatch) => {
	try {
		return await axios.post("/admin/eds/request", { entity_master_id });
	} catch (e) {
		return await Promise.reject();
	}
};

export const actionEds = (hash, type) => async (dispatch, getState) => {
	try {
		await axios.post(`/admin/eds/request/${type}`, { hash });
		const us = getState().UserReducer.userData;
		us.eds_status = type;
		setUserData(us);
	} catch (e) {
		return await Promise.reject();
	}
};

export const adminCreateSite = (obj) => async (dispatch) => {
	try {
		return await axios.post(`/admin/site/`, obj);
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const getEdsFiles = (master_id) => async (dispatch) => {
	try {
		return await axios.get("/admin/file_upload/" + master_id);
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const getDiseasesByMaster = (master_id) => async (dispatch) => {
	try {
		return await axios.get("/admin/diseases/primary/" + master_id);
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const updateEntity = (siteObj) => async (dispatch) => {
	try {
		return await axios.put("/admin/sites/" + siteObj.id, { ...siteObj });
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const verifyReaderMacAddress = (macAddress) => async (
	dispatch,
	getState
) => {
	try {
		await axios.put(`/reader/connection/${macAddress}`);
		await getReadersOfflineCounter();
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

/**
 * SUPPORT USER
 */

export const getReaderBySerialNumber = (serial_number) => async (dispatch) => {
	try {
		return await axios.get("/admin/readers/search", {
			params: { serial_number: serial_number },
		});
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const createAdminUser = (values) => async (dispatch) => {
	try {
		return await axios.post("/admin/users", { ...values });
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};

export const editAdminUser = (values) => async (dispatch) => {
	try {
		return await axios.put("/admin/users/" + values.id, { ...values });
	} catch (e) {
		return await Promise.reject(e.response || null);
	}
};
