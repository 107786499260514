import React from 'react'
import './index.scss';

export default function customRadio({ active, label, onClick}) {
    return (
        <div className={`customRadioContainer displayFlex ${active}`} onClick={onClick}>
            <div className={`customRadio ${!!active} cp`}>
                {!!active &&
                    <div className={`customRadioInside`} />
                }
            </div>
            <div className="customRadioLabel">{label}</div>
        </div>
    )
}
