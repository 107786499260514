import * as Yup from 'yup';

const validations =
    Yup.object().shape({
        name: Yup.string()
            // .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        place: Yup.string()
            // .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
        , zip_code: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
        , phone_number: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
        , phone_code: Yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
        , address: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required')
        , disease_types: Yup.array()
            .min(1, 'Too Short!')
            .required('Required')
        // , city: Yup.string()
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('Required')
        // , state: Yup.string()
        //     .min(2, 'Too Short!')
        //     .max(50, 'Too Long!')
        //     .required('Required'),
    })

export default validations;