export const FILTRO_DATE_ENUM = {
  FILTER_DATE: {
    en: [
      {
        value: "ALL",
        id: 0
      },
      {
        value: "LAST 30 DAYS",
        id: 30
      },
      {
        value: "LAST 60 DAYS",
        id: 60
      },
      {
        value: "LAST 90 DAYS",
        id: 90
      },
      {
        value: "LAST 120 DAYS",
        id: 120
      }
    ],
    ptBR: [
      {
        value: "TODO PERIODO",
        id: 0
      },
      {
        value: "ÚLTIMOS 30 DIAS",
        id: 30
      },
      {
        value: "ÚLTIMOS 60 DIAS",
        id: 60
      },
      {
        value: "ÚLTIMOS 90 DIAS",
        id: 90
      },
      {
        value: "ÚLTIMOS 120 DIAS  ",
        id: 120
      }
    ]
  },

  SELECTED_DATE: {
    value: "ALL",
    id: 0
  }
};

export const TestsResultEnum = {
  'Positive': {
    ptBR: 'POSITIVO',
    en: 'POSITIVE'
  },
  'Negative': {
    ptBR: 'Negativo',
    en: 'Negative'
  },
  'Recurring': {
    ptBR: 'Recorrente',
    en: 'Re-runs'
  },
  'Retest': {
    ptBR: 'Re-teste',
    en: 'Re-test'
  }
}

export const ALL_SITES = {
  SITES: [
    {
      value: "ALL SITES",
      id: 1
    },
    {
      value: "SITE 1",
      id: 2
    },
    {
      value: "SITE 2",
      id: 3
    },
    {
      value: "SITE 3",
      id: 4
    },
    {
      value: "SITE 4",
      id: 5
    }
  ],
  SELECTED_SITE: {
    value: "ALL SITES",
    id: 0
  },
};

export const test_type_enum = {
  1: "malaria",
  2: "hb",
}