import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import idGenerator from "helper/idGenerator";
import arrowDown from "assets/img/dropdown_icon.png";
import "./index.scss";
import onClickOutside from "react-onclickoutside";

class CustomSelectDiv extends Component {
    static propTypes = {
        prop: PropTypes
    };

    _openOptionsList = () => {
        if (this.props.options.length > 1) {
            this.setState({open: !this.state.open});
        }
    };

    _selectOption = option => {
        this.setState({open: !this.state.open}, () =>
            this.props.onChange(option)
        )
    };

    handleClickOutside = () => {
        if (this.state.open) {
            this.setState({
                open: false
            });
        }
    };

    state = {
        id: idGenerator(),
        open: false
    };

    static defaultProps = {
        options: [],
        selected: {}
    };

    render() {
        const {open} = this.state;
        const {value, error} = this.props;
        return (
            <Fragment>
                <div
                    className={`custom-select-div-container ${!!error ? 'error' : ''}`}
                    onClick={() => this._openOptionsList()}
                >
                    {this.props.leftIcon &&
                    <img src={this.props.leftIcon}/>
                    }
                    <div className={`custom-select-div-selected ${value && value ? null : 'placeHolder'}`}>
                        {!!value ? value : 'Select...'}
                    </div>
                    {this.props.options.length > 1 &&
                    <div className="custom-select-div-arrow-container">
                        <img src={this.props.arrow || arrowDown} className="arrow-image"
                             style={open ? {transform: "rotate(180deg)"} : {}}/>
                    </div>
                    }
                    <div className={"custom-select-div-section-space-container "}/>
                </div>
                {this.props.options.length > 0 && open && (
                    <div className={"option-container"}>
                        {this.props.options.map(option =>
                            <div
                                className="custom-select-div-option cp"
                                onClick={() => this._selectOption(option)}
                            >
                                {option.value}
                            </div>
                        )}
                    </div>
                )}
            </Fragment>
        );
    }
}

export default CustomSelectDiv
