import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import printer from "assets/img/header-new/table_print_icon@2x.png";
import edsIcon from "assets/img/admin/eds_icn.png";
import searchIcon from "assets/img/header-new/table_search_icon@2x.png";
import deleteIcon from "assets/img/header-new/table_delete_icon@2x.png";
import blockUser from "assets/img/close_lock_icn.png";
import unBlockUser from "assets/img/open_lock_icn.png";
import addIcon from "assets/img/header-new/table_add_icon@2x.png";
import PaginatorTable from "components/paginatorTable/";
import ReactTooltip from "react-tooltip";
import { withTranslation } from 'react-i18next';
import FilterIcon from "assets/img/header-new/table_filter_icon@2x.png"
import ReactToPrint from "react-to-print";
import $ from "jquery";

class SearchBarTable extends Component {
    static propTypes = {
        prop: PropTypes
    };

    state = {
        filterOpen: 0,
        positive: 0,
        negative: 0,
        malaria: 0,
        variant: 0,
    }

    print = () => {
        window.print();
    }

    componentDidMount(){
        $(document).on('click', (e) => {
            if ($(e.target).closest(".filterIcon").length === 0 && this.state.filterOpen) {
                this.setState({filterOpen: 0})
            }
        });
    }


    render() {
        return (
            <div className="searchBarTable">
                <div className="searchContainer">
                    <div className="searchIconContainer">
                        <img src={searchIcon} className="searchImage" />
                    </div>
                    <div className="search">
                        <input
                            className="searchInput"
                            value={this.props.value}
                            onChange={(e) => this.props.onChange(e.target.value)}
                            placeholder={this.props.placeHolder || ""}
                        />
                    </div>
                </div>
                <PaginatorTable {...this.props} />
                <div className="navContainer">
                    {this.props.showPrinter &&
                        <>
                            <div className="navContainerIcon cp" data-tip data-for='label_Print'>
                                <img className="cp" src={printer} onClick={this.print} style={{ height: "18px" }} />
                            </div>
                            <ReactTooltip id='label_Print' type='dark'>
                                <span>{this.props.t("label_Print")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {this.props.showEds &&
                        <>
                            <div className="navContainerIcon cp" data-tip data-for='eds_icon'>
                                <img className="navIcon cp eds" src={edsIcon} onClick={this.props.modalEds} />
                            </div>
                            <ReactTooltip id='eds_icon' type='dark'>
                                <span>{this.props.t("label_Eds")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {this.props.showBlock &&
                        <>
                            <div className="navContainerIcon cp" onClick={() => this.props.block()} data-tip data-for='block_icon'>
                                <img className="navIcon cp" src={blockUser} onClick={this.props.block} />
                            </div>
                            <ReactTooltip id='block_icon' type='dark'>
                                <span>{this.props.t("label_Block")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {this.props.showUnblock &&
                        <>
                            <div className="navContainerIcon cp" onClick={() => this.props.unblock()} data-tip data-for='unblock_icon'>
                                <img className="navIcon cp unblock" src={unBlockUser} onClick={this.props.unblock} />
                            </div>
                            <ReactTooltip id='unblock_icon' type='dark'>
                                <span>{this.props.t("label_Unblock")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {!this.props.hideDelete &&
                        <>
                            <div className="navContainerIcon" data-tip data-for='delete_icon'>
                                <img className="navIcon cp" src={deleteIcon} onClick={this.props.delete} />
                            </div>
                            <ReactTooltip id='delete_icon' type='dark'>
                                <span>{this.props.t("label_Delete")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {!this.props.hidePlus &&
                        <>
                            <div className="navContainerIcon" data-tip data-for='add_icon'>
                                <img className="navIcon cp" src={addIcon} onClick={this.props.add} />
                            </div>
                            <ReactTooltip id='add_icon' type='dark'>
                                <span>{this.props.t("label_Add")}</span>
                            </ReactTooltip>
                        </>
                    }
                    {!this.props.hideFilter &&
                        <div className={`navContainerIcon filterIcon ${this.state.filterOpen == 1 && 'menuActiveFilter'}`}>
                            <img className="navIcon" src={FilterIcon} onClick={() => this.setState({ filterOpen: !this.state.filterOpen })} />

                            {this.state.filterOpen == 1 && this.props.filterBox &&
                                this.props.filterBox
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(SearchBarTable)

SearchBarTable.defaultProps = {
    hideFilter: false
}