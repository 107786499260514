import React, { Component } from "react";
import PropTypes from "prop-types";
import EmailIcon from "assets/img/sign-in/email_icon.png";
import "./index.scss";
import { withTranslation } from 'react-i18next';
import InputDefault from 'components/inputDefault';
import { connect } from 'react-redux';
import { UserActions } from "redux/reducers"
import { bindActionCreators } from 'redux'

class BoxForgotPassword extends Component {
  static propTypes = {
    prop: PropTypes
  };

  state = {
    email: "",
    emailErrorMessage: "",
    triedSubmit: false,
    success: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.triedSubmit) return false;

    if (this.state.email !== prevState.email && this.state.emailErrorMessage.length > 0) {
      this.setState({
        emailErrorMessage: ""
      })
    }
  }

  submit = async () => {
    if (!this.state.email.length) {
      return this.setState({
        emailErrorMessage: this.props.t('inputRequiredField'),
        triedSubmit: true
      })
    } else {
      var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
      if (!reg.test(this.state.email)) {
        return this.setState({
          emailErrorMessage: this.props.t('messageEmailInvalid'),
          triedSubmit: true
        })
      }
    }
    try {
      await this.props.UserActions.forgotPassword(this.state.email);
      this.setState({
        success: true
      })
    } catch (e) {
      this.setState({ triedSubmit: true })
    }

  }
  render() {
    const { t } = this.props;
    return (
      <div className="forgotPassword">
        <div className="div-sign-in forgot-password">
          <div className="div-white-sign">
            <div className="div-label-sign">{t('titleBox_ForgotPassword')}</div>
            <div class="div-input">
              <div className="div-forgot">
                {t('subTitleBox_ForgotPassword')}
              </div>
              <div className="box-forgot-password-input">
                <div className="email-box">
                  <InputDefault
                    leftIcon={EmailIcon}
                    value={this.state.email}
                    type={"email"}
                    placeHolder={t('placeHolderEmail')}
                    onChange={(value) => this.setState({ email: value })}
                    error={this.state.emailErrorMessage.length > 0 ? this.state.emailErrorMessage : null}
                  />
                </div>
              </div>
              <div>
                {!this.state.success ?
                  <button
                    className="button-sign cp"
                    onClick={this.submit}
                  >
                    {t('send')}
                  </button>
                  : <div className="button-sign">{t('SUCCESS')}</div>
                }
              </div>
              <div className="div-contact">
                <a className="link_support" href="mailto: support@hemexhealth.com" target="_blank">{t('label_ContactSupport')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  UserActions: bindActionCreators(UserActions, dispatch)
})

BoxForgotPassword = connect(null, mapDispatchToProps)(BoxForgotPassword)

export default withTranslation()(BoxForgotPassword)