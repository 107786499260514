import React, { Component } from 'react'
import BoxSystem from "components/boxSystem";
import siteManagement from "assets/img/site_system_admin_bg_icn.png";
import userManagement from "assets/img/user_system_admin_bg_icn.png";
import { withTranslation } from 'react-i18next';
import "./index.scss"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import * as services from 'redux/actions'
import { allUsersFiltered } from 'redux/reducers/UserManagementReducer'
class SystemAdminContainer extends Component {


    componentWillMount = async () => {
        await this.props.services.getUsers()
        await this.props.services.getSites()
    }

    render() {
        const { props } = this;
        const { t } = this.props;
        return (
            <div className="systemAdmin-container">
                <div className="w25 paddingDefault">
                    <BoxSystem
                        onClick={() => {
                            props.history.push(`${process.env.REACT_APP_FRONT_URL}/system-admin/userManagement`)
                        }}
                        title={t('label_SystemAdminCardUserManagement')}
                        defaultNumber={`${this.props.users.active} ${t('label_SystemAdminCardUserManagementActive')}`}
                        greenNumber={`${this.props.users.blocked} ${t('label_SystemAdminCardUserManagementBlocked')}`}
                        image={userManagement}
                        imgSize={'120px'}
                    />
                </div>
                {props.entityType == 2 &&
                    <div className="w25 paddingDefault">
                        <BoxSystem
                            onClick={() => {
                                props.history.push(`${process.env.REACT_APP_FRONT_URL}/system-admin/siteManagement`)
                            }}
                            title={t('label_SystemAdminCardSiteManagement')}
                            defaultNumber={this.props.sites.length}
                            image={siteManagement}
                        />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sites: state.UserReducer.userData.sites || [],
    users: allUsersFiltered(state),
})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch)
})

SystemAdminContainer = connect(mapStateToProps, mapDispatchToProps)(SystemAdminContainer)

export default withTranslation()(SystemAdminContainer)