const translations = {
    titleBox_SiteConfirmationRegistration: "CONFIRMAÇÃO DE REGISTRO DE SITE",
    subTitleBoxFirstLine_RegisterSite: "Para continuar seu registro de site, nós precisamos",
    subTitleBoxSecondLine_RegisterSite: " confirmar o endereço de e-mail que você preencheu:",
    labelCheckEmailInboxFirstLine: "Verifique no seu e-mail, a mensagem de registro da Hemex Health, ",
    labelCheckEmailInboxSecondLine: "e depois clique para confirmar",
    labelDidntRecieveEmail: "Não recebeu?",
    labelResendEmail: "Reenviar",
    labelChangeEmail: "Alterar e-mail",
}

export default translations;