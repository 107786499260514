import React, { Component, Fragment } from "react";
import PageContainer from "components/pageContainer/pageContainer";
import Footer from "components/footer";
import { connect } from "react-redux";
import * as actions from "redux/reducers/ReadersReducer";
import * as managementActions from "redux/reducers/ManagementPortalReducer";

import { bindActionCreators } from "redux";
import "./index.scss";
import ModalDevice from '../modalDevice'
import ReadersAdminView from './readersAdminView'
import ReadersUserView from './readersUserView'

class Readers extends Component {

  state = {
    modalDevice: false
  }

  componentWillMount(){
    this.props.managementActions.initialValues()
  }

  isAdmin = () => {
    return this.props.router.location.pathname.includes('/admin')
  }

  render() {
    const isAdmin = this.isAdmin();

    return (
      <Fragment>
        <PageContainer>
          {!!this.state.modalDevice &&
            <ModalDevice open={this.state.modalDevice} />
          }
          {isAdmin ? <ReadersAdminView {...this.props} /> : <ReadersUserView router={this.props.router} />}
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  managementActions: bindActionCreators(managementActions, dispatch),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Readers);
