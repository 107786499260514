import React, { Fragment } from 'react'
import CustomModal from 'components/customModal';
import { withTranslation } from 'react-i18next';
import InviteAdminUser from 'components/inviteAdminUser'

class ModalInviteUser extends React.Component {
    render() {
        const { open, t } = this.props;
        return (
            <div className="modal-invite-user-container">
                <CustomModal open={open} onClose={() => this.props.onClose()} title={this.props.data ? 'EDIT USER': t('title_SystemAdminTools_UserManagementModalInviteUser')}>
                    <InviteAdminUser {...this.props} handleChangePrev={() => this.props.onClose()}/>
                </CustomModal>
            </div>
        )
    }
}

export default withTranslation()(ModalInviteUser)