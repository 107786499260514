import React, { Component, Fragment, useState, useEffect } from "react";
import ManagementPortalCardUser from "./components/card";
import ModalTestComments from "./components/modalTestComments";

import GenericTableHeader from "components/tables/genericTableHeader";
import GenericTableRow from "components/tables/genericTableRow";
import SearchBarTable from "components/searchBarTable";

import { tableHeaderManagementPortal } from "helper/getTableHeader.js";
import Check from "components/checkComponent";

import malaria from "assets/img/header-new/widgets_malaria_background_icon.png";
import hb from "assets/img/header-new/widgets_hbvariant_background_icon.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import BoxNoData from "components/boxNoData";
import { patientsData, cards } from "redux/reducers/ManagementPortalReducer";

import HeaderUser from "features/user/header";
import SubHeader from "features/subHeader";

import * as services from "redux/actions";
import * as actions from "redux/reducers/ManagementPortalReducer";
import { PatientsActions } from "redux/reducers";
import * as ReaderActions from "redux/reducers/ReaderReducer";
import * as SubHeaderActions from "redux/reducers/SubHeaderReducer";
import { withTranslation } from "react-i18next";

import Filters from "./Filters/index";
import Loading from "components/loading";
import "./index.scss";
import { withRouter } from "react-router";

class ManagementPortalUser extends Component {
	state = {
		diseases: [],
		request: true,
	};

	makeRequests = async () => {
		this.setState({
			request: true,
		});
		await this.props.PatientsActions.getMalariaCounter(
			this.props.filterSiteSelected.id == 0
				? null
				: this.props.filterSiteSelected.id,
			this.props.filterDateSelected.id
		);
		await this.props.PatientsActions.getHbCounter(
			this.props.filterSiteSelected.id == 0
				? null
				: this.props.filterSiteSelected.id,
			this.props.filterDateSelected.id
		);
		await this.props.PatientsActions.getPatientTests(
			this.props.filterSiteSelected.id == 0
				? null
				: this.props.filterSiteSelected.id,
			this.props.filterDateSelected.id
		);
		await this.props.ReaderActions.getReadersOffline();
		this.setState({
			request: false,
		});
	};

	componentWillUnmount() {
		// this.props.actions.initialValues();
	}

	componentDidMount = async () => {
		return await this.makeRequests();
	};

	componentDidUpdate(prevProps) {
		if (
			!_.isEqual(
				prevProps.filterSiteSelected.id,
				this.props.filterSiteSelected.id
			) ||
			!_.isEqual(
				prevProps.filterDateSelected.id,
				this.props.filterDateSelected.id
			)
		) {
			return this.makeRequests();
		}
	}

	getPatientHistory = (id) => {
		this.props.PatientsActions.setPatientId(id);
		if (this.props.userData.isAdmin == 2) {
			return this.props.history.replace(
				`${process.env.REACT_APP_FRONT_URL}/admin/patient-history`
			);
		}
		this.props.history.replace(
			`${process.env.REACT_APP_FRONT_URL}/patient-history`
		);
	};

	showPdf = async (id) => {
		this.props.PatientsActions.setTestId(id);
		if (this.props.userData.isAdmin == 2) {
			return this.props.history.replace(
				`${process.env.REACT_APP_FRONT_URL}/admin/patient-pdf`
			);
		}
		return this.props.history.replace(
			`${process.env.REACT_APP_FRONT_URL}/patient-pdf`
		);
	};

	sort = (sortTitle) => {
		if (!sortTitle) return false;
		if (this.props.userSorts == sortTitle) {
			return this.props.actions.setSortType(
				this.props.sort_type == "asc" ? "desc" : "asc"
			);
		}
		this.props.actions.setSortType("asc");
		this.props.actions.sortUserView(sortTitle);
	};

	getDiseasesBySite = () => {
		let diseases = [];
		if (this.props.filterSiteSelected.id == 0) {
			this.props.sites.map((site) => {
				return site.disease_types.map((disease) =>
					!diseases.includes(disease) ? diseases.push(disease) : ""
				);
			});
		} else {
			const site = this.props.sites.find(
				(site) => site.id == this.props.filterSiteSelected.id
			);
			diseases.push(...site.disease_types);
		}
		return diseases;
	};
	getFilters = () => {
		let filters = { ...this.props.filters };

		filters.test_control = this.props.filters.test_control || {
			positive: false,
			negative: false,
		};

		filters.sites = [
			{ id: 0, value: this.props.t("label_AllSites") },
			...this.props.filterSiteOptions,
		];

		filters.test_control_items = [];

		if (this.props.filterSiteOptions.length == 1) {
			filters.site = this.props.filterSiteOptions[0].value;
		} else {
			filters.site = this.props.filterSiteSelected.value;
		}
		let test_types_options = [];

		filters.testLocations = [{ id: 0, value: "ALL" }];

		this.props.patientsDB.forEach((patient) => {
			patient.tests.forEach((test) => {
				if (
					!filters.testLocations.find(
						(lock) => lock.value == test.location
					)
				) {
					filters.testLocations.push({
						id: test.id,
						value: test.location,
					});
				}

				let diseaseItem = test.disease_id == 1 ? "malaria" : "hb";

				if (
					test.is_control &&
					!filters.test_control_items.includes(
						patient.patient_data[0].json_id
					)
				) {
					filters.test_control_items.push(
						patient.patient_data[0].json_id
					);
				}
				if (
					filters.diseases[diseaseItem] &&
					!test_types_options.includes(test.result)
				) {
					test_types_options.push(test.result);
				}
			});
		});

		filters.test_types_options = test_types_options;

		return filters;
	};

	checkAllRows = (bool) => {
		if (bool) {
			this.props.actions.toggleAll();
			this.props.actions.toggleItem(
				this.props.patientsData.allPatientsFiltered.map((pt) => pt.id)
			);
		} else {
			this.props.actions.toggleAll();
			this.props.actions.toggleItem([]);
		}
	};

	getTableRow = (row) => {
		let omits = ["test_id", "id", "scd_band", "site_childrens_name"];

		if (this.props.userData.isAdmin == 2) {
			omits.push("patient_name");
		} else if (this.props.entity_type == 1) {
			omits.push("site_name");
		}

		const table_row = {
			..._.omit(row, omits),
			site_primary: (
				<Check
					check={row.site_primary}
					className={"tdsite_primary"}
					color={row.site_primary ? "#5dd33d" : "red"}
				/>
			),
		};

		return table_row;
	};

	render() {
		const images = {
			Malaria: malaria,
			Hb: hb,
		};

		const Header = this.props.header ? this.props.header : <HeaderUser />;

		const header_translated = tableHeaderManagementPortal(
			this.props.t,
			this.props.userSorts
		);

		let table_header;
		if (this.props.userData.isAdmin == 2) {
			table_header = header_translated.filter(
				(item) => item.sortTitle != "patient_name"
			);
		} else {
			table_header =
				this.props.entity_type == 2
					? header_translated
					: header_translated.filter(
						(item) => item.sortTitle != "site_name"
					);
		}

		let title = `${this.props.t("title_ManagementPortal")} (${
			this.props.patientsData.counter
			})`;

		if (this.props.userData.isAdmin == 2) {
			title = `${this.props.t("READER TESTS FOR ")} (${
				this.props.filterSiteSelected.value
				} - PERIOD: ${this.props.filterDateSelected.value})`;
		}

		return (
			<div className="management-portal-container">
				<Header {...this.props} />
				<SubHeader
					show_alert={
						!this.props.closedAlert &&
						this.props.readersOff > 0 &&
						this.props.userData.isAdmin != 2
					}
					onCloseAlert={() =>
						this.props.ReaderActions.setClosedReaders()
					}
					readers_qty={this.props.readersOff}
					title={title}
					selectSite={true}
				/>
				<Loading show={this.state.request} />
				<div className="managementPortalCard-card-container">
					{this.props.cards.includes(1) && (
						<ManagementPortalCardUser
							disease={1}
							onClick={() =>
								this.props.actions.toggleFiltersUser({
									...this.props.filters,
									diseases: {
										...this.props.diseases,
										malaria: true,
									},
								})
							}
							title={"Malaria"}
							value={{
								first: `${
									this.props.Malaria.counter
									}  ${this.props
										.t(
											this.props.Malaria.counter > 1
												? "label_Tests"
												: "label_TestSingle"
										)
										.toUpperCase()}`,
								second: `${
									this.props.Malaria.positive
									} ${this.props.t("label_Positive")}`,
							}}
							type={"malaria"}
							image={images["Malaria"]}
							numCard={1}
							activeNow={
								this.props.filters.diseases.malaria ||
								this.props.filters.diseases.both
							}
						/>
					)}
					{this.props.cards.includes(2) && (
						<ManagementPortalCardUser
							disease={2}
							onClick={() =>
								this.props.actions.toggleFiltersUser({
									...this.props.filters,
									diseases: {
										...this.props.diseases,
										hb: true,
									},
								})
							}
							title={"Hb Variant"}
							value={{
								first: `${this.props.Hb.counter} ${this.props
									.t(
										this.props.Hb.counter > 1
											? "label_Tests"
											: "label_Test"
									)
									.toUpperCase()}`,
							}}
							type={"hb"}
							image={images["Hb"]}
							numCard={2}
							activeNow={
								this.props.filters.diseases.hb ||
								this.props.filters.diseases.both
							}
						/>
					)}
				</div>
				{
					<Fragment>
						<SearchBarTable
							showConnectDevices={true}
							hideDelete={true}
							hidePlus={true}
							placeHolder={this.props.t(
								"label_SearchForPatientNameOrId"
							)}
							value={this.props.searchValue}
							perPage={this.props.qty_page}
							changeQtyPage={this.props.actions.changeQtyPage}
							currentPage={this.props.currentPage}
							changePage={this.props.actions.changePage}
							showPrinter={true}
							totalPages={this.props.patientsData.totalPages}
							filterBox={
								<Filters
									filter={this.getFilters()}
									diseases={this.getDiseasesBySite()}
									toggleFilter={(filters) =>
										this.props.actions.toggleFiltersUser(
											filters
										)
									}
									selectSite={(option) =>
										this.props.SubHeaderActions.setSite(
											option
										)
									}
								/>
							}
							onChange={(value) =>
								this.props.actions.searchPatient(value)
							}
						/>
						<div className="pages-table-container">
							<GenericTableHeader
								checked={this.props.allChecked}
								cols={table_header}
								type={"PATIENTS"}
								sort_type={this.props.sort_type}
								onClick={(index, sortTitle) =>
									this.sort(sortTitle)
								}
								checkAction={(bool) => this.checkAllRows(bool)}
							/>
							<div className="div_rows">
								{this.props.patientsData.allPatientsFiltered.map(
									(row) => (
										<GenericTableRow
											checksSelecteds={
												this.props.itemsChecked
											}
											allCheckSelected={
												this.props.allChecked
											}
											checkAction={({ id: itemId }) => {
												const itemsChecked = this.props
													.itemsChecked;
												if (
													itemsChecked.includes(
														itemId
													)
												) {
													this.props.actions.toggleItem(
														itemsChecked.filter(
															(i) => i != itemId
														)
													);
												} else {
													this.props.actions.toggleItem(
														[
															...itemsChecked,
															itemId,
														]
													);
												}
											}}
											sites_children={
												row.site_childrens_name.length >
													0
													? row.site_childrens_name
													: null
											}
											showPdf={() =>
												this.showPdf(row.test_id)
											}
											modalComments={ModalTestComments}
											cols={table_header}
											onClick={() =>
												this.getPatientHistory(row.id)
											}
											scd_band={row.scd_band}
											row={this.getTableRow(row)}
											test_type={
												!!row.test_type &&
													row.test_type
														.toLowerCase()
														.includes("hb")
													? "hb"
													: "malaria"
											}
										/>
									)
								)}
							</div>
						</div>
					</Fragment>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.UserReducer.userData,
	allChecked: state.ManagementPortalReducer.allChecked,
	itemsChecked: state.ManagementPortalReducer.itemsChecked,
	sort_type: state.ManagementPortalReducer.sort_type,
	Hb: state.PatientsReducer.hbTestsCounter,
	Malaria: state.PatientsReducer.malariaTestsCounter,
	searchValue: state.ManagementPortalReducer.SEARCH_PATIENT,
	patientsData: patientsData(state),
	patientsDB: state.PatientsReducer.patients,
	CARD_SELECTED: state.ManagementPortalReducer.CARD_SELECTED,
	cards: cards(state),
	qty_page: state.ManagementPortalReducer.QTY_PAGE,
	currentPage: state.ManagementPortalReducer.PAGE,
	filters: state.ManagementPortalReducer.userFilters,
	userSorts: state.ManagementPortalReducer.userSorts,
	sites: state.SiteManagementReducer.sites,
	closedAlert: state.ReaderReducer.closed_readers_alert,
	filterDateSelected: state.SubHeaderReducer.selected_date,
	readersOff: state.ReaderReducer.readers_offline,
	filterSiteSelected: state.SubHeaderReducer.selected_site,
	filterSiteOptions: state.SiteManagementReducer.sites.map((site) => {
		return { ...site, value: site.name };
	}),
});

const mapDispatchToProps = (dispatch) => ({
	services: bindActionCreators(services, dispatch),
	actions: bindActionCreators(actions, dispatch),
	PatientsActions: bindActionCreators(PatientsActions, dispatch),
	ReaderActions: bindActionCreators(ReaderActions, dispatch),
	SubHeaderActions: bindActionCreators(SubHeaderActions, dispatch),
});

ManagementPortalUser = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ManagementPortalUser)
);

export default withTranslation()(ManagementPortalUser);
