import _ from 'lodash';
import NoLogo from 'assets/img/placeholder_nosite.png';

const enumSiteType = {
    "MULTI": 2,
    "SINGLE": 1,
}

const enumDisease = {
    "malaria": 1,
    "hbvariant": 2
}

export const filterSites = (sites, filters, diseases, sort, sort_type) => {
    if (!sites || !sites.length) return []
    const values = _.chain(sites)
        .filter((sites) => filters.search.length > 0 ? sites.name.toLowerCase().includes(filters.search.toLowerCase()) : sites)
        .filter((site) => {
            if (filters.card == 1 && filters.filterCardActive == 'all') return site;
            if (filters.card == 1 && filters.filterCardActive == 0) return site.readers.active > 0;
            if (filters.card == 1 && filters.filterCardActive == 1) return site.readers.discarted > 0;
            if (filters.card == 2 && filters.filterCardActive == 'all') return site;
            if (filters.card == 2 && filters.filterCardActive == 0) return site.readers.updated > 0;
            if (filters.card == 2 && filters.filterCardActive == 1) return site.readers.not_updated > 0;
        })
        .filter((site) => {
            return !filters.site_type.length || filters.site_type == "ALL" || enumSiteType[filters.site_type] == site.site_type
        })
        .filter((site) => {
            let disease = [];
            if (filters.disease.malaria) {
                disease.push(1)
            }
            if (filters.disease.hb) {
                disease.push(2)
            }

            if (!site.diseases.length) return false

            if (!disease.length) return true;

            if (disease.length == 1 && site.diseases.length == 1) {
                const site_d = enumDisease[_.first(site.diseases).trim().toLowerCase()];
                const selected_d = _.first(disease)
                return site_d == selected_d;
            }
            return site.diseases.length == disease.length
        })
        .filter((site) => {
            return filters.city.length ? site.city == filters.city || filters.city == 'ALL' : true
        })
        .filter((site) => {
            return filters.country.length ? site.country == filters.country || filters.country == 'ALL' : true
        })
        .value()
        .map((sites) => formatSites(sites, diseases))

    if (sort == "site_type") {
        return _.orderBy(values, "type", sort_type)
    }
    return _.orderBy(values, sort, sort_type)
}

export const getTotalPages = (sites, qty_page) => {
    return (sites.length / qty_page) % 1 != 0 ? Math.ceil(sites.length / qty_page) : Math.ceil(sites.length / qty_page)
}

export const getItemsByPage = (sites, page, qty_page) => {
    const totalPages = getTotalPages(sites, qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? sites : init + qty_page
    return (totalPages) == 1 ? sites : sites.slice(init, end);
}

export const formatSites = (site, diseases) => {
    return {
        id: site.id,
        devices: site.readers.total,
        active: site.readers.active,
        updated: site.readers.updated,
        not_updated: site.readers.not_updated,
        discarted: site.readers.discarted,
        testtypes: getDisease(site.diseases),
        site: site.name,
        type: site.site_type,
        site_primary: site.site_primary,
        site_childrens_name: site.site_childrens_name,
        country: site.country,
    }
}

const getDisease = diseases => {
    let disease = ''
    diseases.map((i) => disease = disease + ` ${i}`)
    return disease;
}

export const getCounters = (sites) => {
    let counters = {
        active: 0,
        discarted: 0,
        inactive: 0,
        updated: 0,
        not_updated: 0,
        total: 0
    }
    sites.forEach(site => {
        Object.keys(site.readers).forEach(key => counters[key] += site.readers[key])
    });
    return counters;
}