import React, { Component, Fragment } from "react";
import CustomButton from "components/customButton";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomInputComponent from 'helper/formikComponents/customInput';
import CustomSelectComponent from 'helper/formikComponents/customSelect';
import CustomSelectMultiple from 'helper/formikComponents/customSelectMultiple';
import "./index.scss";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import SignupSchema from './validations'
import CustomLabel from 'components/customLabel'
import * as services from 'redux/actions'
import { bindActionCreators } from "redux";
import { connect } from 'react-redux'

class Step3 extends Component {

    state = {
        roles: [{
            id: 1,
            value: this.props.t('label_hemex_admin').toUpperCase(),
        },
        {
            id: 2,
            value: this.props.t('label_support_user').toUpperCase(),
        }
        ]
    }

    getInitialValues = () => {
        if (!this.props.data) {
            return {
                first_name: '',
                last_name: '',
                email_address: '',
                roles: this.state.roles
            }
        }
        return {
            first_name: this.props.data.first_name,
            last_name: this.props.data.last_name,
            email_address: this.props.data.email_address,
            roles: this.state.roles,
            role: this.props.data.isSupport ? this.state.roles.find((role) => role.id == 2) : this.state.roles.find(role => role.id == 1)
        }
    };

    render() {
        return (
            <Fragment>
                <Formik
                    initialValues={this.getInitialValues()}
                    validationSchema={SignupSchema}
                    onSubmit={(values, actions) => {
                        let obj = {}
                        if (this.props.data) {
                            obj.id = this.props.data.id
                        }
                        obj = {
                            ...obj,
                            firstname: values.first_name,
                            lastname: values.last_name,
                            email: values.email_address,
                            default_lang: 'en'
                        }
                        if (values.role.id == 2) {
                            obj.isSupport = 2;
                            obj.isAdmin = 2;
                        } else {
                            obj.isSupport = null
                            obj.isAdmin = 2;
                        }
                        this.props.save(obj)
                    }}
                    enableReinitialize={true}
                    render={({ errors, isSubmitting, setFieldValue, values, initialValues }) => {
                        const { t } = this.props;
                        return (
                            <Form>
                                <div className="divContainer">
                                    <div className={'displayFlex padding-invite-admin-container'}>
                                        <div className={'w50'}>
                                            <Field name="first_name" errorMessage={t('inputRequiredField')} component={CustomInputComponent} placeHolder={t('placeHolderInputFirstName')} label={t('labelInputFirstName')} />
                                        </div>
                                        <div className={'w50'}>
                                            <Field name="last_name" errorMessage={t('inputRequiredField')} component={CustomInputComponent} placeHolder={t('placeHolderInputLastName')} label={t('labelInputLastName')} />
                                        </div>
                                    </div>
                                    <div className={'displayFlex selectOption padding-invite-admin-container'}>
                                        <div className={'w50'}>
                                            <Field name="email_address" errorMessage={t('inputRequiredField')} component={CustomInputComponent} placeHolder={t('placeHolderEmailAddress')} label={t('labelInputEmailAddress')} />
                                        </div>
                                        <div className={'w50 heightDefault'}>
                                            <CustomLabel label={t('label_Roles')} />
                                            <Field
                                                value={values.role ? values.role.value : null}
                                                setFieldValue={setFieldValue}
                                                name="role"
                                                errorMessage={t('inputRequiredField')}
                                                component={CustomSelectComponent}
                                                placeHolder={t('label_Roles')}
                                                label={t('label_Roles')}
                                                options={values.roles}
                                            />
                                        </div>
                                    </div>
                                    <div className={'displayFlex jc-space selectOption padding-invite-admin-container'}>
                                        <div className={'w30 textLeft'}>
                                            <CustomButton type={'button '} className={'buttonCancel'} value={t('label_Cancel')} handleChange={this.props.handleChangePrev} />
                                        </div>
                                        {!_.isEqual(initialValues, values) &&
                                            <div className={'w30 textRight'}>
                                                <CustomButton type={'button '} className={'buttonNext'} value={t('label_Save')} />
                                            </div>
                                        }

                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    }
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch)
})

Step3 = connect(mapStateToProps, mapDispatchToProps)(Step3)

export default withTranslation()(Step3)