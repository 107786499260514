import { createSelector } from "reselect";
import _ from "lodash";
import * as services from "redux/services/SiteManagement";

import React, { Component, Fragment } from "react";

const initial = {
  CARD_SELECTED: 1,

  STATUS: {
    all: 0,
    pilot: 0,
    deactivated: 0,
  },

  filterByTestType: null,
  filterByStatus: "all",

  TEST_TYPES: {
    all: 0,
    both: 0,
    malaria: 0,
    hbVariant: 0,
  },
  EDS_SITES: {
    all: 0,
    invited: 0,
  },
  PARTICIPATION: {
    all: 0,
    both: 0,
    clinicalTesting: 0,
    researchProject: 0,
  },

  SEARCH_SITE: "",

  ALL_SITES_SELECTEDS: false,

  SITES_SELECTEDS: [],

  SITES_DB: [],

  PAGE: 0,

  QTY_PAGE: 10,
  filterByEDS: null,

  filterByParticipation: null,

  sort_type: "asc",

  sortCamp: "name",

  filters: {
    site_type: "",
    city: "",
    country: "",
    site_participation: "",
    disease: {
      malaria: null,
      hb: null,
    },
  },
};

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_ALL_SITES =
  "AdminSiteManagement_ToogleAllSites";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_UNIQ_SITE =
  "AdminSiteManagement_ToogleUniqSite";

export const SYSTEM_ADMIN_SITE_SET_DISEASES = "AdminSiteManagement_SetDiseases";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_DATE_FILTER =
  "AdminSiteManagement_SetDate";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_PAGE =
  "AdminSiteManagement_ChangePage";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_QTY_PAGE =
  "AdminSiteManagement_ChangeQtyPage";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_SEARCH_SITE =
  "AdminSiteManagement_SearchSite";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CARD_TEST =
  "AdminSiteManagement_ToogleCard";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_INITIAL_value =
  "AdminSiteManagement_SetInitial";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_SITE_FILTER =
  "AdminSiteManagement_SiteFilter";

export const SYSTEM_ADMIN_SITE_GET_SITES = "AdminSiteManagement_GetSites";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_TEST_TYPE =
  "AdminSiteManagement_FilterTestType";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_STATUS =
  "AdminSiteManagement_FilterStatus";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS =
  "SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION =
  "SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_TYPE =
  "SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_TYPE";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_CAMP =
  "SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_CAMP";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_FILTER_TABLE =
  "SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_FILTER_TABLE";

export const toggleFilters = (payload) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_FILTER_TABLE,
  payload,
});

export const toogleCheckBoxAllSites = (bool) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_ALL_SITES,
  bool,
});

export const toogleCheckBoxUniqSite = (arraySelecteds) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_UNIQ_SITE,
  arraySelecteds,
});

export const searchSite = (value) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_SEARCH_SITE,
  value,
});

export const changePage = (page) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_PAGE,
  page,
});

export const changeQtyPage = (qty) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_QTY_PAGE,
  qty,
});

export const toogleSiteFilter = (value) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_SITE_FILTER,
  value,
});

export const toogleDateFilter = (value) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_DATE_FILTER,
  value,
});

export const toogleCard = (id) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CARD_TEST,
  id,
});

export const filterByTestType = (id) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_TEST_TYPE,
  id,
});

export const filterByStatus = (id) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_STATUS,
  id,
});

export const filterByEds = (id) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS,
  id,
});

export const filterbyParticipation = (id) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION,
  id,
});

export const initialvalue = () => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_INITIAL_value,
});

export const setSortType = (payload) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_TYPE,
  payload,
});

export const setSortCamp = (payload) => ({
  type: SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_CAMP,
  payload,
});

export const create_site_admin = (payload) => async (dispatch, getState) => {
  return await services.adminCreateSite(payload);
};

const SiteManagementAdminReducer = (state = initial, action) => {
  switch (action.type) {
    case SYSTEM_ADMIN_SITE_MANAGEMENT_INITIAL_value: {
      return initial;
    }

    case SYSTEM_ADMIN_SITE_GET_SITES: {
      return {
        ...state,
        SITES_DB: action.sites,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_ALL_SITES: {
      return { ...state, ALL_SITES_SELECTEDS: action.bool };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_UNIQ_SITE: {
      return {
        ...state,
        SITES_SELECTEDS: action.arraySelecteds,
      };
    }
    case SYSTEM_ADMIN_SITE_MANAGEMENT_SEARCH_SITE: {
      return {
        ...state,
        SEARCH_SITE: action.value,
      };
    }
    case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CARD_TEST: {
      return {
        ...state,
        CARD_SELECTED: action.id,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_TEST_TYPE: {
      return {
        ...state,
        CARD_SELECTED: 2,
        filterByTestType: action.id,
        filterByEDS: null,
        filterByStatus: null,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_STATUS: {
      return {
        ...state,
        CARD_SELECTED: 1,
        filterByTestType: null,
        filterByEDS: null,
        filterByStatus: action.id,
        filterByParticipation: null,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS: {
      return {
        ...state,
        CARD_SELECTED: 3,
        filterByEDS: action.id,
        filterByTestType: null,
        filterByStatus: null,
        filterByParticipation: null,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_PAGE: {
      return {
        ...state,
        PAGE: action.page,
      };
    }
    case SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_QTY_PAGE: {
      return {
        ...state,
        QTY_PAGE: action.qty,
        PAGE: 0,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION: {
      return {
        ...state,
        CARD_SELECTED: 4,
        filterByEDS: null,
        filterByTestType: null,
        filterByStatus: null,
        filterByParticipation: action.id,
      };
    }

    case SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_TYPE:
      return {
        ...state,
        sort_type: action.payload,
      };

    case SYSTEM_ADMIN_SITE_MANAGEMENT_SET_SORT_CAMP:
      return {
        ...state,
        sortCamp: action.payload,
      };

    case SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_FILTER_TABLE:
      return {
        ...state,
        filters: action.payload,
      };
  }
  return state;
};

export const sort_type = (state) => state.SiteManagementAdminReducer.sort_type;

export const sortCamp = (state) => state.SiteManagementAdminReducer.sortCamp;

export const qty_page = (state) => state.SiteManagementAdminReducer.QTY_PAGE;

export const page = (state) => state.SiteManagementAdminReducer.PAGE;

export const searchFilter = (state) =>
  state.SiteManagementAdminReducer.SEARCH_SITE;

export const allSitesDB = (state) => state.SiteManagementReducer.sites;

export const sitesDeactivated = (state) =>
  state.SiteManagementReducer.sites_deactivated || [];

export const allSitesSelecteds = (state) =>
  state.SiteManagementAdminReducer.ALL_SITES_SELECTEDS;

export const sitesSelecteds = (state) =>
  state.SiteManagementAdminReducer.SITES_SELECTEDS;

export const filterDiseaseSelected = (state) =>
  state.SiteManagementAdminReducer.filterByTestType;

export const filterStatusSelected = (state) =>
  state.SiteManagementAdminReducer.filterByStatus;

export const filterByParticipation = (state) =>
  state.SiteManagementAdminReducer.filterByParticipation;

export const edsSelected = (state) =>
  state.SiteManagementAdminReducer.filterByEDS;

export const cardSelected = (state) =>
  state.SiteManagementAdminReducer.CARD_SELECTED;

export const filters = (state) => state.SiteManagementAdminReducer.filters;

export const updateSite = (site) => async (dispatch) => {
  try {
    return await services.updateSite(site);
  } catch (e) {
    throw Error(e);
  }
};

export const createSite = (site) => async (dispatch) => {
  try {
    await services.createSite(site);
  } catch (e) {
    throw Error(e);
  }
};

export const getFilters = createSelector(allSitesDB, filters, (sites, fts) => {
  let items = { ...fts };

  items.site_types = [{ id: "ALL", value: "ALL" }];
  items.cities = [{ id: "ALL", value: "ALL" }];
  items.countries = [{ id: "ALL", value: "ALL" }];
  items.participations = [];
  items.diseases = [];

  sites.forEach((site) => {
    if (
      site.clinical_testing &&
      !items.participations.find((pt) => pt.value == "Clinical Testing")
    ) {
      items.participations.push({
        id: "Clinical Testing",
        value: "Clinical Testing",
      });
    }

    if (site.disease_types && site.disease_types.length) {
      site.disease_types.forEach((ds) => {
        if (!items.diseases.find((i) => i.value == "ds")) {
          items.diseases.push({ id: ds, value: ds });
        }
      });
    }

    if (site.country && !items.countries.find((s) => s.value == site.country)) {
      items.countries.push({ id: site.country, value: site.country });
    }
    if (site.city && !items.cities.find((s) => s.value == site.city)) {
      items.cities.push({ id: site.city, value: site.city });
    }

    let type = null;

    if (site.site_type) {
      type = site.site_type == 1 ? "SINGLE" : "MULTI";
    }

    if (type && !items.site_types.find((s) => s.value == type)) {
      items.site_types.push({ id: type, value: type });
    }
  });
  return items;
});

export const getDiseasesCounter = createSelector(allSitesDB, (sites) => {
  let counters = {
    both: 0,
    malaria: 0,
    hb: 0,
  };

  sites.forEach((site) => {
    if (!!site.disease_types && site.disease_types.length == 2) {
      counters.malaria++;
      counters.hb++;
    } else if (!!site.disease_types && site.disease_types.includes(1)) {
      counters.malaria++;
    } else if (!!site.disease_types && site.disease_types.includes(2)) {
      counters.hb++;
    }
  });
  return counters;
});

export const getEdsCounters = createSelector(allSitesDB, (sites) => {
  let counters = {
    all: 0,
    invited: 0,
  };

  sites.forEach((site) => {
    if (site.eds_status) {
      counters.all++;

      if (site.eds_status == "invited" || site.eds_status == "pending")
        counters.invited++;
    }
  });
  return counters;
});

export const getParticipationCounters = createSelector(allSitesDB, (sites) => {
  let counters = {
    clinical: 0,
    research: 0,
    pilot: 0,
  };
  sites.forEach((site) => {
    if (site.clinical_testing) {
      counters.clinical++;
    } else if (site.project_type == 1 || site.project_type == 3) {
      counters.research++;
    } else if (site.project_type == 2 || site.project_type == 3) {
      counters.pilot++;
    }
  });
  return counters;
});

export const allSitesFiltered = createSelector(
  allSitesDB,
  searchFilter,
  filterDiseaseSelected,
  qty_page,
  page,
  filterStatusSelected,
  sitesDeactivated,
  edsSelected,
  cardSelected,
  filterByParticipation,
  sort_type,
  sortCamp,
  filters,
  (
    allSites,
    searchFilter,
    diseaseSelected,
    qty_page,
    page,
    status,
    sitesDeactivated,
    edsSelected,
    cardSelected,
    filterByParticipation,
    st,
    sc,
    fts
  ) => {
    let sites = allSites;

    if (cardSelected == 1 || cardSelected == 2) {
      if (status == "all" || cardSelected == 2) {
        sites = [...sites, ...sitesDeactivated];
      }
      if (status == 1) {
        sites = [...sitesDeactivated];
      }

      if (status == 0) {
        sites = [];
      }
    }

    if (cardSelected == 2) {
      let diseases = [];

      sites.forEach((site) => {
        site.disease_types.forEach((disease) =>
          !diseases.includes(disease) ? diseases.push(disease) : ""
        );
      });

      sites = _.chain(sites)
        .filter((site) => {
          if (diseaseSelected == null) return site;

          return site.disease_types.includes(diseaseSelected);
        })
        .value();
    }

    if (cardSelected == 3) {
      sites = _.chain(sites)
        .filter((site) => {
          if (edsSelected == "all") return !!site.eds_status;
          return site.eds_status == "invited" || site.eds_status == "pending";
        })
        .value();
    }

    if (cardSelected == 4) {
      sites = _.chain(sites)
        .filter((site) => {
          if (filterByParticipation == 0) return !!site.clinical_testing;
          if (filterByParticipation == 1)
            return site.project_type == 1 || site.project_type == 3;
          return site.project_type == 2 || site.project_type == 3;
        })
        .value();
    }

    sites = filterSites(sites, fts);

    const results = sites
      .filter((site) =>
        searchFilter.length > 0
          ? site.name.toLowerCase().includes(searchFilter.toLowerCase())
          : site
      )
      .sort((a, b) => b.id - a.id);
    const totalPages =
      (results.length / qty_page) % 1 != 0
        ? Math.ceil(results.length / qty_page)
        : Math.ceil(results.length / qty_page);
    const init = page * qty_page < 0 ? 0 : page * qty_page;
    const end = totalPages == 0 ? results : init + qty_page;

    let arrayFormatado = totalPages == 1 ? results : results.slice(init, end);

    arrayFormatado = arrayFormatado.map((row) => {
      let diseases = "";

      return {
        id: row.id,
        name: row.name,
        status: row.status,
        site_primary: row.site_primary,
        city: row.city,
        country: row.country,
        contact: row.name,
        phone_number: row.phone_number,
        address: row.address,
        disease_types: (() => {
          if (row.disease_types.length > 0) {
            if (row.disease_types.includes(1)) {
              diseases += "Malaria";
            }
            if (row.disease_types.includes(2)) {
              diseases += diseases.length > 0 ? ", HB Variant" : "Hb Variant";
            }
          }
          return diseases;
        })(),
        clinical_testing: row.clinical_testing ? "TRUE" : "-",
        research_project:
          row.project_type == 1 || row.project_type == 3 ? "TRUE" : "-",
        pilot: row.project_type == 2 || row.project_type == 3 ? "TRUE" : "-",
        eds: row.eds_status || "-",
        childrens_name: row.childrens_name,
        parent: row.parent,
      };
    });

    if (sc == "site_type") {
      arrayFormatado = _.orderBy(arrayFormatado, "type", st);
    } else {
      arrayFormatado = _.orderBy(arrayFormatado, sc, st);
    }

    return {
      deactivated: sitesDeactivated.length,
      pilot: [...sites, ...sitesDeactivated].filter(
        (site) => site.project_type == "pilot"
      ).length,
      allSites: [...allSites, ...sitesDeactivated],
      allSitesFiltered: arrayFormatado,
      allSitesCounter: allSites.length + sitesDeactivated.length,
      counter: results.length,
      totalPages: parseInt(totalPages),
    };
  }
);

export const getHeader = (allChecked, translate, sort_camp) => {
  const header = {
    checkbox: {
      type: "checkbox",
      checked: allChecked,
      th: "checkbox",
    },
    [translate("tableHeaderName")]: {
      type: "sortCamp",
      sort: sort_camp == "name",
      th: "name",
    },
    [translate("Status")]: {
      type: "sortCamp",
      sort: sort_camp == "status",
      th: "status",
    },
    ["PRIMARY SITE"]: {
      type: "sortCamp",
      sort: sort_camp == "site_primary",
      th: "site_primary",
    },
    [translate("tableHeaderCity")]: {
      type: "sortCamp",
      sort: sort_camp == "city",
      th: "name",
    },
    [translate("tableHeaderCountry")]: {
      type: "sortCamp",
      sort: sort_camp == "country",
      th: "country",
    },
    [translate("contact")]: {
      type: "sortCamp",
      sort: sort_camp == "contact",
      th: "contact",
    },
    [translate("tableHeaderPhoneNumber")]: {
      type: "sortCamp",
      sort: sort_camp == "phone_number",
      th: "phone_number",
    },
    [translate("tableHeaderAddress")]: {
      type: "sortCamp",
      sort: sort_camp == "address",
      th: "address",
    },
    [translate("tableHeaderTestType")]: {
      type: "sortCamp",
      sort: sort_camp == "disease_types",
      th: "disease_types",
    },
    [translate("tableHeaderClinicalTesting")]: {
      type: "sortCamp",
      sort: sort_camp == "clinical_testing",
      th: "clinical_testing",
    },
    [translate("tableHeaderResearchProject")]: {
      type: "sortCamp",
      sort: sort_camp == "research_project",
      th: "research_project",
    },
    [translate("label_Pilot")]: {
      type: "sortCamp",
      sort: sort_camp == "pilot",
      th: "pilot",
    },
    [translate("tableHeaderEdsSite")]: {
      type: "sortCamp",
      sort: sort_camp == "eds",
      th: "eds",
    },
  };
  return header;
};

function filterSites(sites, filters) {
  const enumSiteType = {
    MULTI: 2,
    SINGLE: 1,
  };
  return _.chain(sites)
    .filter((site) => {
      return (
        !filters.site_type.length ||
        filters.site_type == "ALL" ||
        enumSiteType[filters.site_type] == site.site_type
      );
    })
    .filter((site) => {
      let disease = [];
      if (filters.disease.malaria) {
        disease.push(1);
      }
      if (filters.disease.hb) {
        disease.push(2);
      }

      if (!site.disease_types.length) return false;

      if (!disease.length) return true;

      if (disease.length == 1 && site.disease_types.length == 1) {
        const site_d = _.first(site.disease_types);
        const selected_d = _.first(disease);
        return site_d == selected_d;
      }
      return site.disease_types.length == disease.length;
    })
    .filter((site) => {
      return filters.city.length
        ? site.city == filters.city || filters.city == "ALL"
        : true;
    })
    .filter((site) => {
      return filters.country.length
        ? site.country == filters.country || filters.country == "ALL"
        : true;
    })
    .value();
}

export default SiteManagementAdminReducer;
