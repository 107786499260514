import React from "react";
import ReactDOM from "react-dom";
import { unregister } from './serviceWorker';
import Router from "router/index";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store";
import 'assets/css/global.scss';
import { I18nextProvider } from "react-i18next";
import i18n from "i18n/setup.js/";
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter, Switch, Route } from "react-router-dom";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
      </I18nextProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

unregister()
