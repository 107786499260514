import { createSelector } from "reselect";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { TestsResultEnum, FILTRO_DATE_ENUM, test_type_enum } from "helper/enum";
import { getDateByLanguage } from "helper/dateByLanguage";

const initial = {
	CARD_SELECTED: 1,

	SELECTED_SITE: {
		id: 0,
		value: "ALL SITES",
	},

	...FILTRO_DATE_ENUM,

	SEARCH_PATIENT: "",

	hbTestsCounter: {
		counter: 0,
		positive: 0,
	},
	malariaTestsCounter: {
		counter: 0,
		positive: 0,
	},
	patients: [],

	PAGE: 0,

	QTY_PAGE: 10,

	ALL_PATIENTS_SELECTEDS: false,

	allChecked: false,

	itemsChecked: [],

	DASHBOARD_ADMIN: [
		{
			status: "green",
			site: "Doctor menu",
			city: "Kano City",
			state: "Kano",
			country: "Nigeria",
			readers: "5",
			siteContact: "John Doe",
			email: "jhon.doe@gmail.com",
			phoneNumber: "+232 (322) 345-3333",
		},
	],
	sorts: {
		status: "sort",
		name: null,
		city: null,
		country: null,
		readers: null,
		site_contact: null,
		emailaddress: null,
		phone_number: null,
	},

	// id: patient.id,
	// patient_id: patient.patient_data[0].json_id,
	// date: getDateTimeByLanguage(test.applied_at, window.currentLanguage),
	// patient_name: patient.name,
	// site_name: patient.site_name ? patient.site_name : "-",
	// site_type: patient.site_type ? patient.site_type : "-",
	// test_id: test.id,
	// test_type: test.test_type,
	// test_result: testResultString,
	// location: test.location,
	// testReport: '-',
	// test_comments: patient.test_comments ? patient.test_comments : '-',
	sort_type: "asc",
	userSorts: "date",
	userFilters: {
		diseases: {
			malaria: true,
			hb: true,
			both: false,
		},
		site: null,
		test_result: [],
		test_control: {
			positive: false,
			negative: false,
		},
		testLocation: "ALL",
		reader_location: {
			on_site: false,
			out_site: false,
		},
	},
	test_result: {
		selected: [],
	},
};

const types = {
	MANAGEMENT_TOOGLE_CHECK_ALL_PATIENTS:
		"MANAGEMENT_TOOGLE_CHECK_ALL_PATIENTS",
	HB_TESTS_COUNTER: "HB_TESTS_COUNTER",
	MALARIA_TESTS_COUNTER: "MALARIA_TESTS_COUNTER",
	PATIENTS: "PATIENTS",
	MANAGEMENT_TOOGLE_CHECK_UNIQ_PATIENT:
		"MANAGEMENT_TOOGLE_CHECK_UNIQ_PATIENT",
	MANAGEMENT_CHANGE_QTY_PAGE: "MANAGEMENT_CHANGE_QTY_PAGE",
	MANAGEMENT_CHANGE_PAGE: "MANAGEMENT_CHANGE_PAGE",
	MANAGEMENT_TOOGLE_DATE_FILTER: "MANAGEMENT_TOOGLE_DATE_FILTER",
	MANAGEMENT_SEARCH_PATIENT: "MANAGEMENT_SEARCH_PATIENT",
	MANAGEMENT_TOOGLE_CARD_TEST: "MANAGEMENT_TOOGLE_CARD_TEST",
	MANAGEMENT_TOOGLE_SITE_FILTER: "MANAGEMENT_TOOGLE_SITE_FILTER",
	SET_MANAGEMENT_SORT_USER_VIEW: "SET_SORT_USER_VIEW",
	TOGGLE_FILTERS_USER: "TOGGLE_FILTERS_USER",
	SET_SORT_TYPE: "SET_SORT_TYPE",
	MANAGEMENT_INITIAL_VALUES: "MANAGEMENT_INITIAL_VALUES",
};

export const toggleFiltersUser = (payload) => ({
	type: types.TOGGLE_FILTERS_USER,
	payload,
});

export const toggleAll = (bool) => ({
	type: types.MANAGEMENT_TOOGLE_CHECK_ALL_PATIENTS,
	bool,
});

export const changePage = (page) => ({
	type: types.MANAGEMENT_CHANGE_PAGE,
	page,
});

export const changeQtyPage = (qty) => ({
	type: types.MANAGEMENT_CHANGE_QTY_PAGE,
	qty,
});

export const toggleItem = (arraySelecteds) => ({
	type: types.MANAGEMENT_TOOGLE_CHECK_UNIQ_PATIENT,
	arraySelecteds,
});

export const searchPatient = (value) => ({
	type: types.MANAGEMENT_SEARCH_PATIENT,
	value,
});

export const toogleSitefilter = (value) => ({
	type: types.MANAGEMENT_TOOGLE_SITE_FILTER,
	value,
});

export const toogleDateFilter = (value) => ({
	type: types.MANAGEMENT_TOOGLE_DATE_FILTER,
	value,
});

export const toogleCardTest = (id) => ({
	type: types.MANAGEMENT_TOOGLE_CARD_TEST,
	id,
});

export const sortUserView = (payload) => ({
	type: types.SET_MANAGEMENT_SORT_USER_VIEW,
	payload,
});

export const setSortType = (payload) => ({
	type: types.SET_SORT_TYPE,
	payload,
});

export const initialValues = () => ({ type: types.MANAGEMENT_INITIAL_VALUES });

const ManagementPortalReducer = (state = initial, action) => {
	switch (action.type) {
		case types.MANAGEMENT_INITIAL_VALUES: {
			return initial;
		}

		case types.MALARIA_TESTS_COUNTER:
			return {
				...state,
				malariaTestsCounter: action.counter,
			};

		case types.HB_TESTS_COUNTER:
			return {
				...state,
				hbTestsCounter: action.counter,
			};

		case types.PATIENTS:
			return {
				...state,
				patients: action.patients,
			};

		case types.MANAGEMENT_TOOGLE_CHECK_ALL_PATIENTS: {
			return { ...state, allChecked: !state.allChecked };
		}

		case types.MANAGEMENT_TOOGLE_CHECK_UNIQ_PATIENT: {
			return {
				...state,
				itemsChecked: action.arraySelecteds,
			};
		}
		case types.MANAGEMENT_SEARCH_PATIENT: {
			return {
				...state,
				SEARCH_PATIENT: action.value.toLowerCase(),
			};
		}
		case types.MANAGEMENT_TOOGLE_CARD_TEST: {
			return {
				...state,
				CARD_SELECTED: action.id,
			};
		}

		case types.MANAGEMENT_TOOGLE_SITE_FILTER: {
			return { ...state, SELECTED_SITE: action.value };
		}

		case types.MANAGEMENT_TOOGLE_DATE_FILTER: {
			return {
				...state,
				SELECTED_DATE: action.value,
			};
		}

		case types.MANAGEMENT_CHANGE_PAGE: {
			return {
				...state,
				PAGE: action.page,
			};
		}

		case types.MANAGEMENT_CHANGE_QTY_PAGE: {
			return {
				...state,
				QTY_PAGE: action.qty,
				PAGE: 0,
			};
		}

		case types.SET_MANAGEMENT_SORT_USER_VIEW: {
			return {
				...state,
				userSorts: action.payload,
			};
		}
		case types.TOGGLE_FILTERS_USER: {
			return {
				...state,
				userFilters: action.payload,
				PAGE: 0,
			};
		}

		case types.SET_SORT_TYPE: {
			return {
				...state,
				sort_type: action.payload,
			};
		}
	}
	return state;
};

export const sort_type = (state) => state.ManagementPortalReducer.sort_type;

export const qty_page = (state) => state.ManagementPortalReducer.QTY_PAGE;

export const page = (state) => state.ManagementPortalReducer.PAGE;

export const sortManagementUser = (state) =>
	state.ManagementPortalReducer.userSorts;

export const searchFilter = (state) =>
	state.ManagementPortalReducer.SEARCH_PATIENT;

export const allPatientsDB = (state) => state.PatientsReducer.patients;

export const allDataDashBoardAdmindDB = (state) =>
	state.ManagementPortalReducer.DASHBOARD_ADMIN;

export const cardSelected = (state) =>
	state.ManagementPortalReducer.CARD_SELECTED;

export const siteSelected = (state) =>
	state.ManagementPortalReducer.SELECTED_SITE;

export const allSites = (state) => state.UserReducer.userData.sites;

export const dateSelected = (state) =>
	state.ManagementPortalReducer.SELECTED_DATE;

export const userFilters = (state) => state.ManagementPortalReducer.userFilters;

export const patientsData = createSelector(
	allPatientsDB,
	searchFilter,
	cardSelected,
	qty_page,
	page,
	dateSelected,
	sortManagementUser,
	userFilters,
	sort_type,
	(
		patients,
		searchFilter,
		cardSelected,
		qty_page,
		page,
		date,
		userSorts,
		filters,
		sort_direction
	) => {
		let tests = [];

		if (searchFilter.length > 0) {
			patients = patients.filter((patient) => {
				if (!patient.patient_data || patient.patient_data.length == 0)
					return false;
				const patientJsonId = patient.patient_data[0].json_id;
				return (
					patient.name.toLowerCase().includes(searchFilter) ||
					patientJsonId.toLowerCase().includes(searchFilter)
				);
			});
		}

		patients.map((patient) => {
			patient.tests.map((test, key) => {
				if (
					(filters.reader_location.on_site &&
						!test.isMobile &&
						!filters.reader_location.out_site) ||
					(filters.reader_location.out_site &&
						!!test.isMobile &&
						!filters.reader_location.on_site)
				)
					return false;

				if (
					filters.test_control &&
					!filters.test_control.positive &&
					!filters.test_control.negative &&
					test.is_control
				)
					return false;

				if (
					filters.test_control &&
					filters.test_control.negative &&
					filters.test_control.positive &&
					patient.patient_data[0].json_id != "NEGATIVE CONTROL" &&
					patient.patient_data[0].json_id != "POSITIVE CONTROL"
				) {
					return false;
				} else {
					if (
						filters.test_control &&
						filters.test_control.positive &&
						!filters.test_control.negative &&
						!patient.patient_data[0].json_id.includes("POSITIVE")
					)
						return false;
					if (
						filters.test_control &&
						filters.test_control.negative &&
						!filters.test_control.positive &&
						!patient.patient_data[0].json_id.includes("NEGATIVE")
					)
						return false;
				}

				if (
					filters.testLocation != "ALL" &&
					test.location != filters.testLocation
				) {
					return false;
				}

				const disease = test_type_enum[test.disease_id];

				if (
					!!filters.diseases.both ||
					(!!filters.diseases[disease] &&
						!filters.test_result.length) ||
					!!filters.test_result.includes(test.result)
				) {
					let testResultString = "";

					if (test.result.length > 0) {
						testResultString = Object.keys(
							TestsResultEnum
						).filter((items) => items.includes(test.result));

						if (testResultString.length == 0) {
							testResultString = test.result;
						} else {
							testResultString =
								TestsResultEnum[testResultString[0]][
									window.currentLanguage
								];
						}
					}

					let scd = !test.scd_band ? [] : JSON.parse(test.scd_band);

					if (test.iterpretation && test.iterpretation.length) {
						const noteIndex = test.iterpretation.indexOf("Note:");
						const note = test.iterpretation.substring(noteIndex);
						const interpretation = test.iterpretation.substring(
							0,
							noteIndex
						);
						scd.push({
							HbType: "Interpretation",
							Percentage: [
								<div>{interpretation}</div>,
								<div>{note}</div>,
							],
						});
					}

					tests.push({
						id: patient.id,
						patient_id: patient.patient_data[0].json_id,
						patient_name: patient.name,
						site_childrens_name: patient.site_childrens_name,
						date: getDateByLanguage(
							moment(test.applied_at, "YYYY-MM-DD HH:mm:ss"),
							window.currentLanguage
						),
						site_name: patient.site_name ? patient.site_name : "-",
						site_primary: patient.site_primary ? true : false,
						test_id: test.id,
						test_type: test.test_type,
						test_result: testResultString,
						location: test.location,
						testReport: "-",
						test_comments: test.test_comments
							? test.test_comments
							: "-",
						scd_band: scd,
					});
				}
			});
		});

		const format =
			window.currentLanguage == "ptBR" ? "DD/MM/YYYY" : "MM/DD/YYYY";
		if (userSorts == "date") {
			tests = _.orderBy(
				tests,
				(item) => moment(item.date, format),
				sort_direction == "asc" ? "desc" : "asc"
			);
		} else if (userSorts) {
			tests = _.orderBy(tests, userSorts, sort_direction);
		}

		const totalPages =
			(tests.length / qty_page) % 1 != 0
				? Math.ceil(tests.length / qty_page)
				: Math.ceil(tests.length / qty_page);
		const init = page * qty_page < 0 ? 0 : page * qty_page;
		const end = totalPages == 0 ? tests : init + qty_page;

		let arrayFormatado = totalPages == 1 ? tests : tests.slice(init, end);

		return {
			allPatientsFiltered: arrayFormatado,
			counter: tests.length,
			totalPages: parseInt(totalPages),
		};
	}
);

export const cards = createSelector(
	siteSelected,
	allSites,
	(siteSelected, allSites) => {
		if (!allSites || allSites.length == 0) return [];

		let cards = [];

		if (allSites.length == 1) {
			allSites[0].disease_types.map((disease) => {
				cards.push(disease);
			});
			return cards;
		}

		if (Object.keys(siteSelected).length == 2) {
			allSites.forEach((site) => {
				site.disease_types.map((disease) => {
					cards.push(disease);
				});
			});

			return cards;
		}

		siteSelected.disease_types.map((disease) => {
			cards.push(disease);
		});

		return cards;
	}
);

export const allReadersFiltered = createSelector(
	allDataDashBoardAdmindDB,
	searchFilter,
	(readers, searchFilter) =>
		_.chain(readers)
			.filter((reader) =>
				searchFilter.length > 0
					? reader.site.includes(searchFilter)
					: reader
			)
			.value()
);

export default ManagementPortalReducer;
