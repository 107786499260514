import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.scss';
import UnhidePassword from 'assets/img/sign-in/unhide_password.png';
import HidePassword from 'assets/img/sign-in/hide_password_icon.png';
import EmailIcon from 'assets/img/sign-in/email_icon.png';
import PasswordIcon from 'assets/img/sign-in/password_icon.png';
import { withTranslation } from 'react-i18next';
import CustomCheckBox from 'components/customCheckbox';
import InputDefault from 'components/inputDefault';
import _ from 'lodash';
import * as userActions from 'redux/reducers/UserReducer';
import * as services from 'redux/actions';
import { withRouter } from 'react-router-dom';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
class BoxLogin extends Component {
    static propTypes = {
        prop: PropTypes
    };
    
    state = {
        email: '',
        emailErrorMessage: '',
        password: '',
        passwordErrorMessage: '',
        showPassword: false,
        triedSubmit: false,
        keepSign: false
    };

    keyPress = (e) => {
        if (e.keyCode == 13) return this.sign();
    };
    componentDidMount() {
        document.addEventListener('keypress', this.keyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.keyPress);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.triedSubmit) return false;

        if (
            this.state.email !== prevState.email &&
            this.state.emailErrorMessage.length > 0
        ) {
            this.setState({
                emailErrorMessage: ''
            });
        }
        if (
            this.state.password !== prevState.password &&
            this.state.passwordErrorMessage.length > 0
        ) {
            this.setState({
                passwordErrorMessage: ''
            });
        }
    }

    sign = async () => {
        try {
            await this.props.userActions.SignIn({
                email: this.state.email,
                password: this.state.password,
                keepSign: this.state.keepSign
            });
        } catch (e) {
            this.setState({
                passwordErrorMessage: this.props.t(e),
                triedSubmit: true
            });
        }
    };

    render() {
        const { t } = this.props;
        return (
            <>
                <div className={`div-sign-in ${this.props.page}`}>
                    <div className="div-white-sign">
                        <div className="div-label-sign">
                            {t('label_SignIn')}
                        </div>
                        <div class="div-input">
                            <InputDefault
                                leftIcon={EmailIcon}
                                value={this.state.email}
                                type={'email'}
                                placeHolder={t('placeHolderEmail')}
                                onChange={(value) =>
                                    this.setState({ email: value })
                                }
                                error={
                                    this.state.emailErrorMessage.length > 0
                                        ? this.state.emailErrorMessage
                                        : null
                                }
                            />
                            <div className="div-password-box">
                                <InputDefault
                                    rightIcon={
                                        !this.state.showPassword
                                            ? UnhidePassword
                                            : HidePassword
                                    }
                                    onClickRightIcon={() =>
                                        this.setState({
                                            showPassword: !this.state
                                                .showPassword
                                        })
                                    }
                                    leftIcon={PasswordIcon}
                                    type={
                                        this.state.showPassword
                                            ? 'text'
                                            : 'password'
                                    }
                                    value={this.state.password}
                                    placeHolder={t('placeHolderPassword')}
                                    onChange={(value) =>
                                        this.setState({ password: value })
                                    }
                                    error={
                                        this.state.passwordErrorMessage.length >
                                            0
                                            ? this.state.passwordErrorMessage
                                            : null
                                    }
                                />
                            </div>
                            <Link
                                to={`${process.env.REACT_APP_FRONT_URL}/forgot-password`}
                            >
                                <div className="label-forgot">
                                    {t('labelForgotPassword')}
                                </div>
                            </Link>
                            <div>
                                <button
                                    onClick={() => this.sign()}
                                    className="button-sign cp"
                                >
                                    {t('label_SignIn')}
                                </button>
                            </div>
                            <div className="div-keep displayFlex ali-center cgray">
                                <CustomCheckBox
                                    action={() =>
                                        this.setState({
                                            keepSign: !this.state.keepSign
                                        })
                                    }
                                    active={this.state.keepSign}
                                />{' '}
                                {t('checkBoxKeepSigned')}
                            </div>
                            <div className="div-contact">
                                <a
                                    className="link_support cgray"
                                    href="mailto: support@hemexhealth.com"
                                    target="_blank"
                                >
                                    {t('label_ContactSupport')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        services: bindActionCreators(services, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    };
};

BoxLogin = connect(null, mapDispatchToProps)(BoxLogin);
BoxLogin = withRouter(BoxLogin);

export default withTranslation()(BoxLogin);
