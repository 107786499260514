import * as Patients from "redux/services/Patients";
import { createSelector } from "reselect";
import _ from "lodash";


const initial = {
    filters: {
        status: [],
        site_type: [],
        site: "",
        country: []
    },
    sort_type: "asc",
    sort_camp: "status",
}

const types = {
    SET_FILTER: "SET_FILTER",
    SET_SORTS: "SET_SORTS",
    SET_SORT_TYPE: "SET_SORT_TYPE"
}

export const setFilter = payload => ({ type: types.SET_FILTER, payload })
export const setSort = payload => ({ type: types.SET_SORT, payload })
export const setSortType = payload => ({ type: types.SET_SORT_TYPE, payload })

const ManagementPortalAdminReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.SET_FILTER: {
            return {
                ...state,
                filters: action.payload
            }
        }
        case types.SET_SORT: {
            return {
                ...state,
                sort_camp: action.payload
            }
        }
        case types.SET_SORT_TYPE: {
            return {
                ...state,
                sort_type: action.payload
            }
        }
    }

    return state
}

export const getHeader = (translate, sort_camp) => {
    const header = {
        "status": {
            type: "status",
            sort: sort_camp == "status"
        },
        [translate('tableHeaderSite')]: {
            type: "sortCamp",
            sort: sort_camp == "name"
        },
        [translate('tableHeaderSiteType')]: {
            type: "sortCamp",
            sort: sort_camp == "site_type"
        },
        [translate('label_RegisteredReaders')]: {
            type: "sortCamp",
            sort: sort_camp == "readers"
        },
        [translate('tableHeaderReadersWithError')]: {
            type: "sortCamp",
            sort: sort_camp == "count_error_log"
        },
        [translate('tableHeaderReadersWithFirmwareMissmach')]: {
            type: "sortCamp",
            sort: sort_camp == "count_firmware_mismatch"
        },
        [translate('tableHeaderSiteContact')]: {
            type: "sortCamp",
            sort: sort_camp == "site_contact"
        },
        [translate('tableHeaderEmail')]: {
            type: "sortCamp",
            sort: sort_camp == "email"
        },
        [translate('tableHeaderPhoneNumber')]: {
            type: "sortCamp",
            sort: sort_camp == "phone_number"
        },
        [translate('tableHeaderCity')]: {
            type: "sortCamp",
            sort: sort_camp == "city"
        },
        [translate('tableHeaderCountry')]: {
            type: "sortCamp",
            sort: sort_camp == "country"
        },
    }
    return header;
}
export default ManagementPortalAdminReducer;