import React from "react";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CustomCheckBox from "components/customCheckbox"
import "./style.scss"
import { withTranslation } from 'react-i18next';

class Filters extends React.Component {


    toggleFilter = (filterName, value) => {
        let filters = { ...this.props.filters };
        filters[filterName] = value;
        this.props.toggleFilter(filters)
    }

    render() {

        const statusEnum = {
            0: this.props.t("label_StatusSiteReaderRed"),
            1: this.props.t("label_StatusSiteReaderYellow"),
            2: this.props.t("label_StatusSiteReaderGreen")
        }

        console.log("filters", this.props.filters)
        return (
            <div className="divFilter">
                <div className="titleFilter reset" onClick={() =>  this.props.toggleFilter({...this.props.default})}>Reset</div>
                <span className="mb-15"></span>
                <span className="titleFilter cp">STATUS</span>
                <span className="mb-15">
                    {this.props.filters.statusAvailable.length > 0 &&
                        this.props.filters.statusAvailable.map((item) => {
                            return (
                                <div className="divWithCheckbox">
                                    <CustomCheckBox
                                        action={() => {
                                            return this.toggleFilter("status", this.props.filters.status.includes(item) ? this.props.filters.status.filter((i) => i != item) : [...this.props.filters.status, item])
                                        }}
                                        active={this.props.filters.status.includes(item)}
                                    /> {statusEnum[item]}
                                </div>
                            )
                        })}
                </span>
                <div className="mb-15" />
                <span className="titleFilter">SITE TYPE</span>
                <span className="mb-15">
                    {this.props.filters.sites_types.length > 0 && this.props.filters.sites_types.map((item) => {
                        return (
                            <div className="divWithCheckbox">
                                <CustomCheckBox
                                    action={() => {
                                        return this.toggleFilter("site_type", this.props.filters.site_type.includes(item) ? this.props.filters.site_type.filter((i) => i != item) : [...this.props.filters.site_type, item])
                                    }}
                                    active={this.props.filters.site_type.includes(item)}
                                /> {item == 1 ? this.props.t("label_SingleSite") : this.props.t("label_MultiSite")}
                            </div>
                        )
                    })}
                </span>
                <div className="mb-15" />
                <span className="titleFilter">SITE NAME</span>
                <span className="mb-15">
                    <div className="divWithCheckbox">
                        <input onChange={(e) => this.toggleFilter("name", e.target.value)} />
                    </div>
                </span>
                <div className="mb-15" />
                <span className="titleFilter">CITY</span>
                <span className="mb-15">
                    {this.props.filters.cities.length > 0 && this.props.filters.cities.map((item) => {
                        return (
                            <div className="divWithCheckbox">
                                <CustomCheckBox
                                    action={() => {
                                        return this.toggleFilter("city", this.props.filters.city.includes(item) ? this.props.filters.city.filter((i) => i != item) : [...this.props.filters.city, item])
                                    }}
                                    active={this.props.filters.city.includes(item)}
                                /> {item}
                            </div>
                        )
                    })}
                </span>
                <div className="mb-15" />
                <span className="titleFilter">COUNTRY</span>
                <span className="mb-15">
                    {this.props.filters.countries.length > 0 && this.props.filters.countries.map((item) =>
                        <div className="divWithCheckbox">
                            <CustomCheckBox
                                action={() => {
                                    return this.toggleFilter("country", this.props.filters.country.includes(item) ? this.props.filters.country.filter((i) => i != item) : [...this.props.filters.country, item])
                                }}
                                active={this.props.filters.country.includes(item)}
                            /> {item}
                        </div>
                    )}
                </span>
            </div>
        )
    }
}

export default withTranslation()(Filters);