const translations = {
    titleBox_RegisterSite: "BEM VINDO",
    subTitleBox_RegisterSite: "REGISTRE SEU SITE",
    placeHolderSite: "Nome do site…",
    placeHolderTypeOfEntity: "Tipo de entidade",
    placeHolderSingleEntity: "Unica entidade",
    placeHolderMultiEntity: "Multiplas entidades",
    labelIsPrimarySite: "É seu site primario? (Corporativo)?",
    boxErrorMustFirstRegisterPrimarySite: "Você deve primeiro registrar seu site primario",
    placeHolderContactName: "Nome de contato…",
    placeHolderEmail_RegisterSite: "E-mail(username)...",
}

export default translations;