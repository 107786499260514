import React, { Component, Fragment } from "react";
import PickerLogo from "components/pickerLogo";
import CustomButton from "components/customButton";
import Doubt from 'components/doubtPopover';
import CustomError from "components/customErrorBox";
import masks from 'helper/masks'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import CustomInputComponent from 'helper/formikComponents/customInput';
import CustomInput from 'components/customInput';
import CustomSelectComponent from 'helper/formikComponents/customSelect';
import SerialNumber from 'assets/img/wizard/serial_numer_img.jpg';

import "./index.scss";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import SignupSchema from './validations'
import CustomLabel from 'components/customLabel'
import $ from 'jquery';
import mask from 'jquery-mask-plugin';
import { getFormatByLanguage } from 'helper/dateByLanguage';
import Effect from "./effect/index";

class Step2 extends Component {

    getInitialValues = () => {
        if (this.props.entity_master_id) return {
            site: !!this.props.sites ? this.props.sites.find((site) => site.id == this.props.entity_master_id) : null,
            serial_number: "",
            product_number: "",
            shipped_date: '',
            shipped_method: '',
            tracking_number: ''
        }

        if (!this.props.data) return {
            site: this.props.sites.length == 1 ? this.props.sites[0] : null,
            serial_number: "",
            product_number: "",
            shipped_date: '',
            shipped_method: '',
            tracking_number: ''
        }
        return {
            shipped_date: this.props.data.shipped_date,
            shipped_method: this.props.data.shipped_method,
            tracking_number: this.props.data.tracking_number,
            serial_number: JSON.parse(this.props.data.settings).serial_number,
            product_number: JSON.parse(this.props.data.settings).product_number,
            site: !!this.props.sites.find((site) => site.id == this.props.data.entity_master_id) ? this.props.sites.find((site) => site.id == this.props.data.entity_master_id) : this.props.sites.find((site) => site.name == this.props.data.site_name)
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            const format = getFormatByLanguage(window.currentLanguage);
            $('.shipped_date').mask('00/00/0000');
        });
    }

    render() {
        return (
            <Fragment>
                <Formik
                    initialValues={this.getInitialValues()}
                    validationSchema={SignupSchema}
                    onSubmit={(values, actions) => {
                        const { product_number, serial_number, shipped_date, shipped_method, tracking_number } = values

                        let params = { site_id: values.site.id, product_number, serial_number, }

                        if (this.props.data) {
                            params.id = this.props.data.id
                        }

                        if (!!shipped_date.length) {
                            params.shipped_date = values.shipped_date
                        }

                        if (!!shipped_method) {
                            params.shipped_method = shipped_method.value
                        }

                        if (!!tracking_number.length) {
                            params.tracking_number = tracking_number
                        }

                        this.props.save(params)
                    }}
                    enableReinitialize={true}
                    render={({ errors, isSubmitting, setFieldValue, values, initialValues }) => {
                        const { t } = this.props;
                        return (
                            <Form>
                                <div className="divContainer addDevice">
                                    <div className={'displayFlex'}>
                                        <div className={'w50 heightDefault'}>
                                            <CustomLabel label={this.props.t('label_SiteName') + "*"} />
                                            <Field
                                                value={values.site ? values.site.value : null}
                                                setFieldValue={setFieldValue}
                                                name="site"
                                                errorMessage={t('inputRequiredField')}
                                                component={CustomSelectComponent}
                                                placeHolder={t('placeHolderSite')}
                                                label={t('placeHolderInputSite')}
                                                options={this.props.sites}
                                            />
                                        </div>
                                        <div className={'w25'}>
                                            <Field name="product_number"
                                                errorMessage={t('inputRequiredField')}
                                                component={CustomInputComponent}
                                                placeHolder={t('placeHolderProductNumber')}
                                                label={[
                                                    <Fragment>
                                                        <label>{t('label_ProductNumber') + "*"}</label>
                                                        {!this.props.hideDoubt &&
                                                            <Doubt
                                                                header={"Where to find the Product Number"}
                                                                subheader={["Just look at the bottom of you ", <b>Gazella Reader</b>, "to find the Product Number"]}
                                                                img={SerialNumber}
                                                            />
                                                        }
                                                    </Fragment>]}
                                            />
                                        </div>
                                        <div className={'w25'}>
                                            <Field
                                                name="serial_number"
                                                errorMessage={t('inputRequiredField')}
                                                component={CustomInputComponent}
                                                placeHolder={t('placeHolderSerialNumber')}
                                                label={[
                                                    <Fragment>
                                                        <label>{t('label_SerialNumber') + "*"}</label>
                                                        {!this.props.hideDoubt &&
                                                            <Doubt
                                                                header={"Where to find the Serial Number"}
                                                                subheader={["Just look at the bottom of you ", <b>Gazella Reader</b>, "to find the Serial Number"]}
                                                                img={SerialNumber}
                                                            />
                                                        }
                                                    </Fragment>
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    {this.props.admin &&
                                        <div className={'displayFlex mt100'}>
                                            <div className={'w50 heightDefault'}>
                                                <CustomInput
                                                    onChange={(val) => {
                                                        setFieldValue("shipped_date", val)
                                                    }}
                                                    name="shipped_date"
                                                    errorMessage={t('inputRequiredField')}
                                                    type={'date'}
                                                    placeHolder={t('Enter Date...')}
                                                    label={t('label_ShippedDate')}
                                                    inputClass={'shipped_date'}
                                                    value={values.shipped_date}
                                                />
                                            </div>
                                            <div className={'w50 heightDefault'}>
                                                <CustomLabel label={this.props.t('SHIPPED BY')} />
                                                <Field
                                                    value={values.shipped_method ? values.shipped_method.value : null}
                                                    setFieldValue={setFieldValue}
                                                    name="shipped_method"
                                                    component={CustomSelectComponent}
                                                    placeHolder={t('SHIPPED BY')}
                                                    label={t('SHIPPED BY')}
                                                    options={[{
                                                        id: 0, value: "FEDEX",

                                                    }, { id: 1, value: "UPS" }, { id: 2, value: "DHL" }]}
                                                />
                                            </div>
                                            <div className={'w50 heightDefault'}>
                                                <Field name="tracking_number" errorMessage={t('inputRequiredField')}
                                                    component={CustomInputComponent}
                                                    placeHolder={t('Enter Tracking Number...')}
                                                    label={t('TRACKING NUMBER')}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className={'displayFlex jc-center'}>
                                        {this.props.canCancel &&
                                            <div className={'w30 textRight heigthDefault btn-step1'}>
                                                <CustomButton type={'button '} className={'buttonCancel'} value={t('label_Cancel')} handleChange={this.props.cancel} />
                                            </div>
                                        }
                                        {!_.isEqual(initialValues, values) &&
                                            <div className={'w30 textCenter'}>
                                                <CustomButton type={'button '} className={'buttonNext'}
                                                    value={t('label_Save')} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Form>
                        )
                    }
                    }
                />
            </Fragment>
        );
    }
}

export default withTranslation()(Step2)