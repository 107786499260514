import React, { Component } from "react";
import PasswordIcon from "assets/img/sign-in/password_icon.png";
import UnhidePassword from "assets/img/sign-in/unhide_password.png";
import HidePassword from "assets/img/sign-in/hide_password_icon.png";
import "./index.scss";
import { withTranslation } from 'react-i18next';
import InputDefault from 'components/inputDefault';
import queryString from 'query-string'
import { UserActions } from "redux/reducers";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from "styled-components";
import _ from "lodash";

class BoxNewPassword extends Component {
  state = {
    token: null,
    newPassword: "",
    newPasswordConfirmation: "",
    showPasswordNewPassword: false,
    showPasswordNewPasswordConfirmation: false,
    error: null,
    triedSubmit: false,
  };

  componentWillMount() {
    var { token } = queryString.parse(this.props.location.search)

    if (!token) {
      return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
    }

    this.setState({ token })
  }

  componentDidUpdate() {
    if (!_.isEqual(this.state.newPassword, this.state.newPasswordConfirmation) && this.state.error) {
      this.setState({ error: null })
    }
  }
  submit = async () => {
    try {
      await this.props.UserActions.changePassword(this.state.newPassword, this.state.newPasswordConfirmation, this.state.token)
    } catch (e) {
      console.log("Error!", e)
      this.setState({ error: e })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="new-password">
        <div className="div-white-sign">
          <div className="div-label-sign">{t('titleBox_NewPassword')}</div>
          <div class="div-input">
            <div className="div-forgot">
              <div>{t('subTitleBox_NewPassword')}</div>
              {/* <div>
                to access <b>HEMEX HEALTH</b>
              </div> */}
            </div>
            <div className="password-box b-none">
              <InputDefault
                rightIcon={!this.state.showPasswordNewPassword ? UnhidePassword : HidePassword}
                onClickRightIcon={() => this.setState({ showPasswordNewPassword: !this.state.showPasswordNewPassword })}
                leftIcon={PasswordIcon}
                type={this.state.showPasswordNewPassword ? 'text' : 'password'}
                value={this.state.newPassword}
                placeHolder={t('placeHolderPassword')}
                onChange={(value) => this.setState({ newPassword: value })}
              />
            </div>
            <div className="password-box b-none">
              <InputDefault
                rightIcon={!this.state.showPasswordNewPasswordConfirmation ? UnhidePassword : HidePassword}
                onClickRightIcon={() => this.setState({ showPasswordNewPasswordConfirmation: !this.state.showPasswordNewPasswordConfirmation })}
                leftIcon={PasswordIcon}
                type={this.state.showPasswordNewPasswordConfirmation ? 'text' : 'password'}
                value={this.state.newPasswordConfirmation}
                placeHolder={t('placeHolderPassword')}
                onChange={(value) => this.setState({ newPasswordConfirmation: value })}
              />
            </div>
            <button className="button-sign cp" onClick={this.submit}>
              {t('buttonConfirmAndSign')}
            </button>
            <PasswordRequirementsDescription is_valid={true}>
              {this.state.error}
            </PasswordRequirementsDescription>
            <div className="div-contact">
              <a className="link_support" href="mailto: support@hemexhealth.com" target="_blank">{t('label_ContactSupport')}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => ({
  UserActions: bindActionCreators(UserActions, dispatch),
})

BoxNewPassword = connect(null, mapDispatchToProps)(BoxNewPassword)

export default withTranslation()(BoxNewPassword)

const PasswordRequirementsDescription = styled.div`
    font-size: 12px;
    text-align: left;
    color: ${({ is_valid }) => !!is_valid ? "#575757" : "red"};
    opacity: 90%;
    margin-top: 10px;
`