import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

const valid_status = ["R", "W"]

export default (Component) => {
  const validSession = (props) => {
    return !!props.status && !!valid_status.includes(props.status)
  };

  const SessionValidator = (props) => {
    if (!validSession(props)) {
      return <Redirect to={`${process.env.REACT_APP_FRONT_URL}/`} />
    }

    return <Component {...props} />;
  };

  return SessionValidator;
};
