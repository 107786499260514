const translations = {
    title_devices_operations: "CONSOLE DE OPERAÇÕES",
    title_devices_utilization: "UTILIZAÇÕES DOS DISPOSITIVOS",
    label_data_sync: "SINCRONIZAÇÃO DE DADOS",
    label_data_sync_error: "ERROS DE SINCRONIZAÇÃO",
    label_deleted: "DELETADOS",
    label_average_used: "MÉDIA DE CARTUCHOS USADOS",
    label_mobile_tests_performed: "TESTES MÓVEIS FEITOS PELOS SITES",
    label_monthly_count: "CONTAGEM MENSAL DE DISPOSITIVOS ATIVOS",
}

export default translations;