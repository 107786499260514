import { createSelector } from "reselect";
import _ from "lodash";
import * as SystemAdminService from "redux/services/SystemAdmin";

const initial = {
  users: [],
  search_user: '',
  cardSelected: 1,
  page: 0,
  qty_page: 10,
  all_users_selecteds: false,
  users_selecteds: [],
  sort: "status",
  sort_type: "asc",
}

const types = {
  TYPES_ADMIN_MANAGEMENT_SET_SORT: "TYPES_ADMIN_MANAGEMENT_SET_SORT",
  TYPES_ADMIN_MANAGEMENT_SET_SORT_TYPE: "TYPES_ADMIN_MANAGEMENT_SET_SORT_TYPE",
  TYPES_ADMIN_MANAGEMENT_INITIAL: "TYPES_ADMIN_MANAGEMENT_INITIAL",
  TYPES_GET_ADMIN_USERS: "TYPES_GET_ADMIN_USERS",
  TYPES_ADMIN_MANAGEMENT_TOOGLE: "TYPES_ADMIN_MANAGEMENT_TOOGLE",
  TYPES_ADMIN_MANAGEMENT_SEARCH: "TYPES_ADMIN_MANAGEMENT_SEARCH",
  TYPES_ADMIN_MANAGEMENT_TOOGLE_UNIQ_USER: "TYPES_ADMIN_MANAGEMENT_TOOGLE_UNIQ_USER",
  TYPES_ADMIN_MANAGEMENT_TOOGLE_ALL_USERS: "TYPES_ADMIN_MANAGEMENT_TOOGLE_ALL_USERS",
  TYPES_ADMIN_MANAGEMENT_CHANGE_PAGE: "TYPES_ADMIN_MANAGEMENT_CHANGE_PAGE",
  TYPES_ADMIN_MANAGEMENT_CHANGE_QTY_PAGE: "TYPES_ADMIN_MANAGEMENT_CHANGE_QTY_PAGE"


}

export const get_admin_users = () => async (dispatch) => {
  const adminUsers = await SystemAdminService.getAdminUsers()
  dispatch({ type: types.TYPES_GET_ADMIN_USERS, payload: adminUsers })
}

export const setSort = (payload) => ({ type: types.TYPES_ADMIN_MANAGEMENT_SET_SORT, payload })

export const setSortType = (payload) => ({ type: types.TYPES_ADMIN_MANAGEMENT_SET_SORT_TYPE, payload })

export const toogle_card = (card) => ({ type: types.TYPES_ADMIN_MANAGEMENT_TOOGLE, card })

export const search_user = (val) => ({ type: types.TYPES_ADMIN_MANAGEMENT_SEARCH, val })

export const toogle_uniq_user = val => ({ type: types.TYPES_ADMIN_MANAGEMENT_TOOGLE_UNIQ_USER, val })

export const toogle_all_users = val => ({ type: types.TYPES_ADMIN_MANAGEMENT_TOOGLE_ALL_USERS, val })

export const change_page = val => ({ type: types.TYPES_ADMIN_MANAGEMENT_CHANGE_PAGE, val })

export const change_qty_page = val => ({ type: types.TYPES_ADMIN_MANAGEMENT_CHANGE_QTY_PAGE, val })

const AdminManagement = (state = initial, action) => {
  switch (action.type) {

    case types.TYPES_ADMIN_MANAGEMENT_INITIAL: {
      return initial;
    };
    case types.TYPES_GET_ADMIN_USERS: {
      return { ...state, users: action.payload }
    }
    case types.TYPES_ADMIN_MANAGEMENT_TOOGLE: {
      return { ...state, cardSelected: action.card }
    }
    case types.TYPES_ADMIN_MANAGEMENT_SEARCH: {
      return { ...state, search_user: action.val }
    }
    case types.TYPES_ADMIN_MANAGEMENT_TOOGLE_UNIQ_USER: {
      return {
        ...state,
        users_selecteds: action.val
      };
    }
    case types.TYPES_ADMIN_MANAGEMENT_TOOGLE_ALL_USERS: {
      return { ...state, all_users_selecteds: action.val };
    }
    case types.TYPES_ADMIN_MANAGEMENT_CHANGE_PAGE: {
      return {
        ...state,
        page: action.val
      }
    }
    case types.TYPES_ADMIN_MANAGEMENT_CHANGE_QTY_PAGE: {
      return {
        ...state,
        qty_page: action.val
      }
    }
    case types.TYPES_ADMIN_MANAGEMENT_SET_SORT: {
      return {
        ...state,
        sort: action.payload,
      }
    }
    case types.TYPES_ADMIN_MANAGEMENT_SET_SORT_TYPE: {
      return {
        ...state,
        sort_type: action.payload,
      }
    }
  }
  return state;
};

export const qty_page = state => state.AdminManagement.qty_page;

export const page = state => state.AdminManagement.page;

export const searchFilter = state =>
  state.AdminManagement.search_user;

export const allUsersDB = state => state.AdminManagement.users

export const allUsersSelecteds = state => state.AdminUserManagementReducer.ALL_USERS_SELECTEDS;

export const usersSelecteds = state => state.AdminUserManagementReducer.USERS_SELECTEDS;

export const sort = state => state.AdminManagement.sort;

export const stype = state => state.AdminManagement.sort_type;

export const cardSelected = state =>
  state.AdminManagement.cardSelected;

export const allUsersFiltered = createSelector(
  allUsersDB,
  searchFilter,
  cardSelected,
  qty_page,
  page,
  sort,
  stype,
  (users, searchFilter, cardSelected, qty_page, page, st, s_type) => {
    if (!users) return []
    let results = _.chain(users)
      .filter(user => searchFilter.length > 0 ? user.name.toUpperCase().includes(searchFilter.toUpperCase()) : user)
      .filter((user) => {
        if (!user.status) return false;
        if (cardSelected == 1) {
          return user.status == "A"
        }
        if (cardSelected == 2) {
          return user.status == "D"
        }
        if (cardSelected == 3) {
          return user.status == "B"
        }
        if (cardSelected == 4) {
          return user.isSupport == 2 && user.status == "A"
        }
      })
      .value()
    results = _.orderBy(results, st, s_type)

    const totalPages = (results.length / qty_page) % 1 != 0 ? Math.ceil(results.length / qty_page) : Math.ceil(results.length / qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? results : init + qty_page
    let arrayFormatado = (totalPages) == 1 ? results : results.slice(init, end);
    return {
      allUsersFiltered: arrayFormatado,
      counter: results.length,
      active: users.filter((user) => user.status == 'A').length,
      deactive: users.filter((user) => user.status == 'D').length,
      blocked: users.filter((user) => user.status == 'B').length,
      support: users.filter((user) => user.isSupport == 2 && user.status == 'A').length,
      totalPages: parseInt(totalPages)
    }
  }
);

export default AdminManagement;
