import React, { Component, Fragment } from 'react'
import TestCard from "components/customTestCard";
import SearchBarTable from "components/searchBarTable";
import GenericTableHeader from "components/tables/genericTableHeader";
import GenericTableRow from "components/tables/genericTableRow";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { tableHeaderSystemAdminToolsAdminManagement } from 'helper/getTableHeader.js';
import { allUsersFiltered } from './redux/reducer'
import AddAdminUser from './addAdminUser'
import Swal from 'sweetalert2'
import * as userActions from 'redux/actions';
import * as actionsAdminManagement from './redux/reducer';
import { getDateByLanguage } from "helper/dateByLanguage";
import Loading from 'components/loading';
import * as systemAdminActions from 'redux/reducers/SystemAdminReducer';

class AdminManagement extends Component {

    state = {
        modalInviteUser: false,
        request: false
    }

    makeRequests = async () => {
        this.setState({
            request: true
        })
        this.props.actionsAdminManagement.get_admin_users();
        this.setState({
            request: false
        })
    }
    componentWillMount = async () => {
        await this.makeRequests()
    }

    componentDidUpdate(prevProps) {
        if (this.props.cardSelected != prevProps.cardSelected) {
            this.props.actionsAdminManagement.toogle_all_users(false)
            this.props.actionsAdminManagement.toogle_uniq_user([])
        }
    }

    introMessage = async () => {
        return await Swal.fire({
            html: "You are trying to access Admin User Management <br/>",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        });
    }

    componentDidMount = async () => {
        const { value } = await this.introMessage();
        if (!value) {
            this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/`)
        }
        this.makeRequests();
    }

    componentWillUnmount = async () => {
        await this.makeRequests()
        this.props.actionsAdminManagement.toogle_all_users(false)
        this.props.actionsAdminManagement.toogle_uniq_user([])
        this.props.systemAdminActions.get_counters_system_admin_admin();
    }

    showAlert = async (message = null) => {
        let text = `You can't ${message ? message : 'delete'} your user!`
        await Swal.fire({
            text,
            type: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        });
    }

    _toogleUniqLine = ({ id }) => {
        if (this.props.allUsersSelecteds) {
            this.props.actionsAdminManagement.toogle_all_users(!this.props.allUsersSelecteds)
        }
        this.props.actionsAdminManagement.toogle_uniq_user(this.props.usersSelecteds.includes(id) ? this.props.usersSelecteds.filter((i) => i !== id) : [...this.props.usersSelecteds, id])
    }

    _blockUser = async () => {
        const users = this.props.usersSelecteds;
        let userEqual = false;
        let arrayPromises = [];

        users.forEach(id => {
            if (id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert('block')
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to block this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                arrayPromises.push(this.props.userActions.blockUser(id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    _unblockuser = async () => {
        let arrayPromises = [];

        const users = this.props.usersSelecteds;

        let userEqual = false;

        users.forEach(id => {
            if (id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert('unblock')
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to unblock this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                arrayPromises.push(this.props.userActions.unblockUser(id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    _deleteUser = async () => {
        let arrayPromises = [];

        const users = this.props.usersSelecteds;
        let userEqual = false;
        users.forEach(id => {
            if (id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert()
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to delete this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                return arrayPromises.push(this.props.userActions.deleteUser(id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    inviteUser = async (values) => {
        this.setState({ request: true })
        if (values.id) {
            await this.props.userActions.editAdminUser(values)
            await this.makeRequests()
            this.setState({
                modalInviteUser: false,
                request: false
            })
        } else {
            try {
                await this.props.userActions.createAdminUser(values)
                await this.makeRequests()
                this.setState({ modalInviteUser: false, request: false })
            } catch (e) {
                let text = `Could not Save User. <br/>This user already has a Hemex Account.`
                await Swal.fire({
                    html: text,
                    type: 'warning',
                    showConfirmButton: false,
                    showCancelButton: false,
                    cancelButtonColor: '#d33',
                });
                this.setState({ request: false })
            }
        }
    }

    editUser = (row) => {
        if (row.status == 'A') return this.setState({
            dataSelected: {
                id: row.id,
                first_name: row.firstname,
                isSupport: row.isSupport,
                isAdmin: row.isAdmin,
                last_name: row.lastname || '',
                email_address: row.email,
            },
            modalInviteUser: true
        })
    }

    checkAllRows = bool => {
        if (bool) {
            this.props.actionsAdminManagement.toogle_all_users(bool)
            return this.props.actionsAdminManagement.toogle_uniq_user(this.props.allUsersFiltered.allUsersFiltered.map((user) => user.id));
        }
        this.props.actionsAdminManagement.toogle_all_users(bool)
        return this.props.actionsAdminManagement.toogle_uniq_user([]);
    }


    sort = sortTitle => {
        if (!sortTitle) return false;
        if (this.props.sort == sortTitle) {
            return this.props.actionsAdminManagement.setSortType(this.props.sort_type == "asc" ? "desc" : "asc");
        }
        this.props.actionsAdminManagement.setSortType("asc")
        this.props.actionsAdminManagement.setSort(sortTitle);
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <Loading show={this.state.request} />
                {
                    this.state.modalInviteUser &&
                    <AddAdminUser
                        hideSite={true}
                        open={this.state.modalInviteUser}
                        onClose={() => this.setState({
                            modalInviteUser: !this.state.modalInviteUser,
                            dataSelected: null
                        })}
                        save={(values) => this.inviteUser(values)}
                        data={this.state.dataSelected ? { ...this.state.dataSelected } : null}
                    />
                }
                <div className="tests-patient-container">
                    <TestCard
                        onClick={() => this.props.actionsAdminManagement.toogle_card(1)}
                        leftValue={this.props.allUsersFiltered.active}
                        rightValue={t('label_SystemAdminUserManagement_CardActiveUsers')}
                        customTestCardClass={`border-green ${this.props.cardSelected ===
                            1}`}
                        customTestCardLeftValue={this.props.cardSelected === 1}
                        customTestRightValue={this.props.cardSelected === 1}
                    />
                    <TestCard
                        onClick={() => this.props.actionsAdminManagement.toogle_card(2)}
                        leftValue={this.props.allUsersFiltered.deactive}
                        rightValue={t('label_SystemAdminUserManagement_CardDeactivedUsers')}
                        customTestCardClass={`border-black ${this.props.cardSelected ===
                            2}`}
                        customTestCardLeftValue={this.props.cardSelected === 2}
                        customTestRightValue={this.props.cardSelected === 2}
                    />
                    <TestCard
                        onClick={() => this.props.actionsAdminManagement.toogle_card(3)}
                        leftValue={this.props.allUsersFiltered.blocked}
                        rightValue={t('label_SystemAdminUserManagement_CardBlockedUsers')}
                        customTestCardClass={`border-red ${this.props.cardSelected === 3}`}
                        customTestCardLeftValue={this.props.cardSelected === 3}
                        customTestRightValue={this.props.cardSelected === 3}
                    />
                    <TestCard
                        onClick={() => this.props.actionsAdminManagement.toogle_card(4)}
                        leftValue={this.props.allUsersFiltered.support}
                        rightValue={t('label_SystemAdminUserManagement_CardSupportUsers')}
                        customTestCardClass={`border-red ${this.props.cardSelected === 4}`}
                        customTestCardLeftValue={this.props.cardSelected === 4}
                        customTestRightValue={this.props.cardSelected === 4}
                    />
                </div>
                <SearchBarTable
                    showPrinter={true}
                    hideFilter={true}
                    showBlock={[1].includes(this.props.cardSelected) && this.props.usersSelecteds.length > 0}
                    showUnblock={[3].includes(this.props.cardSelected) && this.props.usersSelecteds.length > 0}
                    block={() => this._blockUser()}
                    unblock={() => this._unblockuser()}
                    placeHolder={t('Search for user...')}
                    router={this.props.router}
                    hideDelete={[2, 3].includes(this.props.cardSelected) || this.props.usersSelecteds.length == 0}
                    delete={() => this._deleteUser()}
                    value={this.props.searchValue}
                    hidePlus={1 !== this.props.cardSelected}
                    add={() => this.setState({ modalInviteUser: !this.state.modalInviteUser })}
                    onChange={value => this.props.actionsAdminManagement.search_user(value)}
                    perPage={this.props.qty_page}
                    changeQtyPage={this.props.actionsAdminManagement.change_qty_page}
                    currentPage={this.props.currentPage}
                    changePage={this.props.actionsAdminManagement.change_page}
                    totalPages={this.props.allUsersFiltered.totalPages}
                />
                <div className="pages-table-container">
                    <GenericTableHeader
                        checked={this.props.allUsersSelecteds}
                        cols={tableHeaderSystemAdminToolsAdminManagement(t, this.props.sort)}
                        checkAction={bool => this.checkAllRows(bool)}
                        sort_type={this.props.sort_type}
                        onClick={(index, sortTitle) => this.sort(sortTitle)}
                    />
                    <div className="div_rows">
                        {this.props.allUsersFiltered.allUsersFiltered.map(row => {
                            let newRow = {
                                id: row.id,
                                status: row.status,
                                first_name: row.firstname,
                                email_address: row.email,
                                role: row.isSupport == 2 ? t('label_support_user') : t('label_hemex_admin'),
                            }

                            return (
                                <GenericTableRow
                                    cols={tableHeaderSystemAdminToolsAdminManagement(t, this.props.sort)}
                                    checksSelecteds={this.props.usersSelecteds}
                                    allCheckSelected={this.props.allUsersSelecteds}
                                    checkAction={id => this._toogleUniqLine(id)}
                                    row={newRow}
                                    onClick={() => this.editUser(row)}
                                />
                            )
                        })
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    cardSelected: state.AdminManagement.cardSelected,
    users: state.AdminManagement.users,
    allUsersFiltered: allUsersFiltered(state),
    userData: state.UserReducer.userData,
    qty_page: state.AdminManagement.qty_page,
    currentPage: state.AdminManagement.page,
    allUsersSelecteds: state.AdminManagement.all_users_selecteds,
    usersSelecteds: state.AdminManagement.users_selecteds,
    sort: state.AdminManagement.sort,
    sort_type: state.AdminManagement.sort_type,
});

const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    systemAdminActions: bindActionCreators(systemAdminActions, dispatch),
    actionsAdminManagement: bindActionCreators(actionsAdminManagement, dispatch)
});

AdminManagement = connect(mapStateToProps, mapDispatchToProps)(AdminManagement);

export default withTranslation()(AdminManagement)