import React, { Component } from "react";
import PropTypes from "prop-types";

import "./index.scss";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import imageCheck from 'assets/img/sucess_icon@2x.png';
import { UserActions } from "redux/reducers"
import { bindActionCreators } from "redux";

class BoxConfirmed extends Component {
    static propTypes = {
        prop: PropTypes
    };

    componentWillMount() {
        if (this.props.data && Object.keys(this.props.data).length > 0) {
            this.props.UserActions.setUser(this.props.data)
            this.props.i18n.changeLanguage(this.props.data.languageDefault)
        }
    }

    signin = () => {
        if (!this.props.data) return window.location.href = `${process.env.REACT_APP_FRONT_URL}/login`;
        this.props.UserActions.setUser(this.props.data)

        return window.location.href = `${process.env.REACT_APP_FRONT_URL}/`
    }

    render() {
        const { t } = this.props;
        return (
            <div className="box-confirmed div-sign-in">
                <div className="div-white-sign">
                    <div className="div-label-sign">
                        {t('titleBox_SiteRegisterConfirmed')}
                    </div>
                    <div className="div-input">
                        <div className="div-check">
                            <img src={imageCheck} className="image-check" />
                        </div>

                        <div className="label-register">
                            <div>{t('subtitle_SiteRegisterConfirmedFirstLine')}</div>
                            <div>{t('subtitle_SiteRegisterConfirmedSecondLine')}</div>
                        </div>

                        <div className="mt20 displayFlex jc-center">
                            <button className="button-sign cp" onClick={() => this.signin()}>{t('label_Start')}</button>
                        </div>
                        <div className="div-contact">
                            <a className="link_support" href="mailto: support@hemexhealth.com" target="_blank">{t('label_ContactSupport')}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        UserActions: bindActionCreators(UserActions, dispatch)
    }
}


BoxConfirmed = connect(null, mapDispatchToProps)(BoxConfirmed)

export default withTranslation()(BoxConfirmed)