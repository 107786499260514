import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import emailIcon from "assets/img/sign-in/email_icon.png";
import passwordIcon from "assets/img/sign-in/password_icon.png";
import organizationIcon from "assets/img/sign-up/organization_icon.png";
import userIcon from "assets/img/sign-up/user_icon.png";
import InputDefault from "components/inputDefault";
import CustomRadio from "components/customRadio";
import Doubt from "components/doubtPopover";
import hidePasswordIcon from "assets/img/sign-in/hide_password_icon.png";
import unhidePasswordIcon from "assets/img/sign-in/unhide_password.png";
import { UserActions } from "redux/reducers";
import * as actions from "redux/actions";
import { connect } from "react-redux";
import CustomError from "components/customErrorBox";
import "./index.scss";
import Loading from "components/loading";
import _ from "lodash";

class BoxRegister extends Component {
  static propTypes = {
    prop: PropTypes,
  };

  componentWillMount() {
    this.props.setInitial();
  }

  state = {
    siteName: "",
    siteNameError: "",
    entityType: "2",
    entityTypeError: "",
    contactName: "",
    contactNameError: "",
    email: "",
    emailError: "",
    password: "",
    passwordError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    showPassword: false,
    showPasswordConfirmation: false,
    primarySite: 1,
    triedSubmit: false,
    requestError: "",
    request: false,
  };

  submit = async () => {

    this.setState({
      request: true,
    });

    try {
      await this.props
        .register({
          user_name: this.state.contactName,
          email: this.state.email,
          password: this.state.password,
          password_confirmation: this.state.passwordConfirmation,
          site_name: this.state.siteName,
          site_type: this.state.entityType,
          default_lang: this.props.i18n.language,
        })
    } catch (e) {
      if (_.isString(e)) {
        return this.setState({ requestError: this.props.t(e), request: false })
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.triedSubmit) return false;

    if (
      this.state.siteNameError.length > 0 &&
      this.state.siteName !== prevState.siteName
    ) {
      this.setState({
        siteNameError: "",
      });
    }

    if (
      this.state.contactNameError.length > 0 &&
      this.state.contactName !== prevState.contactName
    ) {
      this.setState({
        contactNameError: "",
      });
    }

    if (
      this.state.emailError.length > 0 &&
      this.state.email !== prevState.email
    ) {
      this.setState({
        emailError: "",
      });
    }

    if (
      this.state.passwordError.length > 0 &&
      this.state.password !== prevState.password
    ) {
      this.setState({
        passwordError: "",
      });
    }
    if (
      this.state.passwordConfirmationError.length > 0 &&
      this.state.passwordConfirmation !== prevState.passwordConfirmation
    ) {
      this.setState({
        passwordConfirmationError: "",
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="div-register-site">
        <Loading show={this.state.request} />
        <div className="div-white-sign">
          <div className="div-label-sign">{t("titleBox_RegisterSite")}</div>
          <div class="div-input">
            <div className="label-register">
              {t("subTitleBox_RegisterSite")}
            </div>
            <div className="div-flex">
              <div className="div-inside">
                <div className="mt10">
                  <InputDefault
                    leftIcon={organizationIcon}
                    value={this.state.siteName}
                    placeHolder={t("Site name...")}
                    onChange={(value) => this.setState({ siteName: value })}
                    error={
                      this.state.siteNameError.length > 0
                        ? this.state.siteNameError
                        : null
                    }
                  />
                </div>
                <div className="mt30 text-left">
                  <span className="typeOfEntity mb-10">
                    {t("placeHolderTypeOfEntity")}
                  </span>
                  <div className="radio-container">
                    <div
                      className={`entity ${
                        this.state.entityType == 2
                        } mb-10">`}
                    >
                      <CustomRadio
                        label={t("placeHolderMultiEntity")}
                        active={this.state.entityType == 2}
                        onClick={() => this.setState({ entityType: 2 })}
                      />
                      <div class="ml13">
                        <Doubt
                          header={"What is a Multi-Site Entity"}
                          subheader={
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus sit amet volutpat consequat mauris. Netus et malesuada fames ac turpis egestas. Sed vulputate mi sit amet"
                          }
                          img={""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-inside">
                <div className="mt10">
                  <InputDefault
                    leftIcon={userIcon}
                    value={this.state.contactName}
                    placeHolder={t("Contact name...")}
                    onChange={(value) => this.setState({ contactName: value })}
                    error={
                      this.state.contactNameError.length > 0
                        ? this.state.contactNameError
                        : null
                    }
                  />
                </div>
                <div className="mt10">
                  <InputDefault
                    leftIcon={emailIcon}
                    value={this.state.email}
                    type={"email"}
                    placeHolder={t("Email...")}
                    onChange={(value) => this.setState({ email: value })}
                    error={
                      this.state.emailError.length > 0
                        ? this.state.emailError
                        : null
                    }
                  />
                </div>
                <div className="mt10">
                  <InputDefault
                    leftIcon={passwordIcon}
                    rightIcon={
                      this.state.showPassword
                        ? hidePasswordIcon
                        : unhidePasswordIcon
                    }
                    onClickRightIcon={() =>
                      this.setState({ showPassword: !this.state.showPassword })
                    }
                    value={this.state.password}
                    type={this.state.showPassword ? "text" : "password"}
                    placeHolder={t("Password...")}
                    onChange={(value) => this.setState({ password: value })}
                    error={
                      this.state.passwordError.length > 0
                        ? this.state.passwordConfirmationError
                        : null
                    }
                  />
                </div>
                <div className="mt10">
                  <InputDefault
                    leftIcon={passwordIcon}
                    rightIcon={
                      this.state.showPasswordConfirmation
                        ? hidePasswordIcon
                        : unhidePasswordIcon
                    }
                    onClickRightIcon={() =>
                      this.setState({
                        showPasswordConfirmation: !this.state
                          .showPasswordConfirmation,
                      })
                    }
                    value={this.state.passwordConfirmation}
                    type={
                      this.state.showPasswordConfirmation ? "text" : "password"
                    }
                    placeHolder={t("Confirm Password...")}
                    onChange={(value) =>
                      this.setState({ passwordConfirmation: value })
                    }
                    error={
                      this.state.passwordConfirmationError.length > 0
                        ? this.state.passwordConfirmationError
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <div className="div_alert_message mt10 posRelative">
              <button
                className={`button-sign ${
                  this.state.primarySite === 0 && this.state.entityType == 1
                  } cp`}
                onClick={this.submit}
                disabled={
                  this.state.primarySite === 0 && this.state.entityType == 1
                }
              >
                {t("label_SignUp")}
              </button>
              {this.state.requestError.length > 0 && (
                <CustomError errorMessage={this.state.requestError} />
              )}
            </div>
            <div className="div-contact">
              <a
                className="link_support"
                href="mailto: support@hemexhealth.com"
                target="_blank"
              >
                {t("label_ContactSupport")}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    register: (value) => dispatch(UserActions.Register(value)),
    setInitial: () => dispatch(actions.setInitial()),
  };
};

BoxRegister = connect(null, mapDispatchToProps)(BoxRegister);

export default withTranslation()(BoxRegister);
