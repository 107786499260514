import React, { Component, Fragment } from "react";
import "./index.scss";
import addButton from "assets/img/header-new/widgets_cartridges_add_cartridge.png";
import CustomButton from 'components/customButton'

const AddButton = ({ action }) => {
    return (
        <div className="card-managementPortalCard-container-image-add cp" >
            <img className="card-managementPortalCard-container-image-add-link" src={addButton} onClick={action} />
        </div>
    )
}

export default class ManagementPortalCard extends Component {

    state = {
        showBtn: false,
        thisShowPop: false,
    }

    static defaultProps = {
        title: "",
        type: "",
        value: {
            first: "",
            second: ""
        }
    }

    openModal = val => {
        this.props.openModal(val)
        this.setState({ thisShowPop: false })
    }

    render() {
        const { title, value, type, image, t, onClick, activeNow, numCard, showPop, disease } = this.props;
        const { thisShowPop } = this.state


        let letActive = "card-background boxInactive";

        if (activeNow && disease == 1) {
            letActive = "card-background border-black";
        }else if(activeNow && disease == 2){
            letActive = "card-background border-green"
        }

        return (
            <div
                className={onClick != undefined ? "card-managementPortalCard-container cp" : "card-managementPortalCard-container"}
                onClick={() => {
                    if (onClick != undefined) {
                        return onClick()
                    }
                }
                }
            >
                <div
                    className={letActive}
                    style={{ backgroundImage: `url(${image})` }}
                >
                    <div className="card-managementPortalCard-container-infos">
                        <div className="card-managementPortalCard-container-info-title">
                            {title}
                            <div className="btn-container">
                                {this.props.showPop && this.state.thisShowPop &&
                                    <div onClick={() => this.setState({ thisShowPop: !thisShowPop })} className="ordermore" style={{ marginRight: "20px", backgroundColor: "tomato", color: "#fff", fontSize: "12px", cursor: "pointer" }}>
                                        TIME TO ORDER MORE CARTRIDGES
                                    {/*{t('label_TimeToOrderMore')}*/}
                                    </div>
                                }
                                {thisShowPop && (
                                    <>
                                        <div className="arrow" />
                                        <div className="buttons" style={{ boxShadow: "0px 3px 10px rgba(0,0,0,.2)" }}>
                                            <div className="btn">
                                                <CustomButton
                                                    // value={t('label_AlreadyOrdered')}
                                                    value={"ALREADY ORDERED"}
                                                    className={`first ${this.props.hasOrders ? '' : 'grey'}`}
                                                    handleChange={() => this.props.hasOrders ? this.openModal('already') : ''}
                                                />
                                            </div>
                                            <div className="btn">
                                                <CustomButton
                                                    // value={t('label_Received')}
                                                    value={'RECEIVED'}
                                                    className={`second ${this.props.hasOrders ? '' : 'grey'}`}
                                                    handleChange={() => this.props.hasOrders ? this.openModal('received') : ''}
                                                />
                                            </div>
                                            <div className="btn">
                                                <CustomButton
                                                    // value={t('label_PlaceOrder')}
                                                    value={'PLACE ORDER'}
                                                    className={`third`}
                                                    handleChange={() => this.openModal('place')}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="card-managementPortalCard-container-info-data-container">
                            <div
                                className={
                                    "card-managementPortalCard-container-info-data-container-item-first " +
                                    type
                                }
                            >
                                {value.first}
                            </div>
                            <div
                                className={
                                    "card-managementPortalCard-container-info-data-container-item-second " +
                                    type
                                }
                            >
                                {value.second}
                            </div>
                            {this.props.addButton && <AddButton action={() => this.setState({ thisShowPop: true })} />}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}