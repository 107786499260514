import React, { Component, Fragment } from "react";
import "./index.scss";
import addButton from "assets/img/header-new/widgets_cartridges_add_cartridge.png";

export default class ReadersAdminCard extends Component {
  static defaultProps = {
    title: "",
    type: "",
    value: {
      first: "",
      second: ""
    }
  };

  render() {
    const { title, value, type, image, margin15} = this.props;

    return (
      <div
        className="card-readers-container"
        onClick={this.props.onClick}
      >
        <div
          className="card-background"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="card-readers-container-infos">
            <div className="card-readers-container-info-title">
              {value.firstLeft != null &&
              <div className="card-readers-container-info-title p0">
                {value.firstLeft}
              </div>
              }
              <div className="card-readers-container-info-title">
                {title}
              </div>
            </div>
            <div className="card-readers-container-info-data-container">
              <div
                className={`card-readers-container-info-data-container-item-first ${!value.second ? 'error' : type} ${margin15 && 'margin15'}`}
              >
                {value.first}
              </div>
              {value.second &&
                <div
                  className={"card-readers-container-info-data-container-item-second "}
                >
                  {value.second}
                </div>
              }
            </div>
          </div>
          {this.props.addButton && (
            <div className="card-readers-container-image-add">
              <img
                className="card-readers-container-image-add-link"
                src={addButton}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
