import React from 'react';
import styled from "styled-components";
import closeImage from 'assets/img/close_grey_icn.png'

export const IdleTimeOutModal = ({ showModal, handleLogout, handleRefresh }) => {

    const ModalContainer = styled.div`
        position: fixed;
        background-color: rgba(0,0,0,0.8);
        width: 100%;
        height: 100vh;
        top: 0;
        display: ${({ show }) => show ? "flex" : "none"};
        justify-content: center;
        align-items: center;
        z-index: 999;
    `

    const Modal = styled.div`   
        width: 450px;
        background-color: #fff;
        margin: 0 auto;
        display: table;
        position: fixed;
        left: 0;
        right: 0;
        border-radius: 3px;
    `

    const ModalHeader = styled.div`
        padding: 10px;
        position: relative;
        display: flex;
        justify-content: center;
    `

    const CloseButton = styled.img`
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 5px;
        width: 30px;
        height: 30px;
    `

    const Title = styled.div`
        font-weight: 800;
        font-size: 18px;
    `

    const Separator = styled.div`
        height: 1px;
        background-color: #575757;
        opacity: 50%;
        margin: 0px 50px;
    `

    const ModalBody = styled.div`
        padding: 50px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
    `

    const Button = styled.div`
    background-color: ${({ color }) => color};
    color: #fff;
    flex: 1;
    margin: 0px 20px;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: 3px;
    cursor: ${({ is_valid }) => !!is_valid ? "pointer" : "pointer"}
    `

    const ModalFooter = styled.div`
        display: flex;
        margin: 20px;
        justify-content: center;
    `
    return (
        <ModalContainer show={showModal}>
            <Modal>
                <ModalHeader>
                    <CloseButton src={closeImage} onClick={handleLogout}/>
                    <Title>
                        You Have Been Idle!
                    </Title>
                </ModalHeader>
                <Separator />
                <ModalBody>
                    You Will Get Timed Out. You want to stay?
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={handleLogout}
                        color={"#e74c3c"}
                    >
                        Logout
                    </Button>
                    <Button
                        onClick={handleRefresh}
                        color={"#2ecc71"}
                    >
                        Stay
                    </Button>
                </ModalFooter>
            </Modal>
        </ModalContainer>
    )
}