import React, { Component, Fragment } from 'react'
import TestCard from "components/customTestCard";
import SearchBarTable from "components/searchBarTable";
import GenericTableHeader from "components/tables/genericTableHeader";
import GenericTableRow from "components/tables/genericTableRow";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { tableHeaderSystemAdminToolsUserManagement } from 'helper/getTableHeader.js';
import { allUsersFiltered } from 'redux/reducers/UserManagementReducer';
import ModalInviteUser from './modalInviteUser'
import Swal from 'sweetalert2'
import * as userActions from 'redux/actions';
import * as actions from 'redux/reducers/UserManagementReducer';
import * as siteManagementActions from "redux/reducers/SiteManagementReducer";
import { getDateByLanguage } from "helper/dateByLanguage";
import Loading from 'components/loading';
import "./index.scss";

class UserManagement extends Component {

    state = {
        modalInviteUser: false,
        request: false
    }

    makeRequests = async () => {
        this.setState({
            request: true
        })
        await this.props.userActions.getUsers()
        await this.props.siteManagementActions.get_sites()
        this.setState({
            request: false
        })
    }

    componentWillUnmount() {
        this.props.actions.initialValues()
    }

    componentWillMount = async () => {
        await this.makeRequests()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.cardSelected != prevProps.cardSelected) {
            this.props.actions.toogleCheckBoxAllUsers(false)
            this.props.actions.toogleCheckBoxUniqUser([])
            this.props.actions.changePage(0)
        }
    }


    showAlert = async (message = null) => {
        let text = `You can't ${message ? message : 'delete'} your user!`
        await Swal.fire({
            text,
            type: 'warning',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        });
    }

    _toogleUniqLine = ({ id }) => {
        if (this.props.allUsersSelecteds) {
            this.props.actions.toogleCheckBoxAllUsers(!this.props.allUsersSelecteds)
        }
        this.props.actions.toogleCheckBoxUniqUser(this.props.usersSelecteds.includes(id) ? this.props.usersSelecteds.filter((i) => i !== id) : [...this.props.usersSelecteds, id])
    }

    _blockUser = async () => {
        const users = this.props.usersSelecteds;
        let userEqual = false;
        let arrayPromises = [];

        users.forEach(id => {
            const user = this.props.users.find(us => us.id == id);
            if (user.user_id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert('block')
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to block this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                const user = this.props.users.find(us => us.id == id);
                if (user.status == 'A') return arrayPromises.push(this.props.userActions.blockUser(user.user_id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    shouldShowBlockIcon = () => {

        if (!this.props.usersSelecteds.length) return false;
        const user = this.props.allUsersFiltered.allUsersFiltered.find((u) => u.id == this.props.usersSelecteds[0]);

        if (!user) return false;
        return user.status == 'A' && user.user_id != this.props.userData.user_id;
    }

    shouldShowUnblockIcon = () => {
        if (!this.props.usersSelecteds.length || !this.props.usersSelecteds.length > 1) return false;

        const user = this.props.allUsersFiltered.allUsersFiltered.find((u) => u.id == this.props.usersSelecteds[0]);
        if (!user) return false;
        return user.status == 'B' && user.user_id != this.props.userData.user_id;
    }

    _unblockuser = async () => {
        let arrayPromises = [];

        const users = this.props.usersSelecteds;

        let userEqual = false;

        users.forEach(id => {
            const user = this.props.users.find(us => us.id == id);
            if (user.user_id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert('unblock')
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to unblock this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                const us = this.props.users.find(us => us.id == id);
                return arrayPromises.push(this.props.userActions.unblockUser(us.user_id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    _deleteUser = async () => {
        let arrayPromises = [];

        const users = this.props.usersSelecteds;
        let userEqual = false;
        users.forEach(id => {
            const user = this.props.users.find(us => us.id == id);
            if (user.user_id == this.props.userData.user_id) {
                userEqual = true;
                return this.showAlert()
            }
        })

        if (userEqual) return false;

        const result = await Swal.fire({
            text: "Do you really want to delete this user?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        })
        if (result.value) {
            users.forEach((id) => {
                const us = this.props.users.find(us => us.id == id);
                return arrayPromises.push(this.props.userActions.deleteUser(us.user_id))
            })
        }
        await Promise.all(arrayPromises)

        await this.makeRequests()
    }

    inviteUser = async (values) => {
        if (values.id) {
            await this.props.userActions.editUser(values)
            await this.makeRequests()
            this.setState({
                modalInviteUser: false
            })
        } else {
            try {
                await this.props.userActions.inviteUser(values)
                await this.makeRequests()
                this.setState({ modalInviteUser: false })
            } catch (e) {
                let text = `Could not Save User. <br/>This user already has a Hemex Account.`
                await Swal.fire({
                    html: text,
                    type: 'warning',
                    showConfirmButton: false,
                    showCancelButton: false,
                    cancelButtonColor: '#d33',
                });
            }
        }
    }

    editUser = (row) => {
        if (row.status == 'A' || row.status == 'I') return this.setState({
            dataSelected: row,
            modalInviteUser: true
        })
    }

    checkAllRows = bool => {
        if (bool) {
            this.props.actions.toogleCheckBoxAllUsers(bool)
            return this.props.actions.toogleCheckBoxUniqUser(this.props.allUsersFiltered.allUsersFiltered.map((user) => user.id));
        }
        this.props.actions.toogleCheckBoxAllUsers(bool)
        return this.props.actions.toogleCheckBoxUniqUser([]);
    }

    sort = sortTitle => {
        if (!sortTitle) return false;
        if (this.props.sort == sortTitle) {
            return this.props.actions.setSortType(this.props.sort_type == "asc" ? "desc" : "asc");
        }
        this.props.actions.setSortType("asc")
        this.props.actions.setSort(sortTitle);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="user-management-container">
                <Loading show={this.state.request} />
                {
                    this.state.modalInviteUser &&
                    <ModalInviteUser
                        sitesOptions={this.props.sites}
                        open={this.state.modalInviteUser}
                        onClose={() => this.setState({
                            modalInviteUser: !this.state.modalInviteUser,
                            dataSelected: null
                        })}
                        save={(values) => this.inviteUser(values)}
                        data={this.state.dataSelected ? { ...this.state.dataSelected, id: this.state.dataSelected.user_id } : null}
                    />
                }
                <div className="tests-patient-container">
                    <TestCard
                        onClick={() => this.props.actions.toogleCard(2)}
                        leftValue={this.props.allUsersFiltered.active}
                        rightValue={t('label_SystemAdminUserManagement_CardActiveUsers')}
                        customTestCardClass={`border-green ${this.props.cardSelected ===
                            2}`}
                        customTestCardLeftValue={this.props.cardSelected === 2}
                        customTestRightValue={this.props.cardSelected === 2}
                    />
                    <TestCard
                        onClick={() => this.props.actions.toogleCard(5)}
                        leftValue={this.props.allUsersFiltered.inactive}
                        rightValue={t('label_Pending').toUpperCase()}
                        customTestCardClass={`border-yellow ${this.props.cardSelected ===
                            5}`}
                        customTestCardLeftValue={this.props.cardSelected === 5}
                        customTestRightValue={this.props.cardSelected === 5}
                    />
                    <TestCard
                        onClick={() => this.props.actions.toogleCard(4)}
                        leftValue={this.props.allUsersFiltered.updated}
                        rightValue={t('label_SystemAdminUserManagement_CardUpdatedUsers')}
                        customTestCardClass={`border-blue ${this.props.cardSelected ===
                            4}`}
                        customTestCardLeftValue={this.props.cardSelected === 4}
                        customTestRightValue={this.props.cardSelected === 4}
                    />
                    <TestCard
                        onClick={() => this.props.actions.toogleCard(1)}
                        leftValue={this.props.allUsersFiltered.blocked}
                        rightValue={t('label_SystemAdminUserManagement_CardBlockedUsers')}
                        customTestCardClass={`border-red ${this.props.cardSelected ===
                            1}`}
                        customTestCardLeftValue={this.props.cardSelected === 1}
                        customTestRightValue={this.props.cardSelected === 1}
                    />
                    <TestCard
                        onClick={() => this.props.actions.toogleCard(3)}
                        leftValue={this.props.allUsersFiltered.deactive}
                        rightValue={t('label_SystemAdminUserManagement_CardDeactivedUsers')}
                        customTestCardClass={`border-black ${this.props.cardSelected ===
                            3}`}
                        customTestCardLeftValue={this.props.cardSelected === 3}
                        customTestRightValue={this.props.cardSelected === 3}
                    />
                </div>
                <SearchBarTable
                    showPrinter={true}
                    hideFilter={true}
                    showBlock={[2, 4].includes(this.props.cardSelected) && this.props.usersSelecteds.length > 0}
                    showUnblock={[1].includes(this.props.cardSelected) && this.props.usersSelecteds.length > 0}
                    block={() => this._blockUser()}
                    unblock={() => this._unblockuser()}
                    placeHolder={t('Search for user...')}
                    router={this.props.router}
                    hideDelete={[3, 4, 1].includes(this.props.cardSelected) || this.props.usersSelecteds.length == 0}
                    delete={() => this._deleteUser()}
                    value={this.props.searchValue}
                    hidePlus={2 !== this.props.cardSelected}
                    add={() => this.setState({ modalInviteUser: !this.state.modalInviteUser })}
                    onChange={value => this.props.actions.searchUser(value)}
                    perPage={this.props.qty_page}
                    changeQtyPage={this.props.actions.changeQtyPage}
                    currentPage={this.props.currentPage}
                    changePage={this.props.actions.changePage}
                    totalPages={this.props.allUsersFiltered.totalPages}
                />
                <div className="pages-table-container">
                    <GenericTableHeader
                        sort_type={this.props.sort_type}
                        onClick={(index, sortTitle) => this.sort(sortTitle)}
                        cols={tableHeaderSystemAdminToolsUserManagement(t, this.props.sort)}
                        checkAction={bool => this.checkAllRows(bool)}
                    />
                    <div className="div_rows">
                        {this.props.allUsersFiltered.allUsersFiltered.map(row => {
                            let newRow = {
                                id: row.id,
                                status: row.status,
                                first_name: `${row.first_name} ${row.last_name}`,
                                email_address: row.email_address,
                                role: row.role,
                                site: row.site,
                                last_update: !!row.updated_at ? getDateByLanguage(row.updated_at, window.currentLanguage) : '-',
                                update_by: !!row.updated_by ? row.updated_by : '-'
                            }
                            return (<GenericTableRow
                                cols={tableHeaderSystemAdminToolsUserManagement(t)}
                                checksSelecteds={this.props.usersSelecteds}
                                allCheckSelected={this.props.allUsersSelecteds}
                                checkAction={id => this._toogleUniqLine(id)}
                                row={newRow}
                                onClick={() => this.editUser(row)}
                            />
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    sites: state.SiteManagementReducer.sites.map((site) => { return { ...site, value: site.name } }),
    allUsersFiltered: allUsersFiltered(state),
    qty_page: state.UserManagementReducer.QTY_PAGE,
    page: state.UserManagementReducer.PAGE,
    users: state.UserManagementReducer.USERS_DB,
    usersBlocked: state.UserManagementReducer.usersBlocked,
    usersActive: state.UserManagementReducer.usersActive,
    usersDeactived: state.UserManagementReducer.DEACTIVATED_USERS,
    usersUpdated: state.UserManagementReducer.UPDATED_USERS,
    allUsersSelecteds: state.UserManagementReducer.ALL_USERS_SELECTEDS,
    usersSelecteds: state.UserManagementReducer.USERS_SELECTEDS,
    cardSelected: state.UserManagementReducer.CARD_SELECTED,
    userData: state.UserReducer.userData,
    sort: state.UserManagementReducer.sort,
    sort_type: state.UserManagementReducer.sort_type,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    siteManagementActions: bindActionCreators(siteManagementActions, dispatch),
});

UserManagement = connect(mapStateToProps, mapDispatchToProps)(UserManagement);

export default withTranslation()(UserManagement)