export const enumUserStatus = {
    "active": "A",
    "terms": "C",
    "register": "R",
    "wizard": "W",
    "blocked": "B",
    "deactivated": "D",
}

export const edsStatus = {
    "invited": "invited",
}

class ValidateUserStatus {

    constructor() {
    }

    checkStatus(userStatus) {
        switch (userStatus) {
            case enumUserStatus.active:
            case enumUserStatus.terms:
            case enumUserStatus.register:
            case enumUserStatus.wizard:
                return true;

            default:
                throw ("label_userDeactivated")
        }

    }
}

export default () => {
    return new ValidateUserStatus()
}