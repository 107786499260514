import React from 'react';
import "./index.scss";

const Doubt = ({ header, subheader, img }) => {
    return (
        <div className="doubt">
            ?
            <div className="gaselleImageContainer">
                <div className="arrow" />
                <div className="gaselleDoubtHeader">
                    {header}
                </div>
                <div className="subheader">
                    {subheader}
                </div>
                {img && <img src={img} className="deviceNumber" />}
            </div>
        </div>
    )
}

export default Doubt;
