import { enumUserStatus } from "./status";
import { userPermission } from "./permissions";
import EmailValidate from "helper/emailValidate";
import PasswordValidate from "helper/passwordValidate";

class ValidateUser {
  constructor({ checkStatus, checkRedirect }) {
    this.checkStatus = checkStatus;
    this.checkRedirect = checkRedirect;
    this.requiredFieldsToRegister = [
      "password",
      "password_confirmation",
      "email",
      "site_name",
      "site_type",
      "user_name"
    ]
  }

  checkUser(userStatus, userRole, tokenStatus) {
    try {
      this.checkStatus(userStatus)
      return this.checkUserRedirect(userStatus, userRole, tokenStatus);
    } catch (e) {
      throw (e)
    }
  }

  checkUserStatus(userStatus) {
    try {
      this.checkStatus(userStatus)
    } catch (e) {
      throw (e)
    }
  }

  checkUserRedirect(userStatus, userRole, tokenStatus) {
    return this.checkRedirect(enumUserStatus, userPermission, userStatus, userRole, tokenStatus)
  }

  validateCredentials(email, password) {
    try {
      EmailValidate().validateEmail(email)
      PasswordValidate().validatePassword(password)
    } catch (e) {
      throw (e)
    }
  }

  validatePasswordIsValidAndMatch(password, confirmPassword) {
    try {
      PasswordValidate().validatePassword(password)
      PasswordValidate().validatePassword(confirmPassword)

      if (password == confirmPassword) return true;

      throw "password_notMatch"
    } catch (e) {
      throw (e)
    }
  }

  validateFieldsToRegisterAccount(fields) {
    try {
      this.validatePasswordIsValidAndMatch(fields.password, fields.password_confirmation);

      this.requiredFieldsToRegister.map(item => {
        if (fields.hasOwnProperty(item) && !fields[item].length) throw ("missing_required_fields")
      })
    } catch (e) {
      throw (e)
    }
  }
}

export default (params) => {
  return new ValidateUser(params)
}