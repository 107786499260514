import React, { Component } from 'react'
import "./index.scss"
export default class customErrorBox extends Component {

    render() {
        const {errorMessage} = this.props;
        return (
            <div className="customErrorBox">{errorMessage}</div>
        )
    }
}
