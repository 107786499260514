import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as actions from 'redux/actions';
import * as userManagementActions from 'redux/reducers/UserManagementReducer';
import { withTranslation } from 'react-i18next';
import { store } from 'redux/store';
import _ from 'lodash';
import Login from 'features/signIn/login';
import Routes from './Routes';
import ForgotPassword from 'features/user/signIn/forgotPassword';
import NewPassword from 'features/user/signIn/forgotPassword/newPassword';
import RegisterSite from 'features/user/signUp/registerSite';
import ConfirmSiteRegistered from 'features/user/signUp/registerSite/confirmRegister';
import RegisterConfirmed from 'features/user/signUp/registerSite/registerConfirmed';
import ConfirmInvite from 'features/user/invite/';
import DataView from 'features/dataView';
import EdsParticipation from 'features/admin/edsParticipation/';
import EdsTerms from 'features/admin/edsTerms';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PasswordExpired from '../pages/PasswordExpired';

class SiteUserRoutes extends React.Component {
    componentWillMount() {
        window.showAlert = ({ message, type }) => {
            return false;
        };

        const user = store.getState().UserReducer.userData;

        if (!!user && !!user.languageDefault && Object.keys(user).length > 0) {
            return this.props.i18n.changeLanguage(user.languageDefault);
        }

        const lng = navigator.language || navigator.userLanguage;

        switch (lng) {
            case 'pt-BR': {
                return this.props.i18n.changeLanguage('ptBR');
            }
            case 'en': {
                return this.props.i18n.changeLanguage('en');
            }
            default:
                this.props.i18n.changeLanguage('en');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;
        if (
            !Object.keys(user).length ||
            !user.languageDefault ||
            user.languageDefault == this.props.i18n.language ||
            prevProps.user.languageDefault == this.props.user.languageDefault
        ) {
            return false;
        }
        this.setLanguage();
    }

    setLanguage = () => {
        const { userData } = store.getState().UserReducer;

        if (
            !userData ||
            !userData.languageDefault ||
            userData.languageDefault == this.props.i18n.language
        )
            return false;

        window.currentLanguage = this.props.i18n.language;

        this.props.i18n.changeLanguage(userData.languageDefault);
    };

    render() {
        window.currentLanguage = this.props.i18n.language;
        const basename = process.env.REACT_APP_FRONT_URL;
        const { user } = this.props;
        return (
            <Switch>
                <Route
                    path={`/eds/terms/:id`}
                    exact
                    component={(props) => (
                        <EdsTerms
                            router={props}
                            type="accepted"
                            path="/eds/terms/:id"
                        />
                    )}
                />
                <Route
                    path={`/eds/accepted/:id`}
                    exact
                    component={(props) => (
                        <EdsParticipation
                            router={props}
                            type="accepted"
                            path="/eds/accepted/:id"
                        />
                    )}
                />
                <Route
                    path={`/eds/declined/:id`}
                    exact
                    component={(props) => (
                        <EdsParticipation
                            router={props}
                            type="declined"
                            path="/eds/declined/:id"
                        />
                    )}
                />
                <Route
                    path={`/login`}
                    exact
                    component={(props) => <Login {...props} />}
                />
                <Route
                    path={`/forgot-password`}
                    exact
                    component={ForgotPassword}
                />
                <Route
                    path={`/new-password`}
                    component={(props) => <NewPassword {...props} />}
                />
                <Route path={`/register`} exact component={RegisterSite} />
                <Route path={`/registro`} exact component={RegisterSite} />
                <Route path={`/การลงทะเบียน`} exact component={RegisterSite} />
                <Route path={`/रजिस्टर करें`} exact component={RegisterSite} />
                <Route path={`/Sinscrire`} exact component={RegisterSite} />
                <Route
                    path={`/confirm`}
                    exact
                    component={(props) => <ConfirmSiteRegistered {...props} />}
                />
                <Route
                    path={`/confirmed`}
                    exact
                    component={RegisterConfirmed}
                />
                <Route
                    path={`/confirmInvite`}
                    exact
                    component={ConfirmInvite}
                />
                <Route
                    path={`/confirmInvite/admin`}
                    exact
                    component={(props) => (
                        <ConfirmInvite isAdmin={true} {...props} />
                    )}
                />
                <Route path={`/password-expired`} exact component={(props) => <PasswordExpired {...props} />} />
                
                <Route path={`/verify`} exact component={DataView} />
                {!user ||
                    (!Object.keys(user).length && (
                        <Redirect
                            to={`${process.env.REACT_APP_FRONT_URL}/login`}
                        />
                    ))}
                <Routes
                    basename={basename}
                    user={user}
                    {...this.props}
                    getUsers={() => this.props.actions.getUsers()}
                    closeEditUser={() =>
                        this.props.userManagementActions.modalUser()
                    }
                    editUser={(values) =>
                        this.props.actions
                            .editUser(values)
                            .then(() =>
                                this.props.userManagementActions.modalUser()
                            )
                    }
                />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    let currentUSToEdit = null;

    const user = state.UserReducer.userData;

    const users = state.UserManagementReducer.USERS_DB;

    if (users.length) {
        const us = users.find((u) => u.user_id == user.user_id);
        currentUSToEdit = us;
    }
    return {
        user,
        users,
        currentUSToEdit,
        modalEditUser: state.UserManagementReducer.modalUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    userManagementActions: bindActionCreators(userManagementActions, dispatch)
});

SiteUserRoutes = connect(mapStateToProps, mapDispatchToProps)(SiteUserRoutes);

export default withTranslation()(SiteUserRoutes);
