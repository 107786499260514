import React, { Component, Fragment } from "react";
import "./index.scss";
import logout from "assets/img/header-new/exit_white_icon.png";
import person from "assets/img/header-new/user_placeholder.png"
import home from "assets/img/header-new/home_white_icon.png";
import devices from "assets/img/header-new/register_gazelle_white_add_site_icn.png";
import patients from "assets/img/header-new/patients_white_icon.png";
import cog from "assets/img/header-new/config_white_icon.png";
import { withRouter } from 'react-router-dom';
import logoutHelper from "helper/logoutHelper";
import catridges from "assets/img/header-new/header_cartridges.png";
import about from "assets/img/header-new/header_aboutthisscreen.png";
import doubt from 'assets/img/header-new/doubt_white_icon.png';
import help from "assets/img/header-new/header_help.png";
import comment_tips_icn from "assets/img/header-new/comment_tips_icn.png";
import info_tips_icn from "assets/img/header-new/info_tips_icn.png";
import lamp_tips_icn from "assets/img/header-new/lamp_tips_icn.png";
import homeOrange from "assets/img/header-new/home_orange_icon.png";
import devicesOrange from "assets/img/header-new/header_devices_selected.png";
import patientsOrange from "assets/img/header-new/header_patients_selected.png";
import cogOrange from "assets/img/header-new/header_settings_selected.png";
import { bindActionCreators } from 'redux'
import usaLanguage from 'assets/img/header-new/header_language_usa.png';
import brLanguage from 'assets/img/header-new/brazil_language_icn.png';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import DropDownOption from './dropDownOption'
import { UserActions } from 'redux/reducers';
import NoLogo from 'assets/img/placeholder_nosite.png';
import ModalFeedback from 'components/modalFeedBack'
import Faq from 'components/faq'

class Header extends Component {

  state = {
    dropDownLanguage: false,
    dropDownHelp: false,
    languageSelected: null,
    helpSelected: doubt,
    iconsList: {
      'en': usaLanguage,
      'ptBR': brLanguage,
    },
    ModalFeedback: false,
    faq: false
  }

  componentDidMount() {
    let IconLanguage = null;

    if (window.currentLanguage === 'ptBR') IconLanguage = brLanguage;

    if (window.currentLanguage === 'en') IconLanguage = usaLanguage;

    this.setState({
      languageSelected: IconLanguage
    })
  }

  getLinkSignUp = () => {
    return `${process.env.REACT_APP_FRONT_URL}/register`
  }

  _redirect = (url) => {

    if (this.props.location.pathname.includes('/admin/'))
      return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/admin${url}`)
    return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}${url}`)
  }

  getUserRoleTranslation = () => {
    if (this.props.userData.isAdmin == 2 && this.props.userData.isSupport != 2) {
      return this.props.t('label_hemex_admin')
    }
    return this.props.t('label_support_user')
  }

  getLanguageIcon = () => {
    if (this.props.userData.languageDefault == 'ptBR') {
      return brLanguage
    }
    return usaLanguage
  }

  getIconsAvaliable = () => {
    const keysAvaliable = Object.keys(this.state.iconsList).filter((icon) => icon != this.props.userData.languageDefault);
    let icons = []
    keysAvaliable.forEach((icon) => icons.push(this.state.iconsList[icon]));
    return icons;
  }

  render() {
    return (
      <Fragment>
        {this.state.ModalFeedback &&
          <ModalFeedback open={this.state.ModalFeedback} onClose={() => this.setState({ ModalFeedback: false })} />
        }
        {this.state.faq &&
          <Faq open={this.state.faq} onClose={() => this.setState({ faq: false })} />
        }
        <div className="headerAdmin">
          <div className="logo">
            Hemex Health
          </div>
          <div className="nav-container">
            <div className={"icons-container " + this.props.userData.isSupport}>
              <div className="icon-container" onClick={() => this._redirect('/')}>
                <img className={`icon ${this.props.location.pathname === '/'} `} src={this.props.location.pathname === '/admin/' ? homeOrange : home} />
              </div>
              {!this.props.userData.isSupport &&
                <div className="icon-container" onClick={() => this._redirect('/patients')}>
                  <img className={`icon ${this.props.location.pathname.includes('/patients')} `} src={this.props.location.pathname.includes('/patients') ? patientsOrange : patients} />
                </div>
              }
              <div className="icon-container" onClick={() => this._redirect('/devices')}>
                <img className={`icon ${this.props.location.pathname.includes('/devices')} `} src={this.props.location.pathname.includes('/readers') ? devicesOrange : devices} />
              </div>

              <div className="icon-container">
                <div className="separator" />
              </div>
              {!this.props.userData.isSupport &&
                <div className="icon-container" onClick={() => this._redirect('/system-admin')}>
                  <img className={`icon ${this.props.location.pathname.includes('/system-admin')} `} src={this.props.location.pathname.includes('/system-admin') ? cogOrange : cog} />
                </div>
              }
              <div className="icon-container language">
                <img className="icon languageSelected" src={this.getLanguageIcon()} onClick={() => this.setState({ dropDownLanguage: !this.state.dropDownLanguage })} />
                {this.state.dropDownLanguage &&
                  <DropDownOption
                    icons={this.getIconsAvaliable()}
                    selected={this.getLanguageIcon()}
                    clickOutSide={() => this.setState({ dropDownLanguage: !this.state.dropDownLanguage })}
                    open={this.state.dropDownLanguage}
                    set={(icon) => {
                      if (icon === usaLanguage) {
                        this.props.UserActions.updateUserLanguage('en', this.props.i18n)
                      };
                      if (icon === brLanguage) {
                        this.props.UserActions.updateUserLanguage('ptBR', this.props.i18n)
                      }
                    }
                    }
                  />
                }
              </div>
              {/* <div className="icon-container">
                <img className="icon" src={about} />
              </div> */}
              <div className="icon-container language">
                <img className="icon languageSelected" src={this.state.helpSelected} onClick={() => this.setState({ dropDownHelp: !this.state.dropDownHelp })} />
                {this.state.dropDownHelp &&
                  <DropDownOption
                    icons={[lamp_tips_icn, info_tips_icn, comment_tips_icn]}
                    selected={help}
                    clickOutSide={() => this.setState({ dropDownHelp: !this.state.dropDownHelp })}
                    open={this.state.dropDownHelp}
                    set={(icon) => {
                      if (icon == lamp_tips_icn) return window.location.href = `${process.env.REACT_APP_FRONT_URL}/admin/tutorial`
                      if (icon == info_tips_icn) {
                        return this.setState({
                          ModalFeedback: true,
                          dropDownHelp: false
                        })
                      }
                      return this.setState({
                        faq: true,
                        dropDownHelp: false
                      })
                    }}
                  />
                }
              </div>
            </div>
            <div className="user-container">
              <div className="user-container-image">
                <img src={person} className="user-image" />
              </div>
              <div className="info-person-container">
                <div className="info-person-name">{this.props.userData.name}</div>
                <div className="info-person-profission">{this.getUserRoleTranslation()}</div>
              </div>
            </div>
            <div className="logout" onClick={() => logoutHelper(this.props.UserActions)}>
              <img src={logout} className="logout-image cp" />
            </div>
          </div>
        </div>


      </Fragment>
    )
  }
}
const mapStateToProps = (state) => ({
  userLanguage: state.UserReducer.languageData,
  userData: state.UserReducer.userData
})

const mapDispatchToProps = (dispatch) => ({
  UserActions: bindActionCreators(UserActions, dispatch)
})

Header = connect(mapStateToProps, mapDispatchToProps)(Header);
Header = withRouter(Header);

export default withTranslation()(Header);
