
import { Route, Redirect } from "react-router-dom";
import React from 'react'

export default ({ userData, services, component: Component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => {
            return Object.keys(userData).length > 0 ? <Component {...userData} router={props} /> :
                <Redirect to={`${process.env.REACT_APP_FRONT_URL}/login`} />
        }} />

    )
}
