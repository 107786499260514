import React, { Component } from "react";
import PropTypes from "prop-types";
import emailIcon from 'assets/img/sign-in/email_icon.png';
import axios from 'helper/axiosHelper'
import "./index.scss";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UserActions } from 'redux/reducers';

import toastr from 'reactjs-toastr';
import 'reactjs-toastr/lib/toast.css'
import Loading from 'components/loading';

class BoxConfirmation extends Component {

    state = {
        readOnly: true,
        newEmail: "",
        id: '',
        email: '',
        loading: false
    }

    componentWillMount() {
        if (!this.props.data.id || !this.props.data.email) return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`);
    }

    _resend = () => {
        this.setState({ loading: true })
        this.props.resendEmail({ pre_register_id: this.props.data.id, email: this.state.newEmail.length > 0 ? this.state.newEmail : this.props.data.email })
            .then(() => {
                toastr.success('Success', '', { displayDuration: 2000 })
            })
            .catch(() => {
                toastr.error('Error', '', { displayDuration: 2000 })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    _changeEmail = () => {
        if (!this.state.readOnly) {
            this.setState({ loading: true })
            this.props.changeEmail({ email: this.state.newEmail.length > 0 ? this.state.newEmail : this.props.data.email, pre_register_id: this.props.data.id })
                .then(() => {
                    toastr.success('Success', '', { displayDuration: 2000 })
                })
                .catch(() => {
                    toastr.error('Error', '', { displayDuration: 2000 })
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        }
        this.setState({ readOnly: false }, () => {
            document.querySelector(".span-email").focus();
        })
    }

    render() {
        const { t } = this.props;

        return (
            <div className="site-registration-confirmation div-sign-in">
                <Loading show={this.state.loading} />
                <div className="div-white-sign">
                    <div className="div-label-sign">
                        {t('titleBox_SiteConfirmationRegistration')}
                    </div>
                    <div class="div-input">
                        <div className="label-register">
                            <div>{t('subTitleBoxFirstLine_RegisterSite')}</div>
                            <div>{t('subTitleBoxSecondLine_RegisterSite')}</div>
                        </div>

                        <div className="label-email displayFlex ali-center site-registration-email-container">
                            <img src={emailIcon} />
                            <input
                                type="email"
                                readOnly={this.state.readOnly}
                                className={`span-email ${this.state.readOnly}`}
                                onChange={(e) => this.setState({ newEmail: e.target.value })}
                                value={this.state.newEmail.length > 0 ? this.state.newEmail : this.props.data.email}
                            />
                        </div>

                        <div className="label-check mb75">
                            <div>{t('labelCheckEmailInboxFirstLine')}</div>
                            <div>{t('labelCheckEmailInboxSecondLine')}</div>
                        </div>
                        <div className="mt30 display-flex jc-space b-none">
                            <div className="fs16 sb">{t('labelDidntRecieveEmail')}</div>
                            <div className="fs14 cp bd1" onClick={() => this._resend()}>{t('labelResendEmail')}</div>
                            <div className="fs14 cp bd1" onClick={() => this._changeEmail()}>{t(`${this.state.readOnly ? 'labelChangeEmail' : 'SAVE'}`)}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.UserReducer.userData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resendEmail: values => dispatch(UserActions.resendEmail(values)),
        changeEmail: values => dispatch(UserActions.changeEmail(values)),
    }
}

BoxConfirmation = connect(mapStateToProps, mapDispatchToProps)(BoxConfirmation);

export default withTranslation()(BoxConfirmation)