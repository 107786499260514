class EmailValidate {
  constructor() {
    this.regexp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
  };

  validateEmail(email) {
    if(!this.regexp.test(email)){
      throw "invalid_email"
    }
    return true;
  }
}

export default () => {
  return new EmailValidate()
}