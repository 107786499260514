import axios from "axios";

import { store } from 'redux/store';

export const url = process.env.REACT_APP_BACK_URL

const instance = axios.create({
  baseURL: url
});

instance.interceptors.request.use(function (config) {

  if(config?.data?.refresh){
    config.headers.Authorization = `Bearer ${config.data.refresh}`
    return config;
  }
  const reducer = store.getState().UserReducer;

  if (reducer?.userData?.token){
    config.headers.Authorization = `Bearer ${reducer.userData.token}`
  }
  
  return config;
}, function (error) {
  if (error && error.hasOwnProperty('status') && error.status === 401 || error.response.status === 403) {
    return window.location.href = `${process.env.REACT_APP_FRONT_URL}/login`
  }
  return Promise.reject(error)
})


instance.interceptors.response.use(
  function (response) {
    return response.hasOwnProperty('data') ? response.data : response
  },
  function (error) {

    const errors = [401, 403]
    if (error && error.response && error.response.hasOwnProperty('status') && errors.includes(error.response.status)) {
      store.dispatch({ type: 'SET_INITIAL' })
      return window.location.href = `${process.env.REACT_APP_FRONT_URL}/login`
    }
    return Promise.reject(error);
  }
);

export default instance;
