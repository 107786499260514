import { createSelector } from "reselect";
import _ from "lodash";
import moment from "moment";
import { filterByDates } from "helper/filterByDates";
import { FILTRO_DATE_ENUM, ALL_SITES } from "helper/enum";

const initial = {
    READER_SELECTED: null,

    ...FILTRO_DATE_ENUM,

    SELECTED_SITE: {
        id: 0,
        value: "ALL SITES"
    },

    READER_ADMIN_CARD: 1,

    DEVICES_UTILIZATION: [
        {
            status: 'green',
            productNumber: '543872-12 GAZELLE',
            serialNumber: 12,
            siteName: 'DOCTOR OFFICE',
            reruns: 1,
            reused: 1,
            repeatTests: 0,
            cartridgesUsed: 34,
            mobileTests: 13,
            wifi: 138,
            pcLaptop: 1,
            swVersion: '2.34.22',
            lastUpdate: '05/08/2019'
        },
        {
            status: 'green',
            productNumber: '543872-12 GAZELLE',
            serialNumber: 12,
            siteName: 'DOCTOR OFFICE',
            reruns: 1,
            reused: 1,
            repeatTests: 0,
            cartridgesUsed: 34,
            mobileTests: 13,
            wifi: 138,
            pcLaptop: 1,
            swVersion: '2.34.22',
            lastUpdate: '05/08/2019'
        },
        {
            status: 'green',
            productNumber: '543872-12 GAZELLE',
            serialNumber: 12,
            siteName: 'DOCTOR OFFICE',
            reruns: 1,
            reused: 1,
            repeatTests: 0,
            cartridgesUsed: 34,
            mobileTests: 13,
            wifi: 138,
            pcLaptop: 1,
            swVersion: '2.34.22',
            lastUpdate: '05/08/2019'
        }
    ],

    readers: []
};

const SET_INITIAL_VALUES = "SET_INITIAL_VALUES";

const READERS_TOOGLE_READER = "READERS_TOOGLE_READER";

const READERS_TOOGLE_DATE_FILTER = "READERS_TOOGLE_DATE_FILTER";

const READERS_TOOGLE_SITE_FILTER = "READERS_TOOGLE_SITE_FILTER";

const READERS_TOGGLE_CARD = 'READERS_TOGGLE_CARD';

export const GET_READERS = "GET_READERS"

export const toogleReader = reader => ({ type: READERS_TOOGLE_READER, reader });

export const toogleDateFilter = value => ({
    type: READERS_TOOGLE_DATE_FILTER,
    value
});

export const toogleSitefilter = value => ({
    type: READERS_TOOGLE_SITE_FILTER,
    value
});

export const initialValues = () => ({
    type: SET_INITIAL_VALUES,
});

export const toggleCard = (id) => ({ type: READERS_TOGGLE_CARD, id })

const ReadersReducer = (state = initial, action) => {
    switch (action.type) {
        case READERS_TOOGLE_READER: {
            return {
                ...state,
                READER_SELECTED: { ...action.reader }
            };
        }
        case READERS_TOOGLE_DATE_FILTER: {
            return {
                ...state,
                SELECTED_DATE: action.value
            };
        }
        case READERS_TOOGLE_SITE_FILTER: {
            return {
                ...state,
                SELECTED_SITE: action.value
            };
        }
        case READERS_TOGGLE_CARD: {
            return {
                ...state,
                READER_ADMIN_CARD: action.id
            }
        }
        case GET_READERS: {
            return {
                ...state,
                readers: action.readers
            }
        }
        case SET_INITIAL_VALUES:{
            return initial;
        }
    }
    return state;
};

export const allReadersDB = state => state.ReadersReducer.readers;

export const selectedDate = state => state.ReadersReducer.SELECTED_DATE;
export const selectedSite = state => state.ReadersReducer.SELECTED_SITE;

export const getReadersFiltered = createSelector(
    allReadersDB,
    selectedDate,
    selectedSite,
    (readers, date, site) => {
        readers = _.chain(readers)
            .filter(reader =>
                _.isEmpty(date) ? reader : filterByDates(moment(reader.update).format("MM/DD/YYYY"), moment().subtract(date.id, "d").format("MM/DD/YYYY"))
            )
            .filter((reader) => _.isEmpty(site) ? reader : reader.site === site.id)
            .value()

        let readersInactive = _.sortBy(readers.filter((reader) => reader.status == "inactive"), "product_number");
        let readersActive = _.sortBy(readers.filter((reader) => reader.status == "active"), "product_number");
        let readersDiscarted = _.sortBy(readers.filter((reader) => reader.status == "discarted"), "product_number");
        let readersShipped = _.sortBy(readers.filter((reader) => reader.status == "shipped"), "product_number");
        return [...readersInactive, ...readersActive, ...readersDiscarted, ...readersShipped];
    }
);

export default ReadersReducer;
