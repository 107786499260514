const translations = {
    titleBox_RegisterSite: "WELCOME",
    subTitleBox_RegisterSite: "REGISTER YOUR SITE",
    placeHolderSite: "Site Name…",
    placeHolderTypeOfEntity: "Type of Entity",
    placeHolderSingleEntity: "Single Site Entity",
    placeHolderMultiEntity: "Multi-Site Entity",
    labelIsPrimarySite: "Is this the Primary Site (Corporate)?",
    boxErrorMustFirstRegisterPrimarySite: "You must first register the Primary Site",
    placeHolderContactName: "Contact Name…",
    placeHolderEmail_RegisterSite: "Email(username)...",
}

export default translations;