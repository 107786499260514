import axios from "helper/axiosHelper";
import FileDownload from "js-file-download"
export async function getDevicesCounters(date = 0) {
    try {
        return await axios.get('/admin/readers/counts', {
            params: { lastNDays: date }
        })
    } catch (e) {
        return await Promise.reject(e)
    }
}

export async function getAdminDevices(date = 0) {
    try {
        return await axios.get('/admin/readers', {
            params: { lastNDays: date }
        })
    } catch (e) {
        return await Promise.reject(e)
    }
}

export async function getReadersDeactivated(date) {
    return await axios.get('/admin/readers/deactivated', {
        params: { lastNDays: date }
    })
}

export async function getAuditLog(date, serial_number = null) {
    const response = await axios.get('/AuditLog/downloadall/' + serial_number, {
        params: { lastNDays: date },
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/x-tar'
        }
    })
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.tar'); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export const getReadersOfflineCounter = async () => {
    try {
        const readers = await axios.get('/reader/offline')
        return readers;
    } catch (e) {
        return await Promise.reject(e.response || null);
    }
}

export const getReadersCounter = async (date = 0, site = null) => {

    let params = {
        lastNDays: date
    }

    if (!!site) {
        params.site = site
    }

    const { counter } = await axios.get('/reader/count', { params })

    return counter
}

export const getReaders = async (date = 0, site = null) => {

    let params = {
        lastNDays: date
    }

    if (!!site) {
        params.site = site
    }

    try {

        const readers = await axios.get('/reader', {
            params
        })

        return readers;

    } catch (e) {
        await Promise.reject();
    }
}

export const deleteReader = async (id) => {
    try {
        await axios.delete('/reader',
            {
                data: {
                    ...id
                }
            }
        )
        return true;
    } catch (e) {
        window.showAlert({ type: "error" })
        await Promise.reject();
    }
}


export const createDevice = async (values) => {
    try {
        const device = await axios.post('/reader', {
            settings: {
                product_number: values.product_number,
                serial_number: values.serial_number,
            },
            ...values,
            entity_master_id: values.site_id
        })
        return device;
    } catch (e) {
        window.showAlert({ type: "error" })
        return await Promise.reject(e.response || null);
    }
}
