import React, { Component, Fragment } from "react";
import CardLeftTexts from "./cards/cardLeftTexts";
import CardRightTexts from "./cards/cardRightTexts";
import SearchBarTable from "components/searchBarTable";
import Status from "components/statusComponent";
import Check from "components/checkComponent";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as services from "redux/actions";
import * as actions from "redux/reducers/SiteManagementAdminReducer";
import { getHeader } from "redux/reducers/SiteManagementAdminReducer";
import {
  allSitesFiltered,
  filterDiseaseSelected,
  getEdsCounters,
  getParticipationCounters,
  getDiseasesCounter,
  getFilters,
} from "redux/reducers/SiteManagementAdminReducer";
import "./index.scss";
import Swal from "sweetalert2";
import Loading from "components/loading";
import ModalDownloadEdsFiles from "./modalEdsDownload";
import ModalEditSite from "./modalEditSite";
import ModalAddSiteAdmin from "./modalAddSiteAdmin";
import ModalEdsInvite from "./inviteEds";
import CustomTable from "components/customTable";
import * as systemAdminActions from "redux/reducers/SystemAdminReducer";
import * as siteManagementActions from "redux/reducers/SiteManagementReducer";
import Filters from "./Filters";
import _ from "lodash";

class SiteManagement extends Component {
  state = {
    diseases: [],
    modalAddSite: false,
    modalEditSite: false,
    dataSelected: null,
    request: false,
    modalDownloadEds: false,
    modalEdsInvite: false,
  };

  isAdmin = () => {
    return this.props.router.location.pathname.includes("/admin");
  };

  makeRequests = async () => {
    try {
      this.setState({
        request: true,
      });
      await this.props.siteManagementActions.get_sites_deactivated();
      await this.props.siteManagementActions.get_sites();
      const diseases = await this.props.services.getDiseases();
      this.setState({
        request: false,
        diseases,
      });
    } catch (e) {
      this.setState({ request: false });
    }
  };

  componentWillMount = async () => {
    await this.makeRequests();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cardSelected != prevProps.cardSelected) {
      this.props.actions.changePage(0);
    }
  }

  componentWillUnmount() {
    this.props.actions.initialvalue();
    this.props.systemAdminActions.get_counters_system_admin_admin();
  }

  hideDelete = () => {
    if (this.props.SitesSelecteds.length == 0) return true;

    const sites = this.props.SitesSelecteds;

    let hide = false;

    sites.forEach((id) => {
      const site = this.props.allSitesFiltered.allSitesFiltered.find(
        (site) => site.id == id
      );
      if (!site) return (hide = true);
      if (site.status != 2) return (hide = true);
    });

    return hide;
  };

  _handleCheckRow = (id) => {
    if (this.props.allSitesSelecteds) {
      this.props.actions.toogleCheckBoxAllSites(!this.props.allSitesSelecteds);
    }
    this.props.actions.toogleCard(1);
    this.props.actions.toogleCheckBoxUniqSite(
      this.props.SitesSelecteds.includes(id)
        ? this.props.SitesSelecteds.filter((i) => i !== id)
        : [...this.props.SitesSelecteds, id]
    );
  };

  showAlert = async (message = null) => {
    await Swal.fire({
      html: message ? message : "You can't delete your primary site!",
      type: "warning",
      showConfirmButton: true,
      showCancelButton: false,
    });
  };

  deleteSite = async () => {
    let arrayPromises = [];

    const sites = this.props.SitesSelecteds;

    let primarySelected = false;

    sites.forEach((id) => {
      const site = this.props.sites.find((site) => site.id == id);

      if (!site || !site.user) return this.showAlert("invalid site");

      if (site.user.id == this.props.data.user_id) {
        primarySelected = true;
        return this.showAlert();
      }
    });

    if (primarySelected) return false;

    const result = await Swal.fire({
      text: "Do you really want to delete this site?",
      type: "warning",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonColor: "#d33",
    });
    if (result.value) {
      sites.forEach((id) => {
        return arrayPromises.push(this.props.services.deleteSite(id));
      });
    }

    await Promise.all(arrayPromises);
    await this.makeRequests();
    this.props.actions.toogleCheckBoxAllSites(false);
    this.props.actions.toogleCheckBoxUniqSite([]);
  };

  _filterByTest = (value) => {
    this.props.actions.changePage(0);
    this.setState({ dataSelected: null });
    if (value == null || typeof value == "object")
      return this.props.actions.toogleCard(2);
    this.props.actions.filterByStatus("all");
    this.props.actions.filterByTestType(value);
    this.props.actions.toogleCheckBoxAllSites(false);
    this.props.actions.toogleCheckBoxUniqSite([]);
  };

  _filterByEds = (value) => {
    this.props.actions.changePage(0);
    this.setState({ dataSelected: null });
    if (value == null || typeof value == "object")
      return this.props.actions.toogleCard(3);
    this.props.actions.filterByStatus("all");
    this.props.actions.filterByEds(value);
    this.props.actions.toogleCheckBoxAllSites(false);
    this.props.actions.toogleCheckBoxUniqSite([]);
  };

  _filterByStatus = (value) => {
    this.props.actions.changePage(0);
    this.setState({ dataSelected: null });
    if (value == null || typeof value == "object")
      return this.props.actions.toogleCard(1);
    this.props.actions.filterByStatus(value);
    this.props.actions.toogleCheckBoxAllSites(false);
    this.props.actions.toogleCheckBoxUniqSite([]);
  };

  _filterByParticipation = (value) => {
    this.props.actions.changePage(0);
    this.setState({ dataSelected: null });
    if (value == null || typeof value == "object")
      return this.props.actions.toogleCard(4);
    this.props.actions.filterbyParticipation(value);
    this.props.actions.toogleCheckBoxAllSites(false);
    this.props.actions.toogleCheckBoxUniqSite([]);
  };

  editSite = (site) => {
    this.setState({
      dataSelected: site,
      modalEditSite: true,
    });
  };

  checkAllRows = (bool) => {
    if (bool) {
      this.props.actions.toogleCheckBoxAllSites(bool);
      return this.props.actions.toogleCheckBoxUniqSite(
        this.props.allSitesFiltered.allSitesFiltered.map((site) => site.id)
      );
    }
    this.props.actions.toogleCheckBoxAllSites(bool);
    return this.props.actions.toogleCheckBoxUniqSite([]);
  };

  showEds = () => {
    if (this.props.data.isAdmin != 2) return false;

    if (this.props.cardSelected != 1) return false;

    if (this.props.statusSelected != "all") return false;

    if (this.props.SitesSelecteds.length != 1) return false;

    const site = this.props.allSitesFiltered.allSites.find(
      (site) => site.id == this.props.SitesSelecteds[0]
    );

    if (!site || site.eds_status != null || site.status != 2) return false;

    return site.eds_status == null;
  };

  hidePlus = (site = null) => {
    if (this.props.data.isAdmin != 2) {
      return this.props.SitesSelecteds.length > 0;
    }

    if (!site) {
      if (this.props.SitesSelecteds.length > 1) return true;
      if (this.props.SitesSelecteds.length == 0) return false;
      site = this.props.allSitesFiltered.allSites.find(
        (site) => site.id == this.props.SitesSelecteds[0]
      );
    }

    switch (site.site_type) {
      case 1:
        return true;
      case 2: {
        if (!this.state.dataSelected || this.state.dataSelected.id != site.id) {
          this.setState({ dataSelected: site });
        }
        return false;
      }
      case null:
        const arrayString = site.parent;
        if (!arrayString || arrayString.length == 0) return true;
        const parentId = arrayString.split("] ")[0].substr(1);
        const parent = this.props.allSitesFiltered.allSites.find(
          (site) => site.id == parentId
        );
        return this.hidePlus(parent);
    }
  };

  getRows = () => {
    return this.props.allSitesFiltered.allSitesFiltered.map((row) => {
      return {
        ..._.omit(row, ["type", "parent"]),
        status: [
          <Status
            className={"Status"}
            color={row.status == 2 ? "#5dd33d" : "red"}
          />,
        ],
        site_primary: [
          <Check
            check={row.parent == "-"}
            className={"tdsite_primary"}
            color={row.parent == "-" ? "#5dd33d" : "red"}
          />,
        ],
        research_project: !row.project_type
          ? "-"
          : row.project_type == 1
          ? this.props.t("label_Research")
          : this.props.t("label_Pilot"),
      };
    });
  };

  sort = (sortTitle) => {
    if (!sortTitle) return false;
    if (this.props.sortCamp == sortTitle) {
      return this.props.actions.setSortType(
        this.props.sort_type == "asc" ? "desc" : "asc"
      );
    }
    this.props.actions.setSortType("asc");
    this.props.actions.setSortCamp(sortTitle);
  };

  getDiseases = () => {
    if (this.props.data.isAdmin == 2) {
      return this.state.diseases.filter(
        (disease) =>
          !!this.state.dataSelected.disease_types.find(
            (data) => data == disease.id
          )
      );
    }

    return this.state.diseases;
  };

  render() {
    const { t } = this.props;

    const allDiseases = [];

    this.props.allSitesFiltered.allSites.forEach((site) => {
      site.disease_types.forEach((disease) =>
        !allDiseases.includes(disease) ? allDiseases.push(disease) : ""
      );
    });

    const options = [
      {
        key: 0,
        value: `${this.props.diseasesCounter.both} ${t(
          "label_Both"
        ).toUpperCase()}`,
      },
      {
        key: 1,
        value: `${this.props.diseasesCounter.malaria} ${t(
          "label_Malaria"
        ).toUpperCase()}`,
      },
      {
        key: 2,
        value: `${this.props.diseasesCounter.hb} ${t(
          "label_Hb"
        ).toUpperCase()}`,
      },
    ];

    const leftValues = [];

    if (allDiseases.includes(1)) {
      leftValues.push(options[1]);
    }
    if (allDiseases.includes(2)) {
      leftValues.push(options[2]);
    }

    return (
      <Fragment>
        <Loading show={this.state.request} />
        {!!this.state.modalEdsInvite && (
          <ModalEdsInvite
            router={this.props.router}
            open={this.state.modalEdsInvite}
            onClose={() => this.setState({ modalEdsInvite: false })}
            send={async () => {
              this.setState({ request: true });
              await this.props.services.inviteEds(this.state.dataSelected.id);
              await this.makeRequests();
              this.setState({ modalEdsInvite: false, request: false });
            }}
            data={this.state.dataSelected}
          />
        )}
        {!!this.state.modalEditSite && (
          <ModalEditSite
            save={(formValues) => this.save("sites", formValues)}
            userData={this.props.data}
            token={this.props.data.token}
            onClose={(save) => {
              if (!!save && save == "save") {
                return Promise.all([
                  this.props.siteManagementActions.get_sites_deactivated(),
                  this.props.siteManagementActions.get_sites(),
                ]).then(() => this.setState({ modalEditSite: false }));
              }
              return this.setState({ modalEditSite: false });
            }}
            fieldsReadOnly={this.state.dataSelected ? ["name"] : []}
            diseases={this.getDiseases()}
            open={this.state.modalEditSite}
            data={this.state.dataSelected}
            site={
              this.props.data.status == "R"
                ? this.props.data.sites &&
                  this.props.data.sites.find((site) => site.status == 1)
                : null
            }
          />
        )}
        {!!this.state.modalDownloadEds && (
          <ModalDownloadEdsFiles
            router={this.props.router}
            open={true}
            onClose={() =>
              this.setState({
                modalDownloadEds: !this.state.modalDownloadEds,
              })
            }
            data={this.state.dataSelected}
          />
        )}
        {!!this.state.modalAddSite && (
          <ModalAddSiteAdmin
            router={this.props.router}
            open={this.state.modalAddSite}
            onClose={() =>
              this.setState({
                modalAddSite: !this.state.modalAddSite,
              })
            }
            data={this.state.dataSelected}
          />
        )}
        <div className="siteManagement">
          <div className="tests-patient-container">
            <CardRightTexts
              onClick={(value) => this._filterByStatus(value)}
              active={this.props.cardSelected === 1}
              filterActive={this.props.statusSelected}
              featuredRightValue={`${t(
                "label_SystemAdminSiteManagement_CardStatus"
              )}`}
              leftValue={this.props.allSitesFiltered.allSitesCounter}
              normalRightValues={[
                {
                  key: 1,
                  value: `${
                    this.props.allSitesFiltered.deactivated
                  } ${this.props.t("label_CardDeactivated")}`,
                },
              ]}
            />
            <CardLeftTexts
              active={this.props.cardSelected == 2}
              filterActive={this.props.diseaseSelected}
              onClick={(value) => this._filterByTest(value)}
              featuredRightValue={t("label_TestTypes").toUpperCase()}
              normalLeftValues={leftValues}
              leftValue={leftValues.length == 0 ? "0" : null}
            />
            <CardRightTexts
              active={this.props.cardSelected === 3}
              onClick={(value) => this._filterByEds(value)}
              featuredRightValue={`${t(
                "label_SystemAdminSiteManagement_CardEdsSites"
              ).toUpperCase()}`}
              leftValue={this.props.edsCounters.all}
              normalRightValues={[
                {
                  key: 0,
                  value: `${this.props.edsCounters.invited} ${this.props
                    .t("label_SystemAdminSiteManagement_CardEdsSubtitle")
                    .toUpperCase()}`,
                },
              ]}
              filterActive={this.props.filterEdsSelected}
              rightValueItemDiffColor={{ index: 0, color: "#F05324" }}
            />
            <CardLeftTexts
              active={this.props.cardSelected === 4}
              filterActive={this.props.filterByParticipation}
              onClick={(value) => this._filterByParticipation(value)}
              featuredRightValue={t(
                "label_SystemAdminSiteManagement_CardParticipation"
              )}
              normalLeftValues={[
                {
                  key: 0,
                  value: `${this.props.participationCounters.clinical} ${t(
                    "tableHeaderClinicalTesting"
                  ).toUpperCase()}`,
                },
                {
                  key: 1,
                  value: `${this.props.participationCounters.research} ${t(
                    "tableHeaderResearchProject"
                  ).toUpperCase()}`,
                },
                {
                  key: 2,
                  value: `${this.props.participationCounters.pilot} ${t(
                    "label_Pilot"
                  ).toUpperCase()}`,
                },
              ]}
            />
          </div>
          <SearchBarTable
            placeHolder={t("label_SearchForSites")}
            value={this.props.searchValue}
            add={() => {
              if (this.props.data.isAdmin == 2) {
                return this.setState({
                  modalAddSite: true,
                });
              }
              return this.setState({
                modalEditSite: true,
              });
            }}
            printRef={this.tableRef}
            showPrinter={true}
            showEds={this.showEds()}
            modalEds={() => this.setState({ modalEdsInvite: true })}
            hidePlus={this.hidePlus()}
            hideDelete={this.hideDelete()}
            delete={() => this.deleteSite()}
            router={this.props.router}
            onChange={(value) => this.props.actions.searchSite(value)}
            perPage={this.props.qty_page}
            changeQtyPage={this.props.actions.changeQtyPage}
            currentPage={this.props.currentPage}
            changePage={this.props.actions.changePage}
            totalPages={this.props.allSitesFiltered.totalPages}
            filterBox={
              <Filters
                filters={this.props.filters}
                toggleFilter={(filters) =>
                  this.props.actions.toggleFilters(filters)
                }
                selectSite={(option) =>
                  this.props.SubHeaderActions.setSite(option)
                }
              />
            }
          />
          <div className="pages-table-container">
            <CustomTable
              ref={(el) => (this.tableRef = el)}
              allChecked={this.props.allSitesSelecteds}
              rowCheckeds={this.props.SitesSelecteds}
              onClickHeader={(item, key, value) => {
                if (item.checked != "undefined") {
                  this.checkAllRows(value);
                }
              }}
              rowCheckAction={(id) => {
                const site = this.props.allSitesFiltered.allSitesFiltered.find(
                  (site) => site.id == id
                );
                const data = this.props.SitesSelecteds.includes(id)
                  ? null
                  : site;
                this.setState({ dataSelected: data });
                this._handleCheckRow(id);
              }}
              onSort={(item) => this.sort(item)}
              onClick={(row) => {
                const site = this.props.sites.find((site) => site.id == row.id);
                if (!site || site.status != 2) return;
                this.editSite(site);
              }}
              sort_type={this.props.sort_type}
              sortCamp={this.props.sortCamp}
              header={this.props.header}
              rows={this.getRows()}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  sites: state.SiteManagementReducer.sites,
  diseasesCounter: getDiseasesCounter(state),
  allSitesFiltered: allSitesFiltered(state),
  diseaseSelected: filterDiseaseSelected(state),
  allSitesSelecteds: state.SiteManagementAdminReducer.ALL_SITES_SELECTEDS,
  SitesSelecteds: state.SiteManagementAdminReducer.SITES_SELECTEDS,
  cardSelected: state.SiteManagementAdminReducer.CARD_SELECTED,
  qty_page: state.SiteManagementAdminReducer.QTY_PAGE,
  currentPage: state.SiteManagementAdminReducer.PAGE,
  statusSelected: state.SiteManagementAdminReducer.filterByStatus,
  edsCounters: getEdsCounters(state),
  participationCounters: getParticipationCounters(state),
  data: state.UserReducer.userData,
  header: getHeader(
    state.SiteManagementAdminReducer.ALL_SITES_SELECTEDS,
    props.t,
    state.SiteManagementAdminReducer.sortCamp
  ),
  filterEdsSelected: state.SiteManagementAdminReducer.filterByEDS,
  filterByParticipation: state.SiteManagementAdminReducer.filterByParticipation,
  sort_type: state.SiteManagementAdminReducer.sort_type,
  sortCamp: state.SiteManagementAdminReducer.sortCamp,
  filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
  systemAdminActions: bindActionCreators(systemAdminActions, dispatch),
  siteManagementActions: bindActionCreators(siteManagementActions, dispatch),
  services: bindActionCreators(services, dispatch),
  actions: bindActionCreators(actions, dispatch),
});

SiteManagement = connect(mapStateToProps, mapDispatchToProps)(SiteManagement);

export default withTranslation()(SiteManagement);
