const translations = {
    tableHeaderPrimarySite: "SITE PRIMARIO",
    tableHeaderCountry: "PAÍS",
    tableHeaderZip: "CÓDIGO POSTAL",
    tableHeaderPhoneNumber: "NÚMERO DE TELEFONE",
    tableHeaderAddress: "ENDEREÇO",
    tableHeaderCity: "CIDADE",
    tableHeaderState: "ESTADO",
    tableHeaderTestType: "TIPOS DE TESTES",
    tableHeaderPatientId: "PACIENTE ID",
    tableHeaderPatientIdEncrypted: "PACIENTE ID (Encryptado)",
    tableHeaderPatientName: "NOME DO PACIENTE",
    tableHeaderTestId: "TESTE ID",
    tableHeaderTestResult: "TESTE RESULT",
    tableHeaderDateTime: "DATA DO TESTE",
    tableHeaderLocation: "LOCALIZAÇÃO",
    tableHeaderTestReport: "TESTE REPORT",
    tableHeaderTestComments: "COMENTÁRIOS",
    tableHeaderName: "Nome",
    tableHeaderEmail: "E-mail",
    tableHeaderRole: "Regra",
    tableHeaderSite: "Site",
    tableHeaderSiteType: "TIPO DE SITE",
    tableHeaderReadersWithError: "Leitores com erro de log",
    tableHeaderReadersWithFirmwareMissmach: "Leitores com firmware incorreta",
    tableHeaderLastUpdatedDate: "DATA DA ULTIMA ATUALIZAÇÃO",
    tableHeaderLastUpdatedBy: "ULTIMA ATUALIZAÇÃO",
    tableHeaderProductNumber: "NÚMERO DO PRODUTO",
    tableHeaderSerialNumber: "SERIAL DO PRODUTO",
    tableHeaderSiteName: "NOME DO SITE",
    tableHeaderReRuns: "REFEITOS",
    tableHeaderReUsed: "REUTILIZADOS",
    tableHeaderRepeatTest: "TESTES\nREPETIDOS",
    tableHeaderCartridgesUsed: "CARTRIDGES USADOS",
    tableHeaderMobileTests: "TESTES MOBILES",
    tableHeaderWifi: "WI-FI",
    tableHeaderPcLaptop: "PC/LAPTOP",
    tableHeaderSWVersion: "VERSÃO DO SOFTWARE",
    tableHeaderLastUpdate: "ÚLTIMA ATUALIZAÇÃO",
    tableHeaderDate: "DATA",
    tableHeaderTime: "HORA",
    tableHeaderDOB: "DOB",
    tableHeaderTestResult: "RESULTADO",
    tableHeaderPerformedBy: "FEITO POR",
    tableHeaderType: "TIPO",
    tableHeaderQuantityOnHand: "QUANTIDADE",
    tableHeaderOrderDate: "ORDER DATE",
    tableHeaderQuantityOrdered: "QUANTIDADE PEDIDA",
    tableHeaderReceivedDate: "DATA RECEBIDA",
    tableHeaderQuantityReceived: "QUANTIDADE RECEBIDA",
}

export default translations;