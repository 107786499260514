import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import HeaderAdmin from 'features/admin/header';
import SubHeader from 'components/subHeader';
import SiteManagement from "pages/SiteManagement";
import DeviceManagement from "../deviceManagement";
import UserManagement from './userManagement';
import AdminManagement from './adminManagement';

import Card from './card';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import siteManagement from "assets/img/site_system_admin_bg_icn.png";
import profileManagement from "assets/img/profile_system_admin_bg_icn.png";
import devicesManagement from "assets/img/device_system_admin_bg_icn.png";
import dataManagement from "assets/img/data_system_admin_bg_icn.png";
import userManagementImage from 'assets/img/user_system_admin_bg_icn.png'
import plusRed from 'assets/img/plus_user_system_admin_icn.png'
import './index.scss'
import * as systemAdminActions from "redux/reducers/SystemAdminReducer";
import Swal from "sweetalert2"

class AdminView extends React.Component {
    state = {
        label: '',
    }

    makeRequests = async () => {
        await this.props.systemAdminActions.get_counters_system_admin_admin();
    }
    
    componentWillMount = async () => {
        await this.makeRequests();
    }
    
    render() {
        const { t, i18n } = this.props;
        const location = window.location.href;

        let labelLocation = ''
        let showIconPrint = false;

        if (location.includes('userManagement')) {
            labelLocation = `> ${t('title_SystemAdminTools_UserManagement')}`
            showIconPrint = false;
        }
        if (location.includes('siteManagement')) {
            labelLocation = `> ${t('title_SystemAdminTools_SiteManagement')}`
            showIconPrint = false;
        }
        if (location.includes('deviceManagement')) {
            labelLocation = `> ${t('title_SystemAdminTools_DeviceManagement')}`
            showIconPrint = false;
        }
        if (location.includes('/system-admin/admin')) {
            labelLocation = `> ${t('label_SystemAdminAdminManagement')}`
            showIconPrint = false;
        }

        return (
            <div className="sysadmin-adminview">
                <HeaderAdmin {...this.props} />
                <SubHeader
                    showPrint={showIconPrint}
                    title={
                        `${t('title_SystemAdminTools')} ${labelLocation}`}
                    page={"systemAdmin"}
                    onClick={() => window.location.href = `${process.env.REACT_APP_FRONT_URL}/admin/system-admin`}
                />
                <Middle {...this.props} t={t} counters={this.props.counters} adminUsers={this.props.counters.adminUsers} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    counters: state.SystemAdminReducer.counters
})

const mapDispatchToProps = (dispatch) => ({
    systemAdminActions: bindActionCreators(systemAdminActions, dispatch)
})

AdminView = connect(mapStateToProps, mapDispatchToProps)(AdminView);

export default withTranslation()(AdminView)

const Middle = ({ router, t, counters, adminUsers }) => {
    const location = router.location.pathname;
    if (location.includes('userManagement')) return <UserManagement router={router} />
    if (location.includes('/system-admin/admin')) return <AdminManagement router={router} />
    if (location.includes('siteManagement')) return <SiteManagement router={router} />

    if (location.includes('deviceManagement')) return <DeviceManagement router={router} />

    return (
        <div className="sysadmin displayFlex f-wrap">
            <div class="card-system-admin-tools">
                <Card
                    onClick={() => router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/userManagement`)}
                    leftValue={t('title_SystemAdminTools_UserManagement')}
                    rightValue={
                        [
                            <div className="value first">
                                {counters.users}
                            </div>,
                            <div className="value c-green sb">
                                {counters.users_active} {` ${t('label_SystemAdminCardUserManagementActive')}`}
                            </div>
                            ,
                            <div className="value c-red sb">
                                {counters.users_blocked} {` ${t('label_SystemAdminCardUserManagementBlocked')}`}
                            </div>,
                            <img src={plusRed} className="sysadmin-plusred cp" />
                        ]
                    }
                    t={t}
                    header={!!counters && counters.users_blocked > 0}
                    image={userManagementImage}
                />
            </div>
            <div class="card-system-admin-tools">
                <Card
                    leftValue={t('label_SystemAdminAdminManagement')}
                    onClick={() => router.history.push(`/admin/system-admin/admin`)}
                    rightValue={
                        [
                            <div className="value first">
                                {adminUsers.length}
                            </div>
                            ,
                        ]
                    }
                    type='profile'
                    backgroundSize={'100px !important'}
                    image={profileManagement}
                />
            </div>
            <div class="card-system-admin-tools">
                <Card
                    leftValue={t('label_SystemAdminCardSiteManagement')}
                    onClick={() => router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/siteManagement`)}
                    rightValue={
                        [
                            <div className="value first">
                                {counters.sites}
                            </div>
                            ,
                            <div className="value c-red sb">
                                {counters.sites_inactive} {` ${t('label_Inactive')}`}
                            </div>
                        ]
                    }
                    type='site'
                    style={{ disibility: 'hidden' }}
                    image={siteManagement}
                />
            </div>
            <div class="card-system-admin-tools">
                <Card
                    leftValue={t('label_DeviceManagement')}
                    onClick={() => router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/deviceManagement`)}
                    rightValue={
                        [
                            <div className="value first">
                                {counters.readers.readers_count}
                            </div>,
                            // <div className="value c-red sb">
                            //     -
                            //             </div>
                        ]
                    }
                    image={devicesManagement}
                />
            </div>
            {/* <div class="card-system-admin-tools">
                            <Card
                                leftValue={'DATA MANAGEMENT'}
                                rightValue={
                                    [
                                        <div className="value first">
                                            {counters.data_management}
                                        </div>
                                        ,
                                        <div className="value c-red sb">
                                            -
                                        </div>
                                    ]
                                }
                                image={dataManagement}
                            />
                        </div> */}
        </div>
    )
}