const initial = {
    selected_date: {
        id: 0,
        value: "ALL"
    },
    selected_site: {
        id: 0,
        value: "ALL SITES"
    },
    dates: {
        en: [
            {
                value: "ALL",
                id: 0
            },
            {
                value: "LAST 30 DAYS",
                id: 30
            },
            {
                value: "LAST 60 DAYS",
                id: 60
            },
            {
                value: "LAST 90 DAYS",
                id: 90
            },
            {
                value: "LAST 120 DAYS",
                id: 120
            }
        ],
        ptBR: [
            {
                value: "TODO PERIODO",
                id: 0
            },
            {
                value: "ÚLTIMOS 30 DIAS",
                id: 30
            },
            {
                value: "ÚLTIMOS 60 DIAS",
                id: 60
            },
            {
                value: "ÚLTIMOS 90 DIAS",
                id: 90
            },
            {
                value: "ÚLTIMOS 120 DIAS  ",
                id: 120
            }
        ]
    }
}

const types = {
    SELECT_DATE: "SELECT_DATE",
    SELECT_SITE: "SELECT_SITE"
}

export const setSite = payload => ({ type: types.SELECT_SITE, payload })

export const setDate = payload => ({ type: types.SELECT_DATE, payload })

const SubHeaderReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.SELECT_SITE: {
            return {
                ...state,
                selected_site: action.payload
            }
        }
        case types.SELECT_DATE: {
            return {
                ...state,
                selected_date: action.payload
            }
        }
    }
    return state
}

export default SubHeaderReducer