import axios from "helper/axiosHelper";
import { func } from "prop-types";

export async function sendTestReport(params) {
    try {
        return await axios.post(`/tests/send-email/`, params)
    } catch (e) {
        return await Promise.reject(e)
    }
}

export async function SignIn(params) {
    return await axios.post(`/login`, params)
}

export function refresh_token(refresh_token) {
    return axios.post('/refresh', { refresh: refresh_token })
}

export function sendFeedback(subject, comment) {
    return axios.post("/user/feedback", { subject, comment });
}

export function changePassword(password, token) {
    return axios.post("/changePassword", { password, token });
}

export function updateUserLanguage(params) {
    return axios.post("updateUserLanguage", { default_lang: params });
}

export function forgotPassword(email) {
    return axios.post("/forgotPassword", { email });
}

export function Register({ user_name, email, password, password_confirmation, site_name, site_type, default_lang }) {
    return axios.post('register', {
        user_name, email, password, password_confirmation, site_name, site_type, default_lang
    })
}

export function resetExpired(params) {
    return axios.post('/reset-expired', {...params})
}

export function resendEmail(pre_register_id) {
    return axios.post('/resend', { pre_register_id })
}

export function changeEmail(email, pre_register_id) {
    return axios.post('/changeEmail', {
        email,
        pre_register_id
    })
}