import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header/index";
import BoxLogin from "features/signIn/login/boxLogin";
import Footer from "components/footer";
import { connect } from "react-redux";
import * as actions from "redux/actions";
import { bindActionCreators } from "redux";

class Login extends Component {
    static propTypes = {
        prop: PropTypes
    };
    render() {
        return (
            <Fragment>
                <PageContainer page={'login'}>
                    <Header page={'login'} admin={this.props.match.url === '/admin/login'} />
                    <BoxLogin {...this.props} />
                </PageContainer>
                <Footer />
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch)
})

export default connect(null, mapDispatchToProps)(Login)
