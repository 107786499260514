import React, { Component } from "react";
import "./index.scss";
import onClickOutside from "react-onclickoutside";

class ModalTestComments extends Component {
  handleClickOutside = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <div className="modal-table-comments">
        <div className="arrowDownBoxComments"/>
        <div className="title-box">
          <div className="title">{this.props.title}</div>
        </div>
        <div className="commentsBox">{this.props.comment}</div>
      </div>
    );
  }
}
export default onClickOutside(ModalTestComments);
