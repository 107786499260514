import * as SiteManagement from "redux/services/SiteManagement";

const initial = {
    sites: [],
    sites_deactivated: [],
}

const types = {
    GET_SITES: "GET_SITES",
    GET_SITES_DEACTIVATED: "GET_SITES_DEACTIVATED"
}


const SiteManagementReducer = (state = { ...initial }, action) => {

    switch (action.type) {
        case types.GET_SITES: {
            return {
                ...state,
                sites: action.payload
            }
        }
        case types.GET_SITES_DEACTIVATED: {
            return {
                ...state,
                sites_deactivated: action.payload
            }
        }
    }
    return state
}

export const get_sites = (lastNDays = 0) => async (dispatch, getState) => {
    const sites = await SiteManagement.getSites(lastNDays)
    if (!!sites) {
        let value = {
            ...getState().UserReducer.userData,
            sites: sites.sort((a, b) => a.name - b.name),
            entityType: sites.find((site) => site.site_type == 2) ? 2 : 1
        };
        dispatch({ type: "SET_USER_DATA", value })
        dispatch({ type: types.GET_SITES, payload: sites.sort((a, b) => a.name - b.name) })
    }
}

export const get_sites_deactivated = (lastNDays = 0) => async (dispatch, getState) => {
    const sites = await SiteManagement.getSitesDeactivated(lastNDays)
    if (!!sites) {
        dispatch({ type: types.GET_SITES_DEACTIVATED, payload: sites})
    }
}

export default SiteManagementReducer