const translations = {
    label_AddNewReader: "ADD NEW READER",
    label_PowerUp: "POWER UP YOUR GAZELLE READER",
    label_ClinicalTest: "ENTER YOUR CLINICAL TEST SITE INFORMATION",
    label_MacValidation : "GAZELLE READER MAC ADDRESS VALIDATION",
    label_ConnectWifi: "CONNECT TO WIFI",
    label_TurnOn: "Turn on",
    label_GazelleReader: "Your Gazelle Reader",
    label_Press: "PRESS ",
    label_Settings: "SETTINGS",
    label_Icon: "ICON",
    label_Admin: "ADMIN",
    label_EnterLogin: "ENTER LOGIN",
    label_Credentials: "CREDENTIALS AND PASSWORD",
    label_ScrollButton: "SCROLL DOWN BUTTON",
    label_Once: "ONCE",
    label_AboutDevice: "ABOUT DEVICE",
    label_Button: "BUTTON",
    label_Identify: "IDENTIFY",
    label_MacAddress: "DEVICE MAC ADDRESS",
    label_EnterMac: "ENTER THE MAC ADDRESS",
    label_Shown: "SHOWN ON THIS READER",
}

export default translations;