import React, { Fragment } from 'react'
import './index.scss';
import HeaderUser from 'features/user/header'
import axios from 'helper/axiosHelper';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as services from 'redux/actions';
import { withTranslation } from "react-i18next";
import { matchPath, Redirect } from 'react-router-dom'

class Terms extends React.Component {

    _accept = async () => {
        const match = matchPath(this.props.router.history.location.pathname, {
            path: this.props.path,
            exact: true,
            strict: false
        })
        const hash = match.params.id;
        if(!hash) return false;
        // this.props.services.actionEds(hash, 'accept')
        // .then(() => this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/login`))

        this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/eds/terms/${hash}`)
    }

    _decline = async () => {
        const match = matchPath(this.props.router.history.location.pathname, {
            path: this.props.path,
            exact: true,
            strict: false
        })

        const hash = match.params.id;

        if(!hash) return false;

        this.props.services.actionEds(hash, 'denied')
        .then(() => this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}`))
    }

    _back = (url) => {
        const match = matchPath(this.props.router.history.location.pathname, {
            path: this.props.path,
            exact: true,
            strict: false
        })

        this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/eds/${url}/${match.params.id}`);
    }
    render() {
        return (
            <div className="participationPage">
                <HeaderUser {...this.props} page={'login'} />
                <div className="terms-container">
                    <div className="modal-terms">
                        <div className="modal-title">
                            EXTENDED DATA SHARE
                        </div>
                        <div className="margin-default">
                            <div className="termsText">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </div>
                        </div>
                        <div className="bottons displayFlex jc-space">
                            {this.props.type == 'declined' &&
                                <>
                                    <div className="back cp" onClick={() => this._back('accepted')}>BACK</div>
                                    <div
                                        className={`accept cp `}
                                        onClick={() => this._decline()}>OK
                                    </div>
                                </>
                            }
                            {this.props.type == 'accepted' &&
                                <>
                                    <div className="decline cp" onClick={() => this._back('declined')}>DECLINE TO PARTICIPATE</div>

                                    <div
                                        className={`accept cp `}
                                        onClick={() => this._accept()}>PARTICIPATE IN EDS
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.UserReducer.userData
})

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch),
})

Terms = connect(mapStateToProps, mapDispatchToProps)(Terms)

export default withTranslation()(Terms)