const translations = {
    titleBox_SiteConfirmationRegistration: "SITE REGISTRATION CONFIRMATION",
    subTitleBoxFirstLine_RegisterSite: "To continue your site registration, we need you to",
    subTitleBoxSecondLine_RegisterSite: "confirm the email address you provided:",
    labelCheckEmailInboxFirstLine: "Check your email inbox for a Hemex Health, ",
    labelCheckEmailInboxSecondLine: "registration message, and then click to confirm.",
    labelDidntRecieveEmail: "Didn’t Receive?",
    labelResendEmail: "RE-SEND",
    labelChangeEmail: "CHANGE EMAIL",
}

export default translations;