const translations = {

        /**
     * HEADER
     */

    label_SiteInformation: "INFORMAÇÕES DO SITE",
    label_RegisterGazelle: "ADICIONAR DISPOSITIVOS",
    label_AddMoreUsersToSite: "ADICIONAR MAIS USUARIOS",

    label_SingleSiteRegistration: "REGISTRO DO ÚNICO SITE",
    label_PrimarySite: "REGISTRO DO SITE PRINCIPAL",
    label_SateliteSite: "REGISTRO DO SITE SATELITE",
    label_SiteUpdate: "ATUALIZAÇÃO DO SITE",
    

    /**
    * REGISTER 3 STEPS ----  1 STEP
    * */
    headerPrimaryTitle: "SITE PRINCIPAL",
    headerRegisteredSitesTitle: 'SITES REGISTRADOS',
    buttonSave: "SAVE",
    buttonCancel: "CANCEL",
    titleHeader_WizardFirstStep: "PRIMARY SITE",
    inputRequiredField: "Oops, esse campo é obrigatorio",

    labelInputName: "NOME*",
    labelInputCountry: "PAÍS*",
    labelInputZip: "CÓDIGO POSTAL*",
    labelInputPhoneNumberArea: "+1*",
    labelInputPhoneNumber: "NÚMERO DE TELEFONE*",
    labelInputAddress: "ENDEREÇO*",
    labelInputCity: "CIDADE*",
    labelInputState: "ESTADO*",
    labelInputTestTypes: "TIPOS DE TESTE*",
    placeHolderInputName: "Nome...",
    placeHolderInputCountry: "País...",
    placeHolderInputZip: "Código postal...",
    placeHolderInputPhoneNumberArea: "+1",
    placeHolderInputPhoneNumber: "(000) 000-0000",
    placeHolderInputAddress: "Endereço...",
    placeHolderInputCity: "Cidade...",
    placeHolderInputState: "Estado...",

    /**
    * REGISTER 3 STEPS ----  2 STEP
    * */
    headerPrimaryTitle: 'REGISTER GAZELLE READERS',
    labelInputProductNumber: "PRODUCT NUMBER*",
    placeHolderProductNumber: "Numéro do produto...",
    labelInputSerialNumber: "SERIAL NUMBER*",
    placeHolderSerialNumber: "Número serial...",

    /**
     * REGISTER 3 STEPS ----  3 STEP
     * */
    headerPrimaryTitle: 'ADICIONAR MAIS USUARIOS PARA SEU SITE',
    labelInputFirstName: "NOME*",
    placeHolderInputFirstName: "Nome...",
    labelInputLastName: "SOBRENOME*",
    placeHolderInputLastName: "Sobrenome...",
    labelInputEmailAddress: "E-MAIL*",
    placeHolderEmailAddress: "E-Mail...",
    labelForWhichSites: "QUAIS SITES*",
}

export default translations;