import axios from "helper/axiosHelper";

export async function getSystemAdminCounters(date = 0) {
    try {
        return await axios.get('/admin/sites-status', {
            params: { lastNDays: date }
        })
    } catch (e) {
        return await Promise.reject(e)
    }
}

export async function getAdminUsers(){
    try {
        return await axios.get('/admin/users')
    } catch (e) {
        return await Promise.reject(e.response || null);
    }
};