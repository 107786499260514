import React, {Fragment} from 'react';

import BoxReaders from "../readersUserView/boxReaders"
import SubHeader from "components/subHeader";
import Header from "features/admin/header";
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash';
import BoxNoData from "components/boxNoData";
import AddDevice from '../../modalDevice/index'
import * as services from 'redux/actions'
import * as actions from "redux/reducers/ReadersReducer";
import Swal from 'sweetalert2'
import Loading from 'components/loading';
import {withTranslation} from 'react-i18next';
import './style.scss'
import queryString from 'query-string';

class ReadersAdminView extends React.Component {

    state = {
        sites: [],
        site_selected: (() => {
            const {id} = queryString.parse(this.props.router.location.search)
            return id;
        })(),
        child_selected: 0,
        childrens: [],
        addDevice: false,
        request: true,
        readers:[]
    }

    selectReader = (reader) => {
        this.props.actions.toogleReader(reader);
        this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/readers-details`)
    };

    getChildrens = async () => {
        const childrens = await this.props.services.getSitesAdmin(this.state.site_selected);
        this.setState({
            child_selected: 0,
            childrens: [{id: 0, value: this.props.t('label_AllSites')},...childrens.map((child) => {return{...child, value: child.name}})],
        })
    }

    getSites = async () => {
        let sites = await this.props.services.getSitesAdmin();
        this.setState({sites: sites.map((site) => {return {...site, value: site.name}})})
    }

    getReaders = async () =>{
        const readers = await this.props.services.getReadersAdmin(this.state.site_selected, this.state.child_selected == 0 ? 'ALL' : this.state.child_selected, this.props.filterDateSelected.id)
        this.setState({
            readers
        })
    }
    createReader = async (values) => {
        try{
            await this.props.services.createDevice(values)
            await this.getReaders()
            this.setState({addDevice: false})
        }catch(e){
            const text = !!e && e.data.message  ? e.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            return;
        }
    };

    showAlert = async () => {
        return await Swal.fire({
            text: "Are you sure you want to Delete?",
            type: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: '#d33',
        });
    }

    deleteReader = async (id) => {
        const {value} = await this.showAlert();
        if(!value) return false;
        await this.props.services.deleteReader({id})
        await this.getReaders()
    }

    componentWillMount = async () => {
        await this.getChildrens()
        await this.getReaders()
        await this.getSites();
        this.setState({request: false})
    }

    componentDidUpdate = async (prevProps, prevState) => {
        if(!_.isEqual(prevState.site_selected, this.state.site_selected)){
            this.setState({request: true})
            await this.getChildrens()
            await this.getReaders()
            return this.setState({request: false})
        }
        if(!_.isEqual(prevProps.filterDateSelected.id, this.props.filterDateSelected.id) || !_.isEqual(prevState.child_selected, this.state.child_selected)){
            this.setState({request: true})
            await this.getReaders()
            return this.setState({request: false})
        }
    }

    getSiteSelected = () => {
        const site = this.state.sites.find((site) => site.id == this.state.site_selected);
        if(!site) return {id: 0, value: "ALL SITES"};
        return site;
    }

    getChildSelected = () => {
        const site = this.state.sites.find((site) => site.id == this.state.child_selected);
        if(!site) return {id: 0, value: "ALL SITES"};
        return site;
    }

    render() {
        console.log(this.props, )
        return (
            <div className='admin-readers'>
                <Loading show={this.state.request}/>
                <Header
                    router={this.props.router}
                />
                {this.state.addDevice &&
                <AddDevice
                    entity_master_id={this.state.child_selected == 0 ? this.state.site_selected : this.state.child_selected}
                    admin={true}
                    open={this.state.addDevice}
                    sites={this.props.sites && this.props.sites.map((site) => {
                        return {...site, value: site.name}
                    })}
                    save={(values) => this.createReader(values)}
                    onClose={() => this.setState({addDevice: !this.state.addDevice})}
                />
                }
                <SubHeader
                    addReader={() => this.setState({addDevice: !this.state.addDevice})}
                    page={'/admin/readers'}
                    title={`READERS (${this.state.readers.length})`}
                    showChild={this.state.childrens.length > 2}
                    childSelected={this.getChildSelected()}
                    childOptions={this.state.childrens}
                    childOnchange={option => this.setState({child_selected: option.id})}
                    firstOptions={this.state.sites}
                    firstSelected={this.getSiteSelected()}
                    firstOnChange={option => this.setState({site_selected: option.id})}
                    secondOptions={this.props.filterDateOptions}
                    secondSelected={this.props.filterDateSelected}
                    secondOnChange={option =>
                        this.props.actions.toogleDateFilter(option)
                    }
                />
                {!this.state.readers.length && !this.state.request?
                    <BoxNoData
                        data={"NO READERS AVAILABLE"}
                        styles={{
                            margin: "50px 50px 0px 50px",
                            height: "calc(100vh - 226px)"
                        }}
                    />
                    :
                    <div className="div-padding">
                        {this.state.readers.map(reader => {
                            return (
                                <BoxReaders
                                router={this.props.router}
                                onClick={() => this.selectReader(reader)}
                                reader={reader}
                                t={this.props.t}
                                dateSelected={this.props.filterDateSelected}
                                deleteReader={(id) => this.deleteReader(id)}
                            />
                            )
                        })}
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    let allSites = !!state.UserReducer.userData && _.isArray(state.UserReducer.userData.sites) ? [...state.UserReducer.userData.sites.map((site) => {
        return {...site, value: site.name}
    })] : [];
    return {
        filterSiteOptions: allSites.length == 1 ? allSites : [{id: 0, value: props.t('label_AllSites')},...allSites].filter((site) => site.status != 3),
        filterSiteSelected: state.ReadersReducer.SELECTED_SITE.id == 0 ?  {id: 0, value: props.t('label_AllSites')} : state.ReadersReducer.SELECTED_SITE,
        filterDateOptions: state.ReadersReducer.FILTER_DATE[window.currentLanguage],
        filterDateSelected: state.ReadersReducer.SELECTED_DATE.id == 0 ? state.ReadersReducer.FILTER_DATE[window.currentLanguage][0] : state.ReadersReducer.SELECTED_DATE,
        readers: state.ReadersReducer.readers,
        sites: state.UserReducer.userData.sites.filter((site) => site.status != 3),
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
    services: bindActionCreators(services, dispatch),
})

ReadersAdminView = connect(mapStateToProps, mapDispatchToProps)(ReadersAdminView)

export default withTranslation()(ReadersAdminView)
