class PasswordValidate {
    constructor() {
        this.regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W\_])[a-zA-Z0-9\W\_]{8,15}$/
    };

    validatePassword(password) {
        if (!password || !password.length) {
            throw "missing_required_fields"
        }
        if (!this.regexp.test(password)) {
            throw "required_password_valid"
        }
        return true;
    }
}

export default () => {
    return new PasswordValidate()
}