import React, { Component } from "react";
import PropTypes from "prop-types";
import printer from "assets/img/header-new/table_print_icon.png";
import add from "assets/img/header-new/table_add_icon.png";
import { withTranslation } from 'react-i18next';
import arrowDown from "assets/img/header-new/dropdown_arrow.png";
import calendar from "assets/img/header-new/filter_calendar_icon.png";
import CustomSelectDiv from "components/customSelect/customSelectDiv";
import CloseGrey from 'assets/img/close_invite_user_bg_icn.png'
import AlertIcon from 'assets/img/alert_red_icn.png'

import "./index.scss";

class subHeaderSection extends Component {
    static propTypes = {
        prop: PropTypes
    };

    renderIcons() {
        const { page } = this.props;
        return (
            <>
                {!!page && page.includes("readers") &&
                    <div className="display-flex">
                        {/* <div className="second-section-printer-container">
                        <img src={printer} className="printer-icon cp" />
                    </div> */}
                        <div className="second-section-printer-container">
                            <img src={add} className="printer-icon cp" onClick={this.props.addReader} />
                        </div>
                    </div>
                }

                {this.props.showPrint &&
                    <div className="second-section-printer-container">
                        <img src={printer} className="printer-icon cp" />
                    </div>
                }
            </>
        )
    }

    render() {
        const { t, page } = this.props;
        return (
            <div className="subHeaderSection-container">
                <div className="first-section">
                    <div className={`first-section-title ${this.props.onClick ? 'cp' : ''}`}
                        onClick={!!this.props.onClick ? this.props.onClick : ''}>{this.props.title}</div>
                    {this.renderIcons()}
                </div>
                {
                    page !== "systemAdmin" &&
                    <div className="second-section">
                        {!!this.props.firstOptions &&
                            <div className="subHeader-select cp">
                                <CustomSelectDiv
                                    arrow={arrowDown}
                                    value={this.props.firstSelected.value}
                                    options={this.props.firstOptions}
                                    onChange={this.props.firstOnChange}
                                />
                            </div>
                        }
                        {page == '/admin/readers' && this.props.showChild &&
                            <div className="subHeader-select cp">
                                <CustomSelectDiv
                                    arrow={arrowDown}
                                    value={this.props.childSelected.value}
                                    options={this.props.childOptions}
                                    onChange={this.props.childOnchange}
                                />
                            </div>
                        }
                        <div className="subHeader-select cp">
                            <CustomSelectDiv
                                leftIcon={calendar}
                                arrow={arrowDown}
                                value={this.props.secondSelected.value}
                                options={this.props.secondOptions}
                                onChange={this.props.secondOnChange}
                            />
                        </div>
                    </div>
                }
                {!!this.props.readers_qty && parseInt(this.props.readers_qty) > 0 && this.props.show_alert &&
                    <div className='connect-readers'>
                        <div className='left-container'>
                            <div className='icon alert'>
                                <img src={AlertIcon} />
                            </div>
                            <a className='text'>
                                {t('label_ToStartUsingReaders', { qty: this.props.readers_qty })}
                            </a>
                            <a className='click' onClick={() => {
                                this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/readers`)
                            }}>
                                {t('label_ClickHereToProceed')}
                            </a>
                        </div>
                        <div className='right-container'>
                            <div className='icon close cp'>
                                <img src={CloseGrey} onClick={() => this.props.onCloseAlert()} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}


export default withTranslation()(subHeaderSection);

