import { createSelector } from "reselect";
import _ from "lodash";

import { FILTRO_DATE_ENUM, ALL_SITES } from "helper/enum";

const initial = {
	CARD_SELECTED: 2,

	...ALL_SITES,

	usersBlocked: 0,

	usersActive: 0,

	DEACTIVATED_USERS: 0,

	UPDATED_USERS: 0,

	...FILTRO_DATE_ENUM,

	SEARCH_USER: "",

	ALL_USERS_SELECTEDS: false,

	USERS_SELECTEDS: [],

	USERS_DB: [],

	PAGE: 0,

	QTY_PAGE: 10,
	sort: "status",
	sort_type: "asc",

	modalUser: false,
};

const SET_SORT_USER_MANAGEMENT = "SET_SORT_USER_MANAGEMENT";

const SET_SORT_TYPE_USER_MANAGEMENT = "SET_SORT_TYPE_USER_MANAGEMENT";

const COUNTER_USER_ACTIVE = "COUNTER_USER_ACTIVE";

const COUNTER_USER_BLOCKED = "COUNTER_USER_BLOCKED";

const COUNTER_USER_DEACTIVE = "COUNTER_USER_DEACTIVE";

const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS =
	"SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS";

const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER =
	"SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER";

const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER =
	"SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER";

const SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER =
	"SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER";

const SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE =
	"SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE";

const SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE =
	"SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE";

const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST =
	"SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST";

const SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES =
	"SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES";

const SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER =
	"SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER";

const SYSTEM_ADMIN_USER_MANAGEMENT_MODAL_USER =
	"SYSTEM_ADMIN_USER_MANAGEMENT_MODAL_USER";

export const modalUser = () => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_MODAL_USER,
});

export const toogleCheckBoxAllUsers = (bool) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS,
	bool,
});

export const toogleCheckBoxUniqUser = (arraySelecteds) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER,
	arraySelecteds,
});

export const searchUser = (value) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER,
	value,
});

export const toogleSiteFilter = (value) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER,
	value,
});

export const toogleDateFilter = (value) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER,
	value,
});

export const toogleCard = (id) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST,
	id,
});

export const changePage = (page) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE,
	page,
});

export const changeQtyPage = (qty) => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE,
	qty,
});

export const setSort = (payload) => ({
	type: SET_SORT_USER_MANAGEMENT,
	payload,
});

export const setSortType = (payload) => ({
	type: SET_SORT_TYPE_USER_MANAGEMENT,
	payload,
});

export const initialValues = () => ({
	type: SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES,
});

const UserManagementReducer = (state = initial, action) => {
	switch (action.type) {
		case SYSTEM_ADMIN_USER_MANAGEMENT_INITIAL_VALUES: {
			return initial;
		}

		case COUNTER_USER_ACTIVE:
			return {
				...state,
				usersActive: action.counter,
			};
		case COUNTER_USER_DEACTIVE:
			return {
				...state,
				DEACTIVATED_USERS: action.counter,
			};

		case "USER_MANAGEMENT_USERS":
			return {
				...state,
				USERS_DB: action.users,
			};
		case COUNTER_USER_BLOCKED:
			return {
				...state,
				usersBlocked: action.counter,
			};

		case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_ALL_USERS: {
			return { ...state, ALL_USERS_SELECTEDS: action.bool };
		}

		case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CHECK_UNIQ_USER: {
			return {
				...state,
				USERS_SELECTEDS: action.arraySelecteds,
			};
		}
		case SYSTEM_ADMIN_USER_MANAGEMENT_SEARCH_USER: {
			return {
				...state,
				SEARCH_USER: action.value,
			};
		}
		case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_CARD_TEST: {
			return {
				...state,
				PAGE: 0,
				USERS_SELECTEDS: [],
				ALL_USERS_SELECTEDS: false,
				CARD_SELECTED: action.id,
			};
		}

		case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_SITE_FILTER: {
			return { ...state, SELECTED_SITE: action.value };
		}

		case SYSTEM_ADMIN_USER_MANAGEMENT_TOOGLE_DATE_FILTER: {
			return {
				...state,
				SELECTED_DATE: action.value,
			};
		}

		case SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_PAGE: {
			return {
				...state,
				PAGE: action.page,
			};
		}
		case SYSTEM_ADMIN_USER_MANAGEMENT_CHANGE_QTY_PAGE: {
			return {
				...state,
				QTY_PAGE: action.qty,
			};
		}
		case SET_SORT_TYPE_USER_MANAGEMENT: {
			return {
				...state,
				sort_type: action.payload,
			};
		}
		case SET_SORT_USER_MANAGEMENT: {
			return {
				...state,
				sort: action.payload,
			};
		}

		case SYSTEM_ADMIN_USER_MANAGEMENT_MODAL_USER: {
			return {
				...state,
				modalUser: !state.modalUser,
			};
		}
	}
	return state;
};

export const qty_page = (state) => state.UserManagementReducer.QTY_PAGE;

export const page = (state) => state.UserManagementReducer.PAGE;

export const searchFilter = (state) => state.UserManagementReducer.SEARCH_USER;

export const allUsersDB = (state) => state.UserManagementReducer.USERS_DB;

export const allUsersSelecteds = (state) =>
	state.UserManagementReducer.ALL_USERS_SELECTEDS;

export const usersSelecteds = (state) =>
	state.UserManagementReducer.USERS_SELECTEDS;

export const cardSelected = (state) =>
	state.UserManagementReducer.CARD_SELECTED;

export const siteSelected = (state) =>
	state.UserManagementReducer.SELECTED_SITE;

export const dateSelected = (state) =>
	state.UserManagementReducer.SELECTED_DATE;

const sort = (state) => state.UserManagementReducer.sort;

const sort_type = (state) => state.UserManagementReducer.sort_type;

export const getTestsData = createSelector(allUsersDB, (users) => {
	return users.reduce(
		(initial, value) => {
			if (value.testId === 1 && value.testResult === "Positive") {
				initial.malaria.positive++;
			} else if (value.testId === 2 && value.testResult === "Positive") {
				initial.hb.positive++;
			}
			return initial;
		},
		{
			malaria: {
				tests: users.length,
				positive: 0,
			},
			hb: {
				tests: users.length,
				positive: 0,
			},
		}
	);
});

export const allUsersFiltered = createSelector(
	allUsersDB,
	searchFilter,
	cardSelected,
	qty_page,
	page,
	sort,
	sort_type,
	(users, searchFilter, cardSelected, qty_page, page, st, st_type) => {
		if (!users) return [];
		let usrs = users;
		const results = _.chain(usrs)
			.filter((user) =>
				searchFilter.length > 0
					? user.first_name
							.toLowerCase()
							.includes(searchFilter.toLowerCase()) ||
					  user.last_name
							.toLowerCase()
							.includes(searchFilter.toLowerCase())
					: user
			)
			.filter((user) => {
				if (!user.status) return false;

				if (cardSelected == 0) {
					return user;
				}
				if (cardSelected == 1) {
					return user.status == "B";
				}
				if (cardSelected == 2) {
					return user.status == "A";
				}
				if (cardSelected == 3) {
					return user.status == "D";
				}
				if (cardSelected == 4) {
					return !!user.updated_at;
				}
				return user.status == "I";
			})
			.orderBy(st, st_type)
			.value();
		const totalPages =
			(results.length / qty_page) % 1 != 0
				? Math.ceil(results.length / qty_page)
				: Math.ceil(results.length / qty_page);
		const init = page * qty_page < 0 ? 0 : page * qty_page;
		const end = totalPages == 0 ? results : init + qty_page;
		let arrayFormatado =
			totalPages == 1 ? results : results.slice(init, end);
		return {
			allUsersFiltered: arrayFormatado,
			counter: results.length,
			inactive: users.filter((user) => user.status == "I").length,
			active: users.filter((user) => user.status == "A").length,
			deactive: users.filter((user) => user.status == "D").length,
			blocked: users.filter((user) => user.status == "B").length,
			updated: users.filter((user) => !!user.updated_at).length,
			totalPages: parseInt(totalPages),
		};
	}
);

export default UserManagementReducer;
