import React, { Component, Fragment } from "react";
import CustomButton from "components/customButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PickerLogo from "components/pickerLogo";
import CustomInputComponent from "helper/formikComponents/customInput";
import CustomSelectComponent from "helper/formikComponents/customSelect";
import CustomSelectMultiple from "helper/formikComponents/customSelectMultiple";
import "./index.scss";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import SignupSchema from "./validations";
import CustomLabel from "components/customLabel";
import * as services from "redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import person from "assets/img/header-new/user_placeholder.png";

class Step3 extends Component {
	state = {
		roles: [],
	};

	componentWillMount() {
		this.props.services.getRoles().then((roles) =>
			this.setState({
				roles: roles
					.map((role) => {
						return { id: role.id, value: role.description };
					})
					.sort((a, b) => a.id - b.id),
			})
		);
	}

	getInitialValues = () => {
		if (!this.props.data) {
			return {
				disableRemove: false,
				first_name: "",
				last_name: "",
				email_address: "",
				photo: "",
				sites_opt:
					this.props.sitesOptions.length > 0
						? this.props.sitesOptions
						: [],
				sites:
					this.props.sitesOptions.length > 0
						? this.props.sitesOptions
						: [],
				roles: this.state.roles,
			};
		}
		return {
			disableRemove: this.props.data.disableRemove,
			first_name: this.props.data.first_name,
			last_name: this.props.data.last_name,
			photo: this.props.data.photo || "",
			email_address: this.props.data.email_address,
			sites_opt:
				this.props.sitesOptions.length > 0
					? this.props.sitesOptions
					: [],
			sites:
				this.props.data.sites && this.props.data.sites.length > 0
					? this.props.data.sites.map((i) => ({
							...i,
							value: i.name,
					  }))
					: this.props.sitesOptions.filter(
							(i) => i.id == this.props.data.site_id
					  ),
			role: this.state.roles.find(
				(role) => role.id == this.props.data.role_id
			),
			roles: this.state.roles,
		};
	};

	render() {
		const sameUser = this.props.hasOwnProperty("sameUser")
			? this.props.sameUser
			: this.props.data && this.props.data.id == this.props.user.user_id;
		const initialValues = this.getInitialValues();
		return (
			<Fragment>
				<Formik
					initialValues={initialValues}
					validationSchema={SignupSchema}
					onSubmit={(values, actions) => {
						let ids = values.sites.map((site) => site.id);
						const { photo_url } = values;
						if (this.props.data && this.props.data.id) {
							values = {
								id: this.props.data.id,
								firstname: values.first_name,
								lastname: values.last_name,
								emailaddress: values.email_address,
								entity_master_ids: values.sites.map(
									(i) => i.id
								),
								role_id: values.role.id,
								photo: values.photo,
							};
						} else {
							values = {
								firstname: values.first_name,
								lastname: values.last_name,
								emailaddress: values.email_address,
								entity_master_ids: ids,
								role_id: values.role.id,
								photo: values.photo,
							};
						}

						if (!!photo_url) {
							values.photo_url = photo_url;
						}

						this.props.save(values);
					}}
					enableReinitialize={true}
					render={({
						errors,
						isSubmitting,
						setFieldValue,
						values,
						initialValues,
					}) => {
						const { t } = this.props;
						return (
							<Form>
								<div className="divContainer inviteUser">
									{!!this.props.token && (
										<PickerLogo
											img={person}
											token={this.props.token}
											setFile={({ filename, url }) => {
												setFieldValue(
													"photo",
													filename
												);
												setFieldValue("photo_url", url);
											}}
											file={values.photo || ""}
										/>
									)}
									<div className={"displayFlex"}>
										<div className={"w33"}>
											<Field
												name="first_name"
												errorMessage={t(
													"inputRequiredField"
												)}
												component={CustomInputComponent}
												placeHolder={t(
													"placeHolderInputFirstName"
												)}
												label={t("labelInputFirstName")}
											/>
										</div>
										<div className={"w33"}>
											<Field
												name="last_name"
												errorMessage={t(
													"inputRequiredField"
												)}
												component={CustomInputComponent}
												placeHolder={t(
													"placeHolderInputLastName"
												)}
												label={t("labelInputLastName")}
											/>
										</div>
										<div className={"w33"}>
											<Field
												name="email_address"
												errorMessage={t(
													"inputRequiredField"
												)}
												component={CustomInputComponent}
												placeHolder={t(
													"placeHolderEmailAddress"
												)}
												label={t(
													"labelInputEmailAddress"
												)}
											/>
										</div>
									</div>
									<div className={"flex1 p10"}>
										<CustomLabel
											label={t("labelForWhichSites")}
										/>
										<Field
											placeHolder={t("Which site...")}
											name="sites"
											disableRemove={
												values["disableRemove"]
											}
											component={CustomSelectMultiple}
											errorMessage={t(
												"inputRequiredField"
											)}
											setFieldValue={setFieldValue}
											options={values["sites_opt"]}
											values={values["sites"]}
										/>
									</div>
									<div className={"displayFlex selectOption"}>
										<div className={"w33 heightDefault"}>
											<CustomLabel
												label={t("label_Roles")}
											/>
											<Field
												value={
													values.role
														? values.role.value
														: null
												}
												setFieldValue={setFieldValue}
												name="role"
												errorMessage={t(
													"inputRequiredField"
												)}
												component={
													CustomSelectComponent
												}
												placeHolder={t("label_Roles")}
												label={t("label_Roles")}
												options={values.roles}
												disabled={!!sameUser}
											/>
										</div>
									</div>
									<div
										className={
											"displayFlex jc-space selectOption"
										}
									>
										<div className={"w30 textLeft"}>
											<CustomButton
												type={"button "}
												className={"buttonCancel"}
												value={t("label_Cancel")}
												handleChange={
													this.props.handleChangePrev
												}
											/>
										</div>
										{!_.isEqual(initialValues, values) && (
											<div className={"w30 textRight"}>
												<CustomButton
													type={"button "}
													className={"buttonNext"}
													value={t("label_Save")}
												/>
											</div>
										)}
									</div>
								</div>
							</Form>
						);
					}}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.UserReducer.userData,
});

const mapDispatchToProps = (dispatch) => ({
	services: bindActionCreators(services, dispatch),
});

Step3 = connect(mapStateToProps, mapDispatchToProps)(Step3);

export default withTranslation()(Step3);
