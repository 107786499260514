import React from "react";
import { connect } from "react-redux";

import { StyleSheet, css } from "aphrodite";

import leftArrow from "assets/img/header-new/pagination_arrow_left.png";

import rightArrow from "assets/img/header-new/pagination_arrow_right.png";

import arrowDown from "assets/img/header-new/dropdown_arrow.png";

class PaginationTable extends React.Component {
	static defaultProps = {
		actionPerPage: "",
	};

	state = {
		currentPage: 0,
		totalPages: 0,
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.totalPages != prevState.totalPages) {
			this.setState({ totalPages: this.props.totalPages });
		}

		if (
			!!this.props.currentPage &&
			this.state.currentPage != this.props.currentPage
		) {
			this.setState({ currentPage: this.props.currentPage });
		}
	}

	prevAction = () => {
		const currentPage = this.state.currentPage;
		if (currentPage == 0) return false;
		this.setState({ currentPage: currentPage - 1 });
		this.props.changePage(currentPage - 1);
	};

	nextAction = () => {
		const currentPage = this.state.currentPage;
		if (currentPage + 1 >= this.state.totalPages) return false;
		this.setState({ currentPage: currentPage + 1 });
		this.props.changePage(currentPage + 1);
	};

	customAction = (val) => {
		this.setState({ currentPage: val });
		this.props.changePage(val);
	};

	renderPerPage = () => {
		const { changePage = null, perPage = "50", changeQtyPage } = this.props;
		if (changePage != null) {
			return (
				<div className={css(styles.divSelectPagination)}>
					<select
						name="perpage"
						className={css(styles.paginationSelect)}
						onChange={(e) => changeQtyPage(e.currentTarget.value)}
						value={perPage}
					>
						<option
							selected={perPage == "10" ? true : false}
							value="10"
						>
							10
						</option>
						<option
							value="25"
							selected={perPage == "25" ? true : false}
						>
							25
						</option>
						<option
							value="50"
							selected={perPage == "50" ? true : false}
						>
							50
						</option>
						<option
							selected={perPage == "100" ? true : false}
							value="100"
						>
							100
						</option>
					</select>
					<div className={css(styles.divPaginationArrow)}>
						<img
							src={arrowDown}
							alt="pagination"
							title="pagination"
							className={css(styles.divImagePagination)}
						/>
					</div>
				</div>
			);
		}
	};

	renderPageByItem = (i) => {
		return (
			<div
				key={i}
				className={
					i == this.state.currentPage
						? css(styles.itenPaginationActive)
						: css(styles.itenPagination)
				}
				onClick={() => this.customAction(i)}
			>
				{i + 1}
			</div>
		);
	};

	renderPages = () => {
		let pages = [];

		const currentPage = this.state.currentPage;

		const diffBetween = this.state.totalPages - currentPage;

		if (currentPage < 3 || this.state.totalPages <= 5) {
			const max = this.state.totalPages;
			for (let i = 0; i < max; i++) {
				const page = this.renderPageByItem(i);
				pages.push(page);
			}
		} else if (this.state.totalPages > 5 && currentPage >= 3) {
			if (diffBetween > 2) {
				for (let i = currentPage - 2; i < currentPage; i++) {
					const page = this.renderPageByItem(i);
					pages.push(page);
				}

				[currentPage, currentPage + 1, currentPage + 2].forEach((i) => {
					const page = this.renderPageByItem(i);
					pages.push(page);
				});
			} else {
				for (
					let i = this.state.totalPages - 5;
					i < this.state.totalPages;
					i++
				) {
					const page = this.renderPageByItem(i);
					pages.push(page);
				}
			}
		}
		return pages.map((val) => val);
	};

	renderBlock = () => {
		if (this.props.page !== "notes") {
			if (this.state.totalPages > 1) {
				return (
					<div className={css(styles.pagination)}>
						<img
							title="Previous"
							alt="left"
							src={leftArrow}
							className={css(styles.arrow)}
							onClick={() => this.prevAction()}
						/>
						{this.renderPages()}
						<img
							title="Next"
							alt="right"
							src={rightArrow}
							className={css(styles.arrow)}
							onClick={() => this.nextAction()}
						/>
						{this.renderPerPage()}
					</div>
				);
			} else {
				return (
					<div
						className={css(styles.pagination, styles.marginNoPages)}
					>
						{this.renderPerPage()}
					</div>
				);
			}
		} else {
			return <div className={css(styles.pagination)} />;
		}
	};

	render() {
		//console.log('PAGE HERE', this.props.page);
		return this.renderBlock();
	}
}

export default PaginationTable;
// {
// const titles = getChilds(items)
// Object.keys(titles).map((title) => {
//     //titlebox
//     return titles[title].map((item) => {
// //childs
//     } ))
// })
// }

const styles = StyleSheet.create({
	alignRight: {
		textAlign: "right",
	},
	icons: {
		marginTop: "5px",
		marginBottom: "5px",
		marginLeft: "40px",
	},
	titlePage: {
		color: "#fff",
		fontSize: "20px",
		textTransform: "uppercase",
		paddingTop: "10px",
	},
	titleSmallPage: {
		color: "#fff",
		fontSize: "14px",
		textTransform: "uppercase",
		paddingTop: "0px",
	},
	ui: {
		modal: {
			bottom: "inherit",
			right: "inherit",
		},
	},
	grid30: {
		width: "30%",
		backgroundColor: "#4E4E4E",
		padding: "15px",
		color: "#fff",
		boxShadow: "inset 0px 6px 15px -8px rgba(0,0,0,0.45)",
	},
	grid70: {
		width: "70%",
		backgroundColor: "#747474",
		padding: "9px",
		color: "#fff",
		display: "flex",
		boxShadow: "inset 0px 6px 15px -8px rgba(0,0,0,0.45)",
	},
	row: {
		paddingBottom: "15px",
	},
	btn: {
		fontSize: "14px",
		fontFamily: "Roboto, sans-serif",
		fontWeight: "400",
		padding: "9px 10px 8px",
		borderRadius: "0",
		width: "100%",
	},
	btnBlue: {
		color: "white",
		background: "#1e88e5",
		height: "38px",
		":hover": {
			background: "#176ab3",
		},
	},
	select: {
		fontSize: "14px",
		borderRadius: "0px",
	},
	inputsearch: {
		backgroundColor: "transparent !important",
		boxShadow: "transparent !important",
		border: "transparent !important",
		width: "100%",
		fontSize: "14px",
		outline: "none",
	},
	pagination: {
		width: "65%",
		display: "flex",
		alignItems: "center",
	},
	divIcons: {
		width: "50%",
		textAlign: "right",
		paddingRight: "4px",
	},
	marginNoPages: {
		marginRight: "150px",
	},
	link: {
		marginLeft: "30px",
	},
	barWhite: {
		marginLeft: "30px",
		borderLeft: "2px solid #fff !important",
	},
	itenPaginationActive: {
		color: "#999",
		backgroundColor: "#FFF",
		padding: "0px",
		height: "28px",
		width: "25px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		marginRight: "10px",
		cursor: "pointer",
	},
	itenPagination: {
		backgroundColor: "rgba(255,255,255,.6)",
		padding: "0px",
		height: "28px",
		width: "25px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		marginRight: "10px",
		cursor: "pointer",
	},
	arrow: {
		marginRight: "10px",
		cursor: "pointer",
	},
	paginationSelect: {
		backgroundColor: "#54565B",
		border: "0px",
		outline: "none",
		minWidth: "60px",
		height: "24px",
		color: "#fff",
	},
	divSelectPagination: {
		display: "inline-block",
		border: "1px solid #fff",
		marginLeft: "10px",
		position: "relative",
	},
	divPaginationArrow: {
		position: "absolute",
		top: "0px",
		right: "0px",
		height: "100%",
		backgroundColor: "#54565B",
		paddingLeft: "5px",
		paddingRight: "5px",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		paddingTop: "4px",
	},
	divImagePagination: {
		width: "15px",
		backgroundColor: "#54565B",
		cursor: "pointer",
	},
});
