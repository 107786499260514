import * as UserService from '../services/User';
import axios from 'helper/axiosHelper';

import ValidateUserFactory from "helper/validateUser";
import ValidateUserStatusFactory from "helper/status";
import RedirectManagerFactory from "helper/redirectManager";

import usaLanguage from 'assets/img/header-new/header_language_usa.png'

import _ from "lodash";

const initialState = {
    languageData: {
        icon: usaLanguage,
        language: "en",
    },
    loader: false,
    userData: {},
    closedAlert: false,
}

const types = {
    CHANGE_LANGUAGE_DATA: "CHANGE_LANGUAGE_DATA",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    SET_USER_DATA: "SET_USER_DATA",
    SET_LOADER: "SET_LOADER",
    SET_INITIAL: "SET_INITIAL",
    SET_ALERT: "SET_ALERT",
    SET_LOGO: "SET_LOGO"
}

const UserReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case types.CHANGE_LANGUAGE_DATA:
            return { ...state, languageData: action.value };
        case types.SET_USER_DATA:
            return {
                ...state,
                userData: action.value
            };
        case types.SET_LOADER:
            return {
                ...state,
                loader: action.value
            };
        case types.SET_ALERT:
            return {
                ...state,
                alert: action.value
            };

        case types.SET_ROUTER:
            return {
                ...state,
                router: action.router
            };

        case types.SET_LOGO:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    entity_logo: action.payload
                }
            };
        case types.SET_INITIAL:
            return initialState;

        case 'SET_DEACTIVATED': {
            return {
                ...state,
                userData: {
                    ...state.userData,
                    sitesDeactivated: action.sitesDeactivated
                }
            };
        }

        case 'CLOSED_ALERT': {
            return {
                ...state,
                closedAlert: action.payload
            };
        }
    }
    return state;
};

function loginUserFactory(user) {
    return {
        sites: [],
        ...user,
        isSupport: user.isSupport == 2
    }
}

function setDefaultToken(token) {
    axios.defaults.headers.common['Authorization'] =
        'Bearer ' + token;
}

function makeValidateUser() {
    return ValidateUserFactory({ checkStatus: ValidateUserStatusFactory().checkStatus, checkRedirect: RedirectManagerFactory().redirect, });
}

export const sendTestReport = (params) => async (dispatch) => {
    try {
        return await UserService.sendTestReport(params);
    } catch (e) {
        await Promise.reject('error to send the test report');
    }
};

export const feedback = (subject, comment) => async (dispatch) => {
    try {
        return await UserService.sendFeedback(subject, comment);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const SignIn = ({ email, password, keepSign, }) => async (dispatch) => {
    try {

        const ValidateUser = makeValidateUser()

        ValidateUser.validateCredentials(email, password);

        const userData = await UserService.SignIn({
            username: email,
            password: password,
            keep_me_in: keepSign
        });

        const action = ValidateUser.checkUser(userData.status, userData.isAdmin, userData.token_status);

        const user = loginUserFactory({ ...userData, keepSign });

        setDefaultToken(userData.token);

        dispatch({ type: types.SET_USER_DATA, value: user });

        window.location.href = action;

    } catch (err) {
        console.log("ERROR SIGN", err);
        return _.isString(err) ? await Promise.reject(err) : await Promise.reject("wrong_password")
    }
};

export const setInitialUser = () => ({ type: types.SET_INITIAL })

export const refresh_token = (refreshToken) => async (dispatch, getState) => {
    try {
        const { refresh_token, token } = await UserService.refresh_token(refreshToken);
        const user = getState().UserReducer.userData;
        dispatch({ type: types.SET_USER_DATA, value: { ...user, refresh_token, token } });
    } catch (e) {
        throw Error("Caiu!")
    }
}

export const setSiteLogo = (payload) => ({ type: types.SET_LOGO, payload });

export const setUser = value => ({ type: types.SET_USER_DATA, value })

export const updateUserLanguage = params => async (dispatch, getState) => {
    await UserService.updateUserLanguage(params);
    const { userData } = getState().UserReducer
    dispatch({ type: types.SET_USER_DATA, value: { ...userData, languageDefault: params } });
}

export const changePassword = (new_password, new_password_confirmation, token) => async (dispatch) => {
    try {
        const ValidateUser = makeValidateUser()

        ValidateUser.validatePasswordIsValidAndMatch(new_password, new_password_confirmation);

        const user = await UserService.changePassword(new_password, token)

        dispatch({ type: types.SET_USER_DATA, value: user });

        window.location.href = "/"
    } catch (e) {
        await Promise.reject(_.isString(e) ? e : "Unable to change the password")
    }
}

export const forgotPassword = (email) => async (dispatch) => {
    try {
        return await UserService.forgotPassword(email)
    } catch (err) {
        return Promise.reject(err);
    }
};

export const Register = (params) => async (dispatch, getState) => {
    try {

        const ValidateUser = makeValidateUser()

        ValidateUser.validateFieldsToRegisterAccount(params);

        const { pre_register_id } = await UserService.Register(params)

        const user = {
            ...getState().UserReducer.userData,
            email: params.email,
            id: pre_register_id
        }

        dispatch({ type: types.SET_USER_DATA, value: user });

        window.location.href = "/confirm"
    } catch (error) {

        if (_.isString(error)) return await Promise.reject(error);

        return await Promise.reject(!error.response ? error : error.response && error.response.data && error.response.data.message)
    }
}

export const resetExpired = (params) => async (dispatch, getState) => {
    try {
        const ValidateUser = makeValidateUser()

        ValidateUser.validatePasswordIsValidAndMatch(params.password, params.confirm_password);

        const userData = await UserService.resetExpired(params)

        const user = loginUserFactory({ ...userData, keepSign: false });

        const action = ValidateUser.checkUser(user.status, user.isAdmin, user.token_status);

        setDefaultToken(userData.token);

        dispatch({ type: types.SET_USER_DATA, value: user });

        window.location.href = action;

    } catch (e) {
        throw (e)
    }
}

export const resendEmail = ({ pre_register_id }) => async (dispatch) => {
    try {
        return await UserService.resendEmail(pre_register_id)
    } catch (e) {
        throw ("Unable to send the email")
    }
}

export const changeEmail = ({ email, pre_register_id }) => async (dispatch) => {
    try {
        return await UserService.changeEmail(email, pre_register_id)
    } catch (e) {
        throw ("Unable to change the email")
    }
}

export default UserReducer;
