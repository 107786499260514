import { Switch, Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";

//helpers
import PrivateAdminRoute from "helper/privateAdminRoute";
import PrivateUserRoute from "helper/privateUserRoute";
import PrivateUserAndAdminRoute from "helper/privateUserAndAdminRoute";
import { userPermission } from "helper/permissions";

//pages normaluser
import PatientHistory from "features/patients/patientHistory";
import PatientPdf from "features/patients/patientPdf";
import ManagementPortalUser from "pages/ManagementPortalUser";
import Readers from "features/readers/readers";
import ReadersDetails from "features/readers/readersDetails";
import SystemAdmin from "features/systemAdmin";
import Tutorial from "features/user/signUp/tutorial";
import Wizard from "features/wizard";
import Terms from "features/user/terms";
import VerifyReader from "features/readers/verifyReader";

//pages admin
import Devices from "pages/DevicesAdmin";
import WizardAdmin from "features/admin/wizard";
import ManagementPortalAdmin from "pages/ManagementPortalAdmin";
import HeaderAdmin from "features/admin/header";

//user edit
import EditUS from "features/systemAdmin/userManagement/modalInviteUser";

import SessionManagement from "components/sessionManagement";

const Routes = (props) => {
	useEffect(() => {
		if (!props.users || !props.users.length && props.modalEditUser) {
			props.getUsers();
		}
	}, [props.modalEditUser]);

	const { basename, user, modalEditUser, currentUSToEdit } = props;
	return (
		<>
			<SessionManagement user={user} />
			{modalEditUser && currentUSToEdit && (
				<EditUS
					sitesOptions={
						!!user &&
						!!user.sites &&
						user.sites.map((site) => {
							return { ...site, value: site.name };
						})
					}
					sameUser={true}
					token={user.token}
					open={modalEditUser}
					onClose={() => props.closeEditUser()}
					save={(values) => props.editUser(values)}
					data={{
						...currentUSToEdit,
						id: currentUSToEdit.user_id,
						photo: user.photo,
					}}
				/>
			)}
			<Switch>
				{/* USER AND ADMIN ROUTES  */}
				<PrivateUserAndAdminRoute
					path={`${basename}/patient-history`}
					exact={true}
					component={PatientHistory}
					userData={props.user}
				/>
				<PrivateUserAndAdminRoute
					path={`${basename}/patient-pdf`}
					exact={true}
					component={PatientPdf}
					userData={props.user}
				/>

				{/* USER PRIVATE ROUTES */}
				<PrivateUserRoute
					path={`${basename}/verify-reader`}
					exact={true}
					component={VerifyReader}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/reader`}
					exact={true}
					component={VerifyReader}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/readers`}
					exact={true}
					component={Readers}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/readers-details`}
					exact={true}
					component={ReadersDetails}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/wizard`}
					exact={true}
					component={Wizard}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/system-admin`}
					component={SystemAdmin}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/tutorial`}
					exact={true}
					component={Tutorial}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/terms`}
					exact={true}
					component={Terms}
					userData={props.user}
				/>
				<PrivateUserRoute
					path={`${basename}/`}
					exact
					component={ManagementPortalUser}
					userData={props.user}
				/>

				{props.user.isAdmin != userPermission.admin && (
					<Redirect to={`${basename}/`} />
				)}

				{/* ADMIN ROUTES */}
				<PrivateAdminRoute
					path={`${basename}/admin/`}
					exact={true}
					header={HeaderAdmin}
					userData={props.user}
					component={ManagementPortalAdmin}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/patients`}
					exact={true}
					header={HeaderAdmin}
					userData={props.user}
					component={ManagementPortalUser}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/devices/`}
					exact={true}
					header={HeaderAdmin}
					userData={props.user}
					component={Devices}
					userData={props.user}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/patient-history`}
					exact={true}
					header={HeaderAdmin}
					component={PatientHistory}
					userData={props.user}
				/>

				<PrivateAdminRoute
					path={`${basename}/admin/patient-pdf`}
					exact={true}
					component={PatientPdf}
					userData={props.user}
					header={HeaderAdmin}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/readers/`}
					exact={true}
					component={Readers}
					userData={props.user}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/readers-details`}
					exact={true}
					component={ReadersDetails}
					userData={props.user}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/wizard`}
					exact={true}
					component={WizardAdmin}
					userData={props.user}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/system-admin`}
					component={SystemAdmin}
					userData={props.user}
				/>
				<PrivateAdminRoute
					path={`${basename}/admin/tutorial`}
					exact={true}
					component={Tutorial}
					userData={props.user}
				/>
			</Switch>
		</>
	);
};

export default Routes;
