import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckSquare,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const checkComponent = ({ color, className, check }) => {
	if (!check) {
		return (
			<div
				style={{
					fontSize: 16,
					textAlign: "center",
				}}
			>
        -
      </div>
		);
	}
	return (
		<FontAwesomeIcon
			icon={faCheckSquare}
			className={className}
			style={{
				fontSize: 16,
				textAlign: "center",
				color: color,
			}}
		/>
	);
};

export default checkComponent;
