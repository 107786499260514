import {FILTRO_DATE_ENUM, ALL_SITES} from "helper/enum";

const initial = {
    CARD_SELECTED: 1,

    STATUS: {
        all: 0,
        pilot: 0,
        deactivated: 0
    },

    filterByTestType: null,
    filterByEDS: null,
    filterByStatus: 'all',

    TEST_TYPES: {
        all: 0,
        both: 0,
        malaria: 0,
        hbVariant: 0
    },
    EDS_SITES: {
        all: 0,
        invited: 0
    },
    PARTICIPATION: {
        all: 0,
        both: 0,
        clinicalTesting: 0,
        researchProject: 0
    },

    SEARCH_SITE: "",

    ALL_SITES_SELECTEDS: false,

    SITES_SELECTEDS: [],

    SITES_DB: [],

    PAGE: 0,

    QTY_PAGE: 10,
};

export default initial;
