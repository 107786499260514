const translations = {
    placeHolderEmail: "E-mail...",
    messageEmailInvalid: "Ops... e-mail invalido",
    placeHolderPassword: "Senha ...",
    label_userBlocked: "Este usuario {{name}} está bloqueado, para desbloquear entre em contato com o admin.",
    label_userDeactivated: "Este usuario foi desativado.",
    labelForgotPassword: "Esqueci a senha",
    checkBoxKeepSigned: "Manter conectado",
    required_password_valid: "A senha deve ter entre 8 e 25 caracteres, conter pelo menos: uma letra minúscula, uma letra maiúscula e um número.",
}

export default translations;