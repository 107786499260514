import SystemAdminSiteUser from './SystemAdminSite/user'
import SignIn from './SignIn'
import ForgotPassword from './ForgotPassword'
import NewPassword from './NewPassword'
import SiteRegisterConfirmed from './SiteRegisterConfirmed'
import SiteRegistrationConfirmation from './SiteRegistrationConfirmation'
import RegisterSite from './RegisterSite'
import Wizard from './Wizard'
import ManagementPortalUser from './ManagementPortal/user';
import Patients from './Patients';
import Readers from './Readers';
import Cartridges from './Cartridges';
import SystemAdminUserManagement from './SystemAdminUserManagement'
import SystemAdminPageContainer from './SystemAdminPageContainer'
import ValidationsMessages from './ValidationsMessages'
import TableHeader from './TableHeader';
import Header from './Header';
import AdminManagementPortal from './Admin/DashboardManagementPortal'
import AdminDevices from './Admin/Devices'
import AdminAddSite from './Admin/AddSite'
import TermsOfUseSiteUser from './TermsOfUse'
import InviteEds from './Admin/modalInviteEds'
import Tutorial from './Tutorial';
import VerifyReader from "./VerifyNewReader";

const translations = {
    label_AllSites: "ALL SITES",
    /**
     * GENERAL
     */
    label_Print: "Print",
    label_SitesWithReaders: "Sites with Readers",
    label_RegisteredSitesWithActiveGazelleReaders: "Registered Sites with Active Gazelle Readers",
    label_SitesWithIssuesToResolve: "Sites with Reader issues to Resolve",
    label_GazelleReadersInUse: "Gazelle Readers in Use",
    label_ActiveGazelleReaders: "Active Gazelle Readers",
    label_GazelleReadersWithIssues: "Gazelle Readers with issues to Review/Resolve",
    label_TotalMalariaTestsDone: "Total Malaria Tests Done",
    label_TotalHbTestsDone: "Total Hb Variant Tests Done",
    label_RepeatHbTests: "Repeat Hb Variant Tests",
    label_RepeatMalariaTests: "Repeat Malaria Tests",
    label_RegisteredReaders: "Registered\nReaders",
    label_StatusSiteReaderRed: "Site having MAJOR Reader Issues",
    label_StatusSiteReaderYellow: "Site with Reader needing Firmware Update",
    label_StatusSiteReaderGreen: "No Reader issues detected",
    label_ToStartUsingReaders: "To start using your {{qty}} Registered Gazelle Readers, you need to setup the connection with this web portal.",
    label_ClickHereToProceed: "Click here to proceed!",
    label_Search: "Search",
    label_ReaderSerialNumberSearch: "READER SERIAL NUMBER SEARCH",
    label_Active: "Active",
    label_Discarded: "Deleted",
    label_Updated: "Updated",
    label_TotalDevices: "Total Readers",
    label_FirmwareCurrent: "Firmware Current",
    label_FirmwareOuDate: "Firmware Out of Date",
    tableHeaderClinicalTesting: "Clinical Testing",
    tableHeaderResearchProject: "Research Project",
    tableHeaderPilotSites: "Pilot Site",
    tableHeaderEdsSite: "EDS Site",
    label_NotUpdated: "Not Updated",
    label_Eds: "EDS",
    label_Block: "Block",
    label_Current: "Current",
    label_ReadersWithErrors: "Readers with Errors",
    label_ReadersWithErrorsLog: "Readers with error logs",
    label_Unblock: "Unblock",
    label_Delete: "Deactivate",
    label_Add: "Add",
    label_Version: "Version",
    label_AccessToTheSite: "Access to the site",
    label_TermsOfUseDeclined: "Terms of Use Declined",
    label_AreYouSure: "Are you Sure?",
    label_SendRequest: "SEND REQUEST",
    label_In: "In",
    label_Days: "Days",
    label_AddSatelliteSite: "ADD SATELLITE SITE",
    label_AddPrimarySite: "ADD PRIMARY SITE",
    label_Success: "Success",
    label_Send: "Send",
    label_Back: "BACK",
    label_Feedback: "Feedback / Support",
    label_EmailTestReport: "Email Test Report",
    label_To: "To",
    label_Message: "Message",
    label_Subject: "Subject",
    label_Comment: "Comment",
    label_Pending: "PENDING",
    label_WelcomeTo: "Welcome To",
    label_Last30Days: 'LAST 30 DAYS',
    label_Last60Days: 'LAST 60 DAYS',
    label_Last90Days: 'LAST 90 DAYS',
    label_Last120Days: 'LAST 120 DAYS',
    label_LastWeek: "LAST WEEK",
    label_LastMonth: "LAST MONTH",
    label_AllPeriod: "ALL PERIOD",
    label_Decline: "DECLINE",
    label_Skip: "SKIP",
    label_Next: "NEXT",
    label_Done: "DONE",
    label_SiteInformation: "SITE INFORMATION",
    label_AcceptTerms: "ACCEPT TERMS",
    label_TermsOfUse: "Terms of Use",
    label_SearchForPatients: "Search for patients...",
    label_SearchForUsers: "Search for users...",
    label_SearchForSites: "Search for sites...",
    label_SearchForCartridges: "Search for cartridges...",
    label_PositiveTests: "POSITIVE TESTS",
    label_Recurring: "RE-RUNS",
    label_MalariaTests: "MALARIA\nTESTS",
    label_HbTests: "Hb Variant \n TESTS",
    label_SignIn: "SIGN IN",
    label_Cartridges: "CARTRIDGES",
    label_Failed: 'FAILED',
    label_SignUp: "SIGN UP",
    label_ContactSupport: "Contact Support",
    label_Start: "START",
    label_ZipInvalid: "Invalid zip code",
    label_Yes: "Yes",
    label_No: "No",
    label_MobileTests: "MOBILE TESTS PERFORMED",
    label_CartridgesTests: "CARTRIDGES",
    label_Tests: "Tests",
    label_Test: "TEST",
    label_TestSingle: "Test",
    label_Positive: "POSITIVE",
    label_Run: "Run",
    label_PatientTestHistory: "PATIENT TEST HISTORY",
    label_PatientTestReport: "PATIENT TEST REPORT",
    label_ClickToViewTestDetails: "Click to view Test Details",
    label_ClickToViewPatientHistory: "Click to view Patient History",
    label_ClickToViewTestReport: "Click to view Test Report",
    label_ClickToViewReaderDetails: "Click to view Reader Details",
    label_Inactive: "Inactive",
    label_Registering: "Registering",
    label_Avaliable: "AVAILABLE",
    label_Percent: "Porcent",
    label_Total: "Total",
    label_Used: "USED",
    label_Save: "SAVE",
    label_Cancel: "CANCEL",
    label_SiteName: "SITE NAME",
    label_ProductNumber: "PRODUCT NUMBER",
    label_SerialNumber: "SERIAL NUMBER",
    label_AddSite: "ADD SITE",
    label_TestTypes: "TEST TYPES",
    label_Malaria: "MALARIA",
    label_Hb: "Hb Variant",
    label_Both: "BOTH",
    label_Roles: "ROLES",
    label_AlreadyRegistered: 'Already Registred?',
    label_AlreadyOrdered: "ALREADY ORDERED",
    label_Received: "RECEIVED",
    label_PlaceOrder: "PLACE ORDER",
    label_ShippingDetails: "SHIPPING DETAILS",
    label_DeliveryEstimate: "DELIVERY ESTIMATE",
    label_Ordered: 'ORDERED',
    label_Quantity: "QTY",
    label_Boxes: "BOXES",
    label_CartridgesType: "CARTRIDGES TYPE",
    label_CartridgesAddReceived: "ADD RECEIVED CARTRIDGES",
    label_SearchForPatientNameOrId: "Search for patient name or id",
    /**
     * TABLES
     *  */
    ...TableHeader,

    /**
     * Sign In 
     */
    ...SignIn,

    /**
    * /FORGOT PASSWORD
    */
    ...ForgotPassword,

    /**
    * /NEW PASSWORD
    */
    ...NewPassword,

    /**
     * /REGISTER YOUR SITE
     */
    ...RegisterSite,

    /**
     * SITE CONFIRMATION REGISTRATION
     * */
    ...SiteRegistrationConfirmation,

    /**
    * SITE REGISTER CONFIRMED
    * */
    ...SiteRegisterConfirmed,

    /**
     * WIZARD
     */
    ...Wizard,

    /**
     * SUB-HEADER
     * 
     */

    placeHolderSelectSites: "ALL SITES",
    placeHolderSelectDate30: "LAST 30 DAYS",
    placeHolderSelectDate60: "LAST 60 DAYS",
    placeHolderSelectDate90: "LAST 90 DAYS",
    placeHolderSelectDate90: "LAST 120 DAYS",

    /**
     * MANAGEMENT PORTAL
     */
    ...ManagementPortalUser,

    /**
     * PATIENTS
     */
    ...Patients,

    /**
     * READERS
     */
    ...Readers,

    /**
     * CARTRIDGES
     */
    ...Cartridges,

    /**
     * SYSTEM ADMIN 
     */
    ...SystemAdminPageContainer,

    /**
     * SYSTEM ADMIN USER MANAGEMENT
     */
    ...SystemAdminUserManagement,

    /**
     * SYSTEM ADMIN USER MANAGEMENT - MODAL INVITE USER
     */
    title_SystemAdminTools_UserManagementModalInviteUser: "INVITE USER",

    ...SystemAdminSiteUser,

    /**
     * VALIDATION MESSAGES
     */
    ...ValidationsMessages,

    /**
     * HEADER
     */
    ...Header,

    /**
     * Tutorial
     */
    ...Tutorial,

    /**
     * ..TermsOfUseSiteUser
     */
    ...TermsOfUseSiteUser,

    /**
     * 
     * ADMIN
     */
    ...AdminManagementPortal,
    ...AdminDevices,
    ...AdminAddSite,
    ...InviteEds,
    ...VerifyReader,
}

export default translations;