const translations = {

    /**
     * HEADER
     */

    label_SiteInformation: "SITE INFORMATION",
    label_RegisterGazelle: "REGISTER GAZELLE READERS",
    label_AddMoreUsersToSite: "ADD MORE USERS TO  YOUR SITE",

    label_SingleSiteRegistration: "SINGLE SITE REGISTRATION",
    label_PrimarySite: "PRIMARY SITE REGISTRATION",
    label_SateliteSite: "SATELLITE SITE REGISTRATION",
    label_SiteUpdate: "SITE UPDATE",
    /**
    * REGISTER 3 STEPS ----  1 STEP
    * */
    headerPrimaryTitle: 'PRIMARY SITE',
    headerRegisteredSitesTitle: 'REGISTERED SITES',
    titleHeader_WizardFirstStep: "PRIMARY SITE",
    inputRequiredField: "Oops, this is a required field",

    labelInputName: "NAME*",
    labelInputCountry: "COUNTRY*",
    labelInputZip: "ZIP / PIN CODE*",
    labelInputPhoneNumberArea: "+1*",
    labelInputPhoneNumber: "PHONE NUMBER*",
    labelInputAddress: "ADDRESS*",
    labelInputCity: "CITY*",
    labelInputState: "STATE*",
    labelInputTestTypes: "TEST TYPES*",
    placeHolderInputName: "Name...",
    placeHolderInputCountry: "Country...",
    placeHolderInputZip: "ZIP / PIN CODE...",
    placeHolderInputPhoneNumberArea: "+1",
    placeHolderInputPhoneNumber: "(000) 000-0000",
    placeHolderInputAddress: "Address…",
    placeHolderInputCity: "City…",
    placeHolderInputState: "State…",

    /**
    * REGISTER 3 STEPS ----  2 STEP
    * */
    headerPrimaryTitle: 'REGISTER GAZELLE READERS',
    labelInputProductNumber: "PRODUCT NUMBER*",
    placeHolderProductNumber: "Product Number...",
    labelInputSerialNumber: "SERIAL NUMBER*",
    placeHolderSerialNumber: "Serial Number...",

    /**
     * REGISTER 3 STEPS ----  3 STEP
     * */
    headerPrimaryTitle: 'ADD MORE USERS TO YOUR SITE',
    labelInputFirstName: "FIRST NAME*",
    placeHolderInputFirstName: "First name...",
    labelInputLastName: "LAST NAME*",
    labelInputEmailAddress: "EMAIL ADDRESS*",
    placeHolderEmailAddress: "Email Address...",
    placeHolderInputLastName: "Last name...",
    labelForWhichSites: "FOR WHICH SITES*",
    labelWhichSites: "Which sites*",

}

export default translations;