import { createSelector } from "reselect";
import initial from "../initial";
import _ from "lodash";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_ALL_SITES =
    "AdminSiteManagement_ToogleAllSites";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_UNIQ_SITE =
    "AdminSiteManagement_ToogleUniqSite";

export const SYSTEM_ADMIN_SITE_SET_DISEASES = 'AdminSiteManagement_SetDiseases';

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_DATE_FILTER = "AdminSiteManagement_SetDate";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_PAGE = "AdminSiteManagement_ChangePage";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_QTY_PAGE = "AdminSiteManagement_ChangeQtyPage";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_SEARCH_SITE = "AdminSiteManagement_SearchSite";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CARD_TEST = "AdminSiteManagement_ToogleCard";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_INITIAL_VALUES = "AdminSiteManagement_SetInitial";

export const SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_SITE_FILTER = "AdminSiteManagement_SiteFilter";

export const SYSTEM_ADMIN_SITE_GET_SITES = "AdminSiteManagement_GetSites"

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_TEST_TYPE = 'AdminSiteManagement_FilterTestType';

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_STATUS = 'AdminSiteManagement_FilterStatus';

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS = 'SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS';

export const SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION = 'SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_PARTICIPATION';


const SystemAdminSiteManagementReducer = (state = initial, action) => {
    switch (action.type) {
        case SYSTEM_ADMIN_SITE_MANAGEMENT_INITIAL_VALUES: {
            return initial;
        }

        case SYSTEM_ADMIN_SITE_GET_SITES: {
            return {
                ...state,
                SITES_DB: action.sites
            }
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_ALL_SITES: {
            return { ...state, ALL_SITES_SELECTEDS: action.bool };
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CHECK_UNIQ_SITE: {
            return {
                ...state,
                SITES_SELECTEDS: action.arraySelecteds
            };
        }
        case SYSTEM_ADMIN_SITE_MANAGEMENT_SEARCH_SITE: {
            return {
                ...state,
                SEARCH_SITE: action.value
            };
        }
        case SYSTEM_ADMIN_SITE_MANAGEMENT_TOOGLE_CARD_TEST: {
            return {
                ...state,
                CARD_SELECTED: action.id
            };
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_TEST_TYPE: {
            return {
                ...state,
                CARD_SELECTED: 2,
                filterByTestType: action.id,
                filterByEDS: null,
                filterByStatus: null
            }
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_STATUS: {
            return {
                ...state,
                CARD_SELECTED: 1,
                filterByTestType: null,
                filterByEDS: null,
                filterByStatus: action.id
            }
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_FILTER_BY_EDS: {
            return {
                ...state,
                CARD_SELECTED: 3,
                filterByEDS: action.id,
                filterByTestType: null,
                filterByStatus: null
            }
        }

        case SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_PAGE: {
            return {
                ...state,
                PAGE: action.page
            }
        }
        case SYSTEM_ADMIN_SITE_MANAGEMENT_CHANGE_QTY_PAGE: {
            return {
                ...state,
                QTY_PAGE: action.qty
            }
        }
    }
    return state;
};

export const qty_page = state => state.SystemAdminSiteManagementReducer.QTY_PAGE;

export const page = state => state.SystemAdminSiteManagementReducer.PAGE;

export const searchFilter = state =>
    state.SystemAdminSiteManagementReducer.SEARCH_SITE;

export const allSitesDB = state => state.UserReducer.userData.sites;

export const sitesDeactivated = state => state.UserReducer.userData.sitesDeactivated || [];

export const allSitesSelecteds = state => state.SystemAdminSiteManagementReducer.ALL_SITES_SELECTEDS;

export const sitesSelecteds = state => state.SystemAdminSiteManagementReducer.SITES_SELECTEDS;

export const filterDiseaseSelected = state => state.SystemAdminSiteManagementReducer.filterByTestType;

export const filterStatusSelected = state => state.SystemAdminSiteManagementReducer.filterByStatus;

export const filterByParticipation = state => state.SystemAdminSiteManagementReducer.filterByParticipation;

export const edsSelected = state => state.SystemAdminSiteManagementReducer.filterByEDS;


export const cardSelected = state =>
    state.SystemAdminSiteManagementReducer.CARD_SELECTED;

export const getTestsData = createSelector(
    allSitesDB,
    site => {
        return site.reduce(
            (initial, value) => {
                if (value.testId === 1 && value.testResult === "Positive") {
                    initial.malaria.positive++;
                } else if (value.testId === 2 && value.testResult === "Positive") {
                    initial.hb.positive++;
                }
                return initial;
            },
            {
                malaria: {
                    tests: site.length,
                    positive: 0
                },
                hb: {
                    tests: site.length,
                    positive: 0
                }
            }
        );
    }
);

export const getEdsCounters = createSelector(
    allSitesDB,
    (sites) => {
        let counters = {
            all: 0,
            invited: 0
        }

        sites.forEach((site) => {
            if (site.eds_status) {
                counters.all++

                if (site.eds_status == 'invited' || site.eds_status == 'pending') counters.invited++
            }
        })
        return counters
    }
)

export const getParticipationCounters = createSelector(
    allSitesDB,
    (sites) => {
        let counters = {
            both: 0,
            project_type: 0,
            clinical_testing: 0,
        }

        sites.forEach((site) => {
            if (!!site.project_type && !!site.clinical_testing) {
                counters.both++;
            } else if (!!site.project_type) {
                counters.project_type++;
            } else if (!!site.clinical_testing) {
                counters.clinical_testing++;
            }
        });
        return counters
    }
)


export const getDiseasesCounter = createSelector(
    allSitesDB,
    (sites) => {
        let counters = {
            both: 0,
            malaria: 0,
            hb: 0,
        }

        sites.forEach((site) => {
            if (!!site.disease_types && site.disease_types.length == 2) {
                counters.both++;
            } else if (!!site.disease_types && site.disease_types.length == 1 && site.disease_types.includes(1)) {
                counters.malaria++;
            } else if (!!site.disease_types && site.disease_types.length == 1 && site.disease_types.includes(2)) {
                counters.hb++;
            }
        });
        return counters
    }
)


export const allSitesFiltered = createSelector(
    allSitesDB,
    searchFilter,
    filterDiseaseSelected,
    qty_page,
    page,
    filterStatusSelected,
    sitesDeactivated,
    edsSelected,
    cardSelected,
    filterByParticipation,
    (allSites, searchFilter, diseaseSelected, qty_page, page, status, sitesDeactivated, edsSelected, cardSelected, filterByParticipation) => {

        let sites = allSites;

        if (cardSelected == 1) {
            if (status == 'all') {
                sites = [...allSites, ...sitesDeactivated]
            }
            if (status == 1) {
                sites = [...sitesDeactivated];
            }

            if (status == 0) {
                sites = [...allSites, ...sitesDeactivated].filter(site => site.project_type == 'pilot')
            }
        }

        if (cardSelected == 2) {
            let diseases = []

            sites.forEach((site) => {
                site.disease_types.forEach(disease => !diseases.includes(disease) ? diseases.push(disease) : '')
            })

            sites = _.chain(sites)
                .filter(site => {
                    if (diseaseSelected == null) return site;

                    if (diseaseSelected == 0 && site.disease_types.length == 2) {
                        return site
                    }

                    const isEqual = site.disease_types.length == 1 && site.disease_types.includes(diseaseSelected);
                    return isEqual;
                })
                .value()
        }

        if (cardSelected == 3) {
            sites = _.chain(sites)
                .filter(site => {
                    if (edsSelected == 'all') return !!site.eds_status;
                    return site.eds_status == 'invited' || site.eds_status == 'pending';
                })
                .value()
        }

        if (cardSelected == 4) {
            sites = _.chain(sites)
                .filter(site => {
                    if (filterByParticipation == 0) return !!site.project_type && !!site.clinical_testing
                    if (filterByParticipation == 1) return !!site.project_type && !site.clinical_testing
                    return !!site.clinical_testing && !site.project_type
                })
                .value()
        }
        const results = sites.filter(site => searchFilter.length > 0 ? site.name.toLowerCase().includes(searchFilter.toLowerCase()) : site)
        const totalPages = (results.length / qty_page) % 1 != 0 ? Math.ceil(results.length / qty_page) : Math.ceil(results.length / qty_page)
        const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
        const end = totalPages == 0 ? results : init + qty_page
        let arrayFormatado = (totalPages) == 1 ? results : results.slice(init, end);
        arrayFormatado = _.sortBy(arrayFormatado, (i) => i.id);
        return {
            pilot: [...allSites, ...sitesDeactivated].filter(site => site.project_type == 'pilot').length,
            deactivated: sitesDeactivated.length,
            allSites: [...allSites, ...sitesDeactivated],
            allSitesFiltered: arrayFormatado,
            allSitesCounter: allSites.length + sitesDeactivated.length,
            counter: results.length,
            totalPages: parseInt(totalPages)
        }
    }
);

export default SystemAdminSiteManagementReducer;
