export default (items,qty_page, page, ) => {
    qty_page = parseInt(qty_page);
    const totalPages = (items.length / qty_page) % 1 != 0 ? Math.ceil(((items.length / qty_page))) : Math.ceil(items.length / qty_page)
    const init = (page * qty_page) < 0 ? 0 : (page * qty_page);
    const end = totalPages == 0 ? items : init + qty_page;
    const itemsPerPage = (totalPages) == 1 ? items : items.slice(init, end);    
    return {
        totalPages,
        init,
        end,
        itemsPerPage
    }
}