import React from 'react';
import CustomModal from "components/customModal";
import './style.scss'
import arrowDown from "assets/img/dropdown_icon.png";

export default class Faq extends React.Component{


    state = {
        options: [{
            title: 'What is Hemex Health',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'Is it all for free?',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'How i do contact with Hemex Health',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'What does Hemex Health include?',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'What does Hemex Health include?',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'How i do contact Hemex Health',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'What does Hemex Health do?',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
        {
            title: 'What does Hemex Health do?',
            subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec orci turpis. Duis tincidunt nec odio id tempor. Nunc pulvinar volutpat lacus, volutpat ultricies mauris condimentum non. Sed sollicitudin pulvinar eros eget commodo. Fusce maximus eros non nibh posuere, ultricies tincidunt ligula gravida. Proin tristique nunc vel risus bibendum fringilla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce eget felis nec est maximus mollis sed vel lorem.'
        },
    ],
    optionOpen: null
    }
    render(){
        return(
            <div className='faq-container'>
                <CustomModal open={this.props.open} onClose={this.props.onClose} title='FAQ'>
                    <div className='header-black'/>
                    <div className='faq-padding'>
                        <div className='faq-middle'>
                        {this.state.options && this.state.options.map((option,key) => {
                            if(key == this.state.optionOpen ){
                                return (
                                    <div className='faq-box open'>
                                        <div className='faq-box-items'>
                                            <div className='faq-title'>
                                                What is hemex health?
                                            </div>
                                            <img src={arrowDown} className='arrow-right cp' onClick={() => this.setState({optionOpen: key == this.state.optionOpen ? null : key})}/>
                                        </div>
                                        <div className='faq-box-subtitle'>
                                            {option.subtitle}
                                        </div>
                                        <div className='faq-border-bottom'/>
                                    </div>
                                )
                            }
                            return (
                                <div className='faq-box'>
                                    <div className='faq-box-items'>
                                        <div className='faq-title'>
                                           {option.title}
                                        </div>
                                        <img src={arrowDown} className='arrow-right cp' onClick={() => this.setState({optionOpen: key == this.state.optionOpen ? null : key})}/>
                                    </div>
                                    <div className='faq-border-bottom'/>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </CustomModal>
            </div>
        )
    }
}