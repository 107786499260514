import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.js";
import translationPtBR from "./locales/ptBR/translation.js";
i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,

    fallbackLng: "en", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    // interpolation: {
    //   escapeValue: false // react already safes from xss
    // },
    react: { useSuspense: false },
    resources: {
      en: {
        translations: { ...translationEn }
      },
      ptBR: {
        translations: translationPtBR
      },

    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;