import React, { Fragment } from "react";
import PageContainer from "components/pageContainer/pageContainer";
import Steps from "features/admin/wizard/stepsHeader";
import BarTitle from "features/wizard/barTitle";
import Footer from "components/footer";
import CustomButton from "components/customButton"
import addMoreIcon from "assets/img/wizard/plus_add_satelite_add_site_icn.png"
import NoLogo from 'assets/img/placeholder_nosite.png';
import TrashIcon from 'assets/img/readers/trash_icn_table.png'
import axios from 'helper/axiosHelper'
import GenericTableHeader from "components/tables/genericTableHeader";
import GenericTableRow from "components/tables/genericTableRow";
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import * as services from 'redux/actions';
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import Loading from 'components/loading';
import queryString from 'query-string';
import { getTableHeaderWizardSites, getTableHeaderWizardDevices, getTableHeaderWizardInvites } from "helper/getTableHeader"

// Steps
import Step1 from "components/addSiteUser";
import Step2 from "components/addDevice";
import Step3 from "components/inviteUser";

import "./index.scss";
import { withTranslation } from 'react-i18next';

class RegisterDetails extends React.Component {

    state = {
        step: 1,
        previousStep: 1,
        registered: "",
        readers: [],
        invites: [],
        diseases: [],
        dataSelected: null,
        loading: true,
        sites: [],
        devices: [],
        entity_master: null
    }

    componentDidMount = async () => {

        const { id } = queryString.parse(this.props.router.location.search);
        const sites = await this.props.services.getSitesAdmin(id);
        let dataSelected = sites.find(site => site.status == 1) ? sites.find(site => site.status == 1) : sites.find((site) => site.id == id)
        const invites = await this.props.services.getAdminInvites(id)
        const entity_master = sites.find(site => site.parent == '-');

        if (!entity_master || entity_master.status != 2 && !!entity_master.city) {
            this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/siteManagement`);
        }

        Object.keys(dataSelected).map((key) => dataSelected[key] == null ? '' : dataSelected[key])
        const diseases = await this.props.services.getDiseases()
        const devices = await this.props.services.getReadersAdmin(entity_master.id, 'ALL');
        this.setState({
            sites,
            invites,
            dataSelected,
            devices,
            diseases,
            loading: false,
            entity_master,
            id
        })
    }




    showLoading = () => this.setState({ loading: !this.state.loading })

    saveSite = async (values) => {
        let { id } = queryString.parse(this.props.router.location.search);
        this.showLoading(true)
        try {
            if (values.id) {
                await this.props.services.updateEntity({ ...values, id: values.id != id ? values.id : id, status: 2 })
            } else if (!!this.state.entity_master && this.state.entity_master.site_type == 2) {
                values.logo_filename = values.logo;
                values.entity_master_id = this.state.entity_master.id;
                values.status = 3
                await this.props.services.adminCreateSite(_.omit(values, 'logo'))
            }

            const sites = await this.props.services.getSitesAdmin(id);
            this.showLoading(false)
            this.setState({
                sites,
                registered: 'sites'
            })
        } catch (response) {
            const text = !!response && !!response.data && !!response.data.message ? response.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            this.setState({ loading: false })
            return;
        };
    }

    saveUser = async (values) => {
        try {
            this.showLoading(true)
            if (!values.id) {
                await this.props.services.inviteUser(values);
            } else {
                await this.props.services.editUser(values);
            }
            const invites = await this.props.services.getAdminInvites(this.state.id);
            this.setState({ registered: 'invites', invites })
            this.showLoading(false)
        } catch ({ response }) {
            const text = !!response && !!response.data && !!response.data.message ? response.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            this.setState({ loading: false })
            return;
        }
    }

    saveDevice = async values => {
        try {
            this.showLoading(true)
            if (!values.id) {
                await this.props.services.createDevice(values);
            } else {
                await this.props.services.updateDevice(values);
            }
            const devices = await this.props.services.getReadersAdmin(this.state.entity_master.id, 'ALL');
            this.setState({
                loading: false,
                devices,
                registered: 'devices'
            })
        } catch (e) {
            const text = !!e && !!e.data.message ? e.data.message : 'Error!'
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
            });
            this.setState({ loading: false })
            return;
        }
    }

    changeStepNext = () => {
        if (this.state.step == 1) {
            return this.props.services.getReaders()
                .then(() => {
                    this.setState({
                        dataSelected: null,
                        registered: this.state.devices.length > 0 ? 'devices' : null, step: 2,
                        previousStep: 1
                    })
                })
        }
        if (this.state.step == 2) {
            return this.props.services.getAdminInvites(this.state.id)
                .then((invites) => {
                    this.setState({
                        dataSelected: null,
                        invites: invites,
                        registered: 'invites', step: 3,
                        previousStep: 2
                    })
                })
        }
        return this.props.router.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/system-admin/siteManagement`)
    }

    changeStepPrev = () => {
        this.state.step != 1 && this.setState({
            step: this.state.step - 1,
            dataSelected: null,
        })
    }

    changeStep = (step, previousStep) => {
        let reg = null;

        if (step === 2) {
            if (this.state.devices.length === 0) {
                reg = null;
            } else {
                reg = 'devices';
            }
        }

        if (step === 3) {
            if (this.state.invites.length === 0) {
                reg = null;
            } else {
                reg = 'invites';
            }
        }

        if (previousStep === null) {
            reg = 'sites';
            this.setState({ step, registered: reg, dataSelected: null, });
        } else {
            this.setState({ step, previousStep, registered: reg, dataSelected: null, });
        }
    }

    changePreviousStep = (previousStep) => {

        let reg = null;

        if (this.state.step - 1 == 2) {
            if (this.state.devices.length == 0) {
                reg = null;
            } else {
                reg = 'devices';
            }
        }

        if (this.state.step - 1 == 1) {
            reg = 'sites';
        }
        if (this.state.step - 1 == 3) {
            reg = 'invites';
        }
        this.setState({
            step: this.state.step - 1,
            registered: reg,
            dataSelected: null,
        });
    }

    skipWizard = async () => {

        if (this.props.data.status == 'W') {
            try {
                await this.props.services.skipReaders()
                return window.location.href = `${process.env.REACT_APP_FRONT_URL}/tutorial`;
            } catch (e) {
                window.location.href = `${process.env.REACT_APP_FRONT_URL}/`;
            }
        }
        window.location.href = `${process.env.REACT_APP_FRONT_URL}/`;
    };

    getFieldsReadOnly = () => {
        if (this.state.step == 1) {
            if (this.state.dataSelected && this.state.dataSelected.city) {
                return ['disease_types']
            };

            if (!this.state.dataSelected) {
                return []
            }

            return []
        }
    }

    getStep = () => {
        switch (this.state.step) {
            case 1:
                return <Step1
                    save={(values) => this.saveSite(values)}
                    userData={this.props.data}
                    token={this.props.data.token}
                    handleChangePrev={() => {
                        this.setState({
                            step: 1,
                            registered: 'sites',
                            dataSelected: null
                        });
                    }}
                    diseases={this.state.diseases.filter((disease) => {
                        const entity_master = this.state.sites.find((site) => site.parent == '-');
                        return entity_master.disease_types.includes(disease.id)
                    })}
                    data={this.state.dataSelected}
                    fieldsReadOnly={this.getFieldsReadOnly()}
                    showCancel={(() => {
                        return !(this.state.sites.length > 0 && !!this.state.sites[0].city) || this.state.sites.length > 1;
                    })()}
                />
            case 2:
                return <Step2
                    save={(formValues) => this.saveDevice(formValues)}
                    data={this.state.dataSelected}
                    canCancel={this.state.devices && this.state.devices.length > 0}
                    cancel={() => this.setState({ registered: 'devices', dataSelected: null })}
                    sites={this.state.sites && this.state.sites.map(
                        (site) => { return { ...site, value: site.name } }
                    )}
                    handleChangePrev={() => this.changeStepPrev()}
                />
            case 3:
                return <Step3
                    save={(values) => this.saveUser(values)}
                    handleChangePrev={() => {
                        this.setState({
                            registered: 'invites', step: 3,
                            dataSelected: null
                        })
                    }}
                    data={
                        this.state.dataSelected ?
                            {
                                id: this.state.dataSelected.user_id,
                                sites: this.state.sites,
                                site_id: this.state.dataSelected.site_id,
                                role_id: this.state.dataSelected.role_id,
                                email_address: this.state.dataSelected.email_address,
                                last_name: this.state.dataSelected.last_name || '',
                                first_name: this.state.dataSelected.first_name || '',
                            }
                            : null
                    }
                    sitesOptions={this.state.sites && this.state.sites.map((site) => {
                        return { ...site, value: site.name }
                    })}
                />
        }
    }

    deleteRow = async (row) => {

        const result = await Swal.fire({
            text: "Are you sure you want to Delete?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })

        if (!result.value) return false;

        if (this.state.step === 1) {
            this.props.services.deleteSite(row.id)
                .then(() => {
                    this.setState({ sites: this.state.sites.filter(site => site.id != row.id) })
                })
        }
        if (this.state.step === 2) {
            this.props.services.deleteReader({ id: row.id })
                .then(() => {
                    this.setState({ devices: this.state.devices.filter(device => device.id != row.id) })
                })
        }
        if (this.state.step == 3) {
            this.props.services.deleteUser(row.id)
                .then(() => {
                    this.setState({ invites: this.state.invites.filter(invite => invite.id != row.id) })
                });
        }
    }

    render() {

        let step1title = this.props.t('label_SingleSiteRegistration');
        if (this.state.sites.length == 1 && !this.state.sites[0].city) {
            step1title = this.props.t('label_PrimarySite')
        } else if (this.state.entity_master && this.state.entity_master.site_type == 2 && !this.state.dataSelected) {
            step1title = this.props.t('label_SateliteSite')
        } else if (this.state.dataSelected && this.state.dataSelected.city) {
            step1title = this.props.t('label_SiteUpdate')
        }
        let titleStep = [step1title, 'REGISTER GAZELLE READERS', 'ADD MORE USERS TO YOUR SITE'];
        let registeredTitle = [this.props.t('headerRegisteredSitesTitle'), this.props.t('label_RegisterGazelle'), this.props.t('label_AddMoreUsersToSite')];
        let btnTitle = ['ADD SATELLITE SITES', "ADD HEMEX READER", "ADD MORE USERS"]
        const { step, previousStep, registered, diseases } = this.state
        return (
            <Fragment>
                <Loading show={this.state.loading} />
                <PageContainer>
                    <Steps
                        stepActive={step} previousStep={previousStep} status={'W'}
                        devices={this.state.devices} invites={this.state.invites}
                        changeStep={this.changeStep} changePreviousStep={this.changePreviousStep} />
                    <BarTitle
                        userData={this.props.data}
                        router={this.props.router}
                        sites={this.state.sites.filter((site) => site.status == 2)}
                        title={!registered ? titleStep[step - 1] : registeredTitle[step - 1]}
                        handleChangeNext={() => this.changeStepNext()}
                        step={step}
                        actions={this.props.services}
                        skip={() => this.changeStepNext()}
                    />
                    {!registered ?
                        <Fragment>
                            {this.getStep()}
                        </Fragment>
                        :
                        !!registered &&
                        <Fragment>
                            <div className="divStepTable">
                                <div
                                    className={`div-middle-container heightDefault displayFlex jc-space ${this.state.registered == 'sites' && this.state.entity_master.site_type == 1 ? "jc-end" : null}`}>

                                    {this.state.registered != 'sites' ?

                                        <CustomButton value={btnTitle[step - 1]} className="buttonWithImage left"
                                            handleChange={() => this.setState({ registered: null })}>
                                            <img src={addMoreIcon} />
                                        </CustomButton>

                                        : this.state.entity_master.site_type == 2 &&

                                        <CustomButton value={btnTitle[step - 1]} className="buttonWithImage left"
                                            handleChange={() => this.setState({ registered: null, dataSelected: null })}>
                                            <img src={addMoreIcon} />
                                        </CustomButton>
                                    }
                                    {this.state.invites.length > 0 && step == 3 &&
                                        <CustomButton value={step === 3 ? this.props.t('label_Done') : this.props.t('label_Next')}
                                            handleChange={() => this.changeStepNext()} />
                                    }

                                    {this.state.step < 3 &&
                                        <CustomButton value={this.props.t('label_Next')} handleChange={() => this.changeStepNext()} />
                                    }
                                </div>
                                <div className={`p-table ${step >= 2 && 'centerTable'}`}>
                                    <GenericTableHeader
                                        cols={(() => {
                                            if (registered == 'sites') return getTableHeaderWizardSites(this.props.t)
                                            if (registered == 'devices') return getTableHeaderWizardDevices(this.props.t)
                                            return getTableHeaderWizardInvites(this.props.t)
                                        })()
                                        }
                                        checkAction={{}}
                                    />

                                    <div className={'div_rows'}>
                                        {this.state[registered] && this.state[registered].map((row, key) => {
                                            let rowReduced = _.omit(row, ['id', 'status']);
                                            if (registered == 'sites') {
                                                rowReduced = {
                                                    site_logo: row.logo && row.logo.length > 0 ? row.logo : NoLogo,
                                                    name: row.name,
                                                    country: row.country,
                                                    zip: row.zip_code,
                                                    phone_number: `${row.phone_code} ${row.phone_number}`,
                                                    address: row.address,
                                                    city: row.city,
                                                    state: row.state,
                                                    disease_types: row.disease_types.map((item, key) => {
                                                        const disease = diseases.find((ds) => ds.id == item)
                                                        if (disease) {
                                                            return ` ${disease.name} ${key === row.disease_types.length - 1 ? '' : ','}`
                                                        }
                                                    }),
                                                    trash: TrashIcon

                                                }
                                            }

                                            if (registered == 'devices') {
                                                rowReduced = {
                                                    product_number: row.product_number,
                                                    serial_number: row.serial_number,
                                                    site_name: row.site_name,
                                                    trash: TrashIcon
                                                }
                                            }

                                            if (registered == 'invites') {
                                                rowReduced = {
                                                    first_name: row.first_name,
                                                    email: row.email_address,
                                                    role: row.role,
                                                    site_name: row.site,
                                                    trash: TrashIcon
                                                }
                                            }

                                            return (
                                                <GenericTableRow
                                                    cols={(() => {
                                                        if (registered == 'sites') return getTableHeaderWizardSites(this.props.t)
                                                        if (registered == 'devices') return getTableHeaderWizardDevices(this.props.t)
                                                        return getTableHeaderWizardInvites(this.props.t)
                                                    })()
                                                    }
                                                    checksSelecteds={[]}
                                                    allCheckSelected={[]}
                                                    checkAction={{}}
                                                    row={rowReduced}
                                                    delete={() => this.deleteRow(row)}
                                                    onClick={() => this.setState({ dataSelected: this.state[this.state.registered][key], registered: '' })}
                                                />
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    }
                </PageContainer>
                <Footer />
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {

    return {
        data: state.UserReducer.userData
    }
}

const mapDispatchToProps = dispatch => ({
    services: bindActionCreators(services, dispatch),
})

RegisterDetails = connect(mapStateToProps, mapDispatchToProps)(RegisterDetails)

export default withTranslation()(RegisterDetails)