import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './index.scss';
import onClickOutside from "react-onclickoutside";
import CloseIcon from 'assets/img/close_invite_user_bg_icn.png'
class ModalPlaceOrder extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
  }

  render() {
    const { open } = this.props;
    if (!open) return false;
    return (
      <Fragment>
        <div className="div_overllay"></div>
        <div className="modal-container">
          <div className="header-bar">
            <div className="title">
              {this.props.title}
            </div>

            <div className="close-bottom" onClick={this.props.onClose}>
              <img src={CloseIcon} />
            </div>
          </div>
          <div className="middle-container">
            {this.props.children}
          </div>
        </div>
      </Fragment>
    )
  }
}
export default onClickOutside(ModalPlaceOrder);
