import axios from "helper/axiosHelper";

export async function getMalariaCounter(
	site = null,
	lastNDays = 0,
	deactivated = false
) {
	let params = {};

	if (!!site) {
		params.site = site;
	}

	if (deactivated) {
		params.deactivated = true;
	}

	try {
		return await axios.get("/tests/malaria/" + lastNDays, { params });
	} catch (e) {
		return await Promise.reject(e);
	}
}

export async function getHbCounter(
	site = null,
	lastNDays = 0,
	deactivated = false
) {
	let params = {};

	if (!!site) {
		params.site = site;
	}

	if (deactivated) {
		params.deactivated = true;
	}

	try {
		return await axios.get("/tests/hb/" + lastNDays, { params });
	} catch (e) {
		return await Promise.reject(e);
	}
}

export async function getPatientsTests(site = null, lastNDays = 0) {
	let params = {
		lastNDays,
	};

	if (!!site) {
		params.site = site;
	}

	try {
		return await axios.get("/patients/", {
			params,
		});
	} catch (e) {
		return await Promise.reject(e);
	}
}

export async function getPositiveTests(site = null, lastNDays = 0) {
	let params = {};
	if (!!site) {
		params.site = site;
	}

	try {
		const { counter } = await axios.get(`/tests/positive/${lastNDays}`, {
			params,
		});
		return counter;
	} catch (e) {
		Promise.reject(e);
	}
}

export async function getTestsRecurring(site = null, lastNDays = 0) {
	let params = {};

	if (!!site) {
		params.site = site;
	}
	try {
		const recurring = await axios.get(`/tests/recurring/${lastNDays}`, {
			params,
		});
		return recurring;
	} catch (e) {
		Promise.reject(e);
	}
}

export async function downloadFile(testId) {
	try {
		const response = await axios.get(`/tests/download/${testId}`, {
			responseType: "blob",
		});
		const url = window.URL.createObjectURL(new Blob([response]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "file.pdf"); //or any other extension
		document.body.appendChild(link);
		link.click();
	} catch (e) {
		return await Promise.reject(e);
	}
}

export async function getTestFile(file_type, id) {
	try {
		const { url } = await axios.get(`/tests/file/${file_type}/${id}`);
		return url;
	} catch (e) {
		return await Promise.reject(e);
	}
}
