import React from "react";
import styled from "styled-components";
import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header/index";
import Footer from "components/footer";
import PasswordIcon from 'assets/img/sign-in/password_icon.png';
import InputDefault from 'components/inputDefault';
import UnhidePassword from 'assets/img/sign-in/unhide_password.png';
import HidePassword from 'assets/img/sign-in/hide_password_icon.png';
import { withTranslation } from 'react-i18next';
import { UserActions } from "redux/reducers";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Container = styled.div`
    width: 100%;
    height: calc(100vh - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BoxContainerPasswordExpired = styled.div`
    height: 450px;
    width: 450px;
`

const HeaderBoxExpired = styled.div`
    text-align: center;
    background-color: white;
    border-radius: 3px;
    width: 100%;
    padding: 30px;
    font-size: 24px;
    color: #fca300;
`

const BoxPasswordExpired = styled.div`
    width: 100%;
    min-height: 250px;
    text-align: center;
    background-color: white;
    border-radius: 3px;
    width: 100%;
    margin-top: 20px;
    padding: 50px 50px;
`

const ContainerButtonSave = styled.div`
    margin-top: 30px
`

const ButtonSave = styled.div`
    border-color: ${({ is_valid }) => !!is_valid ? "#6bc38c" : "#acacac"};
    background-color: ${({ is_valid }) => !!is_valid ? "#6bc38c" : "#acacac"};
    color: #fff;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    cursor: ${({ is_valid }) => !!is_valid ? "pointer" : "initial"}
`

const PasswordRequirementsDescription = styled.div`
    font-size: 12px;
    text-align: left;
    color: ${({ is_valid }) => !!is_valid ? "#575757" : "red"};
    opacity: 90%;
    margin-top: 10px;
`
let PasswordExpired = (props) => {

    const [settings, setSettings] = React.useState({
        showPassword: false,
        showPasswordConfirmation: false,
        password: "",
        confirm_password: "",
        passwordErrorMessage: "",
    })

    React.useEffect(() => {
        setSettings({ ...settings, passwordErrorMessage: "" })
    }, [settings.password, settings.confirm_password])

    const resetExpired = async () => {

        const params = {...settings};
        
        try {
            const { token } = queryString.parse(props.location.search);
            if(token){
                params.refresh = token
            }
            await props.UserActions.resetExpired({ ...params })
        } catch (e) {
            setSettings({ ...settings, passwordErrorMessage: e })
        }
    }

    const { t } = props;

    return (
        <>
            <PageContainer page={'login'}>
                <Header page={'login'} admin={false} />
                <Container>
                    <BoxContainerPasswordExpired>
                        <HeaderBoxExpired>
                            PASSWORD EXPIRED
                        </HeaderBoxExpired>
                        <BoxPasswordExpired>
                            <InputDefault
                                rightIcon={
                                    !settings.showPassword
                                        ? UnhidePassword
                                        : HidePassword
                                }
                                onClickRightIcon={() =>
                                    setSettings({
                                        ...settings,
                                        showPassword: !settings
                                            .showPassword
                                    })
                                }
                                leftIcon={PasswordIcon}
                                type={
                                    settings.showPassword
                                        ? 'text'
                                        : 'password'
                                }
                                value={settings.password}
                                placeHolder={t('placeHolderPassword')}
                                onChange={(value) => setSettings({ ...settings, password: value })}
                            />
                            <div style={{ margin: "30px 0px" }} />
                            <InputDefault
                                rightIcon={
                                    !settings.showPasswordConfirmation
                                        ? UnhidePassword
                                        : HidePassword
                                }
                                onClickRightIcon={() =>
                                    setSettings({
                                        ...settings,
                                        showPasswordConfirmation: !settings
                                            .showPasswordConfirmation
                                    })
                                }
                                leftIcon={PasswordIcon}
                                type={
                                    settings.showPasswordConfirmation
                                        ? 'text'
                                        : 'password'
                                }
                                value={settings.confirm_password}
                                placeHolder={t('placeHolderConfirmPassword')}
                                onChange={(value) => setSettings({ ...settings, confirm_password: value })}
                            />

                            <PasswordRequirementsDescription is_valid={true}>
                                {t("required_password_valid")}
                            </PasswordRequirementsDescription>
                            <ContainerButtonSave>
                                <ButtonSave
                                    is_valid={true}
                                    onClick={() => resetExpired()}
                                >
                                    {t('label_Save')}
                                </ButtonSave>
                            </ContainerButtonSave>
                            <PasswordRequirementsDescription is_valid={false}>
                                {!!settings.passwordErrorMessage.length ? props.t(settings.passwordErrorMessage) : ""}
                            </PasswordRequirementsDescription>
                        </BoxPasswordExpired>
                    </BoxContainerPasswordExpired>
                </Container>
            </PageContainer>
            <Footer />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        UserActions: bindActionCreators(UserActions, dispatch),
    };
};

PasswordExpired = connect(null, mapDispatchToProps)(PasswordExpired);
PasswordExpired = withRouter(PasswordExpired);

export default withTranslation()(PasswordExpired);