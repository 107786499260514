import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { withTranslation } from 'react-i18next';
import InputDefault from 'components/inputDefault';
import { connect } from 'react-redux';
import PageContainer from "components/pageContainer/pageContainer";
import Header from "features/user/header";
import Footer from "components/footer";
import emailIcon from 'assets/img/sign-in/email_icon.png';
import PasswordIcon from "assets/img/sign-in/password_icon.png";
import UnhidePassword from "assets/img/sign-in/unhide_password.png";
import HidePassword from "assets/img/sign-in/hide_password_icon.png";
import { UserActions } from "redux/reducers";
import * as services from 'redux/actions'
import { bindActionCreators } from 'redux';
import queryString from 'query-string'
class Invite extends Component {
    static propTypes = {
        prop: PropTypes
    };

    state = {
        code: null,
        email: "",
        name: "",
        triedSubmit: false,
        newPassword: '',
        newPasswordConfirmation: '',
        newPasswordConfirmationErrorMessage: '',
        newPasswordErrorMessage: ''
    };

    passValidate = password => {
        var re = {
            capital: /[A-Z]/,
            lower: /[a-z]/,
            digit: /[0-9]/,
            //'full'    : /^[@#][A-Za-z0-9]{7,13}$/
        };

        return re.capital.test(password) &&
            re.digit.test(password) &&
            re.lower.test(password) &&
            password.length >= 8;
    }

    componentWillMount() {
        var { token } = queryString.parse(this.props.location.search)

        if (!token) {
            return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
        }

        this.setState({ code: token })
        if (this.props.isAdmin) {
            return this.props.getInviteDetailsAdmin(token)
                .then((details) => {
                    if (details.userActive != 'A') {
                        return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
                    }
                    this.setState({ email: details.email, name: details.name })
                }).catch(() => {
                    return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
                })
        }
        this.props.getInviteDetails(token)
            .then((details) => {
                if (details.userActive) {
                    return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
                }
                this.setState({ email: details.email, name: details.name })
            }).catch(() => {
                return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/login`)
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.newPasswordErrorMessage.length > 0 && this.state.newPassword !== prevState.newPassword) {
            this.setState({
                newPasswordErrorMessage: ""
            })
        }
        if (this.state.newPasswordConfirmationErrorMessage.length > 0 && this.state.newPasswordConfirmation !== prevState.newPasswordConfirmation) {
            this.setState({
                newPasswordConfirmationErrorMessage: ""
            })
        }
    }

    submit = async () => {

        if (this.state.newPassword.length === 0 || this.state.newPasswordConfirmation.length === 0) {
            return this.setState({
                newPasswordErrorMessage: this.state.newPassword.length > 0 ? "" : this.props.t('inputRequiredField'),
                newPasswordConfirmationErrorMessage: this.state.newPasswordConfirmation.length > 0 ? "" : this.props.t('inputRequiredField'),
                triedSubmit: true
            })
        }

        if (this.state.newPasswordConfirmation !== this.state.newPassword) {
            return this.setState({
                newPasswordConfirmationErrorMessage: this.props.t('password_notMatch'),
                triedSubmit: true
            })
        }

        else if (!this.passValidate(this.state.newPassword)) {
            return this.setState({
                newPasswordErrorMessage: this.props.t('required_password_valid'),
                newPasswordConfirmationErrorMessage: this.props.t('required_password_valid'),
                triedSubmit: true
            })
        }

        if (this.props.isAdmin) {
            const result = await this.props.confirmInvite(this.state.email, this.state.newPassword, this.state.newPasswordConfirmation, this.props.isAdmin)
            this.props.loginUser(result)
            return this.props.history.push(`${process.env.REACT_APP_FRONT_URL}/admin/`)
        } else {
            const result = await this.props.confirmInvite(this.state.code, this.state.newPassword, this.state.newPasswordConfirmation, this.props.isAdmin)
            this.props.loginUser(result)
            return window.location.href = `${process.env.REACT_APP_FRONT_URL}/`
        }
    }
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <PageContainer page={'login'}>
                    <Header page={'login'} />
                    <div className="forgotPassword">
                        <div className="div-sign-in forgot-password">
                            <div className="div-white-sign">
                                <div className="div-label-sign">{t('WELCOME')}</div>
                                <div class="div-input">
                                    <div className="div-forgot">
                                        {t('YOU HAVE BEEN INVITED TO REGISTER AS A USER FOR ')}
                                    </div>

                                    <div className="site_name">
                                        {this.state.name}
                                    </div>

                                    <div className="email_invitecontainer label-email displayFlex jc-center ali-center site-invite-email-container">
                                        <img src={emailIcon} />
                                        <input
                                            type="email"
                                            readOnly={this.state.readOnly}
                                            className={`span-email ${this.state.readOnly}`}
                                            onChange={(e) => this.setState({ newEmail: e.target.value })}
                                            value={this.state.email}
                                        />
                                    </div>
                                    <div className="box-forgot-password-input">
                                        <div className="email-box confirmInvite">
                                            <div className="password-box b-none f-column">
                                                <InputDefault
                                                    rightIcon={!this.state.showPasswordNewPassword ? UnhidePassword : HidePassword}
                                                    onClickRightIcon={() => this.setState({ showPasswordNewPassword: !this.state.showPasswordNewPassword })}
                                                    leftIcon={PasswordIcon}
                                                    type={this.state.showPasswordNewPassword ? 'text' : 'password'}
                                                    value={this.state.newPassword}
                                                    placeHolder={t('placeHolderPassword')}
                                                    onChange={(value) => this.setState({ newPassword: value })}
                                                    error={this.state.newPasswordErrorMessage.length > 0 ? this.state.newPasswordErrorMessage : null}
                                                />
                                            </div>
                                        </div>
                                        <div className="email-box confirmInvite">
                                            <div className="password-box b-none">
                                                <InputDefault
                                                    rightIcon={!this.state.showPasswordNewPasswordConfirmation ? UnhidePassword : HidePassword}
                                                    onClickRightIcon={() => this.setState({ showPasswordNewPasswordConfirmation: !this.state.showPasswordNewPasswordConfirmation })}
                                                    leftIcon={PasswordIcon}
                                                    type={this.state.showPasswordNewPasswordConfirmation ? 'text' : 'password'}
                                                    value={this.state.newPasswordConfirmation}
                                                    placeHolder={t('placeHolderConfirmPassword')}
                                                    onChange={(value) => this.setState({ newPasswordConfirmation: value })}
                                                    error={this.state.newPasswordConfirmationErrorMessage.length > 0 ? this.state.newPasswordConfirmationErrorMessage : null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            className="button-sign"
                                            onClick={this.submit}
                                        >
                                            {t('label_SignIn')}
                                        </button>
                                    </div>
                                    <div className="div-contact">
                                        <a className="link_support" href="mailto: support@hemexhealth.com" target="_blank">{t('label_ContactSupport')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageContainer>
                <Footer />
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (values) => dispatch(UserActions.setUser(values)),
        getInviteDetails: (token) => dispatch(services.getInviteDetails(token)),
        getInviteDetailsAdmin: (token) => dispatch(services.getInviteDetailsAdmin(token)),
        confirmInvite: (code, newPasssword, newPasswordConfirmation, isAdmin) => dispatch(services.confirmInvite(code, newPasssword, newPasswordConfirmation, isAdmin))
    }
}
Invite = connect(null, mapDispatchToProps)(Invite)

export default withTranslation()(Invite)