import React, { Fragment } from 'react';
import SystemAdminContainer from "./systemAdminContainer";
import SiteManagement from "pages/SiteManagement";
import UserManagement from '../userManagement';
import Header from "features/user/header";
import SubHeader from "components/subHeader";
import { Switch, Route } from "react-router-dom";
import './index.scss';
import { useSelector } from 'react-redux'

export default function UserView(props) {
    const { t, router } = props;

    const site =  useSelector(state => { return state.UserReducer.userData.sites[0]});

    const entityType = useSelector(state => { 

        const user = state.UserReducer.userData;

        if(user.entity_type && user.entity_type.length){
            return user.entity_type;
        }

        return site.site_type;
    });

    const location = props.router.location.pathname;
    const label = location.includes('userManagement') ? `> ${t('title_SystemAdminTools_UserManagement')}` : location.includes('siteManagement') ? `> ${t('title_SystemAdminTools_SiteManagement')}` : ""
    
    return (
        <Fragment>
            <Header router={props.router} />
            <SubHeader
                title={
                    `${t('title_SystemAdminTools')} ${label}`}
                page={"systemAdmin"}
                onClick={() => window.location.href = `${process.env.REACT_APP_FRONT_URL}/system-admin`}
            />

            {location.includes('userManagement') ? <UserManagement router={router} /> : location.includes('siteManagement') ? <SiteManagement router={router} /> : <SystemAdminContainer {...props} entityType={entityType} history={props.router.history} />
            }
        </Fragment>
    )
}