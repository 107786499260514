
const initial = {
  "reader": {},
}

export const types = {
  "SET_READER": "SET_READER",
  "VERIFY_READER_SET_INITIAL_VALUES": "VERIFY_READER_SET_INITIAL_VALUES"
}

export const SET_READER = reader => ({ type: types.SET_READER, reader })
export const SET_INITIAL = () => ({ type: types.VERIFY_READER_SET_INITIAL_VALUES });

const VerifyReaderReducer = (state = initial, action) => {
  switch (action.type) {
    case types.SET_READER: {
      return {
        ...state,
        reader: { ...action.reader }
      }
    }
    case types.VERIFY_READER_SET_INITIAL_VALUES: {
      return initial
    }
  }
  return state;
};

export default VerifyReaderReducer;