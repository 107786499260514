import React from 'react'
import onClickOutside from "react-onclickoutside";

class DropDownOption extends React.Component {

    handleClickOutside = evt => {
        if (!this.props.open) return null;
        this.props.clickOutSide()
    };

    render() {

        const { selected, icons, set } = this.props;

        return (
            <div className="optionsContainer">
                <div className="icon-separator" />
                {icons.map((icon, key) =>
                    <img
                        className="icon"
                        src={icon}
                        style={{ width: '25px', height: '25px', marginBottom: key === 2 ? '7px' : '15px' }}
                        onClick={() => set(icon)}
                    />
                )}
            </div>
        )
    }
}

export default onClickOutside(DropDownOption);

