import moment from 'moment'


export default function getDateTimeByLanguage(value, language) {
    if (language == 'en') {
        moment.locale = 'en';
        return moment(value).format('MM/DD/YYYY hh:mm A')
    } else {
        moment.locale = 'ptBR';
        return moment(value).format('DD/MM/YYYY HH:mm')
    }
}

export function getHourByLanguage(value, language) {
    if (language == 'en') {
        moment.locale = 'en';
        return moment(value).format('hh:mm A')
    } else {
        moment.locale = 'ptBR';
        return moment(value).format('HH:mm')
    }
}

export function getDateByLanguage(value, language) {
    if (language == 'en') {
        moment.locale = 'en';
        return moment(value).format('MM/DD/YYYY')
    } else {
        moment.locale = 'ptBR';
        return moment(value).format('DD/MM/YYYY')
    }
}

export function getFormatByLanguage(language) {
    return '00/00/0000'
}