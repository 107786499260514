import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./index.scss";

class BoxNoData extends Component {
    render() {
        return (
            <div className='container div_box_no_data' style={this.props.styles || {}}>
                <div className='text-tests'>
                {this.props.data}
                </div>
                {/* <div class="div_loading_general">
                    <div class="loader_circle"></div>
                </div> */}
            </div>
        );
    }
}

BoxNoData.defaultProps = {
    data: "NO TEST RAN YET"
}

export default BoxNoData;