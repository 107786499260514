import React, { Component, Fragment } from 'react';
import CardLeftTexts from './cards/cardLeftTexts';
import CardRightTexts from './cards/cardRightTexts';
import SearchBarTable from 'components/searchBarTable';
import GenericTableHeader from 'components/tables/genericTableHeader';
import GenericTableRow from 'components/tables/genericTableRow';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tableHeaderDeviceManagementAdmin } from 'helper/getTableHeader.js';
import * as services from 'redux/actions';
import Check from 'components/checkComponent';

import './index.scss';
import {
    filterSites,
    getTotalPages,
    getItemsByPage,
    getCounters
} from './logics';
import AddDevice from 'features/readers/modalDevice';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Filters from './Filters';

class DeviceManagement extends Component {
    state = {
        allSites: [],
        diseases: [],
        filters: {
            search: '',
            card: 1,
            filterCardActive: 'all',
            site_type: '',
            city: '',
            country: '',
            site_participation: '',
            disease: {
                malaria: null,
                hb: null
            }
        },
        qty_page: 10,
        currentPage: 0,
        counters: {
            active: 0,
            discarted: 0,
            inactive: 0,
            total: 0,
            updated: 0,
            not_updated: 0
        },
        rows: [],
        allRows: false,
        sort: 'devices',
        sort_type: 'asc'
    };

    componentDidMount = async () => {
        const diseases = await this.props.services.getDiseases();
        const sites = await this.props.services.getDevicesSites();
        const counters = getCounters(sites);
        this.setState({
            diseases,
            allSites: sites,
            counters
        });
    };

    openReaders = (row) => {
        this.props.router.history.replace(
            `${process.env.REACT_APP_FRONT_URL}/admin/readers?id=${row.id}`
        );
    };

    createDevice = async (values) => {
        try {
            await this.props.services.createDevice(values);
            const diseases = await this.props.services.getDiseases();
            const sites = await this.props.services.getDevicesSites();
            const counters = getCounters(sites);
            this.setState({
                diseases,
                allSites: sites,
                counters,
                modalAddDevice: false
            });
        } catch (e) {
            const text =
                !!e && !!e.data && e.data.message ? e.data.message : 'Error!';
            await Swal.fire({
                text,
                type: 'warning',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#d33'
            });
            return;
        }
    };

    _handleCheckRow = ({ id }) => {
        if (this.state.allRows) {
            this.setState({ allRows: false });
        }
        this.setState({
            rows: this.state.rows.includes(id)
                ? this.state.rows.filter((row) => row != id)
                : [...this.state.rows, id]
        });
    };

    handleAllRows = (bool) => {
        if (bool) {
            const sites = filterSites(
                this.state.allSites,
                this.state.filters,
                this.state.diseases
            );
            return this.setState({
                allRows: true,
                rows: sites.map((site) => site.id)
            });
        }
        this.setState({
            allRows: false,
            rows: []
        });
    };

    touchCard = (card, value) => {
        if (value == null || typeof value == 'object') {
            return this.setState({
                filters: { ...this.state.filters, card },
                currentPage: 0
            });
        }

        this.setState({
            filters: { ...this.state.filters, card, filterCardActive: value },
            currentPage: 0,
            allRows: false,
            rows: []
        });
    };

    sort = (sortTitle) => {
        if (!sortTitle) return false;
        if (this.state.sort == sortTitle) {
            return this.setState({
                sort_type: this.state.sort_type == 'asc' ? 'desc' : 'asc'
            });
        }
        this.setState({ sort_type: 'asc', sort: sortTitle });
    };

    getFilters = () => {
        let items = { ...this.state.filters, sites: this.props.allSites };

        items.site_types = [{ id: 'ALL', value: 'ALL' }];
        items.cities = [{ id: 'ALL', value: 'ALL' }];
        items.countries = [{ id: 'ALL', value: 'ALL' }];
        items.participations = [];
        items.diseases = [];

        this.props.allSites.forEach((site) => {
            if (
                site.clinical_testing &&
                !items.participations.find(
                    (pt) => pt.value == 'Clinical Testing'
                )
            ) {
                items.participations.push({
                    id: 'Clinical Testing',
                    value: 'Clinical Testing'
                });
            }

            if (site.disease_types && site.disease_types.length) {
                site.disease_types.forEach((ds) => {
                    if (!items.diseases.find((i) => i.value == 'ds')) {
                        items.diseases.push({ id: ds, value: ds });
                    }
                });
            }

            if (
                site.country &&
                !items.countries.find((s) => s.value == site.country)
            ) {
                items.countries.push({ id: site.country, value: site.country });
            }
            if (site.city && !items.cities.find((s) => s.value == site.city)) {
                items.cities.push({ id: site.city, value: site.city });
            }

            let type = null;

            if (site.site_type) {
                type = site.site_type == 1 ? 'SINGLE' : 'MULTI';
            }

            if (type && !items.site_types.find((s) => s.value == type)) {
                items.site_types.push({ id: type, value: type });
            }
        });

        return items;
    };

    render() {
        const { t } = this.props;

        const itemsFiltered = filterSites(
            this.state.allSites,
            this.state.filters,
            this.state.diseases,
            this.state.sort,
            this.state.sort_type
        );

        const rows = getItemsByPage(
            itemsFiltered,
            this.state.currentPage,
            this.state.qty_page
        );

        return (
            <Fragment>
                {this.state.modalAddDevice && (
                    <AddDevice
                        admin={true}
                        save={(values) => this.createDevice(values)}
                        open={this.state.modalAddDevice}
                        onClose={() => this.setState({ modalAddDevice: false })}
                        sites={this.props.allSites.map((site) => {
                            return { ...site, value: site.name };
                        })}
                    />
                )}
                <div className="deviceManagement">
                    <div className="tests-patient-container">
                        <div className="div-card-device">
                            <CardRightTexts
                                onClick={(val) => this.touchCard(1, val)}
                                filterActive={
                                    this.state.filters.card != 1
                                        ? null
                                        : this.state.filters.filterCardActive
                                }
                                active={this.state.filters.card == 1}
                                featuredRightValue={`${t(
                                    'label_SystemAdminSiteManagement_CardStatus'
                                )}`}
                                leftValue={this.state.counters.total}
                                normalRightValues={[
                                    {
                                        key: 0,
                                        value: `${
                                            this.state.counters.active
                                        } ${t('label_Active').toUpperCase()}`
                                    },
                                    {
                                        key: 1,
                                        value: `${
                                            this.state.counters.discarted
                                        } ${t(
                                            'label_CardDeactivated'
                                        ).toUpperCase()}`
                                    }
                                ]}
                            />
                        </div>
                        <div className="div-card-device">
                            <CardLeftTexts
                                onClick={(val) => this.touchCard(2, val)}
                                active={this.state.filters.card == 2}
                                filterActive={
                                    this.state.filters.card != 2
                                        ? null
                                        : this.state.filters.filterCardActive
                                }
                                featuredRightValue={this.props
                                    .t('FIRMWARE')
                                    .toUpperCase()}
                                normalLeftValues={[
                                    {
                                        key: 0,
                                        value: `${
                                            this.state.counters.updated
                                        } ${t('label_Current').toUpperCase()} `
                                    },
                                    {
                                        key: 1,
                                        value: `${
                                            this.state.counters.not_updated
                                        } ${t(
                                            'label_NotUpdated'
                                        ).toUpperCase()} `
                                    }
                                ]}
                            />
                        </div>
                        <div className="div-card-device">
                            <CardLeftTexts
                                onClick={(val) => ''}
                                active={false}
                                filterActive={false}
                                featuredRightValue={this.props
                                    .t('label_ReadersWithErrorsLog')
                                    .toUpperCase()}
                                normalLeftValues={[{ key: 1, value: `0` }]}
                            />
                        </div>
                    </div>
                    <SearchBarTable
                        placeHolder={t('label_SearchForSites')}
                        value={this.props.searchValue}
                        add={() => {
                            this.setState({
                                modalAddDevice: true
                            });
                        }}
                        hideDelete={true}
                        router={this.props.router}
                        onChange={(value) =>
                            this.setState({
                                filters: {
                                    ...this.state.filters,
                                    search: value
                                }
                            })
                        }
                        showPrinter={true}
                        perPage={this.state.qty_page}
                        changeQtyPage={(value) =>
                            this.setState({ qty_page: value })
                        }
                        currentPage={this.state.currentPage}
                        changePage={(value) =>
                            this.setState({ currentPage: value })
                        }
                        totalPages={getTotalPages(
                            filterSites(
                                this.state.allSites,
                                this.state.filters,
                                this.state.diseases,
                                this.state.sort,
                                this.state.sort_type
                            ),
                            this.state.qty_page
                        )}
                        filterBox={
                            <Filters
                                filters={this.getFilters()}
                                toggleFilter={(val) =>
                                    this.setState({ filters: val })
                                }
                            />
                        }
                    />
                    <div className="pages-table-container">
                        <GenericTableHeader
                            checked={this.state.allRows}
                            cols={tableHeaderDeviceManagementAdmin(
                                t,
                                this.state.sort
                            )}
                            checkAction={(bool) => this.handleAllRows(bool)}
                            sort_type={this.state.sort_type}
                            onClick={(index, sortTitle) => this.sort(sortTitle)}
                        />
                        <div className="div_rows">
                            {rows.map((row) => {
                                row.site_primary = (
                                    <Check
                                        check={row.site_primary}
                                        className={'tdsite_primary'}
                                        color={
                                            row.site_primary ? '#5dd33d' : 'red'
                                        }
                                    />
                                );
                                return (
                                    <GenericTableRow
                                        sites_children={
                                            row.site_childrens_name.length
                                                ? row.site_childrens_name
                                                : null
                                        }
                                        cols={tableHeaderDeviceManagementAdmin(
                                            t,
                                            this.state.sort
                                        )}
                                        checksSelecteds={this.state.rows}
                                        allCheckSelected={this.state.allRows}
                                        checkAction={(id) =>
                                            this._handleCheckRow(id)
                                        }
                                        row={{
                                            ..._.omit(
                                                row,
                                                'site_childrens_name'
                                            ),
                                            type:
                                                row.type == 1
                                                    ? `${this.props.t(
                                                          'label_SingleSite'
                                                      )}`
                                                    : `${this.props.t(
                                                          'label_MultiSite'
                                                      )}`
                                        }}
                                        onClick={() => this.openReaders(row)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        allSites: state.UserReducer.userData.sites,
    };
};

const mapDispatchToProps = (dispatch) => ({
    services: bindActionCreators(services, dispatch)
});

DeviceManagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceManagement);

export default withTranslation()(DeviceManagement);
