import axios from "helper/axiosHelper";

export async function deleteSite(id) {
    try {
        return await axios.delete(`/site/${id}`)
    } catch (e) {
        await Promise.reject()
    }
}

export async function createSite(values) {
    try {
        const site = await axios.post('/site', {
            ...values
        });
        return site;
    } catch (e) {
        return await Promise.reject();
    }
}

export async function updateSite(values) {
    try {
        const { id } = values;
        await axios.put(`/site/${id}`, {
            ...values
        })
        return true;
    } catch (e) {
        return await Promise.reject();
    }
}

export async function getDevicesSites() {
    try {
        return await axios.get('/admin/sites')
    } catch (e) {
        return await Promise.reject(e)
    }
}

export async function getSitesAdmin(id = null) {
    try {
        if (!!id) {
            let sites = await axios.get(`/admin/sites/${id}`);
            return sites.sort((a, b) => a.name - b.name);
        }
        let sites = await axios.get('site', { params: { lastNDays: 0 } });
        return sites.sort((a, b) => a.name - b.name);
    } catch (e) {
        return await Promise.reject();
    }
}

export async function adminCreateSite(obj) {
    try {
        return await axios.post(`/admin/site/`, obj)
    } catch (e) {
        return await Promise.reject(e.response || null);
    }
}

export async function getSites(lastNDays = 0) {
    try {
        let params = {};

        if (lastNDays != undefined) params.lastNDays = lastNDays;

        return await axios.get('site', { params });
    } catch (e) {
        return await Promise.reject();
    }
}

export async function getSitesDeactivated(lastNDays = 0){
    try {
        return await axios.get('site/deactivated', { params: lastNDays });
    } catch (e) {
        return await Promise.reject();
    }
}
