import React, { Fragment } from "react";
import PageContainer from "components/pageContainer/pageContainer";
import Steps from "features/wizard/stepsHeader";
import BarTitle from "features/wizard/barTitle";
import Footer from "components/footer";
import CustomButton from "components/customButton";
import addMoreIcon from "assets/img/wizard/plus_add_satelite_add_site_icn.png";
import NoLogo from "assets/img/placeholder_nosite.png";
import TrashIcon from "assets/img/readers/trash_icn_table.png";
import { headerWizardDevices, headerWizardUsers } from "helper/getTableHeader";
import _ from "lodash";
import { bindActionCreators } from "redux";
import * as services from "redux/actions";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Loading from "components/loading";
import wizardRoleValidation from "./wizardRoleValidation";

//customtable
import CustomTable from "components/customTable";

// Steps
import Step1 from "components/addSiteUser";
import Step2 from "components/addDevice";
import Step3 from "components/inviteUser";

import "./index.scss";
import { withTranslation } from "react-i18next";
import { thisExpression } from "@babel/types";
import { headerWizardSites } from "../../helper/getTableHeader";

class RegisterDetails extends React.Component {
  state = {
    step: 0,
    previousStep: 1,
    registered: "",
    diseases: [],
    dataSelected: null,
    loading: false,
  };

  componentWillMount() {
    this.props.services.getSites();
    this.props.services.getReaders();
    this.props.services.getDiseases().then((diseases) => {
      this.setState({ diseases });
    });

    if (["A", "W"].includes(this.props.data.status)) {
      return this.setState({
        step: 1,
        registered: "sites",
      });
    }
    this.setState({ step: 1 });
  }

  deleteRow = async (row) => {
    if (this.state.step === 3 && this.props.user_id === row.id) {
      await Swal.fire({
        text: "You can't delete your user!",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: false,
        cancelButtonColor: "#d33",
      });
      return;
    }

    if (
      this.state.step == 1 &&
      row.id == this.props.data.sites.find((site) => site.parent == "-").id
    ) {
      await Swal.fire({
        text: "You can't delete your primary site!",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: false,
        cancelButtonColor: "#d33",
      });
      return;
    }
    const result = await Swal.fire({
      text: "Are you sure you want to Delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.value) {
      if (this.state.step === 1) {
        await this.props.services.deleteSite(row.id);
        await this.props.services.getSites();
      }
      if (this.state.step === 2) {
        await this.props.services.deleteReader({ id: row.id });
        await this.props.services.getReaders();
        await this.props.services.getReadersOfflineCounter();
      }
      if (this.state.step == 3) {
        await this.props.services.deleteUser(row.id);
        await this.props.services.getInvites();
      }

      let registered = "sites";

      if (this.state.step == 2) {
        registered = this.props.devices.length > 0 ? "devices" : null;
      }
      if (this.state.step == 3) registered = "invites";
      return this.setState({
        registered: registered,
        dataSelected: null,
      });
    }
  };
  editRow = async (row) => {
    if (this.state.step == 1)
      this.setState({ registered: null, dataSelected: row });
  };

  updateSite = async (values) => {
    await this.props.services.updateSite(values);
    await this.props.services.getSites();
    this.setState({
      registered: "sites",
    });
  };

  createSite = async (values) => {
    try {
      await this.props.services.createSite(values);
      await this.props.services.getSites();
      const diseases = await this.props.services.getDiseases();
      this.setState({
        registered: "sites",
        dataSelected: null,
        diseases,
      });
    } catch (e) {
      console.log(e);
    }
  };

  inviteUser = async (values) => {
    this.setState({ loading: true });
    try {
      await this.props.services.inviteUser(values);
      await this.props.services.getInvites();
      this.setState({ registered: "invites", loading: false });
    } catch (e) {
      let text = `Could not Save User. <br/>This user already has a Hemex Account.`;
      await Swal.fire({
        html: text,
        type: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#d33",
      });
      this.setState({ loading: false });
    }
  };

  createDevice = async (values) => {
    this.setState({ loading: true });
    try {
      if (values.id) {
        await this.props.services.updateDevice(values);
        this.setState({ loading: false });
      } else {
        await this.props.services.createDevice(values);
        this.setState({ loading: false });
      }
    } catch (e) {
      const text = !!e && !!e.data.message ? e.data.message : "Error!";
      await Swal.fire({
        text,
        type: "warning",
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonColor: "#d33",
      });
      this.setState({ loading: false });
      return;
    }
    await this.props.services.getReaders();
    await this.props.services.getReadersOfflineCounter();
    this.setState({
      registered: "devices",
      loading: false,
    });
  };

  save = (type, values) => {
    if (this.state.step === 1) {
      if (values.id) {
        return this.updateSite(values);
      }
      return this.createSite(values);
    }

    if (this.state.step === 2) {
      this.createDevice(values);
    }

    if (this.state.step === 3) {
      if (values.id) {
        return this.props.services.editUser(values).then(() => {
          this.props.services.getInvites().then(() => {
            this.setState({ registered: "invites" });
          });
        });
      }
      this.inviteUser(values);
    }
  };

  changeStepNext = () => {
    if (this.state.step == 1) {
      return this.props.services.getReaders().then(() => {
        this.setState({
          dataSelected: null,
          registered: this.props.devices.length > 0 ? "devices" : null,
          step: 2,
          previousStep: 1,
        });
      });
    }
    if (this.state.step == 2) {
      return this.props.services.getInvites().then(() => {
        this.setState({
          dataSelected: null,
          registered: "invites",
          step: 3,
          previousStep: 2,
        });
      });
    }
    return this.skipWizard();
  };

  changeStepPrev = () => {
    this.state.step != 1 &&
      this.setState({
        step: this.state.step - 1,
        dataSelected: null,
      });
  };

  closeAddSite = () => {
    this.setState({
      step: 1,
      registered: "sites",
      dataSelected: null,
    });
  };

  changeStep = (step, previousStep) => {
    let reg = null;

    if (step === 2) {
      if (this.props.devices.length === 0) {
        reg = null;
      } else {
        reg = "devices";
      }
    }

    if (step === 3) {
      if (this.props.invites.length === 0) {
        reg = null;
      } else {
        reg = "invites";
      }
    }

    if (previousStep === null) {
      reg = "sites";
      this.setState({ step, registered: reg, dataSelected: null });
    } else {
      this.setState({
        step,
        previousStep,
        registered: reg,
        dataSelected: null,
      });
    }
  };

  changePreviousStep = (previousStep) => {
    let reg = null;

    if (this.state.step - 1 == 2) {
      if (this.props.devices.length == 0) {
        reg = null;
      } else {
        reg = "devices";
      }
    }

    if (this.state.step - 1 == 1) {
      reg = "sites";
    }
    if (this.state.step - 1 == 3) {
      reg = "invites";
    }
    this.setState({
      step: this.state.step - 1,
      registered: reg,
      dataSelected: null,
    });
  };

  skipWizard = () => {
    if (this.props.data.status == "W") {
      this.props.services
        .skipReaders()
        .then(() => {
          return (window.location.href = `${process.env.REACT_APP_FRONT_URL}/tutorial`);
        })
        .catch((e) => {
          const text =
            !!e && !!e.data && !!e.data.message ? e.data.message : "Error!";
          Swal.fire({
            text,
            type: "warning",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonColor: "#d33",
          }).then(() => {
            return;
          });
        });
    } else {
      window.location.href = `${process.env.REACT_APP_FRONT_URL}/`;
    }
  };

  getFieldsReadOnly = () => {
    if (this.state.step == 1) {
      if (!this.state.dataSelected) {
        return [];
      }

      if (this.state.dataSelected && this.state.dataSelected.id) {
        return ["name", "disease_types"];
      }

      return [];
    }
  };

  getRows = () => {
    const { registered, diseases } = this.state;

    if (!this.props[registered]) return [];

    return this.props[registered].map((row) => {
      let rowReduced = _.omit(row, ["status"]);

      if (registered == "sites") {
        rowReduced = {
          logo: row.logo && row.logo.length > 0 ? row.logo : NoLogo,
          name: row.name,
          country: row.country,
          zip: row.zip_code,
          phone_number: `${row.phone_code} ${row.phone_number}`,
          address: row.address,
          city: row.city,
          state: row.state,
          disease_types: row.disease_types.map((item, key) => {
            const disease = diseases.find((ds) => ds.id == item);
            if (disease) {
              return ` ${disease.name} ${
                key === row.disease_types.length - 1 ? "" : ","
              }`;
            }
          }),
          trash: TrashIcon,
          id: row.id,
          zip_code: row.zip_code,
          phone_code: row.phone_code,
          phone_number: row.phone_number,
        };
      }

      if (registered == "devices") {
        rowReduced = {
          site_name: row.site_name,
          product_number: JSON.parse(row.settings).product_number,
          serial_number: JSON.parse(row.settings).serial_number,
          trash: TrashIcon,
          id: row.id,
        };
      }

      if (registered == "invites") {
        rowReduced = {
          first_name: row.first_name,
          email: row.email_address,
          role:
            row.role_id == 2
              ? this.props.t("label_role_user_admin")
              : this.props.t("label_role_user_site"),
          site_name: row.site,
          trash: TrashIcon,
          ..._.omit(row, "role"),
          user_id: this.props.data.user_id,
        };
      }
      return rowReduced;
    });
  };

  render() {
    let step1title = this.props.t("label_SingleSiteRegistration");
    if (this.props.data.entity_type == 2 && this.props.data.status == "R") {
      step1title = this.props.t("label_PrimarySite");
    } else if (
      this.props.data.entity_type == 2 &&
      this.props.data.status != "R"
    ) {
      step1title = this.props.t("label_SateliteSite");
    }
    if (this.state.dataSelected) {
      step1title = this.props.t("label_SiteUpdate");
    }
    let titleStep = [
      step1title,
      "REGISTER GAZELLE READERS",
      "ADD MORE USERS TO YOUR SITE",
    ];
    let registeredTitle = [
      this.props.t("headerRegisteredSitesTitle"),
      this.props.t("label_RegisterGazelle"),
      this.props.t("label_AddMoreUsersToSite"),
    ];
    let btnTitle = [
      "ADD SATELLITE SITES",
      "ADD HEMEX READER",
      "ADD MORE USERS",
    ];
    const data = this.props;
    const { step, previousStep, registered, diseases } = this.state;

    let headerTable = {};
    if (registered == "sites") headerTable = headerWizardSites(this.props.t);
    if (registered == "devices")
      headerTable = headerWizardDevices(this.props.t);
    if (registered == "invites") headerTable = headerWizardUsers(this.props.t);

    let rowTable = this.getRows();
    return (
      <Fragment>
        <Loading show={this.state.loading} />
        <PageContainer>
          <Steps
            stepActive={step}
            previousStep={previousStep}
            status={data.status}
            devices={data.devices}
            invites={data.invites}
            changeStep={this.changeStep}
            changePreviousStep={this.changePreviousStep}
          />
          <BarTitle
            router={this.props.router}
            sites={
              this.props.data.status == "R"
                ? []
                : !!this.props.data.sites
                ? this.props.data.sites
                : []
            }
            title={
              !registered ? titleStep[step - 1] : registeredTitle[step - 1]
            }
            handleChangeNext={() => this.changeStepNext()}
            step={step}
            actions={this.props.services}
            t={this.props.t}
            userData={this.props.data}
            skip={() => this.changeStepNext()}
          />

          {!registered ? (
            <Fragment>
              {step === 1 && (
                <Step1
                  save={(formValues) => {
                    return this.save("sites", formValues);
                  }}
                  userData={this.props.data}
                  token={this.props.data.token}
                  handleChangePrev={() => this.closeAddSite()}
                  diseases={
                    !this.state.dataSelected ||
                    !this.state.dataSelected.disease_types ||
                    !this.state.dataSelected.disease_types.length
                      ? this.state.diseases
                      : this.state.diseases.filter((di) =>
                          this.state.dataSelected.disease_types.includes(di.id)
                        )
                  }
                  data={this.state.dataSelected}
                  fieldsReadOnly={this.getFieldsReadOnly()}
                  site={
                    this.props.data.status == "R"
                      ? this.props.data.sites &&
                        this.props.data.sites.find((site) => site.status == 1)
                      : null
                  }
                />
              )}

              {step === 2 && (
                <Step2
                  save={(formValues) => this.save("readers", formValues)}
                  data={this.state.dataSelected}
                  canCancel={
                    this.props.devices && this.props.devices.length > 0
                  }
                  cancel={() =>
                    this.setState({ registered: "devices", dataSelected: null })
                  }
                  sites={
                    data.sites &&
                    data.sites.map((site) => {
                      return { ...site, value: site.name };
                    })
                  }
                  handleChangePrev={() => this.changeStepPrev()}
                />
              )}

              {step === 3 && (
                <Step3
                  save={(formValues) => this.save("users", formValues)}
                  handleChangePrev={() => {
                    this.setState({
                      registered: "invites",
                      step: 3,
                      dataSelected: null,
                    });
                  }}
                  data={
                    !!this.state.dataSelected ? this.state.dataSelected : null
                  }
                  sitesOptions={
                    data.sites &&
                    data.sites.map((site) => {
                      return { ...site, value: site.name };
                    })
                  }
                />
              )}
            </Fragment>
          ) : (
            !!registered && (
              <Fragment>
                <div className="divStepTable">
                  <div
                    className={`div-middle-container heightDefault displayFlex jc-space ${
                      this.state.registered == "sites" &&
                      this.props.data.entity_type == 1
                        ? "jc-end"
                        : null
                    }`}
                  >
                    {this.state.registered != "sites" ? (
                      <CustomButton
                        value={btnTitle[step - 1]}
                        className="buttonWithImage left"
                        handleChange={() => this.setState({ registered: null })}
                      >
                        <img src={addMoreIcon} />
                      </CustomButton>
                    ) : (
                      this.props.data.entity_type == 2 && (
                        <CustomButton
                          value={btnTitle[step - 1]}
                          className="buttonWithImage left"
                          handleChange={() =>
                            this.setState({ registered: null })
                          }
                        >
                          <img src={addMoreIcon} />
                        </CustomButton>
                      )
                    )}
                    <CustomButton
                      value={
                        step === 3
                          ? this.props.t("label_Done")
                          : this.props.t("label_Next")
                      }
                      handleChange={() => this.changeStepNext()}
                    />
                  </div>
                  <div className={`p-table ${step >= 2 && "centerTable"}`}>
                    <CustomTable
                      header={headerTable}
                      rows={rowTable}
                      onClick={(row, column) =>
                        column.includes("delete")
                          ? this.deleteRow(row)
                          : this.editRow(row)
                      }
                    />
                  </div>
                </div>
              </Fragment>
            )
          )}
        </PageContainer>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const sitesList = state.UserReducer.userData.sites
    ? state.UserReducer.userData.sites.filter((site) => site.status != 3)
    : [];
  const invitesList = state.UserReducer.userData.invites
    ? state.UserReducer.userData.invites.filter((user) =>
        ["I", "A"].includes(user.status)
      )
    : [];

  return {
    sites: sitesList,
    devices: state.ReadersReducer.readers,
    invites: invitesList,
    data: state.UserReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  services: bindActionCreators(services, dispatch),
});

RegisterDetails = connect(mapStateToProps, mapDispatchToProps)(RegisterDetails);

export default wizardRoleValidation(withTranslation()(RegisterDetails));
