import React, { useState, useEffect, Fragment } from "react";
import "./index.scss";
import arrowDown from "assets/img/header-new/dropdown_arrow.png";
import emailIcon from "assets/img/header-new/email_orange_icn.png";
import downloadIcon from "assets/img/header-new/download_orange_icn.png";
import printerIcon from "assets/img/header-new/print_orange_icn.png";
import queryString from "query-string";
import { PatientsActions } from "redux/reducers"
import * as services from "redux/actions";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { getDateByLanguage } from "helper/dateByLanguage";
import HeaderUser from "features/user/header";
import HeaderAdmin from "features/admin/header";
import ModalSendEmail from "components/modalSendEmail";
import ReactTooltip from "react-tooltip";
import printJS from "print-js";

class PatientHistory extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
	}

	testsFactory = (tests_list) => {
		return tests_list.map(test => {
			let scd = !test.scd_band
				? []
				: JSON.parse(test.scd_band);
			if (
				test.iterpretation &&
				test.iterpretation.length
			) {
				const noteIndex = test.iterpretation.indexOf(
					"Note:"
				);
				const note = test.iterpretation.substring(
					noteIndex
				);
				const interpretation = test.iterpretation.substring(
					0,
					noteIndex
				);
				scd.push({
					HbType: "Interpretation",
					Percentage: [
						<div>{interpretation}</div>,
						<div>{note}</div>,
					],
				});
			}
			return { ...test, scd_band: scd };
		})
	}

	state = {
		history: [1, 2, 3, 4, 5],
		canvas: null,
		open: null,
		patient_data: {},
		options: {
			orientation: "l",
			unit: "pt",
			format: "letter",
			scale: 5.0,
		},
		image: null,
		modalEmail: false,
		testid: null,
	};

	setOpen = async (open) => {
		if (open !== null) {
			const test = this.state.patient_data.tests[open];
			if (test && test.id && test.disease_id != 1) {
				const image = await this.props.services.getTestFile(
					"image",
					test.id
				);
				return this.setState({
					open,
					image,
				});
			}
		}
		this.setState({ image: null, open });
	};

	componentDidMount = async () => {

		const { patient_id } = this.props;

		if (patient_id == null) return null;

		const patientArray = await this.props.services.getPatientHistory(patient_id);

		if (!patientArray || !patientArray.length) return null;

		const data = {
			...patientArray[0],
			...patientArray[0].patient_data[0],
			tests: this.testsFactory(patientArray[0].tests)
		}

		this.setState({
			patient_data: { ...data }
		})
	}

	redirect = () => {
		if (this.props.router.location.pathname.includes("/admin")) {
			return (window.location.href =
				process.env.REACT_APP_FRONT_URL + "/admin/patients");
		}
		return (window.location.href = process.env.REACT_APP_FRONT_URL + "/");
	};

	setTestId = (id) => {
		this.props.PatientsActions.setTestId(id);
	}

	print = async (id) => {
		const url = await this.props.services.getTestFile("pdf", id);
		if (!url) return;

		this.setTestId(id)
		
		window.open(
			url,
			"Print",
			"left=200, top=200, width=950, height=500, toolbar=0, resizable=0"
		);
	};

	render() {
		const { history, open } = this.state;
		console.log(this.state.patient_data.tests);
		return (
			<Fragment>
				<div
					className="patient-history-container"
					ref={this.myRef}
					id="page-id"
				>
					{this.state.modalEmail && (
						<ModalSendEmail
							open={this.state.modalEmail}
							onClose={() => this.setState({ modalEmail: false })}
							ids={[this.state.testid]}
							patientsIds={[this.state.patient_data.json_id]}
						/>
					)}
					{this.props.userData.isAdmin == 2 ? (
						<HeaderAdmin router={this.props.router} />
					) : (
							<HeaderUser router={this.props.router} />
						)}
					<div className="displayFlex">
						<div className="patient-history-bar">
							<div className="title-page"></div>
						</div>
						<div className="patient-history-bar">
							<div className="title-page">
								{this.props.t("label_PatientTestHistory")}
							</div>
						</div>
						<div
							className="patient-history-bar right"
							onClick={() => this.redirect()}
						>
							<div className="title-page"></div>
						</div>
					</div>
					<div
						className="patient-history-bar"
						style={{ backgroundColor: "#54565B" }}
					>
						<div className="displayFlex jc-space">
							<div className="displayFlex">
								<div className="patient-name pd-left210">
									{this.state.patient_data.name}
								</div>

								<div className="patient-header-info">
									PATIENT ID:{" "}
									{this.state.patient_data.json_id}
								</div>
								<div className="patient-header-info">
									{this.state.patient_data.gender || ""}
								</div>
								<div className="patient-header-info">
									{!!this.state.patient_data.birthdate
										? moment().diff(
											this.state.patient_data
												.birthdate,
											"years"
										) + " years"
										: ""}
								</div>
							</div>
						</div>
					</div>
					<div className="patient-info-container pd-left200 displayFlex ali-center">
						<div className="box">
							<div className="marginBottom25">
								Date of Birth:{" "}
								{this.state.patient_data.birthdate
									? `${getDateByLanguage(
										this.state.patient_data.birthdate,
										window.currentLanguage
									)}`
									: "-"}
							</div>
							<div className="displayFlex">
								<div className="pd-right100">
									Father:{" "}
									{!!this.state.patient_data.father_firstname
										? this.state.patient_data
											.father_firstname + " ,"
										: "-"}
								</div>
								<div>
									Mother:{" "}
									{!!this.state.patient_data.mother_firstname
										? this.state.patient_data
											.mother_firstname + " ,"
										: "-"}
								</div>
							</div>
						</div>
						<div className="box displayFlex pd-right25">
							<div className="right-box">
								<div className="marginBottom25">
									Home Address:
								</div>
								<div>
									{this.state.patient_data.address &&
										this.state.patient_data.address !== "N/A"
										? this.state.patient_data.address
										: "-"}
								</div>
							</div>
							<div className="right-box">
								<div className="marginBottom25">
									Height:{" "}
									{this.state.patient_data.height || "-"}
								</div>
								<div>
									Weight:{" "}
									{this.state.patient_data.weight || "-"}
								</div>
							</div>
						</div>
					</div>
					<div
						className="patient-history-bar"
						style={{ backgroundColor: "#898A8D" }}
					>
						<div
							className="patient-header-info pd-left210"
							style={{ textAlign: "left" }}
						>
							TEST HISTORY
						</div>
					</div>

					<div
						className="time-line-container pd-left210"
						style={{ position: "relative" }}
					>
						<div className="line-left-absolute" />
						<div className="item-container">
							<div className="line" />
							{this.state.patient_data.tests &&
								this.state.patient_data.tests.map(
									(card, key) => (
										<div className="displayFlex">
											<div
												className="ball"
												style={{
													backgroundColor:
														open === key
															? "white"
															: "#ffa300",
													border: "1px solid #ffa300",
												}}
											/>
											<div className="line card-line" />
											<div className="cardContainer">
												<div className="cardDate">
													{getDateByLanguage(
														moment(
															card.applied_at,
															"YYYY-MM-DD HH:mm:ss"
														),
														window.currentLanguage
													)}
												</div>
												<div className="cardInfo">
													<div className="card-left-side displayFlex">
														<div className="left-side">
															<div className="testTitle displayFlex ali-center">
																{card.test_type}
															</div>
															{open === key && (
																<Fragment>
																	<div className="displayFlex marginBottom5">
																		<div className="pd-right10 marginBottom5">
																			Test
																			Type:{" "}
																		</div>
																		<div className="testSubtitle">
																			{
																				card.test_type
																			}
																		</div>
																	</div>
																	<div className="displayFlex marginBottom5">
																		<div className="pd-right10 marginBottom5">
																			Test
																			Date{" "}
																		</div>
																		<div className="testSubtitle">
																			{getDateByLanguage(
																				moment(
																					card.applied_at,
																					"YYYY-MM-DD HH:mm:ss"
																				),
																				window.currentLanguage
																			)}
																		</div>
																	</div>
																	<div className="displayFlex marginBottom5">
																		<div className="pd-right10 marginBottom5">
																			Patient
																			ID:{" "}
																		</div>
																		<div className="testSubtitle">
																			{
																				this
																					.state
																					.patient_data
																					.json_id
																			}
																		</div>
																	</div>
																	<div
																		className="displayFlex"
																		style={{
																			marginBottom:
																				"30px",
																		}}
																	>
																		<div className="pd-right10 marginBottom5">
																			Test
																			Location:{" "}
																		</div>
																		<div className="testSubtitle">
																			{
																				card.location
																			}
																		</div>
																	</div>
																</Fragment>
															)}
														</div>
														{open == key && (
															<div className="displayFlex icons-container flex-1">
																<img
																	src={
																		emailIcon
																	}
																	style={{
																		height: 25,
																		width: 25,
																	}}
																	onClick={() =>
																		this.setState(
																			{
																				modalEmail: true,
																				testid:
																					card.id,
																			}
																		)
																	}
																	data-tip
																	data-for="send-email"
																/>
																<div
																	onClick={() =>
																		this.props.PatientsActions.downloadTest(
																			card.id
																		)
																	}
																>
																	<img
																		src={
																			downloadIcon
																		}
																		style={{
																			height: 25,
																			width: 25,
																			cursor:
																				"pointer",
																		}}
																		data-tip
																		data-for="download"
																	/>
																</div>
																<img
																	src={
																		printerIcon
																	}
																	style={{
																		height: 25,
																		width: 25,
																	}}
																	onClick={() =>
																		""
																	}
																	data-tip
																	data-for="print"
																	onClick={() =>
																		this.print(
																			card.id
																		)
																	}
																/>

																<ReactTooltip
																	id="send-email"
																	type="dark"
																>
																	<span>
																		{this.props.t(
																			"Send Email"
																		)}
																	</span>
																</ReactTooltip>
																<ReactTooltip
																	id="download"
																	type="dark"
																>
																	<span>
																		{this.props.t(
																			"Download"
																		)}
																	</span>
																</ReactTooltip>
																<ReactTooltip
																	id="print"
																	type="dark"
																>
																	<span>
																		{this.props.t(
																			"Print"
																		)}
																	</span>
																</ReactTooltip>
															</div>
														)}
													</div>
													<div className="card-right-side cp">
														<div
															className={`testSelect displayFlex ${
																card.result
																} ${
																card.disease_id ==
																	2
																	? "Hb"
																	: "Malaria"
																}`}
															onClick={() =>
																this.setOpen(
																	open === key
																		? null
																		: key
																)
															}
														>
															<div>
																{card.result.toUpperCase()}
															</div>
															<img
																src={arrowDown}
																style={{
																	transform:
																		open ===
																			card
																			? " rotate(180deg)"
																			: "none",
																}}
															/>
														</div>
														{open === key && (
															<Fragment>
																<div className="right-side-card-info">
																	<div className="white-bar">
																		{card.disease_id ==
																			2 ? (
																				<>
																					<img
																						style={{
																							height:
																								"100%",
																							width:
																								"100%",
																						}}
																						className="columnHover"
																						src={
																							this
																								.state
																								.image
																						}
																					/>
																					<div className="divText multi">
																						<div className="title">
																							Result
																							HB
																							Variant
																					</div>

																						{card.scd_band.map(
																							(
																								scd
																							) => {
																								return (
																									<div
																										className={
																											"scdContainer"
																										}
																									>
																										{scd.HbType ==
																											"Interpretation" ? (
																												<div>
																													<div className="scd-title">
																														{
																															scd.HbType
																														}

																												:
																											</div>
																													<div className="scd_item interpretation">
																														{
																															scd.Percentage
																														}
																													</div>
																												</div>
																											) : (
																												<>
																													<div className="scd-title">
																														{
																															scd.HbType
																														}

																												:
																											</div>
																													<div className="scd_item">
																														{
																															scd.Percentage
																														}

																												%
																											</div>
																												</>
																											)}
																									</div>
																								);
																							}
																						)}
																						<div>
																							<div className="div-arrow" />
																						</div>
																					</div>
																				</>
																			) : (
																				<div
																					style={{
																						textAlign:
																							"center",
																						fontSize:
																							"12px",
																					}}
																				>
																					Test
																				Result:{" "}
																					{
																						card.result
																					}
																					<br />
																					<br />
																				Test
																				Comments:{" "}
																					{card.test_comments
																						? card.test_comments
																						: "-"}
																				</div>
																			)}
																	</div>
																</div>
															</Fragment>
														)}
													</div>
												</div>
											</div>
										</div>
									)
								)}
						</div>
					</div>
					<div className="line" />
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.UserReducer.userData,
	patient_id: state.PatientsReducer.patient_id
});

const mapDispatchToProps = (dispatch) => ({
	services: bindActionCreators(services, dispatch),
	PatientsActions: bindActionCreators(PatientsActions, dispatch),
});

PatientHistory = connect(mapStateToProps, mapDispatchToProps)(PatientHistory);

export default withTranslation()(PatientHistory);

