import React, { Component, Fragment } from "react";
import "./index.scss";
import ReaderIcon from "assets/img/readers/3_4-RIGHT.jpg";
import TrashIcon from "assets/img/readers/trash_icn.png";
import { withTranslation } from "react-i18next";
import { getDateByLanguage } from "helper/dateByLanguage";
import usageRedArrow from "assets/img/readers/arrowDownRed.png";
import usageGreenArrow from "assets/img/readers/arrowUpGreen.png";
import ReactTooltip from "react-tooltip";
import moment from "moment";

class HomeReaders extends Component {
	static defaultProps = {
		title: "",
		type: "",
		value: {
			first: "",
			second: "",
		},
	};

	render() {
		const {
			title,
			id,
			site_name,
			version,
			usage,
      update,
      created_at,
			mac_address,
			software,
			updated_at,
			hardware_last_version,
			tests,
			status,
			online,
			settings,
			serial_number,
			hardware,
			shipped_date,
			shipped_method,
			tracking_number,
		} = this.props.reader;

		const { dateSelected } = this.props;

		let valueColor = usage != null && usage > 0 ? "green" : "red";

		return (
			<div className="div-out-readers">
				<div className="div-readers">
					<div
						className="div-image b-none"
						onClick={() => this.props.onClick(settings)}
					>
						<img
							src={ReaderIcon}
							data-tip
							data-for={"box_reader_view_details"}
						/>
						<div className="status-container">
							{!!online && (
								<div className="displayFlex ali-center p5">
									<div className={"ball online"} />
									<div className="ball-text">
										{this.props.t("label_Online")}
									</div>
								</div>
							)}
							{/* {!online && status != "shipped" &&
                                <div className="displayFlex ali-center p5">
                                    <div className={'ball offline'} />
                                    <div className="ball-text">{this.props.t('Offline').toUpperCase()}</div>
                                </div>
                            } */}
							{status == "active" && (
								<div className="displayFlex ali-center p5">
									<div className={"ball online"} />
									<div className="ball-text">
										{this.props.t("label_StatusOk")}
									</div>
								</div>
							)}

							{(status == "inactive" ||
								status == "discarted") && (
								<div className="displayFlex ali-center p5">
									<div className={"ball notok"} />
									<div className="ball-text">
										{this.props.t("label_StatusNotOk")}
									</div>
								</div>
							)}

							{status == "shipped" && (
								<div className="displayFlex ali-center p5">
									<div className={"ball shipped"} />
									<div className="ball-text">
										{this.props
											.t("label_Shipped")
											.toUpperCase()}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="div-info">
						<div className="div-title">
							{!!serial_number ? serial_number + " GAZELLE" : "-"}
							<img
								onClick={() => this.props.deleteReader(id)}
								src={TrashIcon}
								className="icon-trash cp"
							/>
						</div>
						<div className="div-line" />
						<div className="displayFlex d-column jc-space h100">
							<div className="first-items">
								<>
									{!!site_name && (
										<div className="div-version">
											<b>
												{this.props
													.t("label_SiteName")
													.toUpperCase()}
												:
											</b>{" "}
											{site_name}
										</div>
									)}
									{!!hardware && (
										<div className="div-version">
											<b>
												{this.props
													.t("label_HardwareVersion")
													.toUpperCase()}
												:
											</b>{" "}
											{hardware}
										</div>
									)}
									{!!software && (
										<div className="div-version">
											<b>
												{this.props
													.t(
														"label_InstalledFirmwareVersion"
													)
													.toUpperCase()}
												:
											</b>{" "}
											{software}
										</div>
									)}
									<div className="div-version">
										<b>
											{this.props
												.t("label_FirmwareLastUpdate")
												.toUpperCase()}
											:
										</b>{" "}
										{getDateByLanguage(
											!!updated_at
												? updated_at
												: created_at,
											window.currentLanguage
										)}
									</div>
									{!!serial_number && (
										<div className="div-version">
											<b>
												{this.props
													.t("label_SerialNumber")
													.toUpperCase()}
												:
											</b>{" "}
											{serial_number}
										</div>
									)}
									{!!mac_address && (
										<div className="div-version">
											<b>
												{this.props
													.t("label_MacAddress")
													.toUpperCase()}
												:
											</b>{" "}
											{mac_address}
										</div>
									)}
								</>
								{!!shipped_date && (
									<>
										<div className="div-version">
											<b>
												{this.props.t(
													"label_ShippedDate"
												)}
												:
											</b>{" "}
											{shipped_date}
										</div>
										<div className="div-updated">
											<b>
												{this.props.t(
													"label_ShippedMethod"
												)}
												:
											</b>{" "}
											{shipped_method}
										</div>
										<div className="div-updated">
											<b>
												{this.props.t(
													"label_TrackingNumber"
												)}
												:
											</b>{" "}
											{tracking_number}
										</div>
									</>
								)}
							</div>
							{/* {!!shipped_date || !!hardware && */}
							<div className="div-stats">
								<div className="div-tests">
									{!!tests && (
										<>
											<div>
												{this.props.t("label_Tests")}
											</div>
											<div className="label-tests">
												{!!tests ? tests.length : "-"}
											</div>
										</>
									)}
								</div>
								{dateSelected.id != 0 && (
									<div className="div-usage">
										<>
											<div>{`${this.props.t(
												"label_UsageInPeriod"
											)}`}</div>
											{!!usage ? (
												<div
													className={
														"label-usage up d-flex " +
														valueColor
													}
												>
													<img
														src={
															valueColor ==
															"green"
																? usageGreenArrow
																: usageRedArrow
														}
														className="img-usage-period"
													/>
													{!!usage
														? `${Math.abs(usage)} %`
														: "-"}
												</div>
											) : (
												<div
													className={
														"label-tests no-changes"
													}
												>
													NO CHANGES
												</div>
											)}
										</>
									</div>
								)}
							</div>
							{/* } */}
							{(status == "inactive" || status == "shipped") && (
								<div
									className="div-not-connected"
									onClick={() =>
										this.props.setupReader(settings)
									}
								>
									<b>{this.props.t("label_NotConnected")}</b>
								</div>
							)}
						</div>
					</div>
					<ReactTooltip id="box_reader_view_details" type="dark">
						<span>
							{this.props.t("label_ClickToViewReaderDetails")}
						</span>
					</ReactTooltip>
				</div>
			</div>
		);
	}
}

export default withTranslation()(HomeReaders);
