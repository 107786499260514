import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import PageContainer from "components/pageContainer/pageContainer";
import { connect } from "react-redux";
import "./index.scss";
import Footer from "components/footer";
import SimpleBox from "components/simpleBox";
import { withTranslation } from "react-i18next";
import BoxReaders from "components/boxReaders";
import malaria from "assets/img/header-new/widgets_malaria_background_icon.png";
import hb from "assets/img/header-new/widgets_hbvariant_background_icon.png";
import * as services from "redux/reducers/ReaderReducer";
import Header from "features/user/header";
import HeaderAdmin from "features/admin/header";

import ReaderIcon from "assets/img/readers/3_4-RIGHT.jpg";
import GazelleStatusIcon from "assets/img/readers/gazelle_detail_check_icn.png";
import usageRedArrow from "assets/img/readers/arrowDownRed.png";
import usageGreenArrow from "assets/img/readers/arrowUpGreen.png";
import CloseIcon from "assets/img/close_invite_user_bg_icn.png";
import getDateTimeByLanguage, {
	getDateByLanguage,
	getHourByLanguage,
} from "helper/dateByLanguage";
import { bindActionCreators } from "redux";
import moment from "moment";
import CustomSelect from "components/customSelect/customSelectDiv";
class ReadersDetails extends Component {
	static propTypes = {
		prop: PropTypes,
	};

	state = {
		settings:
			this.props.reader && this.props.reader.settings
				? JSON.parse(this.props.reader.settings)
				: {},
		onhand: {},
		audit: { id: 0, value: this.props.t("label_AllPeriod") },
		error: false,
	};

	getDataNormalized = (paramName) => {
		return this.state.settings.hasOwnProperty(paramName)
			? this.state.settings[paramName]
			: "-";
	};
	componentWillMount = async () => {
		if (!this.props.reader || !this.props.reader.site_id) {
			return false;
		}
	};

	isAdmin = () => {
		return this.props.router.location.pathname.includes("/admin");
	};

	redirect = () => {
		if (this.props.reader && this.props.reader.site_id && this.isAdmin()) {
			if (!!this.props.reader.isSupport) {
				return this.props.router.history.push(
					`${process.env.REACT_APP_FRONT_URL}/admin`
				);
			}
			return this.props.router.history.push(
				`${process.env.REACT_APP_FRONT_URL}/admin/readers?id=${this.props.reader.site_id}`
			);
		}
		if (this.isAdmin()) {
			return (window.location.href = `${process.env.REACT_APP_FRONT_URL}/admin/`);
		}
		return this.props.router.history.push(
			`${process.env.REACT_APP_FRONT_URL}/readers`
		);
	};

	getDataSync = (tests) => {
		let testsSorted = tests.sort(
			(a, b) => new Date(b.applied_at) - new Date(a.applied_at)
		);
		const date = testsSorted[0].applied_at;
		return getDateByLanguage(
			moment(date, "YYYY-MM-DD HH:mm:ss"),
			window.currentLanguage
		);
	};

	getLog = async () => {
		try {
			await this.props.services.getAuditLog(
				this.state.audit.id,
				this.props.reader.serial_number
			);
		} catch (e) {
			this.setState({ error: true });
		}
	};
	render() {
		if (!this.props.reader) return this.redirect();
		const malariaLabelTest =
			this.props.reader.tests.filter((test) => test.disease_id == 1)
				.length > 1
				? this.props.t("label_Tests")
				: this.props.t("label_Test");
		const hbLabelTest =
			this.props.reader.tests.filter((test) => test.disease_id == 2)
				.length > 1
				? this.props.t("label_Tests")
				: this.props.t("label_Test");
		const { t } = this.props;

		let valueColor =
			this.props.reader.usage != null && this.props.reader.usage > 0
				? "green"
				: "red";

		let HeaderPage = this.props.header ? this.props.header : <Header />;
		let valueImage = null;

		if (valueColor == "green" && this.props.dateSelected.id != 0) {
			valueImage = usageGreenArrow;
		} else if (valueColor == "red" && this.props.dateSelected.id != 0) {
			valueImage = usageRedArrow;
		}

		return (
			<div className="reader-details-page">
				{this.props.userData.isAdmin == 2 ? (
					<HeaderAdmin {...this.props} />
				) : (
					<Header {...this.props} />
				)}
				<PageContainer>
					<div className="div-reader">
						<div>{t("label_ReaderDetails")}</div>
						<div className="iconClosePosition">
							<img
								onClick={() => this.redirect()}
								src={CloseIcon}
							/>
						</div>
					</div>
					<div className="div-reader-container">
						{this.props.reader.isSupport && (
							<div className="div-search">
								<div className="date">
									{`${t(
										"label_ReadersDiagnosticSupportCode"
									)} ${getDateByLanguage(
										moment().toDate(),
										window.currentLanguage
									)}`}
								</div>
								<div className="serial">
									{this.props.reader.serial_number}
								</div>
							</div>
						)}
						<div className="display-flex mt20 ci">
							<div className="div-title direction ci">
								<div className="title-big ci">
									{this.props.reader.serial_number
										? this.props.reader.serial_number +
										  " GAZELLE"
										: "-"}
								</div>
								<div className="line ci"></div>
							</div>
							<div className="div-text-read direction ci">
								<div className="title-reader ci">
									{t("label_ReaderSettings")}
								</div>
								<div className="line ci"></div>
							</div>
						</div>
						<div className="display-flex-informations ci">
							<div className="div-title">
								<div className="div-image-details">
									<div className="div-image ci">
										<img
											className="img-big"
											src={ReaderIcon}
										/>
										<div className="status-container">
											{!!this.props.reader.online && (
												<div className="displayFlex ali-center p5">
													<div
														className={
															"ball online"
														}
													/>
													<div className="ball-text">
														{this.props.t(
															"label_Online"
														)}
													</div>
												</div>
											)}
											{/* {!this.props.reader.online && this.props.reader.status != "shipped" &&
                                            <div className="displayFlex ali-center p5">
                                                <div className={'ball offline'} />
                                                <div className="ball-text">{this.props.t('Offline').toUpperCase()}</div>
                                            </div>
                                        } */}
											{this.props.reader.status ==
												"active" && (
												<div className="displayFlex ali-center p5">
													<div
														className={
															"ball online"
														}
													/>
													<div className="ball-text">
														{this.props.t(
															"label_StatusOk"
														)}
													</div>
												</div>
											)}

											{(this.props.reader.status ==
												"inactive" ||
												this.props.reader.status ==
													"discarted") && (
												<div className="displayFlex ali-center p5">
													<div
														className={"ball notok"}
													/>
													<div className="ball-text">
														{this.props.t(
															"label_StatusNotOk"
														)}
													</div>
												</div>
											)}
										</div>
									</div>
									<div className="div-description ci">
										<div>
											{this.props.reader.diseases.includes(
												1
											) && (
												<div className="mt10 ci">
													<BoxReaders
														image={malaria}
														title={t(
															"label_Malaria"
														)}
														label_UsageInPeriod
														serial_number={
															this.props.reader
																.serial_number
														}
														thirdItem={`${
															this.props.reader.tests.filter(
																(test) =>
																	test.disease_id ==
																	1
															).length
														} ${malariaLabelTest} ${t(
															"label_Run"
														).toUpperCase()}`}
														dataSync={
															this.props.reader.tests.filter(
																(test) =>
																	test.disease_id ==
																	1
															).length > 0
																? this.getDataSync(
																		this.props.reader.tests.filter(
																			(
																				test
																			) =>
																				test.disease_id ==
																				1
																		)
																  )
																: ""
														}
													/>
												</div>
											)}
											{this.props.reader.diseases.includes(
												2
											) && (
												<div className="mt20">
													<BoxReaders
														image={hb}
														title={t("label_Hb")}
														serial_number={
															this.props.reader
																.serial_number
														}
														thirdItem={`${
															this.props.reader.tests.filter(
																(test) =>
																	test.disease_id ==
																	2
															).length
														} ${hbLabelTest} ${t(
															"label_Run"
														).toUpperCase()}`}
														dataSync={
															this.props.reader.tests.filter(
																(test) =>
																	test.disease_id ==
																	2
															).length > 0
																? this.getDataSync(
																		this.props.reader.tests.filter(
																			(
																				test
																			) =>
																				test.disease_id ==
																				2
																		)
																  )
																: ""
														}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="displayFlex line-container">
									<div className="line cards"></div>
								</div>
								<div className="full-cards">
									<div className="w25">
										<SimpleBox
											boxWidth={"100"}
											text={t("label_RegistrationDate")}
											value={getDateByLanguage(
												this.props.reader.created_at,
												window.currentLanguage
											)}
										/>
									</div>
									<div className="w25 bg-grey fs14 div-usage-box">
										<div>
											<div
												style={{
													color: "#6f6f6f",
													textAlign: "center",
												}}
											>
												CHANGE IN PERIOD:
											</div>
											{!!this.props.reader.usage &&
											this.props.dateSelected.id != 0 ? (
												<div
													className={
														"label-usage up d-flex " +
														valueColor
													}
												>
													<img
														src={
															valueColor ==
															"green"
																? usageGreenArrow
																: usageRedArrow
														}
														className="img-usage-period"
													/>
													{!!this.props.reader.usage
														? `${this.props.reader.usage} %`
														: "-"}
												</div>
											) : (
												this.props.dateSelected.id ==
													0 &&
												this.props.reader.usage !=
													0 && (
													<div
														className={
															"label-tests"
														}
														style={{
															color: "#6f6f6f",
														}}
													>
														NOT APPLICABLE
													</div>
												)
											)}
											{this.props.reader.usage == 0 && (
												<div
													className={
														"label-tests no-changes"
													}
												>
													NO CHANGES
												</div>
											)}
										</div>
									</div>
									<div className="w25">
										<SimpleBox
											boxWidth={"100"}
											text={"SITE"}
											value={this.props.reader.site_name}
										/>
									</div>
								</div>
							</div>
							<div className="div-text-read">
								<div className="div-details">
									<div className="mt30">
										{this.props.t("label_ProductNumber")}:{" "}
										{this.props.reader.product_number}
									</div>
									<div className="mt30">
										{this.props.t("label_SerialNumber")}:{" "}
										{this.props.reader.serial_number}
									</div>
									<div className="mt30">
										HARDWARE VERSION:{" "}
										{this.props.reader.hardware
											? this.props.reader.hardware
											: "N/A"}
									</div>
									<div className="mt30">
										{this.props
											.t("label_InstalledFirmwareVersion")
											.toUpperCase()}{" "}
										:{" "}
										{this.props.reader.software
											? this.props.reader.software
											: "N/A"}
									</div>
									<div className="mt30">
										{`${this.props.t(
											"tableHeaderLastUpdatedDate"
										)} ${getDateByLanguage(
											!!this.props.reader.updated_at
												? this.props.reader.updated_at
												: this.props.reader.created_at,
											window.currentLanguage
										)}`}
									</div>
									{/* <div className="mt30 dflex">
										AUDIT LOG:
										<div className="slc-contianer">
											<CustomSelect
												options={[
													{
														id: 7,
														value: this.props.t(
															"label_LastWeek"
														),
													},
													{
														id: 30,
														value: this.props.t(
															"label_LastMonth"
														),
													},
													{
														id: 0,
														value: this.props.t(
															"label_AllPeriod"
														),
													},
												]}
												onChange={(audit) =>
													this.setState({
														audit,
														error: false,
													})
												}
												value={this.state.audit.value}
											/>
										</div>
									</div> */}
									{/* <div className="download-container">
										<div
											className={`download-audit-log true`}
											onClick={() => this.getLog()}
										>
											DOWNLOAD
										</div>
									</div> */}
									{/* {this.state.error && (
										<div className="download-container">
											<div
												className={`download-audit-log false`}
											>
												FILE NOT FOUND
											</div>
										</div>
									)} */}
								</div>
							</div>
						</div>
					</div>
				</PageContainer>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.UserReducer.userData,
	reader: state.ReadersReducer.READER_SELECTED,
	dateSelected:
		state.ReadersReducer.SELECTED_DATE.id == 0
			? state.ReadersReducer.FILTER_DATE[window.currentLanguage][0]
			: state.ReadersReducer.SELECTED_DATE,
});

const mapDispatchToProps = (dispatch) => ({
	services: bindActionCreators(services, dispatch),
});

ReadersDetails = connect(mapStateToProps, mapDispatchToProps)(ReadersDetails);

export default withTranslation()(ReadersDetails);
