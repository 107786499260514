const translations = {
    label_AddNewReader: "ADICIONAR NOVO LEITOR",
    label_PowerUp: "LIGUE SEU LEITOR GAZELLE",
    label_ClinicalTest: "DIGITE A INFORMAÇÃO CLINICA",
    label_MacValidation: "VALIDAÇÃO DO ENDEREÇO MAC",
    label_ConnectWifi: "CONECTAR NO WIFI",
    label_TurnOn: "Ligue seu",
    label_GazelleReader: "Leitor Gazelle",
    label_Press: "PRESSIONE O",
    label_Settings: "CONFIGURAÇÕES",
    label_Icon: "ICONE",
    label_Admin: "ADMINISTRADOR",
    label_EnterLogin: "ENTRE COM O LOGIN",
    label_Credentials: "ENTRE COM AS CREDENCIAIS DE USUARIO E SENHA",
    label_ScrollButton: "BOTÃO DE ROLAR A PAGINA",
    label_Once: "DEPOIS",
    label_AboutDevice: "SOBRE O DISPOSITIVO",
    label_Button: "BOTÃO",
    label_Identify: "IDENTIFIQUE O",
    label_MacAddress: "MAC DO DISPOSITIVO",
    label_EnterMac: "DIGITE O ENDERECO MAC",
    label_Shown: "MOSTRADO NO READER",
}

export default translations;