import React, { Component } from 'react'
import CustomModal from 'components/customModal'
import CustomButton from 'components/customButton'
import { withTranslation } from 'react-i18next';

import './index.scss';

class ExternalDataShare extends Component {
    render() {
        return (
            <div className="external-data-share-container">
                <CustomModal open={this.props.open} title="EXTERNAL DATA SHARE" onClose={() => this.props.onClose()}>
                    <div className="external-data-intern">
                        <div className='img-container'>
                            <img src="https://hemex-dev.s3.amazonaws.com/logo_gazelle.png" />
                        </div>
                        <div className="external-data-subtitle">
                            <div>Hi KEVIN WILIAMS </div>
                            <div>{this.props.t('invite_eds')}</div>
                        </div>
                        <div className="displayFlex btns">
                            <div className="w50 cbleft">
                                <CustomButton value={this.props.t('decline_eds')} />
                            </div>
                            <div className="w50 right">
                                <CustomButton value={this.props.t('accept_eds')} />
                            </div>
                        </div>
                        <div className='external-data-email'>
                            This email was sent by Hemex Health, 4640 SW Macadam Avenue, Suite 250, Portland, OR 97239
                        </div>
                        <div className='external-data-email'>
                            You are receiving this email because you
                            <br />
                            Have been invited to register with HemexHealth.com with this email address.
                        </div>
                        <div className="external-data-email">
                            Copyright ® 2019 Hemex Health. All rights reserved.
                        </div>
                        <div className="w40 displayFlex jc-center mauto">
                            <CustomButton value={this.props.t('label_SendRequest')} handleChange={this.props.send} />
                        </div>
                    </div>
                </CustomModal>
            </div>
        )
    }
}

export default withTranslation()(ExternalDataShare)