import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import backgroundIndian from "../../assets/img/bg_sign_in_img.jpg";
import backgroundBrazil from "../../assets/img/bg_pt_hemex_login_img.jpg";
import styled from "styled-components";


const Container = styled.div``;

export default class PageContainer extends Component {

  componentDidMount() {
    const element = document.getElementById("page-login")
    if (element) {
      if (window.currentLanguage == "ptBR") {
        element.style.backgroundImage = `url(${backgroundBrazil})`
      } else {
        element.style.backgroundImage = `url(${backgroundIndian})`
      }
    }
  }
  render() {
    const { page } = this.props;
    return (
      <Container className={page == 'login' ? 'page-login' : 'page-container'} id={page == "login" ? "page-login" : "page-container"}>
        {this.props.children}
      </Container>
    )
  }
}
