import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./index.scss";

class BoxSystem extends Component {
  render() {
    let imageBg = this.props.image ? this.props.image : '';
    let imgSize = this.props.imgSize ? this.props.imgSize : '';
    let boxWidth = this.props.boxWidth ? 'w' + this.props.boxWidth : 'w25';
    return (
      <div className="divBox" style={{ backgroundImage: 'url("' + imageBg + '")', backgroundSize: imgSize, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom left' }} onClick={this.props.onClick}>
        <div className="firstDiv">
          <div className="title">{this.props.title}</div>
          <div className="number">{this.props.defaultNumber}</div>
        </div>
        <div className="secoundDiv">
          <div className="greenNumber">{this.props.greenNumber}</div>
          <div className="orangeNumber">{this.props.orangeNumber}</div>
        </div>
      </div>
    );
  }
}

export default BoxSystem;
