import { ThemeConsumer } from "styled-components";


class RedirectManager {
  constructor() {
    this.url = window.location.pathname
  }

  redirect(enumStatus, enumRole, userStatus, userRole, tokenStatus) {

    if (tokenStatus == "expired") return "password-expired";

    switch (userStatus) {
      case enumStatus.active:
        return userRole == enumRole.admin ? "/admin/" : "/"
      case enumStatus.register:
      case enumStatus.wizard:
        return "/wizard"
      case enumStatus.terms:
        return "/terms"
      default:
        return "/login"
    }
  }

  middleUserPrivateRoutes(userRole, adminPermission) {
    if (userRole == adminPermission) throw (`${process.env.REACT_APP_FRONT_URL}/admin`)
  }

  middleAdminPrivateRoutes(userRole, adminPermission) {
    if (userRole != adminPermission) throw (`${process.env.REACT_APP_FRONT_URL}/`)
  }

  middleEds(eds_status, eds_hash, role_id) {
    if (eds_hash && eds_status == "invited" && role_id == 2 && !this.url.includes('eds')) {
      throw `${process.env.REACT_APP_FRONT_URL}/eds/accepted/${eds_hash}`
    }
  }

  middleWizard(userStatus) {
    if (!this.url.includes('/wizard') && ["R", "W"].includes(userStatus)) throw `${process.env.REACT_APP_FRONT_URL}/wizard`
  }

  middleTerms(userStatus) {
    if (!this.url.includes('/terms') && userStatus == "C") throw `${process.env.REACT_APP_FRONT_URL}/terms`
  }

  middleSystemAdmin(role_id) {
    if (this.url.includes('/system-admin') && role_id != 2) throw `${process.env.REACT_APP_FRONT_URL}/`
  }
}

export default () => {
  return new RedirectManager()
}