import * as Yup from 'yup';

const validations =
    Yup.object().shape({
        site: Yup.object()
            .required('Required'),
        product_number: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        serial_number: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
    })

export default validations;