import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import masks from 'helper/masks'
import uuidv4 from 'uuid/v4'

class CustomInput extends Component {
  static propTypes = {
    onChangeCallback: PropTypes
  };

  static defaultProps = {
    labelClassName: "customInputLabel",
    inputContainerClassName: "customInputContainer",
    inputClassName: "customInput",
    value: "",
    placeHolder: "",
    max: 25
  };

  state = {
    placeHolder: "",
    id: uuidv4()
  }

  componentDidMount() {
    this.setState({ placeHolder: this.props.placeHolder || "" })
  }
  renderLabel = () => <label>{this.props.label}</label>;

  handleChange = value => {
    return this.props.onChange ? this.props.onChange(value) : null;
  };

  render() {

    const inputClass = this.props.class ? `customInput ${this.props.class}` : 'customInput'
    const { label, placeHolder, value, children, mask, maskChar } = this.props;

    return (
      <div className="customInputContainer">
        {label && label.length > 0 &&
        <div className={'customInputLabel'}>
          {label && this.renderLabel()}
        </div>
        }
        {children ? children : mask ?
          <InputMask
            className="customInput"
            mask={this.props.mask || null}
            onChange={e => this.props.onChange(e.target.value)}
            maskChar={maskChar ? maskChar : null}
          />
          :
          <input
            autocomplete={this.state.id}
            id={this.props.id || this.state.id}
            minlength={this.props.min ? this.props.min : null}
            maxlength={this.props.max ? this.props.max : null}
            type={this.props.type ? this.props.type : 'text'}
            onClick={() => this.setState({ placeHolder: "" })}
            className={inputClass}
            placeholder={this.state.placeHolder}
            value={value}
            disabled={this.props.disabled}
            onChange={e => this.handleChange(e.target.value)}
          />
        }
      </div>
    );
  }
}

export default CustomInput;
