import React, { Component, Fragment } from "react";
import "./index.scss";
import addButton from "assets/img/header-new/widgets_cartridges_add_cartridge.png";
import { withTranslation } from 'react-i18next';
import ReactToolTip from "react-tooltip";
class ManagementPortalCard extends Component {
  static defaultProps = {
    title: "",
    type: "",
    value: {
      first: "",
      second: ""
    },
    // search: ''
  };

  state = {
    search: ''
  }
  render() {
    const { title, value, type, image, flex, search, searchError, onSearch, removeError } = this.props;

    return (
      <div
        className={`card-managementPortalCard-container flex${flex}`}
        onClick={this.props.onClick}
      >
        <div
          className="card-background"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="card-managementPortalCard-container-infos">
            <div className="card-managementPortalCard-container-info-title">
              {title}
            </div>
            {!search &&
              <div className="card-managementPortalCard-container-info-data-container">
                <div
                  className={
                    "card-managementPortalCard-container-info-data-container-item-first " +
                    type
                  }
                >
                  {value.first}
                </div>
                <div
                  className={
                    "card-managementPortalCard-container-info-data-container-item-second " +
                    type
                  }
                >
                  {value.second}
                </div>
              </div>
            }
            {search &&
              <div className="card-managementPortalCard-container-info-search-container">
                <div className='searchInputContainer'>
                  <div className='box'>
                    <div className='box-input'>
                      <input
                        className={
                          "card-search-input"
                        }
                        onChange={e => {
                          this.setState({ search: e.target.value });
                          if (!!searchError) {
                            removeError()
                          }
                        }}
                        value={this.state.search}
                      />
                      {searchError &&
                        <div className="card-search-input error">
                          {this.props.t('label_ReaderNotFound').toUpperCase()}
                        </div>
                      }
                    </div>
                    <div
                      className={`card-search-btn ${this.state.search.length == 0 || searchError ? 'disabled' : ''}`}
                      onClick={() => onSearch(this.state.search)}
                    >
                      {this.props.t('label_Search').toUpperCase()}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          {this.props.addButton && (
            <div className="card-managementPortalCard-container-image-add">
              <img
                className="card-managementPortalCard-container-image-add-link"
                src={addButton}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(ManagementPortalCard);