import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import SystemAdminReducer from "./SystemAdminReducer";
import ManagementPortalReducer from "./ManagementPortalReducer";
import PatientsReducer from "./PatientsReducer";
import SiteManagementReducer from "./SiteManagementReducer";
import SubHeaderReducer from "./SubHeaderReducer";
import ReaderReducer from "./ReaderReducer";
import DeviceManagementReducer from "./DeviceManagementReducer";
import SiteManagementAdminReducer from './SiteManagementAdminReducer'
import ManagementPortalAdminReducer from "./ManagementPortalAdminReducer";
import UserManagementReducer from "./UserManagementReducer";
import VerifyReaderReducer from './VerifyReaderReducer';
import ReadersReducer from "./ReadersReducer";

import SystemAdminSiteManagementReducer from "features/systemAdmin/siteManagement/redux/reducer"
import AdminUserManagementReducer from 'features/systemAdmin/adminView/userManagement/redux/reducer'
import AdminManagement from 'features/systemAdmin/adminView/adminManagement/redux/reducer'


export default combineReducers({ ManagementPortalReducer, ManagementPortalAdminReducer, DeviceManagementReducer, ReaderReducer, SubHeaderReducer, SiteManagementReducer, PatientsReducer, ReadersReducer, UserManagementReducer, SystemAdminSiteManagementReducer, SiteManagementAdminReducer, AdminUserManagementReducer, VerifyReaderReducer, UserReducer, AdminManagement, SystemAdminReducer });